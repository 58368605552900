import { Autocomplete, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaSearch, FaSearchMinus, FaTimes, FaTrash } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../auth/UseUser';
import { deleteNationalMeasureRelation, getNationalMeasureRelations, getNationalMeasureRelationTypes, insertNationalMeasureRelation } from '../../../../server/server';
import ChooseEuRegisterModal from '../../../modals/ChooseEuRegisterModal';
import ChooseNatRegisterModal from '../../../modals/ChooseNatRegisterModal';
import { notif } from '../../../utils/util';

function NatRegisters() {
	const { user } = useUser();
	const [searchParams] = useSearchParams();
	const [relations, setRelations] = useState<any>([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [items, setItems] = useState<any>([]);
	const [selRegister, setSelRegister] = useState<any>(null);
	const [selRelation, setSelRelation] = useState<any>(null);
	const [registerType, setRegisterType] = useState('');
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(6);
	const [rowCountState, setRowCountState] = useState(6);
	const [isLoading, setIsLoading] = useState(true);
	const [editId, setEditId] = useState('');
	const closeModal = () => {
		setModalIsOpen(false);
	};

	const loadRelationTypes = async () => {
		const resp = await getNationalMeasureRelationTypes();

		if (resp?.status === 200) {
			setRelations(resp?.data);
		}
	};

	useEffect(() => {
		loadRelationTypes();
		let eId = searchParams.get('id');
		if (eId) {
			setEditId(eId);
			loadRelations(0, eId);
		}
	}, []);

	const createRelation = async () => {
		const resp = await insertNationalMeasureRelation({
			parentMeasure: parseInt(editId),
			childMeasure: selRegister?.id,
			relationId: selRelation?.id,
		});

		if (resp.status === 201) {
			notif('Релација је успешно креирана', 'success');
			loadRelations(0, editId);
			setPage(0);
			setSelRegister(null);
			setSelRelation(null);
		} else {
			notif('Релација није обрисана креирана', 'error');
		}
	};
	const TYPE_MAP = {
		'Планирани пропис': 0,
		'Постојећи пропис': 1,
	};

	// const handleAdd = () => {
	//   setItems([
	//     ...items,
	//     {
	//       id: '1',
	//       relation: selRelation,
	//       register: selRegister?.title,
	//       pid: selRegister?.pid,
	//       type: registerType,
	//       user: user?.username,
	//     },
	//   ]);
	// };

	const deleteRelation = async (id: any) => {
		const resp = await deleteNationalMeasureRelation(id);

		if (resp.status === 200) {
			notif('Релација је успешно обрисана', 'success');
		} else {
			notif('Релација није обрисана обрисана', 'error');
		}
	};

	const deleteItem = React.useCallback(
		(id: GridRowId) => () => {
			deleteRelation(id);
			setTimeout(() => {
				setItems((prevRows: any) => prevRows.filter((row: any) => row.id !== id));
			});
		},
		[]
	);

	const loadRelations = async (offset: any = 0, pid: any) => {
		const resp = await getNationalMeasureRelations(offset, pageSize, pid);

		if (resp?.status === 200) {
			setItems(resp.data?.relations);
			setRowCountState(resp?.data?.total);
			setIsLoading(false);
		}
	};
	const setPageAndCall = (page: any) => {
		setPage(page);
		loadRelations(page * pageSize, editId);
	};
	const columns = React.useMemo<GridColumns<any>>(
		() => [
			{
				field: 'relation',
				headerName: 'Тип релације',
				flex: 1,
				maxWidth: 250,
				minWidth: 250,
			},
			{
				field: 'title',
				headerName: 'Назив националног прописа',
				flex: 1,
			},
			{
				field: 'pid',
				headerName: 'Ознака прописа',
				maxWidth: 140,
				minWidth: 140,
				flex: 1,
			},

			{
				field: 'username',
				headerName: 'Корисник',
				maxWidth: 140,
				minWidth: 140,
				flex: 1,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 60,
				getActions: (params: any) => [<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20 }} />} sx={{ width: 50, height: 50 }} label='Обриши' onClick={deleteItem(params.id)} />],
			},
		],
		[deleteItem]
	);

	return (
		<div className='pt-4'>
			<div className='flex mt-6 gap-6 justify-between'>
				<div className='flex items-center gap-6'>
					<FormControl size='small' sx={{ minWidth: 270 }}>
						<InputLabel id='demo-select-small'>Тип Релације</InputLabel>
						<Select labelId='demo-select-small' id='demo-select-small' value={selRelation?.parentDescription || ''} onChange={(e) => setSelRelation(relations?.find((r: any) => r?.parentDescription === e.target.value))} label='Тип Релације' fullWidth className='capitalize'>
							{relations?.map((c: any) => {
								return (
									<MenuItem value={c?.parentDescription} className='capitalize' key={c?.id}>
										{c?.parentDescription}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
				<div className='flex items-center gap-6'>
					<FormControl size='small' sx={{ minWidth: 270 }}>
						<InputLabel id='demo-select-small'>Веза са</InputLabel>
						<Select labelId='demo-select-small' id='demo-select-small' value={registerType || ''} onChange={(e) => setRegisterType(e.target.value)} label='Веза са' fullWidth className='capitalize'>
							{['Планирани пропис', 'Постојећи пропис']?.map((c) => {
								return (
									<MenuItem value={c} className='capitalize' key={c}>
										{c}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>

				<div className='flex flex-col'>
					<button className='h-[40px] w-[350px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer' onClick={() => setModalIsOpen(true)}>
						{selRegister ? (
							<div className='flex px-4 justify-start text-left items-center'>
								<FaSearchMinus className='fill-[#404040]' />
								<p className='text-darkBlue w-full pl-3'>{selRegister?.title?.length > 30 ? selRegister?.title?.substring(0, 30) + '...' : selRegister?.title}</p>
							</div>
						) : (
							<div className='flex px-4 justify-between items-center'>
								<FaSearch className='fill-[#404040]' />
								<p className='text-[#404040] w-full pl-3'>Одабир Националног прописа</p>
							</div>
						)}
					</button>
				</div>
			</div>
			<div className='w-full flex justify-end mt-6 items-center'>
				<Button variant='contained' sx={{ backgroundColor: '#0F2765' }} onClick={createRelation}>
					Потврди
				</Button>
			</div>
			{items?.length > 0 && (
				<div>
					<div className='text-[#04438b]'>Повезаност са националним прописом</div>
					<div className='mt-5 h-[430px] w-full'>
						<DataGrid
							rowCount={rowCountState}
							loading={isLoading}
							pagination
							disableColumnMenu
							paginationMode='server'
							onPageChange={(newPage) => setPageAndCall(newPage)}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							page={page}
							pageSize={pageSize}
							rows={items}
							columns={columns}
							style={{ zIndex: 0 }}
							rowsPerPageOptions={[5]}
							disableSelectionOnClick
							components={{
								NoRowsOverlay: () => (
									<Stack height='100%' alignItems='center' justifyContent='center'>
										Не постоје NPAA поглавља
									</Stack>
								),
								NoResultsOverlay: () => (
									<Stack height='100%' alignItems='center' justifyContent='center'>
										Не постоје NPAA поглавља
									</Stack>
								),
							}}
						/>
					</div>
				</div>
			)}
			<ChooseNatRegisterModal modalIsOpen={modalIsOpen} closeModal={closeModal} setSelRegister={setSelRegister} type={TYPE_MAP[registerType as keyof typeof TYPE_MAP]} modalTitle={'Одабир домаћег прописа '} />
		</div>
	);
}

export default NatRegisters;
