import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaQuestionCircle, FaSave, FaUpload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useDataImport from '../../../server/sse';
import { formatCyDate, notif } from '../../utils/util';
import { getEuStats } from '../../../server/server';
import HelpPageModal from '../../modals/HelpPageModal';

function EuInsert() {
  const navigate = useNavigate();
  const [selSet, setSelSet] = useState<any>(null);
  const [importStarted, setImportStarted] = useState(false);
  const [helpModal, setHelpModal] = useState(false);

  const [stats, setStats] = useState<any>({
    date: '2023-04-16T21:09:55.731Z',
    groupNo: 0,
    groupSetName: '',
    name: '',
    totalRows: 0,
    updatedRows: 0,
    addedRows: 0,
  });
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const { progress, startImport, finish } = useDataImport({
    set: parseInt(selSet),
    onProgressStart: () => setImportStarted(true),
  });
  const handleSubmit = () => {
    if (selSet) {
      setImportStarted(true);
      startImport();
    }
  };

  const loadEuStats = async () => {
    const resp = await getEuStats();

    if (resp.status === 200) {
      setStats(resp?.data);
    }
  };

  useEffect(() => {
    if (finish) {
      setImportStarted(false);
      notif('Успешно увезено ' + progress + ' редова', 'success');
      loadEuStats();
    }
  }, [finish]);

  useEffect(() => {
    loadEuStats();
  }, []);

  const eventMessage = () => {
    if (progress) {
      return (
        <div className="flex gap-2">
          <p>Учитано </p> <p className="text-[#fbdb3e]">{progress}</p>
        </div>
      );
    } else {
      return <p>Процес увоза је започет</p>;
    }
  };
  return (
    <div
      className="py-10  px-2 overflow-auto gray-small-scroll h-full"
      key={finish ? 1 : 0}
    >
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">Унос ЕУ прописа</h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => setHelpModal(true)}
        >
          <p className="rounded-full   flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>
      <div className="flex w-full gap-6">
        <div className="rounded-lg border border-[#81848f3d] w-[500px] p-6 pb-4  mt-8 flex flex-col justify-between">
          <h1 className="text-[#04438bae] text-3xl font-bold mb-6">
            Увоз европских прописа
          </h1>
          <FormControl size="small" sx={{ minWidth: 310 }}>
            <InputLabel id="demo-select-small">Груписано по</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={selSet ?? ''}
              size="small"
              label="Груписано по"
              onChange={(e) => setSelSet(e.target.value)}
            >
              <MenuItem value="">
                <em>Одабир</em>
              </MenuItem>
              <MenuItem key="2" value="2">
                <strong>2.</strong> Directory
              </MenuItem>
              <MenuItem key="84" value="84">
                <strong>84.</strong> Screening new
              </MenuItem>
            </Select>
          </FormControl>
          <div className="w-full flex justify-end mt-12 items-center">
            {importStarted ? (
              <div className="flex items-center gap-4">
                <div className="w-[260px] text-[#04438b] rounded-lg h-[40px] gap-2 flex items-center justify-center">
                  {/* {eventMessage()}
                  <CircularProgress
                    style={{ height: 20, width: 20, color: '#fbdb3e' }}
                  /> */}
                  Увоз покренут, молимо сачекајте пар минута и освежите страницу
                </div>
              </div>
            ) : (
              <Button
                variant="contained"
                sx={{ backgroundColor: '#1D4B8E' }}
                endIcon={<FaUpload />}
                onClick={handleSubmit}
              >
                Потврди
              </Button>
            )}
          </div>
        </div>
        <div className="rounded-lg border border-[#81848f3d] w-[500px] p-6 pb-4 mt-8">
          <h1 className="text-[#04438bae] text-3xl font-bold mb-6">
            Статистика и хронологија увоза
          </h1>

          <p className="text-[#0a395bbd] text-lg font-medium mb-2">
            Последњи пут увезено:{' '}
            <span className="font-bold">{formatCyDate(stats?.date)}</span>
          </p>
          <p className="text-[#0a395bbd] text-lg font-medium mb-2">
            Груписано по:{' '}
            <span className="font-bold">{stats?.groupSetName}</span>
          </p>
          <p className="text-[#0a395bbd] text-lg font-medium mb-2">
            Увоз извршио корисник:{' '}
            <span className="font-bold">{stats?.name}</span>
          </p>
          <p className="text-[#0a395bbd] text-lg font-medium mb-2">
            Укупнан број редова са сервера:{' '}
            <span className="font-bold">{stats?.totalRows}</span>
          </p>
          <p className="text-[#0a395bbd] text-lg font-medium mb-2">
            Укупан број додатих редова:{' '}
            <span className="font-bold">{stats?.addedRows}</span>
          </p>
          <p className="text-[#0a395bbd] text-lg font-medium mb-2">
            Укупан број измењених редова:{' '}
            <span className="font-bold">{stats?.updatedRows}</span>
          </p>
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'insertEU'}
      />
    </div>
  );
}

export default EuInsert;
