import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  FaLock,
  FaUserAlt,
  FaUserEdit,
  FaUserLock,
  FaUserPlus,
  FaUsers,
  FaUserTie,
} from 'react-icons/fa';
import { login, whoAmI } from '../../server/server';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import { ReactComponent as Grb } from '../../assets/svg/srbija-grb.svg';
import { dividerClasses } from '@mui/material';
const theme = createTheme({
  typography: { fontFamily: 'Fira Sans Condensed' },
});

export default function SignIn() {
  let navigate = useNavigate();
  const { setUser } = useUser();

  const [errorMessage, setErrorMessage] = React.useState('');
  const [datas, setDatas] = React.useState<any>(null);
  const [screen, setScreen] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const resp = await login({
      username: data.get('username') as string,
      password: data.get('password') as string,
    });
    if (resp?.status === 201) {
      const data = resp.data;
      await localStorage.setItem('accessToken', data['accessToken']);

      const response = await whoAmI();
      if (response.status === 200) {
        setScreen(true);
        setDatas(response?.data);
      } else {
        console.log('who am i failed ', response.status);
      }
    } else {
      setErrorMessage(resp?.data?.message);
    }
  };
  const handleRolePick = async (role: any) => {
    setUser({ ...datas, roleView: role });
    console.log({ ...datas, roleView: role });
    await localStorage.setItem(
      'user',
      JSON.stringify({ ...datas, roleView: role })
    );
  };
  const ROLE_ICON_MAP = {
    Администратор: <FaUserTie className=" " />,
    Координатор: <FaUserAlt className="" />,
    'Уносилац података': <FaUserEdit className="w-5 h-5" />,
    'Секретар преговарачке групе': <FaUsers className="w-5 h-5" />,
    'Одговорна особа': <FaUserLock className="w-5 h-5" />,
  };

  React.useEffect(() => {
    if (screen && datas?.roles?.length === 1) {
      handleRolePick(datas?.roles[0]);
    }
  }, [screen]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 22,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="flex">
            <Grb />
            <div className="flex flex-col justify-between my-2 ml-3">
              <span className="font-medium text-base">Република Србија</span>
              <span className="text-xl">
                Министарство за <br /> европске интеграције
              </span>
            </div>
          </div>
          {screen && datas?.roles?.length != 1 ? (
            <div className="flex flex-col items-center">
              <h1 className="text-2xl mt-12 text-darkBlue text-center">
                Одабир надлежности
              </h1>
              <div className="flex flex-wrap w-[490px] justify-between gap-y-2 mt-4 ">
                {datas?.roles?.map((r: any) => {
                  return (
                    <div
                      className="w-[240px] h-[100px] border gap-3 text-[#0f2765d2] border-darkBlue rounded-lg flex flex-col items-center justify-center hover:bg-darkBlue hover:text-white cursor-pointer"
                      onClick={() => handleRolePick(r)}
                      key={r?.name}
                    >
                      {ROLE_ICON_MAP[r?.name as keyof typeof ROLE_ICON_MAP]}
                      {r?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div>
              <p className=" text-darkBlue  mt-12">
                Национални програм за усвајање правних тековина ЕУ
              </p>
              <h1 className="text-2xl text-darkBlue text-center">
                Пријављивање за рад
              </h1>

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Корисничко име"
                  name="username"
                  autoComplete="username"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Лозинка"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Typography component="p" sx={{ color: 'error.main' }}>
                  {errorMessage}
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: '#0F2765' }}
                >
                  Пријави се
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
