import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getHelpList } from '../../server/server';

function Help() {
  let navigate = useNavigate();
  const location = useLocation();
  const [term, setTerm] = useState('');
  const [constMenu, setConstMenu] = useState<any>();

  const [menuList, setMenuList] = useState<any>();

  const loadHelpList = async () => {
    const resp = await getHelpList();

    if (resp?.status === 200) {
      setConstMenu(resp?.data);
      setMenuList(resp?.data);
    }
  };

  useEffect(() => {
    if (term) {
      const fML = [] as any;
      const newML = constMenu?.filter((s: any) => {
        const t = s?.pages?.filter((item: any) =>
          item?.title?.toLowerCase()?.includes(term?.toLowerCase())
        );
        console.log('tetetetete', t);
        fML.push({ ...s, pages: t });
        return t;
      });
      console.log(term, fML);
      setMenuList(fML);
    } else {
      setTerm('');
      setMenuList(constMenu);
    }
  }, [term]);

  const handleTermDelete = () => {
    if (term) {
      setTerm('');
      setMenuList(constMenu);
    }
  };

  useEffect(() => {
    loadHelpList();
  }, []);

  return (
    <div className=" w-full h-full flex flex-row justify-start">
      <div className="h-full bg-[#1e3f940a] w-[320px] min-w-[180px] my-5 first:mt-0 overflow-auto gray-small-scroll">
        <div className="w-full flex items-center justify-center mt-6">
          <TextField
            label="Претрага упутства"
            id="outlined-size-small"
            type="helpSearch"
            name="helpSearch"
            sx={{ width: 220 }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleTermDelete}
                  className="cursor-pointer min-h-[35px]"
                >
                  {term ? <FaTimes /> : <FaSearch />}
                </InputAdornment>
              ),
            }}
            size="small"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
          />
        </div>

        {menuList?.[0]?.name &&
          menuList?.map((section: any) => {
            return (
              <div key={section?.name}>
                <div className="flex items-center pl-6 mt-6 py-3 text-gray-500 font-semibold mx-6 uppercase text-sm text-[#1e4094]">
                  {section?.name}
                </div>
                {section?.pages?.map((sub: any) => {
                  console.log('?id=' + sub?.id, location?.search);
                  return (
                    <div
                      key={sub?.title}
                      onClick={() => navigate(`/help/page?id=${sub?.name}`)}
                      className={`flex items-center cursor-pointer py-1 pl-6  mx-6 my-2  rounded-md ${
                        '?id=' + sub?.name === location?.search
                          ? 'text-white bg-[#0F2765]'
                          : 'hover:text-white hover:bg-[#0F2765] text-gray-300'
                      }`}
                    >
                      {sub?.title}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      <div className=" w-full h-full overflow-y-scroll gray-small-scroll help-page pb-4">
        <Outlet />
      </div>
    </div>
  );
}

export default Help;
