import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaSearch, FaSearchMinus, FaTimes, FaTrash } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../auth/UseUser';
import {
  deleteNationalMeasureRelation,
  getNationalMeasureRelations,
  getNationalMeasureRelationTypes,
  insertNationalMeasureRelation,
  measureAddNationalMeasure,
  measureListNationalMeasures,
  measureRemoveNationalMeasure,
} from '../../../../server/server';
import ChooseEuRegisterModal from '../../../modals/ChooseEuRegisterModal';
import ChooseNatRegisterModal from '../../../modals/ChooseNatRegisterModal';
import { notif } from '../../../utils/util';
import ConfirmationModal from '../../../modals/ConfirmationModal';

function NatMeasureRegisters() {
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const [relations, setRelations] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [selRegister, setSelRegister] = useState<any>(null);
  const [selRelation, setSelRelation] = useState<any>(null);
  const [registerType, setRegisterType] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [items1, setItems1] = useState<any>([]);

  const [page1, setPage1] = useState(0);
  const [pageSize1, setPageSize1] = useState(6);
  const [rowCountState1, setRowCountState1] = useState(6);
  const [isLoading1, setIsLoading1] = useState(true);
  const [editId, setEditId] = useState('');
  const closeModal = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    let eId = searchParams.get('id');
    if (eId) {
      setEditId(eId);
      loadRelations(parseInt(eId));
    }
  }, []);

  const createRelation = async () => {
    setIsLoading(false);
    const resp = await measureAddNationalMeasure(
      parseInt(editId),
      selRegister?.id
    );

    if (resp.status === 201) {
      notif('Релација је успешно креирана', 'success');
      loadRelations(parseInt(editId));
      setPage(0);
      setSelRegister(null);
      setSelRelation(null);
    } else {
      notif('Релација није  креирана', 'error');
    }
  };

  const deleteRelation = async () => {
    const resp = await measureRemoveNationalMeasure(deleteId);

    if (resp.status === 200) {
      notif('Релација је успешно обрисана', 'success');
      loadRelations(editId);
    } else {
      notif('Релација није обрисана обрисана', 'error');
    }
  };
  const deleteRel = () => {
    deleteRelation();
    setDeleteModalIsOpen(false);
  };
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteModalIsOpen(true);
        setDeleteId(id);
      });
    },
    []
  );

  const loadRelations = async (pid: any) => {
    console.log(pid);
    const resp = await measureListNationalMeasures(pid);

    if (resp?.status === 200) {
      setItems(resp.data?.planned?.items);
      setRowCountState(resp?.data?.planned?.total);
      setIsLoading(false);

      setItems1(resp.data?.inForce?.items);
      setRowCountState1(resp?.data?.inForce?.total);
      setIsLoading1(false);
    }
  };
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRelations(parseInt(editId));
  };
  const setPageAndCall1 = (page: any) => {
    setPage1(page);
    loadRelations(parseInt(editId));
  };
  const TYPE_MAP = {
    'Планирани пропис': 0,
    'Постојећи пропис': 1,
  };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'title',
        headerName: 'Назив националног прописа',
        flex: 1,
      },
      {
        field: 'pid',
        headerName: 'Ознака прописа',
        maxWidth: 140,
        minWidth: 140,
        flex: 1,
      },

      {
        field: 'username',
        headerName: 'Корисник',
        maxWidth: 140,
        minWidth: 140,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<FaTrash style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Обриши"
            onClick={deleteItem(params.id)}
          />,
        ],
      },
    ],
    [deleteItem]
  );
  const isThere = () => {
    const ids = [...items, ...items1]?.map((item: any) => item?.pid);
    console.log('aaaaa', ids, selRegister?.pid);
    return !(selRegister && !ids?.includes(selRegister?.pid));
  };

  return (
    <div className="pt-4">
      <div className="flex mt-6 gap-6 justify-between">
        <div className="flex items-center gap-6">
          <FormControl size="small" sx={{ minWidth: 300 }}>
            <InputLabel id="demo-select-small">Веза са</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={registerType || ''}
              onChange={(e) => setRegisterType(e.target.value)}
              label="Веза са"
              fullWidth
              className="capitalize"
            >
              {['Планирани пропис', 'Постојећи пропис']?.map((c) => {
                return (
                  <MenuItem value={c} className="capitalize" key={c}>
                    {c}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className="flex flex-col">
          <button
            className="h-[40px] w-[400px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer"
            onClick={() => setModalIsOpen(true)}
            disabled={!registerType}
          >
            {selRegister ? (
              <div className="flex px-4 justify-start text-left items-center">
                <FaSearchMinus className="fill-[#404040]" />
                <p className="text-darkBlue w-full pl-3">
                  {selRegister?.title?.length > 35
                    ? selRegister?.title?.substring(0, 35) + '...'
                    : selRegister?.title}
                </p>
              </div>
            ) : (
              <div className="flex px-4 justify-between items-center">
                <FaSearch className="fill-[#404040]" />
                <p className="text-[#404040] w-full pl-3">
                  Одабир Националног прописа
                </p>
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="w-full flex justify-end mt-6 items-center">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          onClick={createRelation}
          disabled={isThere()}
        >
          Додај
        </Button>
      </div>

      <div>
        {items1?.length > 0 && (
          <div className="mt-5 w-full">
            <div className="text-[#04438b]">
              Повезаност са постојећим националним прописом
            </div>

            <DataGrid
              rowCount={rowCountState1}
              autoHeight={true}
              loading={isLoading1}
              pagination
              disableColumnMenu
              paginationMode="server"
              onPageChange={(newPage) => setPageAndCall1(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize1(newPageSize)}
              page={page1}
              pageSize={pageSize1}
              rows={items1}
              columns={columns}
              style={{ zIndex: 0 }}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје ставке
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје ставке
                  </Stack>
                ),
              }}
            />
          </div>
        )}
        {items?.length > 0 && (
          <div className="mt-5  w-full">
            <div className="text-[#04438b]">
              Повезаност са планираним националним прописом
            </div>

            <DataGrid
              rowCount={rowCountState}
              autoHeight={true}
              loading={isLoading}
              pagination
              disableColumnMenu
              paginationMode="server"
              onPageChange={(newPage) => setPageAndCall(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              page={page}
              pageSize={pageSize}
              rows={items}
              columns={columns}
              style={{ zIndex: 0 }}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје ставке
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје ставке
                  </Stack>
                ),
              }}
            />
          </div>
        )}
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={closeDeleteModal}
        confirmation={deleteRel}
        message={`Обриши релацију са домаћим прописом?`}
      />
      <ChooseNatRegisterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setSelRegister={setSelRegister}
        type={TYPE_MAP[registerType as keyof typeof TYPE_MAP]}
        modalTitle={'Одабир домаћег прописа са којим је мера повезана'}
      />
    </div>
  );
}

export default NatMeasureRegisters;
