import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { FaEdit, FaFile, FaFileArchive, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  deleteNoCelex,
  getAdministrationNoCelexRelations,
  getOfficialJournals,
} from '../../../server/server';
import { notif } from '../../utils/util';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function InternConvManagement() {
  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [natRegister, setNatRegister] = useState('');
  const [registers, setRegisters] = useState<any>([
    {
      id: '1',
      name: 'Test register',
    },
  ]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  // const loadOJ = async (offset: any = 0) => {
  //   const resp = await getOfficialJournals(offset, 8, term);
  //   if (resp?.status === 200) {
  //     setIsLoading(false);
  //     setRowCountState(resp.data?.total);
  //     setRegisters(resp.data?.journals);
  //   }
  // };

  const removeNoCelex = async (id: any) => {
    const resp = await deleteNoCelex(id);
    if (resp.status === 200) {
      notif('Међународна конвенција успешно обрисана', 'success');
    } else {
      notif('Међународна конвенција није обрисана', 'error');
    }
  };

  const loadNoCelexResl = async (offset: any = 0) => {
    const resp = await getAdministrationNoCelexRelations(
      offset,
      pageSize,
      term
    );

    if (resp?.status === 200) {
      setRegisters(resp?.data?.items);
      setRowCountState(resp.data?.total);
      setIsLoading(false);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadNoCelexResl(page * pageSize);
    // loadOJ(page * pageSize);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      removeNoCelex(id);
      setTimeout(() => {
        setRegisters((prevRows: any) =>
          prevRows.filter((row: any) => row.id !== id)
        );
      });
    },
    []
  );
  const editItem = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/administration/intconv/edit?id=' + id);
    },
    []
  );

  const columns = React.useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Назив међународне конвенције',
        flex: 1,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Box
            sx={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              overflow: 'hidden',
            }}
          >
            {params.value}
          </Box>
        ),
      },
      {
        field: 'comment',
        headerName: 'Коментар',
        flex: 1,
        maxWidth: 420,
        minWidth: 420,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Box
            sx={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              overflow: 'hidden',
            }}
          >
            {params.value}
          </Box>
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<FaTrash style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Обриши"
            onClick={deleteItem(params.id)}
          />,
          <GridActionsCellItem
            icon={<FaEdit style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Ажурирај"
            onClick={editItem(params.id)}
          />,
        ],
      },
    ],
    [deleteItem, editItem]
  );
  useEffect(() => {
    loadNoCelexResl();
  }, [term]);

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Управљање међународним конвенцијама које немају броја
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[260px] text-sm text-[#404040]">
              Назив међународне конвенције
            </p>
            <TextField
              label="Назив међународне конвенције"
              id="outlined-size-small"
              size="small"
              sx={{ width: 400 }}
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-5 h-[530px] w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            paginationMode="server"
            getRowHeight={() => 'auto'}
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје међународне конвенције са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје међународне конвенције са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'internConvManagement'}
      />
    </div>
  );
}

export default InternConvManagement;
