import React, { createContext, useContext, useState } from "react";

const UserContext = createContext<any>(undefined);

const UserProvider = (props: any) => {
  //   const user = {} || JSON.parse(localStorage.getItem('user'))
  //   const setUser = useAuth().data.setUser

  const _user = JSON.parse(localStorage.getItem("user") || "{}");
  const [user, setUser] = useState(_user);
  const isSignedIn = !!user.email;

  return (
    <UserContext.Provider value={{ user, setUser, isSignedIn }} {...props} />
  );
};

function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }

  return context;
}

export { UserProvider, useUser };
