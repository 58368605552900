import React from 'react';
import { Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './components/home/Home';
import Login from './components/login/Login';
import PublicRoute from './router/PublicRoute';
import PrivateRoute from './router/PrivateRoute';
import Layout from './components/login/Layout';
import Settings from './components/settings/Settings';
import Profile from './components/profile/Profile';
import { Toaster } from 'react-hot-toast';
import Administration from './components/administration/Administration';
import Register from './components/register/Register';
import EditUser from './components/administration/screens/EditUser';
import InsertUser from './components/administration/screens/InsertUser';
import UserManagement from './components/administration/screens/UserManagement';
import InstitututionManagement from './components/administration/screens/InstitututionManagement';
import InsertInstitution from './components/administration/screens/InsertInstitution';
import OJManagement from './components/administration/screens/OJManagement';
import InsertOfficialJournal from './components/administration/screens/InsertOfficialJournal';
import InsertRegister from './components/register/srceens/InsertRegister';
import ManagementRegister from './components/register/srceens/ManagementRegister';
import Help from './components/help/Help';
import AdministrationHelp from './components/help/screens/AdministrationHelp';
import OJInsertDate from './components/administration/screens/OJInsertDate';
import OJManagamentDate from './components/administration/screens/OJManagamentDate';
import TUManagement from './components/administration/screens/TUManagement';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ChangeInstitution from './components/administration/screens/ChangeInstitution';
import EditRegister from './components/register/srceens/EditRegister';
import Jurisdiction from './components/jurisdiction/jurisdiction';
import HelpPage from './components/help/screens/HelpPage';
import InstitutionUpdating from './components/administration/screens/InstitutionUpdating';
import Views from './components/views/Views';
import SearchRegister from './components/views/screens/SearchRegister';
import InternConvInsert from './components/administration/screens/InternConvInsert';
import InternConvManagement from './components/administration/screens/InternConvManagement';
import Measure from './components/measure/Measure';
import SearchMeasure from './components/measure/screens/SearchMeasure';
import CelexInstitutionView from './components/views/screens/CelexInstitutionView';
import CelexNPIView from './components/views/screens/CelexNPIView';
import QuartalView from './components/views/screens/QuartalView';
import EuInsert from './components/administration/screens/EuInsert';
import NPAAView from './components/views/screens/NPAAView';
import AddMeasure from './components/register/srceens/AddMeasure';
import MeasureView from './components/views/screens/MeasureView';
import MeasureEdit from './components/register/srceens/MeasureEdit';
import MeasureManagement from './components/register/srceens/MeasureManagement';
import FinTable from './components/views/screens/FinTable';
import InsertFinExpense from './components/register/InsertFinExpense';
import ExpidentureManagament from './components/register/srceens/ExpidentureManagament';
import ExpidentureView from './components/views/screens/ExpendituresView';
import InsertFinancing from './components/register/InsertFinancing';
import ClusterView from './components/views/screens/ClusterView';
import FinancingMain from './components/register/FinancingMain';
import Parametrization from './components/administration/screens/Parametrization';
import ChangeRegisterJur from './components/register/ChangeRegisterJur';
function App() {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Routes>
          <Route path="/login" element={<PublicRoute Component={Login} />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<PrivateRoute Component={Home} />} />

            <Route
              path="administration/"
              element={<PrivateRoute Component={Administration} />}
            >
              <Route path="user/edit" element={<EditUser />} />
              <Route path="user/insert" element={<InsertUser />} />
              <Route path="intconv/insert" element={<InternConvInsert />} />
              <Route path="intconv/edit" element={<InternConvInsert />} />
              <Route
                path="intconv/management"
                element={<InternConvManagement />}
              />
              <Route path="user/management" element={<UserManagement />} />
              <Route
                path="institution/management"
                element={<InstitututionManagement />}
              />
              <Route
                path="institution/change"
                element={<ChangeInstitution />}
              />
              <Route
                path="institution/insert"
                element={<InsertInstitution />}
              />
              <Route path="parameters" element={<Parametrization />} />
              <Route path="institution/edit" element={<InsertInstitution />} />
              <Route
                path="institution/changestructure"
                element={<InstitutionUpdating />}
              />
              <Route path="oj/management" element={<OJManagement />} />
              <Route path="oj/insert" element={<InsertOfficialJournal />} />
              <Route path="oj/edit" element={<InsertOfficialJournal />} />
              <Route path="oj/date/insert" element={<OJInsertDate />} />
              <Route path="oj/date/edit" element={<OJInsertDate />} />
              <Route path="oj/date/management" element={<OJManagamentDate />} />

              <Route path="tu/management" element={<TUManagement />} />
              <Route path="eu/insert" element={<EuInsert />} />
              <Route path="*" element={<Administration />} />
            </Route>
            <Route
              path="register/"
              element={<PrivateRoute Component={Register} />}
            >
              <Route path="edit" element={<EditRegister />} />
              <Route path="changejur" element={<ChangeRegisterJur />} />
              <Route path="addmeasure" element={<AddMeasure />} />
              <Route path="measure/edit" element={<MeasureEdit />} />
              <Route
                path="measure/management"
                element={<MeasureManagement />}
              />
              <Route
                path="financial/expenditures"
                element={<ExpidentureManagament />}
              />
              <Route path="financing" element={<FinancingMain />} />
              <Route
                path="financial/expenditure/insert"
                element={<FinancingMain />}
              />{' '}
              <Route
                path="financial/expenditure/edit"
                element={<FinancingMain />}
              />
              <Route path="insert" element={<InsertRegister />} />
              <Route path="management" element={<ManagementRegister />} />
            </Route>
            <Route
              path="jurisdiction/"
              element={<PrivateRoute Component={Jurisdiction} />}
            ></Route>
            <Route path="views/" element={<PrivateRoute Component={Views} />}>
              <Route path="search" element={<SearchRegister />} />
              <Route path="measures" element={<MeasureView />} />
              <Route path="fin" element={<ExpidentureView />} />
              <Route path="npaa/:filterName" element={<NPAAView />} />
              <Route
                path="celexInstitution"
                element={<CelexInstitutionView />}
              />
              <Route path="celexchapter" element={<CelexNPIView />} />\
              <Route path="celexcluster" element={<ClusterView />} />
              <Route path="quartalView" element={<QuartalView />} />
            </Route>
            <Route
              path="measure/"
              element={<PrivateRoute Component={Measure} />}
            >
              <Route path="search" element={<SearchMeasure />} />
              <Route path="insert" element={<AddMeasure />} />
            </Route>
            <Route path="help/" element={<PrivateRoute Component={Help} />}>
              <Route path="page" element={<HelpPage />} />
            </Route>
          </Route>
          <Route path="*" element={<PublicRoute Component={Login} />} />
        </Routes>
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{
            top: 125,
          }}
        />
      </LocalizationProvider>
    </div>
  );
}

export default App;
