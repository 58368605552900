import { Button, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaSave } from 'react-icons/fa';
import { getAllParameters, updateParameters } from '../../../server/server';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

interface Parameter {
	id: string;
	name: string;
	value: string;
	description: string;
}

function Parametrization() {
	const [parameters, setParameters] = useState<Parameter[]>([]);
	const [helpModal, setHelpModal] = useState(false);
	const closeHelpModal = () => {
		setHelpModal(false);
	};
	useEffect(() => {
		getAllParameters().then((response: any) => {
			setParameters(response.data);
		});
	}, []);

	const handleInputChange = (index: number, event: any) => {
		const values = [...parameters];
		values[index].value = event.target.value;
		setParameters(values);
	};

	const handleSave = () => {
		parameters.forEach((parameter) => {
			updateParameters(parameter.id, { value: parameter.value });
		});
	};

	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<div className='flex gap-6'>
				<h1 className='text-[#04438b] text-4xl font-bold'>Параметризација</h1>
				<HelpButton onClick={() => setHelpModal(true)} />
			</div>
			<div className='rounded-lg border border-[#81848f3d] w-fit p-8 pb-4 mt-8 gap-2'>
				<div className='flex flex-col my-2'>
					{parameters.map((parameter, index) => (
						<TextField key={parameter.id} label={parameter.description} value={parameter.value} onChange={(event) => handleInputChange(index, event)} />
					))}
				</div>

				<div className='w-full flex justify-end mt-12 items-center'>
					<Button variant='contained' sx={{ backgroundColor: '#0F2765' }} endIcon={<FaSave />} onClick={handleSave}>
						Потврди
					</Button>
				</div>
			</div>
			<HelpPageModal modalIsOpen={helpModal} closeModal={closeHelpModal} name={'parametrization'} />
		</div>
	);
}

export default Parametrization;
