import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { FaFile, FaSave } from 'react-icons/fa';

import { notif } from '../utils/util';
import {
  addExpenditure,
  getEFileById,
  getExpenditure,
  getExpenditureTypes,
  getFoundSourceTypes,
} from '../../server/server';
import FinantialTable from './srceens/finExpense/fin-table';
import EditEFileModal from '../modals/EditEFileModal';

interface Item {
  year: number;
  type: string;
  value: number;
}

function InsertFinancing({ selRegister }: any) {
  const [years, setYears] = useState([2024]);
  const [data, setData] = useState<Item[]>([]);
  const [idata, setIData] = useState<Item[]>([]);
  const [note1, setNote1] = useState('');
  const [allTypes, setAllTypes] = useState<any>([]);
  const [foundSourceTypes, setFoundSourceTypes] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [selTable, setSelTable] = useState<any>();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    loadFundTypes();
    loadTypes();
  }, []);

  useEffect(() => {
    if (selRegister?.id) {
      loadExpenditure();
    }
  }, [selRegister]);

  const loadFundTypes = async () => {
    const resp = await getFoundSourceTypes();
    if (resp?.status === 200) {
      setFoundSourceTypes(resp?.data);
    }
  };

  const loadTypes = async () => {
    const resp = await getExpenditureTypes();
    if (resp?.status === 200) {
      setAllTypes(resp?.data);
    }
  };
  const initializeData = (types: string[], loadedYears: any) => {
    return loadedYears.flatMap((year: any) =>
      types.map((type) => ({
        year: year,
        type: type,
        value: 0,
      }))
    );
  };

  const typeI = [
    'Буџет државе',
    'Буџет аутономне покраине и јединица локалне самоуправе',
    'IPA (i TAIEX)',
    'Остале донације',
    'Пројектни и програмски кредити',
    'Остало(...)',
  ]

  // useEffect(() => {
  //   if (dataLoaded) return;

  //   if (allTypes.length > 0 && years.length > 0) {
  //     console.log('initializeData(typeI)', initializeData(typeI));
  //     setIData(initializeData(typeI));
  //   }
  // }, [allTypes])


  const loadExpenditure = async () => {
    if (!selRegister || !selRegister.id) {
      return;
    }

    const response: any = await getExpenditure(selRegister.id);

    if (response?.status === 200 && response?.data?.length > 0) {
      setDataLoaded(true);
      const allExpenditures: any[] = response.data.flatMap(
        (fundSource: any) => fundSource?.expenditures
      );

      const filterFn = (item: any) =>
        item.type &&
        [
          'Буџет државе',
          'Буџет аутономне покраине и јединица локалне самоуправе',
          'IPA (i TAIEX)',
          'Остале донације',
          'Пројектни и програмски кредити',
          'Остало(...)',
        ].includes(item.type.name);

      const filterFnData = (item: any) =>
        item.type &&
        [
          'Промена броја запослених',
          '5.1.1 Расходи за запослене',
          '5.2.2 Коришћење роба и услуга',
          '5.2.3 Остало',
          '5.2.1 расходи за запослене',
          '5.2.2 коришћење услуга и роба',
          '5.2.3 инвестиције у институцију',
          '5.2.4 остало',
          '5.3.1 расходи за запослене',
          '5.3.2 коришћење услуга и роба',
          '5.3.3 инвестиције у институцију',
          '5.3.4 остало',
          '5.3.2.1 расходи за запослене',
          '5.3.2.2 коришћење услуга и роба',
          '5.3.2.3 инвестиције у институцију',
          '5.3.2.4 остало',
          '5.4.1 припрема инвестиције',
          '5.4.2 реализација инвестиције',
        ].includes(item.type.name);

      const idataResponse = response.data
        .filter((fundSource: any) => fundSource.type?.id === 0)
        .flatMap((fundSource: any) =>
          fundSource?.expenditures.filter((item: any) => filterFn(item))
        );

      const dataResponse = response.data
        .filter((fundSource: any) => fundSource.type?.id !== 0)
        .flatMap((fundSource: any) =>
          fundSource?.expenditures.filter((item: any) => filterFnData(item))
        );

      setData(
        dataResponse.map((item: any) => ({
          year: item.year,
          type: item.type.name,
          value: parseFloat(item.value),
        }))
      );

      setIData(
        idataResponse.map((item: any) => ({
          year: item.year,
          type: item.type.name,
          value: parseFloat(item.value),
        }))
      );

      const uniqueYears = Array.from(
        new Set(
          response.data.flatMap((fundSource: any) =>
            fundSource.expenditures.map((item: any) => Number(item.year))
          )
        )
      ) as number[];

      uniqueYears.sort((a, b) => a - b);
      setNote1(
        response?.data.find((fundSource: any) => fundSource.type?.id === 0)
          ?.comment ?? ''
      );
      setYears(uniqueYears);
      if (idataResponse.length === 0) {
        setIData(initializeData(typeI, uniqueYears));
      }
    }
  };

  const loadFile = async () => {
    const resp = await getEFileById(selRegister?.id);
    if (resp?.status === 200) {
      setFile(resp?.data);
    }
  };

  const closeFileModal = () => {
    setFileModalOpen(false);
  };

  const handleSave = async () => {
    const allDataWithTypeIds = [
      {
        type: { id: 0 },
        comment: note1,
        measure: { id: selRegister?.id },
        expenditures: [...idata].map((item) => ({
          year: item?.year,
          value: item?.value,
          type: { id: findTypeIdByName(item.type) },
        })),
      },
    ];

    const resp = await addExpenditure(allDataWithTypeIds);
    if (resp.status === 201) {
      notif('Извор финансирања успешно сачуван', 'success');
    } else {
      notif('Извор финансирања није ажуриран', 'error');
    }
  };

  const findTypeIdByName = (typeName: any) => {
    const type = allTypes.find((t: any) => t.name === typeName);
    return type ? type.id : 0;
  };

  return (
    <div>
      {data?.length > 0 ? (
        <div>
          <div className="flex flex-col mt-10">
            <p className="w-[300px] text-[#4a4a4a] my-4 text-sm">
              Структура извора финансирања додатних трошкова потребних за
              спровођење индивидуалног „правног акта / инвестиције“
            </p>
            <div className="my-4">
              <FinantialTable
                years={years}
                setYears={setYears}
                expData={data}
                data={idata}
                setData={setIData}
                text={''}
                allType={allTypes?.filter((type: any) =>
                  [
                    'Буџет државе',
                    'Буџет аутономне покраине и јединица локалне самоуправе',
                    'IPA (i TAIEX)',
                    'Остале донације',
                    'Пројектни и програмски кредити',
                    'Остало(...)',
                  ].includes(type?.name)
                )}
              />
              <div className="relative mt-2">
                <textarea
                  id="message"
                  rows={4}
                  className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                  placeholder="Образложење"
                  value={note1}
                  onChange={(e) => setNote1(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end mt-12 items-center">
            <Button
              variant="contained"
              sx={{ backgroundColor: '#0F2765' }}
              endIcon={<FaSave />}
              onClick={handleSave}
              disabled={!selRegister}
            >
              Сачувај
            </Button>
          </div>
          <EditEFileModal
            modalIsOpen={fileModalOpen}
            closeModal={closeFileModal}
            file={file}
            id={selTable?.id}
          />
        </div>
      ) : (
        <p className="w-[300px] text-[#4a4a4a] my-4">
          Молим Вас унесите издатке, да би сте затим унели изворе финансирања.
        </p>
      )}
    </div>
  );
}

export default InsertFinancing;
