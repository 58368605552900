import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  deleteExpenditure,
  getAllInstitutions,
  getExpenditures,
  getMeasures,
  getRegistersManagement,
} from '../../../server/server';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { notif } from '../../utils/util';
import { useUser } from '../../../auth/UseUser';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';
import { roleMap } from '../../../common/constaints';
function ExpidentureManagament() {
  let navigate = useNavigate();
  const { user } = useUser();

  const [term, setTerm] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [translation, setTranslation] = useState<any>(null);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [helpModal, setHelpModal] = useState(false);

  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };

  const loadRegisters = async (offset: any = 0) => {
    const resp = await getExpenditures(
      pageSize,
      offset,
      roleMap[(user?.roleView?.name ?? '') as keyof typeof roleMap],

      term ?? ''
    );

    if (resp?.status) {
      setIsLoading(false);
      setRegisters(resp?.data?.items);
      setRowCountState(resp?.data?.total);
    }
  };

  const loadInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status) {
      setInstitutions(resp?.data);
    }
  };
  const deleteI = async () => {
    const resp = await deleteExpenditure(deleteId);
    if (resp.status === 200) {
      closeDeleteModal();
      notif('Издатак обрисан', 'success');
    } else {
      notif('Издатак није обрисана', 'error');
    }
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteId(id);
        setDeleteModal(true);
        setRegisters((prevRows: any) =>
          prevRows.filter((row: any) => row.id !== id)
        );
      });
    },
    []
  );
  const editItem = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/register/financial/expenditure/edit?id=' + id);
    },
    []
  );
  const STATUS_MAP = {
    0: 'Неостварено',
    1: 'Делимично остварено',
    2: 'Остварено',
  };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'measure',
        headerName: 'Назив прописа',
        flex: 1,
      },
      {
        field: 'user',
        headerName: 'Корисник',
        maxWidth: 140,
        flex: 1,
      },
      {
        field: 'institution',
        headerName: 'Надлежна институција',
        maxWidth: 180,
        flex: 1,
      },

      {
        field: 'showAdd',
        headerName: 'Додатни трошкови',
        type: 'boolean',
        maxWidth: 150,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params) => {
          if (!params.row.editable) {
            return [];
          }
          return [
            <GridActionsCellItem
              icon={<FaTrash style={{ width: 20, height: 20 }} />}
              sx={{ width: 50, height: 50 }}
              label="Обриши"
              onClick={deleteItem(params.id)}
            />,
            <GridActionsCellItem
              icon={<FaEdit style={{ width: 20, height: 20 }} />}
              sx={{ width: 50, height: 50 }}
              label="Ажурирај"
              onClick={editItem(params.id)}
            />,
          ];
        },
      },
    ],
    [deleteItem, editItem]
  );
  useEffect(() => {
    loadRegisters();
    loadInstitutions();
  }, []);

  useEffect(() => {
    loadRegisters();
  }, [term, defaultInstitution, translation]);

  const translations = [
    { id: 0, name: 'Неостварено' },
    { id: 1, name: 'Делимично остварено' },
    { id: 2, name: 'Остварено' },
  ];

  const handleTranslationsChange = (e: SelectChangeEvent) => {
    const t = translations.find((r) => r?.name === e.target.value);
    setTranslation(t as any);
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Управљање издацима/Изворима финансирања
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3 w-[360px]">
            <p className=" text-sm text-[#404040]">По називу/идентификацији</p>
            <TextField
              label="Назив прописа"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              className='w-[360px]'
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-5 min-h-[430px] w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            disableColumnMenu
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            autoHeight={true}
            getRowHeight={() => 'auto'}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModal}
        closeModal={closeDeleteModal}
        confirmation={deleteI}
        message={`Обриши издатак?`}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'expidentureManagement'}
      />
    </div>
  );
}

export default ExpidentureManagament;
