import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
  GridRow,
  GridRowId,
  GridRowParams,
} from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaFileWord, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  GetViewsNPICelex,
  getAllInstitutions,
  getInstCelexViews,
  getInstitutionCelexFile,
  getNPIFile,
  getRegistersManagement,
  getViewsNPI,
} from '../../../server/server';
import { generateYears, notif, quarterYearEncoder } from '../../utils/util';
import HelpPageModal from '../../modals/HelpPageModal';
import HelpButton from '../../../common/HelpButton';

function CelexNPIView() {
  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCountState, setRowCountState] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [helpModal, setHelpModal] = useState(false);
  const [sortModel, setSortModel] = useState<any>([]);

  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const [error, setError] = useState({
    fromYear: false,
    toQ: false,
    fromQ: false,
    toYear: false,
  });
  const [registerType, setRegisterType] = useState<any>({
    id: 0,
    name: 'Сви прописи',
  });
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>({
    id: '',
    name: 'Сва поглавља',
  });
  const [fromYear, setFromYear] = useState('');
  const [toQ, setToQ] = useState('');
  const [fromQ, setFromQ] = useState('');
  const [toYear, setToYear] = useState('');
  const [fileLoading, setFileLoading] = useState(false);

  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const deleteUser = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setRegisters((prevRows: any) =>
          prevRows.filter((row: any) => row.id !== id)
        );
      });
    },
    []
  );
  const insertFakeRows = (items: any) => {
    const newItems: any = [];

    items.forEach((item: any) => {
      newItems.push(item);
      newItems.push({
        id: item.id * 10000 + Math.floor(Math.random() * 1000), // Create a random id based on the original id
        isFakeRow: true,
        celex: item.celex,
      });
    });

    return newItems;
  };
  const loadRegisters = async (offset: any = 0) => {
    const resp = await GetViewsNPICelex(
      pageSize,
      offset,
      defaultInstitution?.id ?? '',
      registerType?.id ?? '',
      quarterYearEncoder(fromYear, fromQ),
      quarterYearEncoder(toYear, toQ),
      sortModel[0]?.field,
      sortModel[0]?.sort
    );

    if (resp?.status) {
      setIsLoading(false);
      const itemsWithFakeRows = insertFakeRows(resp?.data?.items);
      setRegisters(itemsWithFakeRows);
      setRowCountState(resp?.data?.total);
    }
  };

  const loadInstitutions = async () => {
    const resp = await getViewsNPI();
    if (resp?.status) {
      setInstitutions([
        {
          id: null,
          name: 'Сва поглавља',
        },
        ...resp?.data,
      ]);
    }
  };

  const setPageAndCall = (page: any) => {
    const zeroIndexedPage = page - 1;
    setPage(zeroIndexedPage);
    loadRegisters(zeroIndexedPage * pageSize);
  };
  const editUser = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/register/edit?id=' + id);
    },
    []
  );
  const columns: any = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'pid',
        sortable: false,
        headerName: 'Шифра  план. прописа',
        maxWidth: 160,
        flex: 1,
        colSpan: ({ row }) => {
          if (row?.isFakeRow) {
            return 8;
          }
          return undefined;
        },
        renderCell: (params) => {
          if (params.row.isFakeRow) {
            return (
              <div className="font-bold text-[#0f2765]">
                {' '}
                {params.row.celex}
              </div>
            );
          } else {
            return params.value;
          }
        },
      },
      {
        field: 'fid',
        sortable: false,
        headerName: 'Шифра пост. прописа',
        maxWidth: 160,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'title',
        sortable: false,

        headerName: 'Назив прописа',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'institution',
        sortable: false,
        headerName: `Надлежно
министарство
или посебна
организација`,
        maxWidth: 120,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'type',
        headerName: 'Тип прописа',
        sortable: false,

        maxWidth: 75,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'npi',
        sortable: false,
        headerName: `Место у структури НПАА`,
        maxWidth: 110,
        minWidth: 90,
        renderCell: (params) =>
          params.row.isFakeRow ? null : (
            <div className="font-bold text-[#0f2765]">{params?.row?.npi}</div>
          ),
        flex: 1,
      },
      {
        field: 'planAdoptByGov',
        sortable: false,
        headerName: ` Рок за утврђивање/усвајање од стране Владе/министарства аката`,
        maxWidth: 200,
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'adopByGovDate',
        sortable: false,

        headerName: `Усвојено на Влади/министарству дана`,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'oj',
        sortable: false,
        headerName: `Број 'Службеног гласника РС'`,
        maxWidth: 130,
        flex: 1,
        renderCell: (params) => {
          if (params?.row?.isFakeRow) {
            // Handle fake row here, e.g., return an empty div or custom content
            return <div></div>;
          } else {
            const ojt = params?.row?.oj.split('\n');
            return (
              <div>
                <p className="font-bold text-[#0f2765]">{ojt[0]}</p>
                <p>{ojt[1]}</p>
                <p>{ojt[2]}</p>
              </div>
            );
          }
        },
      },
    ],
    []
  );
  useEffect(() => {
    loadRegisters();
    loadInstitutions();
  }, []);

  useEffect(() => {
    loadRegisters();
  }, [term, defaultInstitution, registerType, fromQ, toQ, fromYear, toYear]);

  const typeRegister = [
    { id: 0, name: 'Сви прописи' },
    { id: 1, name: 'Постојећи прописи' },
    { id: 2, name: 'Планирани прописи' },
  ];

  const handleTypeRegisterChange = (e: SelectChangeEvent) => {
    const t = typeRegister.find((r) => r?.name === e.target.value);
    setRegisterType(t as any);
  };
  const handleSortChange = (model: any) => {
    console.log('handleSortChange', model);
    setSortModel(model);
  };
  const downloadWord = async () => {
    const errorMap = {
      fromYear: !fromYear,
      toQ: !toQ,
      fromQ: !fromQ,
      toYear: !toYear,
    };
    setError(errorMap);
    if (Object.values(errorMap).every((value) => value === false)) {
      setFileLoading(true);
      const resp = await getNPIFile(
        defaultInstitution?.id,
        quarterYearEncoder(fromYear, fromQ),
        quarterYearEncoder(toYear, toQ),
        registerType?.id ?? ''
      );
      if (resp?.status === 200) {
        setFileLoading(false);
        download(resp?.data?.data, resp?.data?.name, resp?.data?.type);
      }
    } else {
      notif(
        'Молимо одаберите почетну и крајњу годину, као и квартале.',
        'error'
      );
    }
  };
  function CustomGridFooter(props: any) {
    const totalPages = Math.ceil(props.count / props.pageSize);
    console.log('strane', totalPages, props.count, props.pageSize);
    const handlePageSizeChange = (event: any) => {
      props.onPageSizeChange(event.target.value);
      // props.loadUsers(props.page * event.target.value);
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
        }}
      >
        <div className="flex gap-5 items-center text-[#04438b] ">
          <FormControl>
            <InputLabel htmlFor="rows-per-page">Редови</InputLabel>
            <Select
              value={props.pageSize}
              size="small"
              label="Редови"
              sx={{ minWidth: 70 }}
              onChange={handlePageSizeChange}
              inputProps={{
                name: 'rows-per-page',
                id: 'rows-per-page',
              }}
            >
              {props.rowsPerPageOptions.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p>Укупно слогова: {rowCountState}</p>
        </div>

        <Pagination
          shape="rounded"
          count={totalPages}
          page={props.page + 1}
          onChange={(event, newPage) => props.onPageChange(event, newPage)}
          siblingCount={1}
          boundaryCount={1}
        />
      </div>
    );
  }
  useEffect(() => {
    loadRegisters(page * pageSize);
  }, [page, pageSize]);
  const QUARTER_MAP = ['I', 'II', 'III', 'IV'];
  const YEAR_MAP = generateYears(2015, 2028);
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Прописи са CELEX-има по преговарачким поглављима
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full flex justify-between items-end  mt-6">
          <div className="w-full flex items-center gap-12">
            <div className="flex flex-col items-start gap-3">
              <p className="w-[160px] text-sm text-[#404040]">По поглављу</p>
              <Autocomplete
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={defaultInstitution}
                size="small"
                onChange={(event: any, newValue: any) =>
                  handleInstChange(event, newValue)
                }
                id="controllable-states-demo"
                options={institutions}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label="Поглавље" />
                )}
              />
            </div>
            <div className="flex flex-col items-start gap-3">
              <p className="w-[160px] text-sm text-[#404040]">Тип прописа</p>
              <FormControl size="small" sx={{ minWidth: 140 }}>
                <InputLabel id="demo-select-small">Тип прописа</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={registerType?.name}
                  onChange={handleTypeRegisterChange}
                  label="Тип прописа"
                  className="capitalize"
                >
                  {typeRegister?.map((t) => {
                    return (
                      <MenuItem
                        value={t?.name}
                        className="capitalize"
                        key={t?.id}
                      >
                        {t?.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="flex pt-4 items-end">
              <FormControl size="small" sx={{ m: 1, width: 85 }}>
                {/* <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel> */}
                <p className="w-[160px] text-sm text-[#404040]">Од Квартала</p>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={fromQ || ''}
                  error={error.fromQ}
                  onFocus={() => setError({ ...error, fromQ: false })}
                  onChange={(e) => setFromQ(e.target.value)}
                // label="Квартал"
                >
                  {QUARTER_MAP?.map((q) => {
                    return <MenuItem value={q}>{q}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ m: 1, width: 85 }}>
                {/* <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel> */}
                <p className="w-[160px] text-sm text-[#404040]">Од Године</p>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={fromYear || ''}
                  error={error.fromYear}
                  onFocus={() => setError({ ...error, fromYear: false })}
                  onChange={(e) => setFromYear(e.target.value)}
                // label="Година"
                >
                  {YEAR_MAP?.map((y) => {
                    return <MenuItem value={y}>{y}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="flex pt-4 items-end">
              <FormControl size="small" sx={{ m: 1, width: 85 }}>
                {/* <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel> */}
                <p className="w-[160px] text-sm text-[#404040]">До Квартала</p>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={toQ || ''}
                  error={error.toQ}
                  onFocus={() => setError({ ...error, toQ: false })}
                  onChange={(e) => setToQ(e.target.value)}
                // label="Квартал"
                >
                  {QUARTER_MAP?.map((q) => {
                    return <MenuItem value={q}>{q}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ m: 1, width: 85 }}>
                {/* <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel> */}
                <p className="w-[160px] text-sm text-[#404040]">До Године</p>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={toYear || ''}
                  error={error.toYear}
                  onFocus={() => setError({ ...error, toYear: false })}
                  onChange={(e) => setToYear(e.target.value)}
                // label="Година"
                >
                  {YEAR_MAP?.map((y) => {
                    return <MenuItem value={y}>{y}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          {fileLoading ? (
            <CircularProgress />
          ) : (
            <FaFileWord
              className="text-[#043e81ae] w-8 h-8 cursor-pointer hover:text-[#043e81]"
              onClick={downloadWord}
            />
          )}
        </div>
        <div className="mt-5 h-fit w-full">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: 'clip',
                whiteSpace: 'break-spaces',
                textAlign: 'center',
                lineHeight: 1,
              },
              // '& .MuiDataGrid-virtualScrollerContent': { maxHeight: 450 },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(n) .MuiDataGrid-cell':
              //   {
              //     height: '60px !important',
              //     minHeight: '60px !important',
              //     maxHeight: '60px !important',
              //   },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(n)': {
              //   height: '60px !important',
              //   minHeight: '60px !important',
              //   maxHeight: '60px !important',
              // },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(2n) .MuiDataGrid-cell':
              //   {
              //     height: '30px !important',
              //     minHeight: '30px !important',
              //     maxHeight: '30px !important',
              //   },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(2n)': {
              //   height: '30px !important',
              //   minHeight: '30px !important',
              //   maxHeight: '30px !important',
              // },
              // '& .MuiDataGrid-main > div:nth-child(2)': {
              //   height: 'auto !important',
              //   minHeight: 'max-content !important',
              // },
            }}
            headerHeight={100}
            disableColumnMenu={true}
            rowCount={rowCountState}
            getRowHeight={() => 'auto'}
            autoHeight={true}
            loading={isLoading}
            pagination
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={(model) => handleSortChange(model)}
            // onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            components={{
              Footer: CustomGridFooter,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
            componentsProps={{
              footer: {
                count: rowCountState,
                page: page,
                pageSize: pageSize,
                onPageChange: (event: any, newPage: any) =>
                  setPageAndCall(newPage),
                onPageSizeChange: (newPageSize: any) =>
                  setPageSize(newPageSize),
                rowsPerPageOptions: [5, 10, 20],
                loadRegisters: loadRegisters,
              },
            }}
          />
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'celexNPIView'}
      />
    </div>
  );
}

export default CelexNPIView;
