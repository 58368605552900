import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { error } from 'console';
import React, { useEffect, useState } from 'react';
import { FaQuestionCircle, FaSave, FaSyncAlt } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  deleteInstitution,
  getAllInstitutions,
  insertInstitution,
  loadInstitutionById,
  updateInstitution,
} from '../../../server/server';
import { notif } from '../../utils/util';
import HelpPageModal from '../../modals/HelpPageModal';
import { validator } from '../../../common/constaints';
import ChosePirvRegistersModal from '../../modals/ChosePirvRegistersModal';
import ChosePirvInsModal from '../../modals/ChosePirvInsModal';

function InsertInstitution() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [editId, setEditId] = useState('');
  const [defaultInstitution, setDefaultInstitution] = useState<any>(null);
  const [institutions, setInstitutions] = useState<any>([]);
  const [title, setTitle] = useState('');
  const [titleEN, setTitleEN] = useState('');
  const [acronym, setAcronym] = useState('');
  const [acronymEN, setAcronymEN] = useState('');
  const [type, setType] = useState('1');
  const [helpModal, setHelpModal] = useState(false);
  const [syncModalIsOpen, setSyncModalIsOpen] = useState(false);
  const [pirvId, setPirvId] = useState<any>(null);
  const [errorMap, setErrorMap] = useState({
    title: '',
    titleEN: '',
    acronym: '',
    acronymEN: '',
  });

  const handleInstChange = (e: any, prm: any) => {
    console.log(prm);
    setDefaultInstitution(prm as any);
  };
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };
  useEffect(() => {
    loadAllInstitutions();
    let eId = searchParams.get('id');
    if (eId) {
      loadById(eId);
      setEditId(eId);
    }
  }, []);
  const loadById = async (id: any) => {
    const resp = await loadInstitutionById(id);
    if (resp.status === 200) {
      setDefaultInstitution(resp?.data?.institution);
      setTitle(resp?.data?.name);
      setTitleEN(resp?.data?.nameEN);
      setAcronym(resp?.data?.abbreviation);
      setAcronymEN(resp?.data?.abbreviationEN);
      setType(resp?.data?.type ?? '1');
      console.log(resp.data);
    }
  };
  const createInstitution = async () => {
    const resp = await insertInstitution({
      name: title,
      nameEN: titleEN,
      abbreviation: acronym,
      abbreviationEN: acronymEN,
      parentId: defaultInstitution?.id,
      type: type?.toString(),
      pirvId,
    });
    if (resp.status === 201) {
      notif('Институција успешно креирана', 'success');
      navigate('/administration/institution/management');
    } else {
      notif(
        resp?.data?.statusCode === 409
          ? resp?.data?.message
          : 'Институција није креирана',
        'error'
      );
    }
  };

  const updateCurrentInstitution = async () => {
    const resp = await updateInstitution({
      id: parseInt(editId),
      name: title,
      nameEN: titleEN,
      abbreviation: acronym,
      abbreviationEN: acronymEN,
      parentId: defaultInstitution?.id,
      type: type?.toString(),
      pirvId,
    });

    if (resp.status === 201) {
      notif('Институција успешно ажурирана', 'success');
      navigate('/administration/institution/management');
    } else {
      notif(resp?.data?.message ?? 'Институција није ажурирана', 'error');
    }
  };

  const handleInsert = async () => {
    const titleError = validator(title, true, true);
    const titleENError = validator(titleEN, true, false);
    const acronymError = validator(acronym, true, true);
    const acronymENError = validator(acronymEN, true, false);

    setErrorMap({
      title: titleError,
      titleEN: titleENError,
      acronym: acronymError,
      acronymEN: acronymENError,
    });
    if (!titleError && !titleENError && !acronymError && !acronymENError) {
      if (editId) {
        updateCurrentInstitution();
      } else {
        createInstitution();
      }
    }
  };
  const closeSyncModal = () => {
    setSyncModalIsOpen(false);
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          {editId ? 'Ажурирање' : 'Унос'} институције
        </h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => setHelpModal(true)}
        >
          <p className="rounded-full   flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-[800px] p-8 pb-4  mt-8 relative">
        <div
          className="absolute top-2 right-2 flex items-center gap-1 cursor-pointer group"
          onClick={() => setSyncModalIsOpen(true)}
        >
          <FaSyncAlt className="group-hover:bg-gray-700 fill-[#134f32]" />
          <span className="group-hover:text-gray-700">ПИРВ</span>
        </div>
        <div className="flex gap-28">
          <div className="flex gap-8 flex-col">
            <div className="flex items-center gap-6">
              <p className="w-[240px]">Назив институције</p>
              <TextField
                label="Назив институције"
                id="outlined-size-small"
                type="institutionName"
                name="institutionName"
                size="small"
                sx={{ width: 340 }}
                helperText={errorMap?.title}
                error={!!errorMap?.title as boolean}
                onFocus={() => setErrorMap({ ...errorMap, title: '' })}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[240px]">Назив институције - енглески</p>
              <TextField
                label="Назив институције - енглески"
                id="outlined-size-small"
                type="institutionNameEN"
                name="institutionNameEN"
                size="small"
                sx={{ width: 340 }}
                helperText={errorMap?.titleEN}
                error={!!errorMap?.titleEN as boolean}
                onFocus={() => setErrorMap({ ...errorMap, titleEN: '' })}
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[240px]">Ако припада некој од институција</p>
              <div className="flex flex-col">
                <Autocomplete
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  value={defaultInstitution}
                  size="small"
                  onChange={(event: any, newValue: any) =>
                    handleInstChange(event, newValue)
                  }
                  id="controllable-states-demo"
                  options={institutions}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 340 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Институција" />
                  )}
                />
              </div>
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[240px]">Акроним</p>
              <TextField
                label="Акроним"
                id="outlined-size-small"
                type="acronym"
                name="acronym"
                size="small"
                onBlur={() => setAcronym(acronym.toUpperCase())}
                sx={{ width: 340 }}
                helperText={errorMap?.acronym}
                error={!!errorMap?.acronym as boolean}
                onFocus={() => setErrorMap({ ...errorMap, acronym: '' })}
                value={acronym}
                onChange={(e) => setAcronym(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[240px]">Акроним - енглески</p>
              <TextField
                label="Акроним - енглески"
                id="outlined-size-small"
                type="acronymEN"
                name="acronymEN"
                size="small"
                sx={{ width: 340 }}
                onBlur={() => setAcronymEN(acronymEN.toUpperCase())}
                helperText={errorMap?.acronymEN}
                error={!!errorMap?.acronymEN as boolean}
                onFocus={() => setErrorMap({ ...errorMap, acronymEN: '' })}
                value={acronymEN}
                onChange={(e) => setAcronymEN(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[240px]">Врста институције</p>
              <FormControl variant="outlined" className="w-[240px]">
                <InputLabel id="institution-type-label">
                  Изаберите тип
                </InputLabel>
                <Select
                  labelId="institution-type-label"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value as string);
                  }}
                  label="Изаберите тип"
                >
                  <MenuItem value="0">Планирана институција</MenuItem>
                  <MenuItem value="1">Постојећа институција</MenuItem>
                  <MenuItem value="2">Укинута институција</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-12 items-center">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#0F2765' }}
            endIcon={<FaSave />}
            onClick={handleInsert}
          >
            Сачувај
          </Button>
        </div>
      </div>
      <ChosePirvInsModal
        modalIsOpen={syncModalIsOpen}
        closeModal={closeSyncModal}
        setTitle={setTitle}
        setAcronym={setAcronym}
        setPirvId={setPirvId}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'insertInstitution'}
      />
    </div>
  );
}

export default InsertInstitution;
