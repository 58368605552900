import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { FaEdit, FaFileWord, FaInfo, FaTrash, FaUsers, FaUsersCog } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "../../../auth/UseUser";
import {
  getAllInstitutions,
  getInstitutions,
  getJurFile,
  getJurisdictions,
} from "../../../server/server";
import JurisdictionEditModal from "../../modals/JurisdictionEditModal";
import HelpPageModal from "../../modals/HelpPageModal";
import HelpButton from "../../../common/HelpButton";
import GetJurInfoModal from "../../modals/GetJurInfoModal";
import download from "downloadjs";

function DefaultJur() {
  let navigate = useNavigate();
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const [celex, setCelex] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [celexModalIsOpen, setCelexModalIsOpen] = useState(false);

  const [sortModel, setSortModel] = useState<any>([]);

  const handleSortChange = (model: any) => {
    console.log("handleSortChange", model);
    setSortModel(model);
  };

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCountState, setRowCountState] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [selItem, setSelItem] = useState<any>();
  const [items, setItems] = useState<any>([]);
  const [institutions, setInstitutions] = useState<any>([]);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>();
  const [defFilter, setDefFilter] = useState<any>({ id: 0, name: "Сви" });
  const [helpModal, setHelpModal] = useState(false);
  const [selectedCelex, setSelectedCelex] = useState("");
  const [fileLoading, setFileLoading] = useState(false)
  const deleteUser = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setItems((prevRows: any) =>
          prevRows.filter((row: any) => row.id !== id),
        );
      });
    },
    [],
  );

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const loadJurisdictions = async (offset: any = 0) => {
    setIsLoading(true);
    const resp = await getJurisdictions(
      offset,
      pageSize,
      defaultInstitution?.id ?? "",
      searchParams.get("group") ?? "",
      defFilter?.id ?? "",
      celex,
      sortModel[0]?.field,
      sortModel[0]?.sort,
    );
    if (resp?.status === 200) {
      setIsLoading(false);
      setItems(resp?.data?.items);
      setRowCountState(resp?.data?.total);
    }
  };
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadJurisdictions(page * pageSize);
  };
  const editItem = React.useCallback(
    (item: any) => () => {
      setSelItem(item);
      setModalIsOpen(true);
    },
    [],
  );
  useEffect(() => {
    loadJurisdictions();
  }, [searchParams, celex, defFilter, defaultInstitution, sortModel]);

  useEffect(() => {
    loadAllInstitutions();
    loadJurisdictions();
  }, []);
  useEffect(() => {
    if (searchParams.get("group")) {
      setPage(0);
      setSortModel([]);
    }
  }, [searchParams.get("group")]);

  const loadItem = () => { };
  // useEffect(() => {
  //   loadInstitutions();
  // }, [term]);
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };
  const celexAction = (cel: string) => {
    setSelectedCelex(cel);
    setCelexModalIsOpen(true);
  };

  const canEdit = (params: any) => {
    return user?.workGroup?.includes(params.row.editable);
  };

  const downloadWord = async () => {

    setFileLoading(true);
    const resp = await getJurFile(searchParams.get("no")?.split('.')[0]);
    if (resp?.status === 200) {
      setFileLoading(false);
      download(resp?.data?.data, resp?.data?.name, resp?.data?.type);
    }
  };

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: "celexNo",
        headerName: "Celex",
        maxWidth: 140,
        minWidth: 140,
        flex: 1,
        renderCell: (params) => (
          <div
            className="flex items-center gap-1"
            onClick={() => celexAction(params.row.celexNo)}
          >
            <div className="group cursor-pointer rounded p-1 hover:bg-[#0f2765]">
              <Tooltip title="Информације о повезаним прописима">
                <FaInfo className="text-current group-hover:text-white" />
              </Tooltip>
            </div>
            <p>{params.row.celexNo}</p>
          </div>
        ),
      },
      {
        field: "description",
        headerName: "Назив",
        minWidth: 220,
        flex: 1,
        renderCell: (params) => (
          <div className="text-small text-[#535353]">
            {params.row?.description?.length > 300
              ? params.row?.description?.substring(0, 300) + "..."
              : params.row?.description}
          </div>
        ),
      },
      {
        field: "groups",
        headerName: "Поглавље",
        maxWidth: 100,
        minWidth: 100,
        renderCell: (params) => (
          <div className="flex flex-col">
            {/* <div className="font-bold text-[#0f2765]">
              {params.row.group?.match(/\d+\.\d+\.\d+\.\d+/)?.[0]}
            </div> */}
            {params.row.groups?.map((g: string) => {
              return (
                <Tooltip title={g}>
                  <Typography>
                    <p className="cursor-pointer text-sm font-bold text-[#0f2765]">
                      {g?.match(/\d+(\.\d+){0,3}/)?.[0]}
                    </p>
                  </Typography>
                </Tooltip>
              );
            })}
          </div>
        ),
        flex: 1,
      },
      {
        field: "userInCharge",
        headerName: "Претежна надлежност",
        maxWidth: 180,
        minWidth: 170,
        renderCell: (params) => (
          <div>
            <div className="font-bold text-[#0f2765]">
              {params.row.predominantInstitution}
            </div>
            {params?.row?.comment1 ? <Typography>{params.row.comment1}</Typography> : <Tooltip title={params.row.userInCharge.email}>
              <Typography>
                {params.row.userInCharge.firstName}{" "}
                {params.row.userInCharge.lastName}
              </Typography>
            </Tooltip>}
            <div className="text-small text-[#a2a2a2]">
              {params.row.userInCharge.title}
            </div>
          </div>
        ),
        flex: 1,
      },
      {
        field: "createdByUser",
        headerName: "Доделио-ла претежна надлежност",
        maxWidth: 245,
        minWidth: 190,
        renderCell: (params) => (
          <div>
            <Tooltip title={params.row.createdByUser.email}>
              <Typography>
                {params.row.createdByUser.firstName}{" "}
                {params.row.createdByUser.lastName}
              </Typography>
            </Tooltip>
          </div>
        ),
        flex: 1,
      },
      {
        field: "institution",
        headerName: "Надлежност",
        maxWidth: 110,
        minWidth: 180,
        flex: 1,
        renderCell: (params) => (
          <div>
            {params?.row?.institution?.map((i: any) => {
              return <div className="font-bold text-[#0f2765]">{i}</div>;
            })}
          </div>
        ),
      },
      {
        field: "amandman",
        headerName: "Амандман",
        maxWidth: 90,
        flex: 1,
      },
      {
        field: "relevantnost",
        headerName: "Релевантност",
        maxWidth: 110,
        flex: 1,
      },
      {
        field: "outOfForceRS",
        headerName: "Ван снаге",
        maxWidth: 90,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        width: 45,
        getActions: (params) => [
          canEdit(params) ? (
            <GridActionsCellItem
              icon={<FaUsersCog style={{ width: 20, height: 20 }} />}
              sx={{ width: 50, height: 50 }}
              label="Ажурирај"
              onClick={editItem(params.row)}
            />
          ) : (
            <p></p>
          ),
        ],
      },
    ],
    [deleteUser, editItem],
  );
  const FILTER_MAP = [
    { id: 0, name: "Сви" },
    { id: 1, name: "Постоји претежно надлежна институција" },
    { id: 2, name: "Не постоји претежно надлежна институција" },
    { id: 3, name: "Постоји надлежна институција" },
    { id: 4, name: "Не постоји надлежна институција" },
  ];
  const closeCelexModal = () => {
    setCelexModalIsOpen(false);
  };

  const confirmationSubmit = () => {
    loadJurisdictions(page * pageSize);
    setModalIsOpen(false);
  };
  const handleCancelFilter = () => {
    navigate("/jurisdiction");
  };
  return (
    <div className="gray-small-scroll h-full overflow-auto px-2 py-10">
      <div className="flex gap-6">
        <h1 className="text-4xl font-bold text-[#04438b]">Надлежности</h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="mt-2 min-w-fit rounded-lg border border-[#81848f3d] p-8 ">
        <div className="flex items-center gap-5">
          <h1 className="text-3xl font-bold text-[#04438bae]">
            Претрага{" "}
            <span className="text-lg font-medium text-[#04438b82]">
              {searchParams.get("no") &&
                `(филтрирана - ${searchParams.get("no")} поглавље)`}
            </span>
          </h1>
          {searchParams.get("no") && (
            <button
              onClick={handleCancelFilter}
              className="ml-2 inline-flex items-center rounded bg-[#ff6868] px-4 py-2 font-bold text-white transition duration-300 ease-in-out hover:bg-[#ff5a5a]"
            >
              <span>Поништи филтер поглавља</span>
            </button>
          )}
        </div>

        <div className="mt-6 flex w-full items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">По CELEX-у</p>
            <TextField
              label="CELEX"
              type="CelexNo"
              name="CelexNo"
              id="outlined-size-small"
              size="small"
              value={celex}
              onChange={(e) => setCelex(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">
              По надлежности институције
            </p>
            <FormControl size="small" sx={{ minWidth: 270 }}>
              <InputLabel id="demo-select-small">Филтер</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={defFilter?.name}
                onChange={(e) =>
                  setDefFilter(
                    FILTER_MAP?.find((f) => f.name === e.target.value),
                  )
                }
                label="Филтер"
                fullWidth
                className="capitalize"
              >
                {FILTER_MAP?.map((t) => {
                  return (
                    <MenuItem value={t?.name} className="" key={t?.id}>
                      {t?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className="flex flex-col items-start gap-3">
            <p className="w-[140px] text-sm text-[#404040]">
              По називу институције
            </p>

            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultInstitution?.name}
              size="small"
              onChange={(event: any, newValue: any) =>
                handleInstChange(event, newValue)
              }
              id="controllable-states-demo"
              options={institutions}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 370 }}
              renderInput={(params) => (
                <TextField {...params} label="Институција" />
              )}
            />
          </div>
          {searchParams.get("no") && <div className="flex flex-col items-start gap-2">
            <p className="text-sm text-[#404040]">
              Поглавље {searchParams.get("no")?.split('.')[0]}
            </p>
            {fileLoading ? <CircularProgress /> : <FaFileWord className='text-[#043e81ae] w-8 h-8 cursor-pointer hover:text-[#043e81]' onClick={downloadWord} />}
          </div>}
        </div>
        <div className="mt-5 h-full w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            disableColumnSelector
            autoHeight={true}
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sortingMode="server"
            onSortModelChange={(model) => handleSortChange(model)}
            page={page}
            pageSize={pageSize}
            rows={items}
            columns={columns}
            getRowId={(row) => row?.celexNo}
            style={{
              zIndex: 0,
            }}
            sx={{
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            getRowHeight={() => "auto"}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <JurisdictionEditModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        confirmation={() => confirmationSubmit()}
        item={selItem}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={"jurisdiction"}
      />
      <GetJurInfoModal
        modalIsOpen={celexModalIsOpen}
        closeModal={closeCelexModal}
        celex={selectedCelex}
      />
    </div>
  );
}

export default DefaultJur;
