import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import {
  getNatMeasuresSimple,
  getNatMeasuresSimpleRelation,
  getPirvIns,
  getPirvMeasurs,
  getRegistersManagement,
  getScreeningGroups,
} from '../../server/server';

function ChosePirvInsModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  setTitle,
  setAcronym,
  setPirvId,
}: any) {
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);

  const [euMeasures, setEuMeasures] = useState<any>(null);
  const [selEuMeasure, setSelEuMeasure] = useState<any>(null);
  const [term, setTerm] = useState('');
  const [idSearch, setIdSearch] = useState('');
  const [value, setValue] = React.useState(0);
  const [selectionModel, setSelectionModel] = useState<any>(null);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const submitConfirm = () => {
    setTitle(selectionModel?.name);
    setAcronym(selectionModel?.abbreviation);
    setPirvId(selectionModel?.id);
    setEuMeasures(null);
    setSelEuMeasure(null);
    setTerm('');
    setIdSearch('');
    setValue(0);
    setSelectionModel(null);
    setRegisters([]);
    setPage(0);
    setPageSize(6);
    setRowCountState(6);
    closeModal();
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleSelEuMeasure = (e: SelectChangeEvent) => {
    const t = euMeasures.find((r: any) => r?.celexNo === e.target.value);
    setSelEuMeasure(t as any);
  };

  const loadRegisters = async (offset: any = 0) => {
    const resp = await getPirvIns(pageSize, offset, term);

    if (resp?.status) {
      setIsLoading(false);
      setRegisters(resp?.data?.items);
      setRowCountState(resp?.data?.total);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };

  useEffect(() => {
    loadRegisters();
  }, [term]);

  useEffect(() => {
    setTerm('');
  }, [value]);

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'name',
        headerName: 'Назив',
        minWidth: 190,
        flex: 1,
        sortable: false,
      },
      {
        field: 'abbreviation',
        headerName: 'Акроним',
        minWidth: 180,
        maxWidth: 90,
        flex: 1,
        sortable: false,
      },
    ],
    []
  );
  const localeText = {
    // other locale text overrides...
    selectedRowCount: (count: number) =>
      `${count} row${count === 1 ? '' : 's'} selected`,
  };

  const closeCurrentModal = () => {
    setEuMeasures(null);
    setSelEuMeasure(null);
    setTerm('');
    setIdSearch('');
    setValue(0);
    setSelectionModel(null);
    // setRegisters([]);
    setPage(0);
    setPageSize(6);
    setRowCountState(6);
    closeModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 800,

          padding: 0,
          opacity: 1,
          minHeight: 600,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
        <div className="w-full">
          <p className="text-[#04438b] text-2xl font-bold">
            Одабир институције у оквиру ПИРВ структуре
          </p>
        </div>
        <div className="w-full flex flex-col ">
          <div>
            <TextField
              label="Назив институције"
              id="outlined-size-small"
              type="natMeasure"
              name="natMeasure"
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => (term ? setTerm('') : null)}
                    className="cursor-pointer min-h-[35px]"
                  >
                    {term ? <FaTimes /> : <FaSearch />}
                  </InputAdornment>
                ),
              }}
              size="small"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
            <p className="text-[#04438b] my-2">{selEuMeasure?.descriptionEN}</p>
          </div>
        </div>
        <div className="mt-5 h-[400px] w-full h-full ">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            style={{ zIndex: 0 }}
            getRowHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                {
                  display: 'none',
                },
            }}
            rowsPerPageOptions={[5]}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              //   setSelectionModel(
              //     (prevModel: any) =>
              //       registers.filter(
              //         (r: any) =>
              //           r?.id ===
              //           newSelectionModel.filter(
              //             (newId) => prevModel?.id != newId
              //           )
              //       )[0]
              //   )[0];

              const newModel = registers.find(
                (r: any) =>
                  r?.id ===
                  newSelectionModel.filter(
                    (newId) => selectionModel?.id !== newId
                  )[0]
              );
              setSelectionModel(newModel || null);

              console.log('here', newSelectionModel);
            }}
            selectionModel={selectionModel?.id ?? []}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center w-full mt-6">
          <div
            className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={submitConfirm}
          >
            Одабери
          </div>
          <div
            className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => closeCurrentModal()}
          >
            Oткажи
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChosePirvInsModal;
