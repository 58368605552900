import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  deleteMeasure,
  getAllInstitutions,
  getMeasures,
  getRegistersManagement,
} from '../../../server/server';
import { notif } from '../../utils/util';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { useUser } from '../../../auth/UseUser';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';
import { roleMap } from '../../../common/constaints';

function MeasureManagement() {
  const { user } = useUser();

  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [translation, setTranslation] = useState<any>(null);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [helpModal, setHelpModal] = useState(false);
  const [sortModel, setSortModel] = useState<any>([]);

  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleSortChange = (model: any) => {
    console.log('handleSortChange', model);
    setSortModel(model);
  };
  const deleteI = async () => {
    const resp = await deleteMeasure(deleteId);
    if (resp.status === 200) {
      notif('Мера успешно обрисана', 'success');
      setDeleteModal(false);
      setRegisters((prevRows: any) =>
        prevRows.filter((row: any) => row.id !== deleteId)
      );
    } else {
      notif('Мера није обрисана', 'error');
    }
  };
  const loadRegisters = async (offset: any = 0) => {
    const resp = await getMeasures(
      pageSize,
      offset,
      term,
      defaultInstitution?.id ?? '',
      translation?.id ?? '',
      roleMap[(user?.roleView?.name ?? '') as keyof typeof roleMap],
      sortModel[0]?.field,
      sortModel[0]?.sort
    );

    if (resp?.status) {
      setIsLoading(false);
      setRegisters(resp?.data?.items);
      setRowCountState(resp?.data?.total);
    }
  };

  const loadInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status) {
      setInstitutions(resp?.data);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };

  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteId(id);
        setDeleteModal(true);
      });
    },
    []
  );
  const editItem = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/register/measure/edit?id=' + id);
    },
    []
  );
  const STATUS_MAP = {
    0: 'Неостварено',
    1: 'Делимично остварено',
    2: 'Остварено',
  };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'cluster',
        headerName: 'Кластер',
        minWidth: 260,
        maxWidth: 260,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex flex-col">
              <div className="font-bold  text-[#0f2765]">Кластер</div>
              <p className="text-[12px]"> {params?.row?.cluster}</p>
              <div className="font-bold text-[#0f2765]">Поглавље</div>
              <p className="text-[12px]"> {params?.row?.group}</p>
              {params?.row?.subChapter &&
                <div className="font-bold text-[#0f2765]">Подпоглавље</div>
              }
              {
                params?.row?.subChapter &&
                <p className="text-[12px]"> {params?.row?.subChapter}</p>
              }
            </div>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Планирама мера',
        minWidth: 260,
        maxWidth: 260,
        flex: 1,
      },
      {
        field: 'nm',
        headerName: 'Веза са националним прописом',
        type: 'boolean',
        maxWidth: 180,
        flex: 1,
      },
      {
        field: 'em',
        headerName: 'Веза са прописом ЕУ',
        type: 'boolean',
        maxWidth: 180,
        flex: 1,
      },
      {
        field: 'pointer',
        headerName: 'Показатељи резултата',
        maxWidth: 180,
        type: 'boolean',
        flex: 1,
      },
      {
        field: 'ndocs',
        headerName: 'Везе са преговарачким документом',
        type: 'boolean',
        maxWidth: 120,
        flex: 1,
      },
      {
        field: 'institution',
        headerName: 'Надлежне институције',
        minWidth: 260,
        maxWidth: 260,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex flex-col">
              <div className="font-bold  text-[#0f2765]">
                Првонадлежна институција:
              </div>
              <p className="text-[12px]"> {params?.row?.institution}</p>
              <div className="font-bold text-[#0f2765]">
                Другонадлежне институције:
              </div>
              {!params?.row?.secondaryInstitutions && (
                <p className="text-[12px]">Ниједна</p>
              )}
              <p className="text-[12px]">
                {params?.row?.secondaryInstitutions}
              </p>
            </div>
          );
        },
      },
      {
        field: 'deadlineType',
        headerName: 'Рок',
        minWidth: 120,
        maxWidth: 120,
        flex: 1,
        renderCell: (params) => {
          const deadlineTypeMap = {
            0: 'Континуирано',
            1: 'квартал/год.',
            2: 'До пристуања ЕУ',
          };
          return (
            <p className="text-[12px]">
              {' '}
              {params?.row?.deadlineType === '1'
                ? params?.row?.deadline
                : deadlineTypeMap[
                params?.row?.deadlineType as keyof typeof deadlineTypeMap
                ]}
            </p>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Статус испуњености мере',
        maxWidth: 230,
        flex: 1,
        renderCell: (params) => {
          const statusMap = {
            0: 'Не',
            1: 'Делимично остварен',
            2: 'Да',
          };
          return (
            <p className="text-[12px]">
              {statusMap[params?.row?.status as keyof typeof statusMap]}
            </p>
          );
        },
      },
      {
        field: 'comment',
        headerName: 'Коментар',
        maxWidth: 200,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params) => {
          if (!params.row.editable) {
            return [];
          }
          return [
            <GridActionsCellItem
              icon={<FaTrash style={{ width: 20, height: 20 }} />}
              sx={{ width: 50, height: 50 }}
              label="Обриши"
              onClick={deleteItem(params.id)}
            />,
            <GridActionsCellItem
              icon={<FaEdit style={{ width: 20, height: 20 }} />}
              sx={{ width: 50, height: 50 }}
              label="Ажурирај"
              onClick={editItem(params.id)}
            />,
          ];
        },
      },
    ],
    [deleteItem, editItem]
  );
  useEffect(() => {
    loadRegisters();
    loadInstitutions();
  }, []);

  useEffect(() => {
    loadRegisters();
  }, [term, defaultInstitution, translation, sortModel]);

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full flex flex-col">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">Управљање мерама</h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] p-8 pb-4 mt-8 flex-grow">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">По Називу</p>
            <TextField
              label="Назив мере"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">
              По надлежној институцији
            </p>
            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultInstitution?.name}
              size="small"
              onChange={(event: any, newValue: any) =>
                handleInstChange(event, newValue)
              }
              id="controllable-states-demo"
              options={institutions}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Институција" />
              )}
            />
          </div>
        </div>
        <div className="mt-5 min-h-[430px] w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            getRowHeight={() => 'auto'}
            autoHeight={true}
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            sortingMode="server"
            onSortModelChange={(model) => handleSortChange(model)}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје мере са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје мере са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModal}
        closeModal={closeDeleteModal}
        confirmation={deleteI}
        message={`Обриши меру?`}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'measureManagement'}
      />
    </div>
  );
}

export default MeasureManagement;
