import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import {
  createJur,
  editJur,
  getAllInstitutions,
  getResp2Jur,
  getUsersByInstitution,
  removeJurResp,
  updateEuJur,
  updateJur,
} from '../../server/server';
import { notif } from '../utils/util';
import ConfirmationModal from './ConfirmationModal';

function JurisdictionEditModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  confirmation,
  item,
}: any) {
  useEffect(() => {
    ReactModal.setAppElement('#root');
    loadAllInstitutions();
  }, []);
  const [institutions, setInstitutions] = useState<any>([]);
  const [defaultInstitution, setDefaultInstitution] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);
  const [value, setValue] = React.useState(0);
  const [defUser, setDefUser] = useState<any>(null);
  const [selectionModel, setSelectionModel] = useState<any>(null);
  const [comment, setComment] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCountState, setRowCountState] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<any>([]);
  const [editID, setEditID] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>(null);
  // New state variables
  const [amandman, setAmandman] = useState(false);
  const [naSnazi, setNaSnazi] = useState(false);
  const [relevantnost, setRelevantnost] = useState('');
  const submitConfirm = () => {
    confirmation();
  };
  const clearState = () => {
    setDefaultInstitution(null);
    setComment('');
    setEditID(null);
  };

  const getComment = (comm: any) => {
    if (comm) {
      return comm;
    }
    if (item?.userInCharge?.firstName) {
      return item?.userInCharge?.firstName + ' ' + item?.userInCharge?.lastName;
    }
    return '';
  }
  const repopulateStates = () => {
    // Repopulate amandman state
    const newAmandman = item?.amandman === 'амандман';
    setAmandman(newAmandman);

    // Repopulate relevantnost state
    let newRelevantnost = '';
    if (item?.relevantnost === 'релевантан') {
      newRelevantnost = 'R';
    } else if (item?.relevantnost === 'релевантан након приступања') {
      newRelevantnost = 'RNP';
    }
    setRelevantnost(newRelevantnost);

    // Repopulate naSnazi state
    const newNaSnazi = !item?.outOfForceRS.startsWith('ван снаге');
    setNaSnazi(newNaSnazi);
  };
  const updateEu = async () => {
    const resp = await updateEuJur({
      id: item?.celexNo,
      amandman,
      relevantnost,
      naSnazi,
    });
    if (resp.status === 201) {
      notif('Претежна надлежност успешно ажурирана', 'success');
      confirmation();
    } else {
      notif('Претежна надлежност није ажурирана', 'error');
    }
  };
  const updateResp1 = async () => {
    const resp = await updateJur({
      resp: 1,
      celexNo: item?.celexNo,
      institution: { id: defaultInstitution?.id || null },
      comment,
    });

    if (resp.status === 201) {
      notif('Претежна надлежност успешно ажурирана', 'success');
      confirmation();
    } else {
      notif('Претежна надлежност није ажурирана', 'error');
    }
  };
  const create = async () => {
    const resp = await createJur({
      resp: 2,
      celexNo: item?.celexNo,
      institution: { id: defaultInstitution?.id },
      comment,
    });
    if (resp.status === 201) {
      loadResp2();
      notif('Додела надлежност успешно ажурирана', 'success');
      clearState();
    } else {
      notif('Додела надлежност није ажурирана', 'error');
    }
  };
  const loadResp2 = async () => {
    const resp = await getResp2Jur(item?.celexNo);
    if (resp?.status) {
      setIsLoading(false);
      setItems(resp?.data);
    }
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions(1);
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };
  useEffect(() => {
    clearState();
    repopulateStates();
  }, []);

  useEffect(() => {
    if (item?.predominantInstitutionId && institutions?.length > 0)
      setDefaultInstitution(
        institutions.find((i: any) => i.id === item?.predominantInstitutionId)
      );
  }, [item, institutions]);
  useEffect(() => {
    setValue(0);
    setComment(getComment(item?.comment1));
  }, [item])


  useEffect(() => {
    if (value) {
      loadResp2();
    }
  }, [value]);

  useEffect(() => {
    if (defaultInstitution?.id) loadUsersByInstId(defaultInstitution?.id);
  }, [defaultInstitution]);

  const loadUsersByInstId = async (id: any) => {
    const resp = await getUsersByInstitution(id);

    if (resp?.status === 200) {
      setDefUser(
        resp?.data?.find(
          (u: any) => u.id === parseInt(item?.userInCharge?.id)
        ) ?? null
      );
      setUsers(resp?.data);
    }
  };
  useEffect(() => {
    if (value === 0) {
      setDefaultInstitution(institutions.find((i: any) => i.id === item?.predominantInstitutionId));
      setComment(getComment(item?.comment1));
    } else {
      setDefaultInstitution(null);
      setComment('');
    }
  }, [value])


  const update = () => {
    if (value === 0) {
      updateResp1();
    }
    if (value === 2) {
      updateEu();
    }
  };
  const deleteI = async () => {
    const resp = await removeJurResp(deleteId);
    if (resp.status === 200) {
      notif('Надлежност успешно обрисан', 'success');
      setItems((prevRows: any) =>
        prevRows.filter((row: any) => row?.id !== deleteId)
      );
      setDeleteModal(false);
    } else {
      notif('Надлежност није обрисан', 'error');
    }
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const editItem = React.useCallback(
    (item: any) => () => {
      setDefaultInstitution(item?.institution);
      setComment(item?.comment);
      setEditID(item?.id);
    },
    []
  );
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteId(id);
        setDeleteModal(true);
      });
    },
    []
  );
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadItems(page * pageSize);
  };

  const loadItems = (offset: any = 0) => { };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'institution',
        headerName: 'Институција',
        minWidth: 150,
        maxWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return params?.row?.institution?.abbreviation;
        },
      },
      {
        field: 'comment',
        headerName: 'Коментар',
        minWidth: 180,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 90,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<FaEdit style={{ width: 20, height: 20 }} />}
            sx={{ width: 40, height: 40 }}
            label="Ажурирај"
            onClick={editItem(params.row)}
          />,
          <GridActionsCellItem
            icon={<FaTrash style={{ width: 20, height: 20 }} />}
            sx={{ width: 40, height: 40 }}
            label="Обриши"
            onClick={deleteItem(params.id)}
          />,
        ],
      },
    ],
    []
  );
  const edit = async () => {
    const resp = await editJur({
      id: editID,
      resp: 2,
      institution: { id: defaultInstitution?.id },
      comment,
    });
    if (resp.status === 201) {
      loadResp2();
      notif('Додела надлежност успешно ажурирана', 'success');
      clearState();
    } else {
      notif('Додела надлежност није ажурирана', 'error');
    }
  };
  const closeDeleteModal = () => {
    clearState();
    closeModal();
    setDeleteModal(false);
  };
  const handleJ = () => {
    // setItems([
    //   ...items,
    //   { id: 1, institution: defaultInstitution?.abbreviation, comment },
    // ]);

    if (editID) {
      edit();
    } else {
      create();
    }
    setIsLoading(false);
  };
  const handleClose = () => {
    clearState();
    closeModal();
    confirmation();
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeDeleteModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 760,
          height: 'fit-content',
          padding: 0,
          opacity: 1,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
        <div className="flex items-center flex-col justify-center">
          <p className="text-[#04438b] text-2xl font-bold">
            {item?.celexNo} надлежности
          </p>
          <p className="text-[#81848f] text-sm"></p>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={(e, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Претежна надлежност" {...a11yProps(0)} />
                <Tab label="НАДЛЕЖНОСТ (ДЕЛИМИЧНА)." {...a11yProps(1)} />
                <Tab label="СВОЈСТВА И РЕЛЕВАНТНОСТ " {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Box>
        </div>
        {value === 0 && (
          <div className="flex flex-col gap-8 pt-4">
            <div className="flex flex-col items-start gap-1">
              <p className="w-[240px]">Надлежна институција</p>
              <div className="flex flex-col">
                <Autocomplete
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  value={defaultInstitution}
                  size="small"
                  onChange={(event: any, newValue: any) =>
                    setDefaultInstitution(newValue)
                  }
                  id="controllable-states-demo"
                  options={institutions}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 450 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Институција" />
                  )}
                />
              </div>
            </div>
            <textarea
              id="message"
              rows={2}
              className="block p-2.5 w-[450px] text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
              placeholder="Коментар..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
        )}
        {value === 1 && (
          <div className="w-full pt-4">
            <div className="w-full flex flex-col gap-2 items-center">
              <Autocomplete
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={defaultInstitution}
                size="small"
                onChange={(event: any, newValue: any) =>
                  setDefaultInstitution(newValue)
                }
                id="controllable-states-demo"
                options={institutions}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label="Институција" />
                )}
              />
              <textarea
                id="message"
                rows={2}
                className="block p-2.5 w-[450px] text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                placeholder="Коментар..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <div className="w-full px-8 flex justify-end">
                {' '}
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#0F2765' }}
                  onClick={handleJ}
                >
                  Потврди
                </Button>
              </div>
            </div>

            {items.length > 0 && (
              <div className="mt-5 h-[400px] w-full h-[200px] ">
                <DataGrid
                  rowCount={rowCountState}
                  loading={isLoading}
                  onPageChange={(newPage) => setPageAndCall(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  page={page}
                  disableColumnMenu
                  rowHeight={38}
                  disableSelectionOnClick
                  pageSize={pageSize}
                  rows={items}
                  columns={columns}
                  style={{ zIndex: 0 }}
                  rowsPerPageOptions={[5]}
                />
              </div>
            )}
          </div>
        )}
        {value === 2 && (
          <div className="flex flex-col gap-4 my-4">
            {/* Amandman Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={amandman}
                  onChange={(e) => setAmandman(e.target.checked)}
                />
              }
              label="Амандман"
            />
            {/* Na snazi Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={naSnazi}
                  onChange={(e) => setNaSnazi(e.target.checked)}
                />
              }
              label="На снази"
            />
            {/* Relevantnost Dropdown */}
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <Select
                value={relevantnost}
                onChange={(e) => setRelevantnost(e.target.value)}
              >
                <MenuItem value="NR">Није релевантно</MenuItem>
                <MenuItem value="R">Релевантно</MenuItem>
                <MenuItem value="RNP">Релевантно након приступања</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
        <div className="flex flex-row justify-between items-center w-full mt-6">
          <div
            className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={update}
            style={{ opacity: [1].includes(value) ? 0 : 100 }}
          >
            Потврди
          </div>
          <div
            className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => handleClose()}
          >
            {value ? 'Затвори' : 'Oткажи'}
          </div>
        </div>
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModal}
        closeModal={closeDeleteModal}
        confirmation={deleteI}
        message={`Обриши пропис?`}
      />
    </Modal>
  );
}

export default JurisdictionEditModal;
