import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import {
  getNatGroupStructure,
  getNatMeasuresSimple,
  getNatMeasuresSimpleRelation,
  getNationalGroupSet,
  getNationalGroupSetByLevel,
  getRegistersManagement,
  getScreeningGroups,
} from '../../server/server';

function ChooseNPI({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  setSelNPIGroup,
  type,
  register,
  modalTitle = 'Одабир НПАА групе',
}: any) {
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);
  const [group, setGroup] = useState<any>(null);
  const [subGroup, setSubGroup] = useState<any>(null);
  const [subSubGroup, setSubSubGroup] = useState<any>(null);
  const [items, setItems] = useState<any>([]);
  const [selGroup, setSelGroup] = useState<any>(null);
  const [selSubGroup, setSelSubGroup] = useState<any>(null);
  const [selSubSubGroup, setSelSubSubGroup] = useState<any>(null);
  const [euMeasures, setEuMeasures] = useState<any>(null);
  const [selEuMeasure, setSelEuMeasure] = useState<any>(null);
  const [term, setTerm] = useState('');
  const [idSearch, setIdSearch] = useState('');
  const [value, setValue] = React.useState(0);
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const getGroupNo = () => {
    if (selSubSubGroup) {
      return selSubSubGroup;
    }
    if (selSubGroup) {
      return selSubGroup;
    }
    if (selGroup) {
      return selGroup;
    }
  };
  const submitConfirm = () => {
    if (value) {
      setSelNPIGroup(getGroupNo());
    }
    setEuMeasures(null);
    setSelEuMeasure(null);
    setTerm('');
    setIdSearch('');
    setValue(0);
    setSelectionModel(null);
    setRegisters([]);
    setPage(0);
    setPageSize(6);
    setRowCountState(6);
    closeModal();
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleSelEuMeasure = (e: SelectChangeEvent) => {
    const t = euMeasures.find((r: any) => r?.celexNo === e.target.value);
    setSelEuMeasure(t as any);
  };
  const selectFetchFunction = () => {
    if (modalTitle === 'Одабир националног прописа') {
      return getNatMeasuresSimpleRelation;
    } else {
      return getNatMeasuresSimple;
    }
  };

  const loadRegisters = async (offset: any = 0) => {
    const fetchFunction = selectFetchFunction();

    if (value) {
      const resp = await fetchFunction(pageSize, offset, '', term, type ?? '');

      if (resp?.status) {
        setIsLoading(false);
        setRegisters(resp?.data?.measures);
        setRowCountState(resp?.data?.total);
      }
    } else {
      const resp = await fetchFunction(pageSize, offset, term, '', type ?? '');

      if (resp?.status) {
        setIsLoading(false);
        setRegisters(resp?.data?.measures);
        setRowCountState(resp?.data?.total);
      }
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };

  useEffect(() => {
    loadRegisters();
  }, [term, type]);

  useEffect(() => {
    setTerm('');
  }, [value]);

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'name',
        headerName: 'Назив',
        minWidth: 180,
        flex: 1,
      },
    ],
    []
  );
  const localeText = {
    // other locale text overrides...
    selectedRowCount: (count: number) =>
      `${count} row${count === 1 ? '' : 's'} selected`,
  };

  const closeCurrentModal = () => {
    setEuMeasures(null);
    setSelEuMeasure(null);
    setTerm('');
    setIdSearch('');
    setValue(0);
    setSelectionModel(null);
    setRegisters([]);
    setPage(0);
    setPageSize(6);
    setRowCountState(6);
    closeModal();
  };
  const loadStructureById = async (id: string) => {
    const resp = await getNatGroupStructure(id, 28);

    if (resp?.status === 200) {
      setItems(resp?.data);
    }
  };

  useEffect(() => {
    loadStructureById(register?.id);
  }, []);
  const subChange = (e: any, prm: any) => {
    setSelSubGroup(prm as any);
  };
  const subSubChange = (e: any, prm: any) => {
    setSelSubSubGroup(prm as any);
  };

  const handleInstChange = (e: any, prm: any) => {
    setSelGroup(prm as any);
  };
  const loadGroups = async () => {
    const resp = await getNationalGroupSetByLevel(28, 1);
    if (resp?.status === 200) {
      setGroup(resp?.data);
    }
  };

  const loadSubGroups = async () => {
    const resp = await getNationalGroupSet(selGroup?.groupNo ?? 0);
    if (resp?.status === 200) {
      setSubGroup(resp?.data);
    }
  };
  const loadSubSubGroups = async () => {
    const resp = await getNationalGroupSet(selSubGroup?.groupNo ?? 0);
    if (resp?.status === 200) {
      setSubSubGroup(resp?.data);
    }
  };

  useEffect(() => {
    loadSubGroups();
  }, [selGroup]);

  useEffect(() => {
    loadSubSubGroups();
  }, [selSubGroup]);

  useEffect(() => {
    loadGroups();
    if (register?.id) {
      loadStructureById(register?.id);
    }
  }, []);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 800,

          padding: 0,
          opacity: 1,
          minHeight: 600,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
        <div className="w-full">
          <p className="text-[#04438b] text-2xl font-bold">{modalTitle}</p>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Повезане НПАА групе" {...a11yProps(0)} />
                <Tab label="Слободан избор" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Box>
        </div>
        <div className="w-full flex flex-col ">
          {value === 0 ? (
            <div>
              {items?.length === 0 && (
                <p className="text-[#04438b]">
                  Пропис не садржи повезане НПАА групе
                </p>
              )}
              {items?.length > 0 && (
                <div>
                  <div className="text-[#04438b]">
                    Положај у оквиру NPAA структуре
                  </div>
                  <div className="mt-5 h-[430px] w-full">
                    <DataGrid
                      rows={items}
                      disableColumnMenu
                      columns={columns}
                      autoHeight={true}
                      sx={{
                        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                          {
                            display: 'none',
                          },
                      }}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                      onSelectionModelChange={(newSelectionModel) => {
                        const newSelectionModelSlice =
                          newSelectionModel.slice(-1);
                        console.log(
                          'newSelectionModelSlice:',
                          newSelectionModelSlice
                        );
                        console.log('items:', items);

                        const selectedRow = items.find(
                          (item: any) => item.id === newSelectionModelSlice[0]
                        );
                        console.log('selectedRow:', selectedRow);

                        setSelectionModel(newSelectionModelSlice);
                        setSelNPIGroup(
                          selectedRow
                            ? {
                                groupNo: selectedRow?.groupNo,
                                name: selectedRow?.name,
                              }
                            : null
                        );
                        console.log('here', newSelectionModelSlice);
                      }}
                      selectionModel={selectionModel ?? []}
                      disableSelectionOnClick
                      components={{
                        NoRowsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            Не постоје NPAA поглавља
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                          <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            Не постоје NPAA поглавља
                          </Stack>
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="flex flex-col items-center gap-6">
                <div className="flex flex-col items-start gap-3">
                  <p className="w-[260px]">NPAA група</p>
                  <div className="flex flex-col">
                    <Autocomplete
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      value={selGroup}
                      size="small"
                      onChange={(event: any, newValue: any) =>
                        handleInstChange(event, newValue)
                      }
                      id="controllable-states-demo"
                      options={group}
                      getOptionLabel={(option) => option?.name}
                      sx={{ width: 340 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Група" />
                      )}
                    />
                  </div>
                </div>
                <div className="flex  flex-col items-start gap-3">
                  <p className="w-[260px]">NPAA подгрупа</p>
                  <div className="flex flex-col">
                    <Autocomplete
                      disabled={!selGroup}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      value={selSubGroup}
                      noOptionsText={'Не постоји подгрупа'}
                      size="small"
                      onChange={(event: any, newValue: any) =>
                        subChange(event, newValue)
                      }
                      id="controllable-states-demo"
                      options={subGroup}
                      getOptionLabel={(option) => option?.name}
                      sx={{ width: 340 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Подгрупа" />
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start gap-3">
                  <p className="w-[260px]">NPAA под-подгрупа</p>
                  <div className="flex flex-col">
                    <Autocomplete
                      disabled={!selSubGroup}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      value={selSubSubGroup}
                      size="small"
                      onChange={(event: any, newValue: any) =>
                        subSubChange(event, newValue)
                      }
                      id="controllable-states-demo"
                      noOptionsText={'Не постоји подгрупа'}
                      options={subSubGroup}
                      getOptionLabel={(option) => option?.name}
                      sx={{ width: 340 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Под-подгрупа" />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between items-center w-full mt-6">
          <div
            className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={submitConfirm}
          >
            Одабери
          </div>
          <div
            className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => closeCurrentModal()}
          >
            Oткажи
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChooseNPI;
