import { Autocomplete, Stack, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowId } from '@mui/x-data-grid';
import { format, parse, parseISO, parseJSON } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { deleteOJNumber, getAllInstitutions, getAllOfficialJournals, getInstitutions, getOJNumbers } from '../../../server/server';
import { notif } from '../../utils/util';
import OJManagement from './OJManagement';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function OJManagementDate() {
	let navigate = useNavigate();
	const [year, setYear] = useState('');
	const [numbers, setNumbers] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(6);
	const [rowCountState, setRowCountState] = useState(6);
	const [isLoading, setIsLoading] = useState(true);

	const [selectedJournal, setSelectedJournal] = useState<any>(null);
	const [journals, setJournals] = useState<any>();
	const [helpModal, setHelpModal] = useState(false);

	const closeHelpModal = () => {
		setHelpModal(false);
	};
	const removeOJNumber = async (id: any) => {
		const resp = await deleteOJNumber(id);

		if (resp.status === 200) {
			notif('Број објаве успешно обрисан', 'success');
		} else {
			notif('Број објаве није обрисан', 'error');
		}
	};
	const deleteItem = React.useCallback(
		(id: GridRowId) => () => {
			removeOJNumber(id);
			setTimeout(() => {
				setNumbers((prevRows: any) => prevRows.filter((row: any) => row.id !== id));
			});
		},
		[]
	);
	const loadAllNumbers = async (offset: any = 0) => {
		const resp = await getOJNumbers(offset, pageSize, year, selectedJournal?.id);
		if (resp?.status === 200) {
			setNumbers(resp?.data?.numbers);
			setRowCountState(resp?.data?.total);
		}
	};
	const setPageAndCall = (page: any) => {
		setPage(page);
		loadAllNumbers(page * pageSize);
	};
	const editUser = React.useCallback(
		(id: GridRowId) => () => {
			navigate('/administration/oj/date/edit?id=' + id);
		},
		[]
	);

	const columns = React.useMemo<GridColumns<any>>(
		() => [
			{
				field: 'number',
				headerName: 'Број службеног гласника',
				maxWidth: 260,
				minWidth: 180,
				flex: 0.1,
			},
			{
				field: 'date',
				headerName: 'Датум',
				minWidth: 180,
				flex: 0.1,
				valueFormatter: ({ value }) => format(new Date(value), 'dd-MM-yyyy'),
			},
			{
				field: 'actions',
				type: 'actions',
				width: 120,
				getActions: (params) => [
					<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20 }} />} sx={{ width: 50, height: 50 }} label='Обриши' onClick={deleteItem(params.id)} />,
					<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} sx={{ width: 50, height: 50 }} label='Ажурирај' onClick={editUser(params.id)} />,
				],
			},
		],
		[deleteItem, editUser]
	);
	const loadAllJournals = async () => {
		const resp = await getAllOfficialJournals();

		if (resp?.status === 200) {
			setIsLoading(false);
			setJournals(resp?.data);
			setSelectedJournal(resp?.data[0]);
		}
	};

	const handleJournalChange = (e: any, prm: any) => {
		setSelectedJournal(prm as any);
	};
	useEffect(() => {
		loadAllNumbers();
	}, [year, selectedJournal]);

	useEffect(() => {
		loadAllJournals();
		loadAllNumbers();
	}, []);
	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<div className='flex gap-6'>
				<h1 className='text-[#04438b] text-4xl font-bold'>Управљање датума објаве Службеног гласника</h1>
				<HelpButton onClick={() => setHelpModal(true)} />
			</div>
			<div className='rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 '>
				<h1 className='text-[#04438bae] text-3xl font-bold'>Претрага</h1>
				<div className='w-full mt-6 flex items-center gap-12'>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По Службеном гласнику</p>
						<Autocomplete
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.id}>
										{option.name}
									</li>
								);
							}}
							value={selectedJournal}
							size='small'
							onChange={(event: any, newValue: any) => handleJournalChange(event, newValue)}
							id='controllable-states-demo'
							options={journals}
							getOptionLabel={(option) => option?.name}
							sx={{ width: 340 }}
							renderInput={(params) => <TextField {...params} label='Службени гласник' />}
						/>
					</div>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По години</p>
						<TextField label='Година' id='outlined-size-small' size='small' autoComplete='off' value={year} onChange={(e) => setYear(e.target.value)} />
					</div>
				</div>
				<div className='mt-5 h-[400px] w-full'>
					<DataGrid
						rowCount={rowCountState}
						loading={isLoading}
						pagination
						disableColumnMenu
						paginationMode='server'
						onPageChange={(newPage) => setPageAndCall(newPage)}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						page={page}
						pageSize={pageSize}
						rows={numbers}
						columns={columns}
						style={{ zIndex: 0 }}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						components={{
							NoRowsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоји број објаве Службеног гласника са задатим критеријумом
								</Stack>
							),
							NoResultsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоји број објаве Службеног гласника са задатим критеријумом
								</Stack>
							),
						}}
					/>
				</div>
			</div>
			<HelpPageModal modalIsOpen={helpModal} closeModal={closeHelpModal} name={'ojManagementDate'} />
		</div>
	);
}

export default OJManagementDate;
