import { Autocomplete, Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaSearch, FaSearchMinus, FaTrash } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../auth/UseUser';
import { deleteNationalMeasureInChargeInstitutions, getAllInstitutions, getNationalMeasureInChargeInstitutions, insertNationalMeasureInChargeInstitutions } from '../../../../server/server';
import ChooseNatRegisterModal from '../../../modals/ChooseNatRegisterModal';
import { notif } from '../../../utils/util';

function InstitutionsInCharge() {
	const { user } = useUser();
	const [searchParams] = useSearchParams();
	const [defaultInstitution, setDefaultInstitution] = useState<any>();
	const [editId, setEditId] = useState('');
	const [selRegister, setSelRegister] = useState<any>(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [items, setItems] = useState<any>([]);
	const [selRelation, setSelRelation] = useState('');
	const [insitutionType, setInstitutionType] = useState('');
	const [institutions, setInstitutions] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(6);
	const [rowCountState, setRowCountState] = useState(6);
	const [isLoading, setIsLoading] = useState(true);
	const [registerType, setRegisterType] = useState('');

	const loadAllInstitutions = async () => {
		const resp = await getAllInstitutions(insitutionType === 'Постојећа институција' ? 1 : 0);
		if (resp?.status === 200) {
			setInstitutions(resp?.data);
		}
	};
	useEffect(() => {
		loadAllInstitutions();
		let eId = searchParams.get('id');
		if (eId) {
			setEditId(eId);
			loadAllInChargeInsts(0, eId);
		}
	}, []);
	useEffect(() => {
		loadAllInstitutions();
	}, [insitutionType]);

	const handleInstChange = (e: any, prm: any) => {
		setDefaultInstitution(prm as any);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const loadAllInChargeInsts = async (offset: any = 0, pid: any) => {
		const resp = await getNationalMeasureInChargeInstitutions(offset, pageSize, pid);

		if (resp?.status === 200) {
			setItems(resp.data?.items);
			setRowCountState(resp?.data?.total);
			setIsLoading(false);
		}
	};

	const createInChargeInst = async () => {
		const resp = await insertNationalMeasureInChargeInstitutions({
			parentMeasure: parseInt(editId),
			childMeasure: selRegister?.id,
			institutionId: defaultInstitution?.id,
		});

		if (resp.status === 201) {
			notif('Институција за спровођење је успешно креирана', 'success');
			loadAllInChargeInsts(0, editId);
			setPage(0);
			setSelRegister(null);
			setDefaultInstitution(null);
		} else {
			notif('Институција за спровођење није обрисана', 'error');
		}
	};

	const TYPE_MAP = {
		'Планирани пропис': 0,
		'Постојећи пропис': 1,
	};

	const deleteIns = async (id: any) => {
		const resp = await deleteNationalMeasureInChargeInstitutions(id);

		if (resp.status === 200) {
			notif('Институција за спровођење је успешно обрисана', 'success');
		} else {
			notif('Институција за спровођење обрисана', 'error');
		}
	};

	const deleteItem = React.useCallback(
		(id: GridRowId) => () => {
			deleteIns(id);
			setTimeout(() => {
				setItems((prevRows: any) => prevRows.filter((row: any) => row.id !== id));
			});
		},
		[]
	);

	const setPageAndCall = (page: any) => {
		setPage(page);
		loadAllInChargeInsts(page * pageSize, editId);
	};

	const columns = React.useMemo<GridColumns<any>>(
		() => [
			{
				field: 'institution',
				headerName: 'Назив институције',
				flex: 1,
				maxWidth: 340,
				minWidth: 180,
			},
			{
				field: 'title',
				headerName: 'Правни основи',
				flex: 1,
			},
			{
				field: 'pid',
				headerName: 'Ознака прописа',
				maxWidth: 150,
				minWidth: 150,
				flex: 1,
			},
			{
				field: 'username',
				headerName: 'Корисник',
				maxWidth: 150,
				minWidth: 150,
				flex: 1,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 60,
				getActions: (params: any) => [<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20 }} />} sx={{ width: 50, height: 50 }} label='Обриши' onClick={deleteItem(params.id)} />],
			},
		],
		[deleteItem]
	);

	return (
		<div className='pt-4'>
			<div className='flex mt-6 gap-6 justify-between'>
				<div className='flex items-center gap-6'>
					<FormControl size='small' sx={{ minWidth: 270 }}>
						<InputLabel id='demo-select-small'>Тип институције</InputLabel>
						<Select labelId='demo-select-small' id='demo-select-small' value={insitutionType || ''} onChange={(e) => setInstitutionType(e.target.value)} label='Тип иннституције' fullWidth className='capitalize'>
							{['Планирана институција', 'Постојећа институција']?.map((c) => {
								return (
									<MenuItem value={c} className='capitalize' key={c}>
										{c}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
				<div className='flex items-center gap-6'>
					<Autocomplete
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id}>
									{option.name}
								</li>
							);
						}}
						value={defaultInstitution?.name}
						size='small'
						onChange={(event: any, newValue: any) => handleInstChange(event, newValue)}
						id='controllable-states-demo'
						options={institutions}
						getOptionLabel={(option) => option?.name}
						sx={{ width: 340 }}
						renderInput={(params) => <TextField {...params} label='Институција' />}
					/>
				</div>
				<div className='flex items-center gap-6'>
					<FormControl size='small' sx={{ minWidth: 200 }}>
						<InputLabel id='demo-select-small'>Веза са</InputLabel>
						<Select labelId='demo-select-small' id='demo-select-small' value={registerType || ''} onChange={(e) => setRegisterType(e.target.value)} label='Веза са' fullWidth className='capitalize'>
							{['Планирани пропис', 'Постојећи пропис']?.map((c) => {
								return (
									<MenuItem value={c} className='capitalize' key={c}>
										{c}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
				<div className='flex flex-col'>
					<button className='h-[40px] w-[350px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer' onClick={() => setModalIsOpen(true)}>
						{selRegister ? (
							<div className='flex px-4 justify-start text-left items-center'>
								<FaSearchMinus className='fill-[#404040]' />
								<p className='text-darkBlue w-full pl-3'>{selRegister?.title}</p>
							</div>
						) : (
							<div className='flex px-4 justify-between items-center'>
								<FaSearch className='fill-[#404040]' />
								<p className='text-[#404040] w-full pl-3'>Одабир Националног прописа</p>
							</div>
						)}
					</button>
				</div>
			</div>
			<div className='w-full flex justify-end mt-6 items-center'>
				<Button variant='contained' sx={{ backgroundColor: '#0F2765' }} onClick={createInChargeInst}>
					Потврди
				</Button>
			</div>
			{items?.length > 0 && (
				<div>
					<div className='text-[#04438b]'>Институције задужене за спровођење правног оквира</div>
					<div className='mt-5 h-[430px] w-full'>
						<DataGrid
							rowCount={rowCountState}
							loading={isLoading}
							pagination
							disableColumnMenu
							paginationMode='server'
							onPageChange={(newPage) => setPageAndCall(newPage)}
							onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
							page={page}
							pageSize={pageSize}
							rows={items}
							columns={columns}
							style={{ zIndex: 0 }}
							rowsPerPageOptions={[5]}
							disableSelectionOnClick
							components={{
								NoRowsOverlay: () => (
									<Stack height='100%' alignItems='center' justifyContent='center'>
										Не постоје NPAA поглавља
									</Stack>
								),
								NoResultsOverlay: () => (
									<Stack height='100%' alignItems='center' justifyContent='center'>
										Не постоје NPAA поглавља
									</Stack>
								),
							}}
						/>
					</div>
				</div>
			)}
			<ChooseNatRegisterModal modalIsOpen={modalIsOpen} closeModal={closeModal} setSelRegister={setSelRegister} type={TYPE_MAP[registerType as keyof typeof TYPE_MAP]} modalTitle={'Одабир домаћег прописа '} />
		</div>
	);
}

export default InstitutionsInCharge;
