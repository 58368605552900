import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

function HelpButton({ onClick = () => {} }) {
	return (
		<div className='flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer' onClick={onClick}>
			<p className='rounded-full   flex items-center justify-center'>
				<FaQuestionCircle />
			</p>
			<p className=''>Упутство</p>
		</div>
	);
}

export default HelpButton;
