import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tab,
  Tabs,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import {
  FaCaretDown,
  FaFile,
  FaSearch,
  FaSearchMinus,
  FaTrash,
} from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../auth/UseUser';
import {
  deleteHarmonization,
  editHarmonizationCompatibility,
  getHarmonizations,
  getNoCelexRelations,
  getScreeningGroups,
  insertHarmonization,
} from '../../../../server/server';
import ChooseEuRegisterModal from '../../../modals/ChooseEuRegisterModal';
import { notif } from '../../../utils/util';
import ConfirmationModal from '../../../modals/ConfirmationModal';
import EditTUFileModal from '../../../modals/EditTUFileModal';

function EuRegisters({ setAvalible, avalible }: any) {
  const { user } = useUser();
  const [value, setValue] = useState(0);
  const [searchParams] = useSearchParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selRegister, setSelRegister] = useState<any>(null);
  const [noCelexRels, setNoCelexRels] = useState<any[]>([]);
  const [selNoCelexRel, setSelNoCelexRel] = useState<any>(null);
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [editId, setEditId] = useState('');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [compactibilities, setCompactibilities] = useState([
    'није усклађен',
    'делимично усклађен',
    'поптпуно усклађен',
  ]);
  const [selTU, setSelTU] = useState<any>();
  const [tuModalIsOpen, setTuModalIsOpen] = useState(false);

  const [selComp, setSelComp] = useState('');
  const isAdmin = () => {
    return user?.roleView?.features?.includes('administration');
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const deleteRelation = async () => {
    const resp = await deleteHarmonization(deleteId);

    if (resp.status === 200) {
      notif('Релација је успешно обрисана', 'success');
      loadHarmonizations(0, editId);
    } else {
      notif('Релација није обрисана обрисана', 'error');
    }
  };
  const deleteRel = () => {
    deleteRelation();
    setDeleteModalIsOpen(false);
  };
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteModalIsOpen(true);
        setDeleteId(id);
      });
    },
    []
  );

  const createHarmonization = async () => {
    if ((selRegister?.celexNo && selComp) || (selNoCelexRel?.id && selComp)) {
      const resp = await insertHarmonization({
        celexNo: selRegister?.celexNo,
        nationalMeasure: parseInt(editId),
        noCelexRelation: selNoCelexRel?.id,
        compatibility:
          COMPACTIBILITY_MAP_REVERSE[
          selComp as keyof typeof COMPACTIBILITY_MAP_REVERSE
          ],
      });
      if (resp?.status === 201) {
        setSelRegister(null);
        setSelComp('');
        setIsLoading(true);
        setRowCountState(6);
        setPage(0);
        loadHarmonizations(0, editId);
        setAvalible({
          ...avalible,
          3: true,
        });
      }
    } else {
      notif(
        `Унос није успео: Обавезно је одабрати  ${selComp ? 'ЕУ пропис' : 'степен усклађености'
        }`,
        'error'
      );
    }
  };
  const isThere = () => {
    const ids = items?.map((item: any) => item?.celexNo);
    if (selNoCelexRel) {
      return false;
    }
    return !(selRegister && !ids?.includes(selRegister?.celexNo));
  };
  // const deleteItem = React.useCallback(
  //   (id: GridRowId) => () => {
  //     delSelectedHarmonization(id);
  //     setTimeout(() => {
  //       setItems((prevRows: any) =>
  //         prevRows.filter((row: any) => row.id !== id)
  //       );
  //     });
  //   },
  //   []
  // );
  const loadNoCelexRels = async () => {
    const resp = await getNoCelexRelations();

    if (resp?.status === 200) {
      setNoCelexRels(resp.data);
    }
  };
  const loadHarmonizations = async (offset: any = 0, pid: any) => {
    const resp = await getHarmonizations(
      offset,
      pageSize,
      searchParams.get('id')
    );

    if (resp?.status === 200) {
      console.log(resp.data?.harmonizations, 'aaaaaaaaaaaaaaaaaaaat');

      setItems(resp.data?.harmonizations);
      setRowCountState(resp?.data?.total);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadNoCelexRels();
    let eId = searchParams.get('id');
    if (eId) {
      setEditId(eId);
      loadHarmonizations(0, eId);
    }
  }, []);

  const COMPACTIBILITY_MAP = {
    0: 'није усклађен',
    1: 'делимично усклађен',
    2: 'поптпуно усклађен',
  };
  const COMPACTIBILITY_MAP_REVERSE = {
    'није усклађен': '0',
    'делимично усклађен': '1',
    'поптпуно усклађен': '2',
  };
  const closeTUModal = () => {
    setTuModalIsOpen(false);
  };
  const changeComp = async (newComp: any, id: any) => {
    const resp = await editHarmonizationCompatibility({
      id,
      compatibility:
        COMPACTIBILITY_MAP_REVERSE[
        newComp as keyof typeof COMPACTIBILITY_MAP_REVERSE
        ],
    });
    if (resp?.status === 201) {
      console.log('test1', editId);
      setIsLoading(true);
      setItems([]);
      loadHarmonizations(0, editId);
    }
  };

  const delSelectedHarmonization = async (id: any) => {
    const resp = await deleteHarmonization(id);

    if (resp.status === 200) {
      notif('Усклађеност је успешно обрисана', 'success');
    } else {
      notif('Усклађеност је није обрисана', 'error');
    }
  };

  const CustomComponent = (params: any) => {
    const ref = useGridApiContext();
    return (
      <div
        className="flex items-center justify-between w-full"
        onClick={(event) => {
          event.stopPropagation(); // to not select row
          ref.current.startCellEditMode({
            id: params.id,
            field: params.field,
          });
        }}
      >
        <div>
          {COMPACTIBILITY_MAP[params?.value as keyof typeof COMPACTIBILITY_MAP]}
        </div>
        <FaCaretDown className="fill-[#707070]" />
      </div>
    );
  };
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadHarmonizations(page * pageSize, editId);
  };
  const editUser = React.useCallback(
    (tu: any) => () => {
      setSelTU(tu);
      setTuModalIsOpen(true);
    },
    []
  );
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'celexNo',
        headerName: 'Celex ознака',
        flex: 1,
        maxWidth: 140,
        minWidth: 140,
        renderCell: (params) => params?.value ?? '-',
      },
      {
        field: 'name',
        headerName: 'Назив прописа ЕУ (на енглеском језику)',
        flex: 1,
      },
      {
        field: 'compatibility',
        editable: true,
        headerName: 'Усклађеност',
        maxWidth: 200,
        minWidth: 200,
        renderCell: CustomComponent,
        type: 'singleSelect',
        renderEditCell: (params) => {
          return (
            <Autocomplete
              disablePortal
              options={
                isAdmin()
                  ? compactibilities
                  : ['делимично усклађен', 'поптпуно усклађен']
              }
              disableClearable
              value={
                COMPACTIBILITY_MAP[
                params.row.compatibility as keyof typeof COMPACTIBILITY_MAP
                ]
              }
              filterOptions={(x) => x}
              onChange={(_, value) => {
                changeComp(value, params.row.id);
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} />}
            />
          );
        },
      },
      {
        field: 'username',
        headerName: 'Корисник',
        maxWidth: 150,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 100,
        getActions: (params: any) =>
          isAdmin()
            ? [
              <GridActionsCellItem
                icon={
                  <FaFile
                    style={{
                      width: 20,
                      height: 20,
                      color: params?.row?.fileId ? '#0f2765' : 'gray',
                    }}
                  />
                }
                sx={{ width: 50, height: 50 }}
                label="Ажурирај"
                onClick={editUser(params.row)}
              />,
              <GridActionsCellItem
                icon={<FaTrash style={{ width: 20, height: 20 }} />}
                sx={{ width: 50, height: 50 }}
                label="Обриши"
                onClick={deleteItem(params.row.id)}
              />,
            ]
            : [
              <GridActionsCellItem
                icon={
                  <FaFile
                    style={{
                      width: 20,
                      height: 20,
                      color: params?.row?.fileId ? '#0f2765' : 'gray',
                    }}
                  />
                }
                sx={{ width: 50, height: 50 }}
                label="Ажурирај"
                onClick={editUser(params.row)}
              />,
            ],
      },
    ],
    [deleteItem]
  );
  return (
    <div className="">
      <div className="flex ">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col items-start gap-2"></div>
        </div>

        <div className="flex flex-col gap-4 w-full justify-between">
          <div className="flex items-center  justify-between"></div>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <Tabs
              value={value}
              onChange={(e, nvalue) => setValue(nvalue)}
              aria-label="basic tabs example"
            >
              <Tab label="Одабир ЕУ прописа" />
              <Tab label="Назив међ. кон. која нема број" />
            </Tabs>

            {value === 0 && (
              <Box sx={{ p: 3 }}>
                <div className="flex items-start gap-6 flex-col">
                  <button
                    className="h-[40px] w-[350px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer"
                    onClick={() => setModalIsOpen(true)}
                  >
                    {selRegister ? (
                      <div className="flex px-4 justify-start text-left items-center">
                        <FaSearchMinus className="fill-[#404040]" />
                        <p className="text-darkBlue w-full pl-3">
                          {selRegister?.celexNo}
                        </p>
                      </div>
                    ) : (
                      <div className="flex px-4 justify-between items-center">
                        <FaSearch className="fill-[#404040]" />
                        <p className="text-[#404040] w-full pl-3">
                          Одабир ЕУ прописа
                        </p>
                      </div>
                    )}
                  </button>
                  <div className="flex items-center gap-6">
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                      <InputLabel id="demo-select-small">
                        Усклађеност
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selComp || ''}
                        onChange={(e) => setSelComp(e.target.value)}
                        label="Усклађеност"
                        fullWidth
                        className="capitalize"
                      >
                        {compactibilities?.map((c) => {
                          return (
                            <MenuItem value={c} className="capitalize" key={c}>
                              {c}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Box>
            )}

            {value === 1 && (
              <Box sx={{ p: 3 }}>
                <div className="flex items-start gap-6 flex-col">
                  <Autocomplete
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    value={selNoCelexRel}
                    size="small"
                    onChange={(e: any, newValue: any) => {
                      setSelNoCelexRel(newValue);
                    }}
                    id="controllable-states-demo"
                    options={noCelexRels}
                    getOptionLabel={(option) => option?.name}
                    sx={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Назив међ. кон. која нема број, али је релевантна за ЕУ"
                      />
                    )}
                  />
                  <div className="flex items-center gap-6">
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                      <InputLabel id="demo-select-small">
                        Усклађеност
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selComp}
                        onChange={(e) => {
                          setSelComp(e.target.value);
                        }}
                        label="Усклађеност"
                        fullWidth
                        className="capitalize"
                      >
                        {compactibilities?.map((c) => {
                          return (
                            <MenuItem value={c} className="capitalize" key={c}>
                              {c}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Box>
            )}
          </Box>
        </div>
      </div>
      <div className="w-full flex justify-end items-center">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          onClick={createHarmonization}
          disabled={isThere()}
        >
          Потврди
        </Button>
      </div>
      {items?.length > 0 && (
        <div>
          <div className="text-[#04438b]">Повезаност са ЕУ прописом</div>
          <div className="mt-5 h-[430px] w-full">
            <DataGrid
              rowCount={rowCountState}
              loading={isLoading}
              pagination
              disableColumnMenu
              paginationMode="server"
              onPageChange={(newPage) => setPageAndCall(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              page={page}
              pageSize={pageSize}
              experimentalFeatures={{ newEditingApi: true }}
              rows={items}
              columns={columns}
              style={{ zIndex: 0 }}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје NPAA поглавља
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје NPAA поглавља
                  </Stack>
                ),
              }}
            />
          </div>
        </div>
      )}
      <ConfirmationModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={closeDeleteModal}
        confirmation={deleteRel}
        message={`Обриши релацију са  прописом?`}
      />
      <ChooseEuRegisterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setSelRegister={setSelRegister}
        selRegister={selRegister}
      />
      <EditTUFileModal
        modalIsOpen={tuModalIsOpen}
        closeModal={closeTUModal}
        confirmation={() => loadHarmonizations(0, editId)}
        fileId={selTU?.fileId}
        id={selTU?.tuid}
      />
    </div>
  );
}

export default EuRegisters;
