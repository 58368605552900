import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaFileWord, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  getAllInstitutions,
  getEDoc,
  getExpenditures,
  getFinDoc,
  getMeasures,
  getRegistersManagement,
} from '../../../server/server';
import download from 'downloadjs';
import { exportToWord } from '../common/DownloadFinTable';
import HelpPageModal from '../../modals/HelpPageModal';
import HelpButton from '../../../common/HelpButton';
function ExpidentureView() {
  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [translation, setTranslation] = useState<any>(null);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>();
  const [fileLoading, setFileLoading] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };

  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const loadRegisters = async (offset: any = 0) => {
    const resp = await getExpenditures(pageSize, offset, '', term);

    if (resp?.status) {
      setIsLoading(false);
      setRegisters(resp?.data?.items);
      setRowCountState(resp?.data?.total);
    }
  };

  const loadInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status) {
      setInstitutions(resp?.data);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setRegisters((prevRows: any) =>
          prevRows.filter((row: any) => row.id !== id)
        );
      });
    },
    []
  );
  const editItem = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/register/measure/edit?id=' + id);
    },
    []
  );
  const downloadWord = async (selRegister: any) => {
    console.log(selRegister);
    // setFileLoading(true);
    const resp = await getEDoc(selRegister?.id);
    if (resp?.status === 200) {
      setFileLoading(false);
      download(resp?.data?.data, resp?.data?.name, resp?.data?.type);
    }
  };
  const STATUS_MAP = {
    0: 'Неостварено',
    1: 'Делимично остварено',
    2: 'Остварено',
  };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'measure',
        headerName: 'Назив прописа',
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <FaFileWord className="text-[#043e81ae] w-5 h-5 cursor-pointer hover:text-[#043e81]" />
            }
            sx={{ width: 50, height: 50 }}
            label="Ажурирај"
            onClick={() => downloadWord(params?.row)}
          />,
        ],
      },
    ],
    [deleteItem, editItem]
  );
  useEffect(() => {
    loadRegisters();
    loadInstitutions();
  }, []);

  useEffect(() => {
    loadRegisters();
  }, [term, defaultInstitution, translation]);

  const translations = [
    { id: 0, name: 'Неостварено' },
    { id: 1, name: 'Делимично остварено' },
    { id: 2, name: 'Остварено' },
  ];

  const handleTranslationsChange = (e: SelectChangeEvent) => {
    const t = translations.find((r) => r?.name === e.target.value);
    setTranslation(t as any);
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Преглед издатака/извора финансирања
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">По Називу</p>
            <TextField
              label="Назив издатка"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-5 h-[430px] w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            disableColumnMenu
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'expidentureView'}
      />
    </div>
  );
}

export default ExpidentureView;
