import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  FaFile,
  FaFileDownload,
  FaFileExport,
  FaPaperclip,
  FaTimes,
  FaUpload,
} from 'react-icons/fa';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import {
  deleteTUFile,
  getEFileById,
  getTUFileById,
  uploadEFile,
  uploadTUFile,
} from '../../server/server';
import { notif } from '../utils/util';
import download from 'downloadjs';
function EditEFileModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  confirmation,
  fileId,
  id,
}: any) {
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [file, setFile] = React.useState<any>(null);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      setFile(acceptedFiles[0]);
    },
    [file]
  );
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      // accept: {
      //   'text/csv': [],
      // },
    });
  useEffect(() => {
    ReactModal.setAppElement('#root');
    setFile(null);
    setUploadingLoading(false);
  }, []);

  useEffect(() => {
    console.log('Loading file');
    if (fileId) loadFile();
  }, [fileId]);

  const inputRef = useRef(null);

  const handleChange = (newFile: any) => {
    setFile(newFile);
  };
  const submitConfirm = () => {
    // confirmation();
  };

  const uploadFile = async () => {
    if (file) {
      setUploadingLoading(true);
      const resp = await uploadEFile(file, id);

      if (resp?.status === 201) {
        setUploadingLoading(false);
        notif('Датотека успешно додата', 'success');
        closeModal();
        confirmation();
        // removeFile();
      } else {
        setUploadingLoading(false);
        notif('Датотека није додата', 'error');
      }
    } else {
      if (fileId) {
        removeFile();
      }
      closeModal();
      confirmation();
    }
  };
  const removeFile = async () => {
    const resp = await deleteTUFile(fileId);

    if (resp?.status === 200) {
      setFile(null);
      notif('Датотека успешно обрисана', 'success');
    } else {
      setUploadingLoading(false);
      notif('Датотека није обрисана', 'error');
    }
  };

  const loadFile = async () => {
    const resp = await getEFileById(id);

    if (resp?.status === 200) {
      setFile(resp?.data);
    }
  };

  const downloadFile = async () => {
    if (file?.data) download(file?.data, file?.name, file?.type);
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 600,
          height: 350,
          padding: 0,
          opacity: 1,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
        <p className="text-[#04438b] text-2xl font-bold">Одабир датотеке</p>

        <div className="flex flex-col items-center justify-center">
          {file?.name ? (
            <div
              className="flex items-center cursor-pointer group hover:border-[#0f6523] justify-between border-[#919191] rounded-lg border w-[350px] px-3 h-[60px]"
              onClick={downloadFile}
            >
              <div className="flex items-center gap-4">
                <FaFile
                  fill="#0f2765"
                  className="w-[25px] h-[25px] group-hover:hidden"
                />
                <FaFileDownload
                  fill="#0f6523"
                  className="w-[25px] h-[25px] hidden group-hover:flex"
                />
                <p className="text-[#81848f] group-hover:text-[#0f6523] text-base mt-2 h-[30px]">
                  {file?.name?.length > 30
                    ? file?.name.substring(0, 30) + '...'
                    : file?.name}
                </p>
              </div>
              <FaTimes
                className="w-[20px] h-[20px] cursor-pointer fill-[#d32929]"
                onClick={(e) => {
                  e.stopPropagation();
                  setFile(null);
                }}
              />
            </div>
          ) : (
            <div
              {...getRootProps({ className: 'dropzone' })}
              className="cursor-pointer w-[350px] h-28 mb-4 text-[#81848f] flex items-center justify-center p-3 border-dashed border-2 border-[#434343] rounded-xl"
            >
              <input {...getInputProps()} multiple={false} />
              <p>Кликни или превуци датотеку</p>
            </div>
          )}

          {/* <LoadingButton
            variant="contained"
            sx={{
              backgroundColor: '',
              color: 'white !important',
              '& .MuiCircularProgress-svg': { color: 'white' },
              '& .MuiLoadingButton-root': { color: 'white' },
              '&  .MuiLoadingButton-loading': { color: 'white' },
            }}
            endIcon={<FaUpload />}
            loading={uploadingLoading}
            loadingPosition="end"
            onClick={uploadFile}
          >
            Додај датотеку
          </LoadingButton> */}
        </div>
        <div className="flex flex-row justify-between items-center w-full mt-6">
          <LoadingButton
            variant="contained"
            sx={{
              backgroundColor: '#0f2765',
              borderRadius: '0.5rem',
              color: 'white !important',
              '& .MuiCircularProgress-svg': { color: 'white' },
              '& .MuiLoadingButton-root': { color: 'white' },
              '&  .MuiLoadingButton-loading': { color: 'white' },
            }}
            className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]"
            endIcon={<FaUpload />}
            loading={uploadingLoading}
            loadingPosition="end"
            onClick={uploadFile}
          >
            Потврди
          </LoadingButton>
          <div
            className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => closeModal()}
          >
            Oткажи
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditEFileModal;
