import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChooseNatRegisterModal from '../modals/ChooseNatRegisterModal';
import { FaFile, FaSave, FaSearch, FaSearchMinus } from 'react-icons/fa';
import DynamicTable from './srceens/finExpense/dynamic-table';
import {
  addExpenditure,
  getEFiles,
  getExpenditure,
  getExpenditureInfo,
  getExpenditureTypes,
} from '../../server/server';
import { notif } from '../utils/util';
import { useNavigate } from 'react-router-dom';
import RadioButtonGroup from '../../common/RadioButton';
import HeaderTable from './srceens/finExpense/header-table';
interface Item {
  year: number;
  type: string;
  value: number;
}
function InsertFinExpense({ selRegister, eid }: any) {
  const navigate = useNavigate();
  const [years, setYears] = useState([2024, 2025]);
  const [harmonizationNote, setHarmonizationNote] = useState('');
  const [capacityNote, setCapacityNote] = useState('');
  const [newInstNote, setNewInstNote] = useState('');
  const [newWorkUnitNote, setNewWorkUnitNote] = useState('');
  const [planInvestmentsNote, setPlanInvestmentsNote] = useState('');
  const [allTypesNote, setAllTypesNote] = useState('');
  const [data, setData] = useState<Item[]>([]);
  const [data1, setData1] = useState<Item[]>([]);
  const [data2, setData2] = useState<Item[]>([]);
  const [data3, setData3] = useState<Item[]>([]);
  const [data4, setData4] = useState<Item[]>([]);

  const [showMain, setShowMain] = useState<any>(null);
  const [showHarmonization, setShowHarmonization] = useState<any>(null);
  const [showCapacity, setShowCapacity] = useState<any>(null);
  const [newInst, setNewInst] = useState<any>(null);
  const [newWorkUnit, setNewWorkUnit] = useState<any>(null);
  const [planInvestments, setPlanInvestments] = useState<any>(null);

  const [allTypes, setAllTypes] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [showHarmonizationError, setShowHarmonizationError] = useState(false);

  const [showCapacityError, setShowCapacityError] = useState(false);
  const [newInstError, setNewInstError] = useState(false);
  const [newWorkUnitError, setNewWorkUnitError] = useState(false);
  const [planInvestmentsError, setPlanInvestmentsError] = useState(false);

  const [noteErrors, setNoteErrors] = useState({
    capacityNote: false,
    newInstNote: false,
    newWorkUnitNote: false,
    planInvestmentsNote: false,
  });

  const [files, setFiles] = useState<any>([]);
  const loadTypes = async () => {
    const resp = await getExpenditureTypes();
    if (resp?.status === 200) {
      setAllTypes(resp?.data);
    }
  };

  useEffect(() => {
    loadTypes();
    if (selRegister?.id) {
      loadFiles();

      loadExpenditure();
    }
  }, []);
  const findTypeIdByName = (typeName: any) => {
    const type = allTypes.find((t: any) => t.name === typeName);
    return type ? type.id : 0;
  };
  const handleSave = async () => {
    // Create an object to track unique combinations of year and type
    const uniqueCombinations: { [key: string]: boolean } = {};

    // Define a function to check if a combination is unique
    const isUnique = (year: number, typeId: number) => {
      const key = `${year}-${typeId}`;
      if (uniqueCombinations[key]) {
        console.log('Found duplicate:', key);
        return false;
      }
      uniqueCombinations[key] = true;
      return true;
    };
    let noteErrors = {
      capacityNote: false,
      newInstNote: false,
      newWorkUnitNote: false,
      planInvestmentsNote: false,
    };

    // Check if each RadioButtonGroup state is null and set isError state accordingly
    let errors = []; // Define an errors array

    if (showHarmonization === null) {
      setShowHarmonizationError(true);
      errors.push(true);
    }
    // If any of the RadioButtonGroup states is null, add an error to the array
    if (showCapacity === null) {
      setShowCapacityError(true);
      errors.push(true);
    }
    if (newInst === null) {
      setNewInstError(true);
      errors.push(true);
    }
    if (newWorkUnit === null) {
      setNewWorkUnitError(true);
      errors.push(true);
    }
    if (planInvestments === null) {
      setPlanInvestmentsError(true);
      errors.push(true);
    }

    // If the corresponding RadioButtonGroup state is null and the corresponding note is empty, set the note error
    if (showCapacity && capacityNote === '') {
      noteErrors.capacityNote = true;
      errors.push(true);
    }
    if (newInst && newInstNote === '') {
      noteErrors.newInstNote = true;
      errors.push(true);
    }
    if (newWorkUnit && newWorkUnitNote === '') {
      noteErrors.newWorkUnitNote = true;
      errors.push(true);
    }
    if (planInvestments && planInvestmentsNote === '') {
      noteErrors.planInvestmentsNote = true;
      errors.push(true);
    }

    // Set the note errors state
    setNoteErrors(noteErrors);

    // If there are any errors, prevent the save operation and display an error notification
    if (errors.length > 0) {
      notif('Молимо вас да попуните сва обавезна поља', 'error');
      return;
    }
    const allDataWithTypeIds = [
      {
        show: showMain ?? false,
        type: { id: 99 },
        measure: { id: selRegister?.id },
        comment: null,
        expenditures: [],
      },
      {
        comment: harmonizationNote,
        show: showHarmonization ?? false,
        type: { id: 1 },
        measure: { id: selRegister?.id },
        expenditures: data
          .map((item) => ({
            year: item?.year,
            value: item?.value,
            type: { id: findTypeIdByName(item.type) },
          }))
          .filter((item) => isUnique(item.year, item.type.id)),
      },
      {
        comment: capacityNote,
        show: showCapacity ?? false,
        type: { id: 2 },
        measure: { id: selRegister?.id },
        expenditures: data1
          .map((item) => ({
            year: item?.year,
            value: item?.value,
            type: { id: findTypeIdByName(item.type) },
          }))
          .filter((item) => isUnique(item.year, item.type.id)),
      },
      {
        comment: newInstNote,
        show: newInst ?? false,
        type: { id: 3 },
        measure: { id: selRegister?.id },
        expenditures: data2
          .map((item) => ({
            year: item?.year,
            value: item?.value,
            type: { id: findTypeIdByName(item.type) },
          }))
          .filter((item) => isUnique(item.year, item.type.id)),
      },
      {
        comment: newWorkUnitNote,
        show: newWorkUnit ?? false,
        type: { id: 4 },
        measure: { id: selRegister?.id },
        expenditures: data3
          .map((item) => ({
            year: item?.year,
            value: item?.value,
            type: { id: findTypeIdByName(item.type) },
          }))
          .filter((item) => isUnique(item.year, item.type.id)),
      },
      {
        comment: planInvestmentsNote,
        show: planInvestments ?? false,
        type: { id: 5 },
        measure: { id: selRegister?.id },
        expenditures: data4
          .map((item) => ({
            year: item?.year,
            value: item?.value,
            type: { id: findTypeIdByName(item.type) },
          }))
          .filter((item) => isUnique(item.year, item.type.id)),
      },
    ];
    // .filter((group) => group.expenditures.length > 0);

    const resp = await addExpenditure(allDataWithTypeIds);
    if (resp.status === 201) {
      notif(`Издатак успешно  ${eid ? 'ажуриран' : 'креиран'}`, 'success');
      navigate('/register/financial/expenditure/insert?id=' + resp?.data?.id);
    } else {
      notif(`Издатак није  ${eid ? 'ажуриран' : 'креиран'}`, 'error');
    }
  };

  useEffect(() => {
    if (dataLoaded) return;

    if (allTypes.length > 0 && years.length > 0) {
      const initializeData = (types: string[]) => {
        return years.flatMap((year) =>
          types.map((type) => ({
            year: year,
            type: type,
            value: 0,
          }))
        );
      };

      // Define the specific types for each data set
      const typesData = [
        'Промена броја запослених',
        '5.1.1 Расходи за запослене',
        '5.2.2 Коришћење роба и услуга',
        '5.2.3 Остало',
      ];

      const typesData1 = [
        'Промена броја запослених',
        '5.2.1 расходи за запослене',
        '5.2.2 коришћење услуга и роба',
        '5.2.3 инвестиције у институцију',
        '5.2.4 остало',
      ];

      const typesData2 = [
        'Промена броја запослених',
        '5.3.1 расходи за запослене',
        '5.3.2 коришћење услуга и роба',
        '5.3.3 инвестиције у институцију',
        '5.3.4 остало',
      ];

      const typesData3 = [
        'Промена броја запослених',
        '5.3.2.1 расходи за запослене',
        '5.3.2.2 коришћење услуга и роба',
        '5.3.2.3 инвестиције у институцију',
        '5.3.2.4 остало',
      ];

      const typesData4 = [
        '5.4.1 припрема инвестиције',
        '5.4.2 реализација инвестиције',
      ];

      // Initialize data states with the respective types and years
      setData(initializeData(typesData));
      setData1(initializeData(typesData1));
      setData2(initializeData(typesData2));
      setData3(initializeData(typesData3));
      setData4(initializeData(typesData4));
    }
  }, [allTypes]);

  const loadExpenditure = async () => {
    const response: any = await getExpenditure(selRegister?.id);
    if (response?.status === 200 && response?.data?.length > 0) {
      setDataLoaded(true);
      const allExpenditures: any[] = response.data.flatMap(
        (fundSource: any) => fundSource?.expenditures || []
      );

      const dataResponse = allExpenditures.filter((item: any) =>
        [
          'Промена броја запослених',
          '5.1.1 Расходи за запослене',
          '5.2.2 Коришћење роба и услуга',
          '5.2.3 Остало',
        ].includes(item.type.name)
      );

      const data1Response = allExpenditures.filter((item: any) =>
        [
          'Промена броја запослених',
          '5.2.1 расходи за запослене',
          '5.2.2 коришћење услуга и роба',
          '5.2.3 инвестиције у институцију',
          '5.2.4 остало',
        ].includes(item.type.name)
      );

      const data2Response = allExpenditures.filter((item: any) =>
        [
          'Промена броја запослених',
          '5.3.1 расходи за запослене',
          '5.3.2 коришћење услуга и роба',
          '5.3.3 инвестиције у институцију',
          '5.3.4 остало',
        ].includes(item.type.name)
      );

      const data3Response = allExpenditures.filter((item: any) =>
        [
          'Промена броја запослених',
          '5.3.2.1 расходи за запослене',
          '5.3.2.2 коришћење услуга и роба',
          '5.3.2.3 инвестиције у институцију',
          '5.3.2.4 остало',
        ].includes(item.type.name)
      );

      const data4Response = allExpenditures.filter((item: any) =>
        [
          '5.4.1 припрема инвестиције',
          '5.4.2 реализација инвестиције',
        ].includes(item.type.name)
      );

      setData(
        dataResponse.map((item: any) => ({
          year: item.year,
          type: item.type.name,
          value: parseFloat(item.value),
        }))
      );

      setData1(
        data1Response.map((item: any) => ({
          year: item.year,
          type: item.type.name,
          value: parseFloat(item.value),
        }))
      );

      setData2(
        data2Response.map((item: any) => ({
          year: item.year,
          type: item.type.name,
          value: parseFloat(item.value),
        }))
      );

      setData3(
        data3Response.map((item: any) => ({
          year: item.year,
          type: item.type.name,
          value: parseFloat(item.value),
        }))
      );

      setData4(
        data4Response.map((item: any) => ({
          year: item.year,
          type: item.type.name,
          value: parseFloat(item.value),
        }))
      );

      if (response.data && response.data.length > 0) {
        const mainItem = response.data.find(
          (item: any) => item?.type?.id === 99
        );
        const harmonizationItem = response.data.find(
          (item: any) => item?.type?.id === 1
        );
        const capacityItem = response.data.find(
          (item: any) => item?.type?.id === 2
        );
        const newInstItem = response.data.find(
          (item: any) => item?.type?.id === 3
        );
        const newWorkUnitItem = response.data.find(
          (item: any) => item?.type?.id === 4
        );
        const planInvestmentsItem = response.data.find(
          (item: any) => item?.type?.id === 5
        );

        setHarmonizationNote(
          harmonizationItem ? harmonizationItem.comment : ''
        );
        setCapacityNote(capacityItem ? capacityItem.comment : '');
        setNewInstNote(newInstItem ? newInstItem.comment : '');
        setNewWorkUnitNote(newWorkUnitItem ? newWorkUnitItem.comment : '');
        setPlanInvestmentsNote(
          planInvestmentsItem ? planInvestmentsItem.comment : ''
        );
        setShowMain(mainItem ? mainItem.show : null);
        setShowHarmonization(harmonizationItem ? harmonizationItem.show : null);
        setShowCapacity(capacityItem ? capacityItem.show : null);
        setNewInst(newInstItem ? newInstItem.show : null);
        setNewWorkUnit(newWorkUnitItem ? newWorkUnitItem.show : null);
        setPlanInvestments(
          planInvestmentsItem ? planInvestmentsItem.show : null
        );
      }

      const uniqueYears = Array.from(
        new Set(allExpenditures.map((item: any) => item?.year))
      ).sort((a, b) => a - b);
      setYears(uniqueYears);
    }
  };

  const loadFiles = async () => {
    const resp = await getEFiles(selRegister?.id);
    if (resp?.status === 200) setFiles(resp?.data);
  };

  useEffect(() => {
    setNoteErrors({
      capacityNote: false,
      newInstNote: false,
      newWorkUnitNote: false,
      planInvestmentsNote: false,
    });
  }, [capacityNote, newInstNote, newWorkUnitNote, planInvestmentsNote]);

  const checkData = (data: any[], comm: string) => {
    if (comm !== '') {
      return true; // Return true if comm is not an empty string
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i].value > 0) {
        return true; // Exit and return true as soon as a positive value is encountered
      }
    }

    return false;
  };

  return (
    <div>
      <div className="flex flex-col mt-10 text-sm">
        {/* <p className="w-[300px] text-[#0a0303] my-4 text-sm">
          Додатни трошкови потребни за увођење / имплементацију индивидуалног
          „правног акта / инвестиције"
        </p> */}
        <div className="my-4">
          <div className="flex items-center">
            <p className="w-[300px] text-[#4a4a4a] my-4 text-sm">
              Да ли су потребни додатни трошкови за увођење/имплементацију
              индивидуалног „правног акта/инвестиције"?
            </p>
            <RadioButtonGroup
              state={showHarmonization}
              setState={setShowHarmonization}
              isError={showHarmonizationError}
              setIsError={setShowHarmonizationError}
              isNoDisabled={checkData(data, harmonizationNote)}
            />
          </div>
          {showHarmonization && (
            <div className="flex flex-col gap-2">
              <DynamicTable
                years={years}
                setYears={setYears}
                data={data}
                setData={setData}
                text={'5.1 ПРАВНО УСКЛАЂИВАЊЕ (ХАРМОНИЗАЦИЈА)'}
                allType={allTypes?.filter((t: any) =>
                  [
                    'Промена броја запослених',
                    '5.1.1 Расходи за запослене',
                    '5.2.2 Коришћење роба и услуга',
                    '5.2.3 Остало',
                  ]?.includes(t?.name)
                )}
              />
              <div className="relative">
                <textarea
                  id="message"
                  rows={4}
                  className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                  placeholder="Образложење"
                  value={harmonizationNote}
                  onChange={(e) => setHarmonizationNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          )}
        </div>

        <div className="my-4">
          <div className="flex items-center">
            <p className="w-[300px] text-[#4a4a4a] my-4 text-sm">
              Да ли је потребно јачати капацитете постојећег органа?
            </p>
            <RadioButtonGroup
              state={showCapacity}
              setState={setShowCapacity}
              isError={showCapacityError}
              setIsError={setShowCapacityError}
              isNoDisabled={checkData(data1, capacityNote)}
            />
          </div>
          {showCapacity && (
            <div className="flex flex-col gap-2">
              <DynamicTable
                years={years}
                setYears={setYears}
                data={data1}
                setData={setData1}
                text={'5.2 имплементација - постојећи орган'}
                allType={allTypes?.filter((t: any) =>
                  [
                    'промена броја запослених',
                    '5.2.1 расходи за запослене',
                    '5.2.2 коришћење услуга и роба',
                    '5.2.3 инвестиције у институцију',
                    '5.2.4 остало',
                  ]?.includes(t?.name)
                )}
              />
              <div className="relative">
                {noteErrors.capacityNote && (
                  <span style={{ color: 'red' }}>Ово поље је обавезно</span>
                )}

                <textarea
                  id="message"
                  rows={4}
                  className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                  placeholder="Образложење"
                  value={capacityNote}
                  onChange={(e) => setCapacityNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          )}
        </div>
        <div className="my-4">
          <div className="flex items-center">
            <p className="w-[300px] text-[#4a4a4a] my-4 text-sm">
              Да ли је потребно основати нови орган?
            </p>

            <RadioButtonGroup
              state={newInst}
              setState={setNewInst}
              isError={newInstError}
              setIsError={setNewInstError}
              isNoDisabled={checkData(data2, newInstNote)}
            />
          </div>
          {newInst && (
            <div className="flex flex-col gap-2">
              <DynamicTable
                years={years}
                setYears={setYears}
                data={data2}
                setData={setData2}
                text={'5.3.1 имплементација - нови орган'}
                allType={allTypes?.filter((t: any) =>
                  [
                    'промена броја запослених',
                    '5.3.1 расходи за запослене',
                    '5.3.2 коришћење услуга и роба',
                    '5.3.3 инвестиције у институцију',
                    '5.3.4 остало',
                  ]?.includes(t?.name)
                )}
              />
              <div className="relative">
                {noteErrors.newInstNote && (
                  <span style={{ color: 'red' }}>Ово поље је обавезно</span>
                )}

                <textarea
                  id="message"
                  rows={4}
                  className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                  placeholder="Образложење"
                  value={newInstNote}
                  onChange={(e) => setNewInstNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          )}
        </div>
        <div className="my-4">
          <div className="flex items-center">
            <p className="w-[300px] text-[#4a4a4a] my-4 text-sm">
              Да ли је потребно основати нову организациону јединицу?
            </p>
            <RadioButtonGroup
              state={newWorkUnit}
              setState={setNewWorkUnit}
              isError={newWorkUnitError}
              setIsError={setNewWorkUnitError}
              isNoDisabled={checkData(data3, newWorkUnitNote)}
            />
          </div>
          {newWorkUnit && (
            <div className="flex flex-col gap-2">
              <DynamicTable
                years={years}
                setYears={setYears}
                data={data3}
                setData={setData3}
                text={'5.3.2 оснивање нове организационе јединице'}
                allType={allTypes?.filter((t: any) =>
                  [
                    'промена броја запослених',
                    '5.3.2.1 расходи за запослене',
                    '5.3.2.2 коришћење услуга и роба',
                    '5.3.2.3 инвестиције у институцију',
                    '5.3.2.4 остало',
                  ]?.includes(t?.name)
                )}
              />
              <div className="relative">
                {noteErrors.newWorkUnitNote && (
                  <span style={{ color: 'red' }}>Ово поље је обавезно</span>
                )}

                <textarea
                  id="message"
                  rows={4}
                  className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                  placeholder="Образложење"
                  value={newWorkUnitNote}
                  onChange={(e) => setNewWorkUnitNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          )}
        </div>
        <div className="my-4">
          <div className="flex items-center">
            <p className="w-[300px] text-[#4a4a4a] my-4 text-sm">
              Да ли је потребно планирати инвестиције?
            </p>
            <RadioButtonGroup
              state={planInvestments}
              setState={setPlanInvestments}
              isError={planInvestmentsError}
              setIsError={setPlanInvestmentsError}
              isNoDisabled={checkData(data4, planInvestmentsNote)}
            />
          </div>
          {planInvestments && (
            <div className="flex flex-col gap-2">
              <DynamicTable
                years={years}
                setYears={setYears}
                data={data4}
                setData={setData4}
                text={'5.4. Инвестиције'}
                allType={allTypes?.filter((t: any) =>
                  [
                    '5.4.1 припрема инвестиције',
                    '5.4.2 реализација инвестиције',
                  ]?.includes(t?.name)
                )}
              />
              <div className="relative">
                {noteErrors.planInvestmentsNote && (
                  <span style={{ color: 'red' }}>Ово поље је обавезно</span>
                )}

                <textarea
                  id="message"
                  rows={4}
                  className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                  placeholder="Образложење"
                  value={planInvestmentsNote}
                  onChange={(e) => setPlanInvestmentsNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full flex justify-end mt-12 items-center">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          endIcon={<FaSave />}
          onClick={handleSave}
          disabled={!selRegister}
        >
          Сачувај
        </Button>
      </div>
    </div>
  );
}

export default InsertFinExpense;
