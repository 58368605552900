import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getSDocs, insertSDocs } from '../../../../server/server';
import { FaTrash } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { notif } from '../../../utils/util';
import RadioButtonGroup from '../../../../common/RadioButton';

function StratDocs() {
  const [searchParams] = useSearchParams();

  const [toggleState, setToggleState] = useState(null);
  const [docNames, setDocNames] = useState<string[]>([]);

  const fetchData = async () => {
    const resp = await getSDocs(searchParams.get('id'));

    if (resp?.status === 200) {
      setToggleState(resp?.data?.show ?? null);
      setDocNames(resp?.data?.names ?? ['']);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  //   useEffect(() => {
  //     if (toggleState) {
  //       setDocNames(['']);
  //     }
  //   }, [toggleState]);

  const handleAddDoc = () => {
    setDocNames((prevDocs: string[]) => [...prevDocs, '']);
  };

  const handleDeleteDoc = (index: number) => {
    setDocNames((prevDocs) => {
      const newDocs = [...prevDocs];
      newDocs.splice(index, 1);
      return newDocs;
    });
  };

  const handleDocNameChange = (event: any, index: number) => {
    setDocNames((prevDocs) => {
      const newDocs = [...prevDocs];
      newDocs[index] = event.target.value;
      return newDocs;
    });
  };

  const handleSave = async () => {
    const resp = await insertSDocs({
      show: toggleState,
      names: docNames,
      nationalMeasure: { id: searchParams.get('id') },
    });

    if (resp?.status === 201) {
      notif(
        `Веза успешно ${toggleState === null ? 'додата' : 'ажурирана'}`,
        'success'
      );
    }
  };

  return (
    <div className="p-4 w-full flex flex-col">
      <p className=" text-[#151515] my-4 text-sm">
        Да ли постоји веза са стратешким документом?
      </p>
      <FormControlLabel
        sx={{ marginLeft: '0px' }}
        control={
          <RadioButtonGroup
            state={toggleState}
            setState={setToggleState}
            isError={false}
            setIsError={() => 1}
          />
        }
        label=""
      />

      {toggleState === true && (
        <div>
          {docNames?.map((name, index) => (
            <div key={index} className="flex items-center my-2 w-full pt-2">
              <TextField
                label="Назив преговарачког документа"
                variant="outlined"
                value={name}
                sx={{ width: 400, paddingRight: 2 }}
                onChange={(e) => handleDocNameChange(e, index)}
              />
              <IconButton
                onClick={() => handleDeleteDoc(index)}
                className="text-gray-500 rounded-full w-[40px] h-[40px]"
              >
                <FaTrash />
              </IconButton>
            </div>
          ))}
          <Button color="primary" onClick={handleAddDoc}>
            Додај
          </Button>
        </div>
      )}
      {toggleState !== null && (
        <div className="w-full text-right">
          <Button
            sx={{ backgroundColor: '#0F2765' }}
            variant="contained"
            color="primary"
            className="mt-4"
            onClick={handleSave}
          >
            Сачувај
          </Button>
        </div>
      )}
    </div>
  );
}

export default StratDocs;
