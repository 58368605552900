import React, { useEffect } from 'react';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import NavBar from '../NavBar';

function Layout() {
  let navigate = useNavigate();
  const { isSignedIn } = useUser();
  useEffect(() => {
    console.log('i am here : ', isSignedIn);
  }, []);

  return (
    <div className="home h-screen overflow-y-hidden">
      <div className="home-container w-full h-full">
        {isSignedIn && <NavBar />}
        <div
          className="outlet-container w-full h-full"
          style={{ height: 'calc(100vh - 117px)' }}
        >
          {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}

          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
