import toast from 'react-hot-toast';
import { parseISO, format, isValid } from 'date-fns';
import { sr } from 'date-fns/locale';
export const notif = (message: string = '', type: string = 'default') => {
  const iconMap = {
    default: '✨',
    success: '✔️',
    error: '❌',
    warning: '⚠️',
    info: '📖',
  };
  toast(message, {
    icon: iconMap[type as keyof typeof iconMap],
    style: {
      position: 'relative', // Position relative for ::before pseudo-element
      background: 'linear-gradient(135deg, #1e3c72, #2a5298)', // Formal blue gradient
      color: '#fff', // White text for contrast
      border: '1px solid #1e3c72', // to emphasize the toast
      boxShadow: '0 0 10px 1px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
      borderRadius: '4px', // Rounded corners for a softer look
      padding: '10px 20px', // Padding for better readability
      fontSize: '16px', // Font size for readability
      fontWeight: '500', // Font weight for emphasis
      display: 'flex', // Flex display for icon and text alignment
      alignItems: 'center', // Align items vertically
      justifyContent: 'space-between', // Space between icon and text
      overflow: 'hidden', // Overflow hidden for the ::before pseudo-element
    },
  });
};
export function generateYears(startYear: any, endYear: any) {
  const years = [];
  for (let year: number = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
}
export const quarterYearEncoder = (year: any, quarter: any) => {
  if (year && quarter) {
    return `${year}/${quarter}`;
  } else {
    return '';
  }
};

export function formatCyDate(inputDate: string, locale: Locale = sr): string {
  const dateObj = parseISO(inputDate);

  if (!isValid(dateObj)) {
    console.error(`Invalid date: ${inputDate}`);
    return ''; // or return a default value
  }

  const formattedDate = format(dateObj, 'dd. MMMM yyyy. HH:mm', { locale });
  return formattedDate;
}
