import { Autocomplete, Button, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { error } from 'console';
import React, { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { editNoCelex, getAdministrationNoCelexRelationById, getAllOfficialJournals, insertNoCelex, insertOJNumber } from '../../../server/server';
import { notif } from '../../utils/util';
import { useSearchParams } from 'react-router-dom';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';
function InternConvInsert() {
	const [searchParams] = useSearchParams();
	const [title, setTitle] = useState<any>(null);
	const [comment, setComment] = useState<any>(null);
	const [editId, setEditId] = useState('');
	const [errorMap, setErrorMap] = useState({ title: false, comment: false });
	const [helpModal, setHelpModal] = useState(false);

	const closeHelpModal = () => {
		setHelpModal(false);
	};
	const createNoCelex = async () => {
		const resp = await insertNoCelex({ title, comment });
		if (resp?.status === 201) {
			notif('Међународна конвенција успешно креирана', 'success');
		} else {
			notif('Међународна конвенција није креирана', 'error');
		}
	};

	const editTheNoCelex = async () => {
		const resp = await editNoCelex({ id: editId, title, comment });
		if (resp?.status === 201) {
			notif('Међународна конвенција успешно ажурирана', 'success');
		} else {
			notif('Међународна конвенција није ажурирана', 'error');
		}
	};

	const loadNoCelexById = async (id: any) => {
		const resp = await getAdministrationNoCelexRelationById(id);

		if (resp?.status === 200) {
			setTitle(resp?.data?.name);
			setComment(resp?.data?.comment);
		}
	};

	const handleInsert = () => {
		setErrorMap({
			title: !title,
			comment: !comment,
		});
		if (title && comment) {
			if (editId) {
				editTheNoCelex();
			} else {
				createNoCelex();
			}
		}
	};

	useEffect(() => {
		let eId = searchParams.get('id');
		if (eId) {
			loadNoCelexById(eId);
			setEditId(eId);
		}
	}, []);

	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<div className='flex gap-6'>
				<h1 className='text-[#04438b] text-4xl font-bold'>Унос међународне конвенције која нема број, али је релевантна за ЕУ</h1>
				<HelpButton onClick={() => setHelpModal(true)} />
			</div>
			<div className='rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 flex flex-col gap-6'>
				<div className='flex gap-4  flex-col'>
					<p className='w-[240px]'>Назив међународне конвенције</p>
					<TextField
						label='Назив међународне конвенције'
						id='outlined-size-small'
						type='intconv'
						name='intconv'
						size='small'
						sx={{ width: 500 }}
						helperText={errorMap?.title ? 'Поље је обавезно' : ''}
						error={errorMap?.title as boolean}
						onFocus={() => setErrorMap({ ...errorMap, title: false })}
						value={title || ''}
						onChange={(e) => setTitle(e.target.value)}
						// helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
						// error={errorMap?.firstName as boolean}
						// onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
						// value={firstName}
						// onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
				<div className='flex gap-4  flex-col'>
					<p className='w-[240px]'>Коментар</p>
					<TextField
						label='Коментар'
						id='outlined-size-small'
						type='intconv'
						name='intconv'
						size='small'
						sx={{ width: 500 }}
						helperText={errorMap?.comment ? 'Поље је обавезно' : ''}
						error={errorMap?.comment as boolean}
						onFocus={() => setErrorMap({ ...errorMap, comment: false })}
						value={comment || ''}
						onChange={(e) => setComment(e.target.value)}
						// helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
						// error={errorMap?.firstName as boolean}
						// onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
						// value={firstName}
						// onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>
				<div className='w-full flex justify-end mt-6 items-center'>
					<Button variant='contained' sx={{ backgroundColor: '#0F2765' }} endIcon={<FaSave />} onClick={handleInsert}>
						Сачувај
					</Button>
				</div>
			</div>
			<HelpPageModal modalIsOpen={helpModal} closeModal={closeHelpModal} name={'interConvInsert'} />
		</div>
	);
}
export default InternConvInsert;
