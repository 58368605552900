import { TextField } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import { updateHelpPage } from '../../server/server';
import { notif } from '../utils/util';

function EditPageModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  editTitle,
  editText,
  editId,
  reloadPage,
}: any) {
  const [title, setTitle] = useState<any>('');
  const [text, setText] = useState<any>('');
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);

  useEffect(() => {
    setTitle(editTitle);
    setText(editText);
  }, [editTitle, editText]);

  const updatePage = async () => {
    const resp = await updateHelpPage({ id: editId, title, text });

    if (resp.status === 201) {
      reloadPage();
      closeModal();
      notif('Упутство успешно ажурирано', 'success');
    } else {
      notif('Упутство није ажурирано', 'error');
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 800,
          padding: 0,
          opacity: 1,
          minWidth: 800,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl  h-full  p-5 flex flex-col justify-between">
        <div className="overflow-auto gray-small-scroll h-full p-5 items-center gap-4 flex flex-col">
          <h1 className="text-[#04438b] text-4xl font-bold text-left mb-4">
            Измена упутства
          </h1>
          <div className="flex items-start gap-6 w-full flex-col ">
            <p className="w-[340px]">Назив упутства</p>
            <TextField
              label="Назив упутства"
              id="outlined-size-small"
              type="title"
              name="title"
              size="small"
              sx={{ width: '100%' }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div
            className="flex items-start gap-6 flex-col w-full"
            data-color-mode="light"
          >
            <p className="w-[140px]">Текст упутства</p>
            {/* <textarea
              id="message"
              rows={18}
              value={text}
              onChange={(e) => (e.target.value)}
              className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
              placeholder="Текст упутства"
            ></textarea> */}
            <MDEditor
              value={text}
              onChange={setText}
              className="w-full"
              height={400}
              maxHeight={400}
              preview="edit"
            />
          </div>
          <p className="text-[#81848f] text-sm mt-2"></p>
        </div>
        <div className="flex flex-row justify-between items-center w-full mt-6">
          <div
            className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => updatePage()}
          >
            Потврди
          </div>
          <div
            className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => closeModal()}
          >
            Oткажи
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditPageModal;
