import React from 'react';

function Home() {
  return (
    <div className=" w-full h-full flex justify-center">
      <h1 className="text-white text-3xl">Home</h1>
    </div>
  );
}

export default Home;
