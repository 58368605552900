import React from 'react';

interface HeaderTableProps {
  text: string;
  fieldValues: {
    imeIPrezime: string;
    funkcija: string;
    brojTelefona: string;
    email: string;
  };
  setFieldValues: React.Dispatch<
    React.SetStateAction<{
      imeIPrezime: string;
      funkcija: string;
      brojTelefona: string;
      email: string;
    }>
  >;
}

const fieldMapping = {
  imeIPrezime: '2.1 Име и презиме',
  funkcija: '2.2 функција',
  brojTelefona: '2.3 број телефона',
  email: '2.4 e-mail',
};

function HeaderTable({ text, fieldValues, setFieldValues }: HeaderTableProps) {
  const updateValue = (fieldKey: keyof typeof fieldValues, value: string) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldKey]: value,
    }));
  };

  return (
    <div className="overflow-hidden rounded-lg border border-[#81848f3d]">
      <div
        className={`flex divide-x divide-[#81848f3d] text-[#0f2765] bg-[#c3c3d6] text-left text-xs font-medium uppercase tracking-wider justify-items-center`}
      >
        <div className="px-4 py-3 w-[50%]  flex items-center font-bold justify-center">
          {text}
        </div>
      </div>

      <div className="divide-y divide-[#81848f3d] text-sm">
        {Object.entries(fieldValues).map(([key, value], index) => (
          <div key={index} className={`flex divide-x divide-[#81848f3d]`}>
            <div
              className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-60 `}
            >
              {fieldMapping[key as keyof typeof fieldValues]}
            </div>
            <div
              className={`flex items-center justify-center whitespace-nowrap text-sm text-gray-500 w-full `}
            >
              <input
                type="text"
                className={`w-full h-full`}
                value={value || ''}
                onChange={(e) =>
                  updateValue(key as keyof typeof fieldValues, e.target.value)
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HeaderTable;
