import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import MDEditor from '@uiw/react-md-editor';
import { getHelpPageByName } from '../../server/server';
import { useNavigate } from 'react-router-dom';
function HelpPageModal({ modalIsOpen, afterOpenModal, closeModal, name }: any) {
  const navigate = useNavigate();
  useEffect(() => {
    ReactModal.setAppElement('#root');
    loadPage(name);
  }, []);
  const [page, setPage] = useState<any>();

  const loadPage = async (name: any) => {
    const resp = await getHelpPageByName(name);

    if (resp?.status === 200) {
      setPage(resp?.data);
    }
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 800,
          height: '90%',
          padding: 0,
          opacity: 1,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div
        className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-2"
        style={{ height: '100%' }}
      >
        <div
          className="w-full flex flex-col justify-between"
          style={{ height: '100%' }}
        >
          <div className="flex-grow flex flex-col h-[90%]">
            <div className="text-[#04438b] w-full justify-center  mt-3 text-center hover:underline text-3xl font-bold mb-8 flex gap-4 items-center cursor-pointer">
              <h1 className="text-center">{page?.title}</h1>
            </div>

            <div
              data-color-mode="light"
              className="w-full px-4 overflow-y-scroll gray-small-scroll flex-grow"
            >
              <MDEditor.Markdown
                source={page?.text}
                className="h-full md-custom w-full"
              />
            </div>
          </div>

          <div className="flex flex-row justify-end items-center w-full mt-6">
            <div
              className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
              onClick={() => closeModal()}
            >
              Затвори
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HelpPageModal;
