import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaSave, FaSearch, FaSearchMinus, FaSyncAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../auth/UseUser';
import { validator } from '../../../common/constaints';
import { getAllInstitutions, insertNatMeasure } from '../../../server/server';
import ChooseNatRegisterModal from '../../modals/ChooseNatRegisterModal';
import EditPageModal from '../../modals/EditPageModal';
import SubActModal from '../../modals/SubAct';
import { notif } from '../../utils/util';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';
import ChosePirvRegistersModal from '../../modals/ChosePirvRegistersModal';
import ConfirmationModal from '../../modals/ConfirmationModal';

function InsertRegister() {
  const { user, setUser } = useUser();
  let navigate = useNavigate();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [subAct, setSubAct] = useState('');
  const [title, setTitle] = useState('');
  const [titleEN, setTitleEN] = useState('');
  const [comment, setComment] = useState('');
  const [selSubRegister, setSelSubRegister] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [syncModalIsOpen, setSyncModalIsOpen] = useState(false);
  const [pirvId, setPirvId] = useState<any>(null);
  const [type, setType] = useState<any>();
  const [pirvName, setPirvName] = useState('');
  const [errorMap, setErrorMap] = useState({
    title: '',
    titleEN: '',
    type: '',
    bylaw: '',
    comment: '',
    plan: '',
  });
  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };

  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [left, setLeft] = React.useState<any[]>([]);
  const [right, setRight] = React.useState<any[]>([]);
  const [showSameNameModalWarning, setSameNameModalWarning] = useState(false)
  const [planAdoptByGov, setPlanAdoptByGov] = useState<any>(null);
  const [planAdoptByParl, setPlanAdoptByParl] = useState<any>(null);
  const [planEntryIntoForce, setPlanEntryIntoForce] = useState<any>(null);
  const types = [
    { id: 0, name: 'С - Стратешки документ - Влада' },
    // { id: 1, name: 'С - Стратешки документ - Министарство' },
    { id: 2, name: 'С - Стратешки документ - НС' },
    { id: 3, name: 'З - Закон' },
    { id: 4, name: 'В - Подзаконска акта (Влада)' },
    { id: 5, name: 'М - Подзаконска акта (Министарства, НБС ...)' },
  ];
  const handleChange = (e: SelectChangeEvent) => {
    setErrorMap({ ...errorMap, type: '' });
    setType(types.find((t: any) => t.name === e.target.value));
  };

  function intersection(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  function not(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleCheckedRight = () => {
    setRight(
      [...right, ...leftChecked].sort((a, b) => a.name.localeCompare(b.name))
    );
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(
      [...left, ...rightChecked].sort((a, b) => a.name.localeCompare(b.name))
    );
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions(1);
    if (resp?.status === 200) {
      setLeft(resp?.data);
    }
  };
  useEffect(() => {
    loadAllInstitutions();
    console.log('user', user);
  }, []);

  const customList = (items: any[]) => (
    <Paper sx={{ width: 300, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              className={`cursor-pointer ${checked.indexOf(value) !== -1 ? 'bg-[#04438be8] text-white' : ''
                }`}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemText id={labelId} primary={value?.name} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
  const QUARTER_MAP = ['I', 'II', 'III', 'IV'];
  const YEAR_MAP = [1, 2, 3, 4, 5, 6].map((y, i) => {
    return 2022 + i;
  });

  const insertNationalMeasure = async (forceInsert = false) => {
    const resp = await insertNatMeasure({
      title: title?.trim(),
      titleEN,
      type: type?.id?.toString(),
      comment,
      subMeasure: selSubRegister?.id,
      institutions: right?.map((i) => i?.id) ?? null,
      planAdoptByGov: `${planAdoptByGov?.y}/${planAdoptByGov?.q}`,
      planAdoptByParl: `${planAdoptByParl?.y}/${planAdoptByParl?.q}`,
      planEntryIntoForce: `${planEntryIntoForce?.y}/${planEntryIntoForce?.q}`,
      isForced: forceInsert,
      pirvId,
      pirvName,
    });

    if (resp.status === 201) {
      notif('Пропис је успешно креиран', 'success');
      navigate('/register/edit?id=' + resp?.data?.id);
    } else {
      if (resp.status === 409) {
        setSameNameModalWarning(true)
      }
      notif(resp?.data?.message || 'Пропис није креиран', 'error');
    }
  };
  const handleRegisterInsert = () => {
    const newErrorMap = {
      title: validator(title, true, true),
      titleEN: validator(titleEN, true, false),
      type: validator(type?.id?.toString(), true, false),
      comment: validator(title, false, true),
      bylaw: '',
      OJNumber: '',
      OJPagesNumber: '',
      plan: checkChronologicalOrder(),
    };
    //Поље је обавезно
    setErrorMap(newErrorMap);
    if (!Object.values(newErrorMap).some((e) => e)) {
      insertNationalMeasure();
    }
  };
  const checkChronologicalOrder = () => {
    if (
      !planAdoptByGov?.q ||
      !planAdoptByGov?.y ||
      (!planAdoptByParl?.q && ![4, 5].includes(type?.id)) ||
      (!planAdoptByParl?.y && ![4, 5].includes(type?.id)) ||
      !planEntryIntoForce?.q ||
      !planEntryIntoForce?.y
    ) {
      return 'Сва поља Плана законодавног поступка су обавезна!';
    }

    const govValue =
      parseInt(planAdoptByGov.y) * 4 + QUARTER_MAP.indexOf(planAdoptByGov?.q);
    const parlValue = [4, 5].includes(type?.id)
      ? govValue
      : parseInt(planAdoptByParl.y) * 4 +
      QUARTER_MAP.indexOf(planAdoptByParl?.q);
    const entryValue =
      parseInt(planEntryIntoForce.y) * 4 +
      QUARTER_MAP.indexOf(planEntryIntoForce?.q);

    if (govValue > parlValue || parlValue > entryValue) {
      return 'Усвајања морају бити у хронолошком редоследу!';
    }
    return '';
  };
  const closeSyncModal = () => {
    setSyncModalIsOpen(false);
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex  items-start gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Унос планираног прописа
        </h1>
        <p className="leading-10 text-start text-[#04438b]">
          Надлежна институција
          <span className="italic">({user?.institution?.name})</span>
        </p>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>

      <div className="relative rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 flex flex-col gap-6">
        <div
          className="absolute top-2 right-2 flex items-center gap-1 cursor-pointer group"
          onClick={() => setSyncModalIsOpen(true)}
        >
          <FaSyncAlt className="group-hover:bg-gray-700 fill-[#134f32]" />
          <span className="group-hover:text-gray-700">ПИРВ</span>
        </div>

        <div className="flex items-center gap-6">
          <p className="w-[260px]">Назив прописа</p>
          <TextField
            label="Назив прописа"
            id="outlined-size-small"
            type="institutionName"
            name="institutionName"
            size="small"
            rows={2}
            multiline
            sx={{ width: 550 }}
            helperText={errorMap?.title}
            error={!!errorMap?.title}
            onFocus={() => setErrorMap({ ...errorMap, title: '' })}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
          // error={errorMap?.firstName as boolean}
          // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
          // value={firstName}
          // onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-6">
          <p className="w-[260px]">Назив прописа (енглески)</p>
          <TextField
            label="Назив прописа (енглески)"
            id="outlined-size-small"
            type="institutionName"
            name="institutionName"
            size="small"
            rows={2}
            multiline
            sx={{ width: 550 }}
            helperText={errorMap?.titleEN}
            error={Boolean(errorMap?.titleEN)}
            onFocus={() => setErrorMap({ ...errorMap, titleEN: '' })}
            value={titleEN}
            onChange={(e) => setTitleEN(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-6">
          <p className="w-[260px]">Врста прописа</p>
          <FormControl size="small" sx={{ minWidth: 450 }}>
            <InputLabel id="demo-select-small">Врста прописа</InputLabel>
            <Select
              labelId="demo-select-small"
              label="Врста прописа"
              id="demo-select-small"
              value={type?.name}
              onChange={handleChange}
              className="capitalize"
              error={Boolean(errorMap?.type)}
            >
              {types?.map((t) => {
                return (
                  <MenuItem value={t?.name} className="capitalize" key={t?.id}>
                    {t?.name}
                  </MenuItem>
                );
              })}
            </Select>
            {errorMap?.type && (
              <FormHelperText sx={{ color: 'red' }}>
                {errorMap?.type}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        {(type?.id === 4 || type?.id === 5) && (
          <div className="flex items-center gap-6">
            <p className="w-[260px]">Је подзаконски акт прописа</p>
            <div className="flex flex-col">
              <button
                className="h-[40px] w-[450px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer"
                onClick={() => setModalIsOpen(true)}
              >
                {selSubRegister ? (
                  <div className="flex px-4 justify-start text-left items-center">
                    <FaSearchMinus className="fill-[#404040]" />
                    <p className="text-darkBlue w-full pl-3">
                      {selSubRegister?.title?.length > 45
                        ? selSubRegister?.title?.substring(0, 45) + '...'
                        : selSubRegister?.title}
                    </p>
                  </div>
                ) : (
                  <div className="flex px-4 justify-between items-center">
                    <FaSearch className="fill-[#404040]" />
                    <p className="text-[#404040] w-full pl-3">
                      Одабир Националног прописа
                    </p>
                  </div>
                )}
              </button>
            </div>
          </div>
        )}
        <div className="flex items-center gap-6">
          <p className="w-[300px]">
            Други органи који сарађују при припреми прописа
          </p>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{ width: '320px' }}>
              {customList(left)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item style={{ width: '320px' }}>
              {customList(right)}
            </Grid>
          </Grid>
        </div>
        <div className="flex items-center gap-6 mt-4">
          <p className="w-[260px]">План законодавног поступка</p>
          <div className="flex items-center gap-14">
            <div className="flex flex-col justify-center">
              <p className="text-[#04438b] text-center  text-sm">
                Усваја Влада/министарство/НБС
              </p>
              <div className="flex justify-start w-full items-center">
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByGov?.q || ''}
                    onChange={(e) =>
                      setPlanAdoptByGov({
                        ...planAdoptByGov,
                        q: e.target.value,
                      })
                    }
                    label="Квартал"
                  >
                    {QUARTER_MAP?.map((q) => {
                      return <MenuItem value={q}>{q}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByGov?.y || ''}
                    onChange={(e) =>
                      setPlanAdoptByGov({
                        ...planAdoptByGov,
                        y: e.target.value,
                      })
                    }
                    label="Година"
                  >
                    {YEAR_MAP?.map((y) => {
                      return <MenuItem value={y}>{y}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p
                className={`text-center text-sm ${[4, 5].includes(type?.id)
                  ? 'text-[#6d6d6d]'
                  : 'text-[#04438b]'
                  }`}
              >
                Усваја Скупштина
              </p>
              <div className="flex justify-start w-full items-center">
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByParl?.q || ''}
                    onChange={(e) =>
                      setPlanAdoptByParl({
                        ...planAdoptByParl,
                        q: e.target.value,
                      })
                    }
                    disabled={[4, 5].includes(type?.id)}
                    label="Квартал"
                  >
                    {QUARTER_MAP?.map((q) => {
                      return <MenuItem value={q}>{q}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByParl?.y || ''}
                    onChange={(e) =>
                      setPlanAdoptByParl({
                        ...planAdoptByParl,
                        y: e.target.value,
                      })
                    }
                    disabled={[4, 5].includes(type?.id)}
                    label="Година"
                  >
                    {YEAR_MAP?.map((y) => {
                      return <MenuItem value={y}>{y}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-[#04438b] text-center text-sm">
                Почетак примене
              </p>
              <div className="flex justify-start w-full items-center">
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planEntryIntoForce?.q || ''}
                    onChange={(e) =>
                      setPlanEntryIntoForce({
                        ...planEntryIntoForce,
                        q: e.target.value,
                      })
                    }
                    label="Квартал"
                  >
                    {QUARTER_MAP?.map((q) => {
                      return <MenuItem value={q}>{q}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planEntryIntoForce?.y || ''}
                    onChange={(e) =>
                      setPlanEntryIntoForce({
                        ...planEntryIntoForce,
                        y: e.target.value,
                      })
                    }
                    label="Година"
                  >
                    {YEAR_MAP?.map((y) => {
                      return <MenuItem value={y}>{y}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <p style={{ color: 'red' }}>{errorMap.plan}</p>

        <div className="flex items-center gap-6">
          <p className="w-[260px]">Коментар</p>
          <TextField
            label="Коментар"
            id="outlined-size-small"
            type="institutionName"
            name="institutionName"
            size="small"
            rows={2}
            multiline
            sx={{ width: 550 }}
            helperText={errorMap?.comment}
            error={Boolean(errorMap?.comment)}
            onFocus={() => setErrorMap({ ...errorMap, comment: '' })}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="w-full flex justify-end mt-12 items-center">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#0F2765' }}
            endIcon={<FaSave />}
            onClick={handleRegisterInsert}
          >
            Сачувај
          </Button>
        </div>
      </div>
      <ChosePirvRegistersModal
        modalIsOpen={syncModalIsOpen}
        closeModal={closeSyncModal}
        setTitle={setTitle}
        setComment={setComment}
        setPirvId={setPirvId}
        setPirvName={setPirvName}
      />
      <ChooseNatRegisterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setSelRegister={setSelSubRegister}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'insertRegister'}
      />
      <ConfirmationModal
        modalIsOpen={showSameNameModalWarning}
        closeModal={() => setSameNameModalWarning(false)}
        message="Постоји пропис са истим називом. Да ли желите да наставите?"
        subMessage={`Пропис већ постоји и направиће се нови са истим називом ако се унесе поново.\n Ако желите да ажурирате постојећи пропис, идите на Претраживање/брисање/измена прописа уместо уноса.`}
        confirmation={() => {
          insertNationalMeasure(true);
          setSameNameModalWarning(false);
        }}
      />
    </div>
  );
}

export default InsertRegister;
