import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useUser } from "../auth/UseUser";

const PrivateRoute = ({ Component }: any) => {
  const { isSignedIn } = useUser();
  return isSignedIn ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
export {};
