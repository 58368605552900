import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import BasicMeasureData from './measureScreens/basicMeasureData';
import NatMeasureRegisters from './measureScreens/NatMeasureRegisters';
import EuMeasureRegisters from './measureScreens/EuMeasureRegister';
import PointerMeasure from './measureScreens/pointerMeasure';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function AddMeasure() {
  const [value, setValue] = React.useState(0);
  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">Унос мере</h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 ">
        {/* <h1 className="text-[#04438bae] text-2xl font-bold">СТАЊЕ ПРЕГОВОРА</h1> */}

        <div className="flex">
          <BasicMeasureData />
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'addMeasure'}
      />
    </div>
  );
}

export default AddMeasure;
