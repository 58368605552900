import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import {
  getMeasureInChargeInstitutions,
  getPointers,
  insertPointer,
  removePointer,
} from '../../../../server/server';
import { useSearchParams } from 'react-router-dom';
import { getAllInstitutions } from '../../../../server/server';
import { notif } from '../../../utils/util';
import ConfirmationModal from '../../../modals/ConfirmationModal';

function PointerMeasure() {
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState('');
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [editID, setEditID] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [insitutionType, setInstitutionType] = useState('');
  const [defaultInstitution, setDefaultInstitution] = useState<any>();
  const [deadline, setDeadline] = useState('');
  const [comment, setComment] = useState('');
  const [selectedOption, setSelectedOption] = useState(0);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const handleAdd = () => {};

  const loadPointers = async (offset: any = 0, id: any = editID) => {
    const resp = await getPointers(id);
    if (resp?.status === 200) {
      setItems(resp?.data);
      setIsLoading(false);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    // loadPointers(page * pageSize);
  };

  const insertNew = async () => {
    const resp = await insertPointer({
      text: title,
      measure: editID,
      deadline,
      institution: defaultInstitution?.id || '',
      comment,
      status: selectedOption?.toString(),
    });

    if (resp?.status === 201) {
      setTitle('');
      setDefaultInstitution({});
      setInstitutionType('');
      setDeadline('');
      setComment('');
      setSelectedOption(0);
      loadPointers();
    }
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };
  const deletePointer = () => {
    delPointer();
    setDeleteModalIsOpen(false);
  };
  const delPointer = async () => {
    const resp = await removePointer(deleteId);
    if (resp.status === 200) {
      notif('Показивач успешно обрисан', 'success');
      setItems((prevRows: any) =>
        prevRows.filter((row: any) => row.id !== deleteId)
      );
    } else {
      notif('Показивач није обрисан', 'error');
    }
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteModalIsOpen(true);
        setDeleteId(id);
      });
    },
    []
  );
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'text',
        headerName: 'Показатељ',
        flex: 1,
      },
      // {
      // 	field: 'deadline',
      // 	headerName: 'Рок',
      // 	flex: 1,
      // 	width: 120,
      // },
      // {
      // 	field: 'institution',
      // 	headerName: 'Надлежна институција',
      // 	flex: 1,
      // },
      // {
      // 	field: 'status',
      // 	headerName: 'Статус',
      // 	flex: 1,
      // 	width: 120,
      // 	renderCell: (params) => {
      // 		return translations.find((s) => s?.id === parseInt(params?.row?.status))?.name;
      // 	},
      // },
      // {
      // 	field: 'comment',
      // 	headerName: 'Коментар',
      // 	flex: 1,
      // },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<FaTrash style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Обриши"
            onClick={deleteItem(params.id)}
          />,
        ],
      },
    ],
    [deleteItem]
  );
  useEffect(() => {
    loadAllInstitutions();
    let eId = searchParams.get('id');
    if (eId) {
      loadPointers(0, eId);
      setEditID(eId);
    }
  }, []);

  const loadAllInChargeInsts = async (offset: any = 0, pid: any) => {
    const resp = await getMeasureInChargeInstitutions(offset, pageSize, pid);

    if (resp?.status === 200) {
      setItems(resp.data?.items);
      setRowCountState(resp?.data?.total);
      setIsLoading(false);
    }
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm); // Updated this line
  };
  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const translations = [
    { id: 0, name: 'Неостварено' },
    { id: 1, name: 'Делимично остварено' },
    { id: 2, name: 'Остварено' },
  ];

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };
  return (
    <div>
      <div className="flex items-center gap-4">
        {' '}
        <div className="flex flex-col items-start gap-3">
          <p className="w-[260px]">Показатељ</p>
          <TextField
            label="Показатељ"
            id="outlined-size-small"
            type="institutionName"
            name="institutionName"
            size="small"
            multiline
            sx={{ width: 300 }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
            // error={errorMap?.firstName as boolean}
            // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
            // value={firstName}
            // onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        {/* <div className="flex flex-col items-start gap-3">
          <p className="w-[100px]">Рок</p>
          <TextField
            label="Рок"
            id="outlined-size-small"
            type="institutionName"
            name="institutionName"
            size="small"
            multiline
            sx={{ width: 100 }}
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
            // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
            // error={errorMap?.firstName as boolean}
            // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
            // value={firstName}
            // onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-center justify-start text-left gap-3">
          <p className="w-full">Тип иннституције</p>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="demo-select-small">Тип иннституције</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={insitutionType || ''}
              onChange={(e) => setInstitutionType(e.target.value)}
              label="Тип иннституције"
              fullWidth
              className="capitalize"
            >
              {['Планирана институција', 'Постојећа институција']?.map((c) => {
                return (
                  <MenuItem value={c} className="capitalize" key={c}>
                    {c}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-col items-center gap-3">
          <p className="w-full">Инстинтуција</p>
          <Autocomplete
            key={defaultInstitution ? defaultInstitution.id : 'empty'} // Add this line
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            value={defaultInstitution} // Updated this line
            size="small"
            onChange={(event: any, newValue: any) =>
              handleInstChange(event, newValue)
            }
            id="controllable-states-demo"
            options={institutions}
            getOptionLabel={(option) => option?.name || ''}
            sx={{ width: 340 }}
            renderInput={(params) => (
              <TextField {...params} label="Институција" />
            )}
          />
        </div>
      </div>
      <div className="flex items-start gap-2 p-4">
        <div className="flex flex-col items-start justify-start text-left gap-3 w-full mb-4">
          <p className="w-full">Статус</p>
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="translations-label">Статус</InputLabel>
            <Select
              labelId="translations-label"
              value={selectedOption}
              onChange={handleChange}
              label="Статус"
              fullWidth
            >
              {translations.map((translation) => (
                <MenuItem
                  key={translation.id}
                  value={translation.id}
                  className="capitalize"
                >
                  {translation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full flex flex-col items-start gap-4">
          <label htmlFor="message" className="text-sm text-gray-600">
            Коментар
          </label>
          <textarea
            id="message"
            rows={4}
            className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
            placeholder="Коментар"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div> */}
      </div>

      <div className="w-full flex justify-end mt-6 items-center">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          onClick={insertNew}
        >
          Потврди
        </Button>
      </div>
      <div className="mt-5 h-[430px] w-full">
        <DataGrid
          rowCount={rowCountState}
          loading={isLoading}
          pagination
          disableColumnMenu
          paginationMode="server"
          onPageChange={(newPage) => setPageAndCall(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          page={page}
          pageSize={pageSize}
          rows={items}
          getRowHeight={() => 'auto'}
          columns={columns}
          style={{ zIndex: 0 }}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Не постоје показатељи
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Не постоје показатељи
              </Stack>
            ),
          }}
        />
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={closeDeleteModal}
        confirmation={deletePointer}
        message={`Обриши  показатељ?`}
      />
    </div>
  );
}

export default PointerMeasure;
