import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getAllInstitutions, getRegistersManagement } from '../../../server/server';

function SearchRegister() {
	let navigate = useNavigate();
	const [term, setTerm] = useState('');
	const [institutions, setInstitutions] = useState<any>([]);
	const [registers, setRegisters] = useState([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(6);
	const [rowCountState, setRowCountState] = useState(6);
	const [isLoading, setIsLoading] = useState(true);
	const [translation, setTranslation] = useState('');
	const [defaultInstitution, setDefaultInstitution] = useState<any | null>();

	const handleInstChange = (e: any, prm: any) => {
		setDefaultInstitution(prm as any);
	};
	const deleteUser = React.useCallback(
		(id: GridRowId) => () => {
			setTimeout(() => {
				setRegisters((prevRows: any) => prevRows.filter((row: any) => row.id !== id));
			});
		},
		[]
	);
	const loadRegisters = async (offset: any = 0) => {
		const resp = await getRegistersManagement(pageSize, offset, term, defaultInstitution?.id ?? '', '');

		if (resp?.status) {
			setIsLoading(false);
			setRegisters(resp?.data?.measures);
			setRowCountState(resp?.data?.total);
		}
	};

	const loadInstitutions = async () => {
		const resp = await getAllInstitutions();
		if (resp?.status) {
			setInstitutions(resp?.data);
		}
	};

	const setPageAndCall = (page: any) => {
		setPage(page);
		loadRegisters(page * pageSize);
	};
	const editUser = React.useCallback(
		(id: GridRowId) => () => {
			navigate('/register/edit?id=' + id);
		},
		[]
	);

	const columns = React.useMemo<GridColumns<any>>(
		() => [
			{
				field: 'pid',
				headerName: 'Идентификација прописа',
				maxWidth: 260,
				minWidth: 180,
				flex: 1,
			},
			{
				field: 'title',
				headerName: 'Назив',
				minWidth: 180,
				flex: 1,
			},
			{
				field: 'user',
				headerName: 'Корисник',
				maxWidth: 140,
				minWidth: 140,
				flex: 1,
			},
			{
				field: 'institution',
				headerName: 'Инстинтуција',
				maxWidth: 110,
				minWidth: 180,
				flex: 1,
			},
			{
				field: 'npi',
				headerName: 'НПИ',
				type: 'boolean',
				maxWidth: 60,
				flex: 1,
			},
			{
				field: 'ssp',
				headerName: 'ССП',
				type: 'boolean',
				maxWidth: 60,
				flex: 1,
			},
			{
				field: 'euRegister',
				headerName: 'ЕУ прописи',
				type: 'boolean',
				maxWidth: 120,
				flex: 1,
			},
			{
				field: 'np',
				headerName: 'Национални прописи',
				type: 'boolean',
				maxWidth: 160,
				flex: 1,
			},
			{
				field: 'institutions',
				headerName: 'Институције за спровођење',
				type: 'boolean',
				maxWidth: 200,
				flex: 1,
			},
		],
		[deleteUser, editUser]
	);
	useEffect(() => {
		loadRegisters();
		loadInstitutions();
	}, []);

	useEffect(() => {
		loadRegisters();
	}, [term, defaultInstitution]);

	const translations = [{ id: 1, name: 'Без Превода' }];

	const handleTranslationsChange = (e: SelectChangeEvent) => {
		const t = translations.find((r) => r?.name === e.target.value);
		setTranslation(t as any);
	};
	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<h1 className='text-[#04438b] text-4xl font-bold'>Претраживање прописа</h1>
			<div className='rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 '>
				<h1 className='text-[#04438bae] text-3xl font-bold'>Претрага</h1>
				<div className='w-full mt-6 flex items-center gap-12'>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По Идентификацији</p>
						<TextField label='Идентификација прописа' id='outlined-size-small' size='small' autoComplete='off' value={term} onChange={(e) => setTerm(e.target.value)} />
					</div>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По Институцији</p>
						<Autocomplete
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.id}>
										{option.name}
									</li>
								);
							}}
							value={defaultInstitution?.name}
							size='small'
							onChange={(event: any, newValue: any) => handleInstChange(event, newValue)}
							id='controllable-states-demo'
							options={institutions}
							getOptionLabel={(option) => option?.name}
							sx={{ width: 300 }}
							renderInput={(params) => <TextField {...params} label='Институција' />}
						/>
					</div>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По Доступности превода</p>
						<FormControl size='small' sx={{ minWidth: 140 }}>
							<InputLabel id='demo-select-small'>Превод</InputLabel>
							<Select labelId='demo-select-small' id='demo-select-small' value={translation} onChange={handleTranslationsChange} label='Превод' className='capitalize'>
								<MenuItem value=''>
									<em>Сви</em>
								</MenuItem>
								{translations?.map((t) => {
									return (
										<MenuItem value={t?.name} className='capitalize' key={t?.id}>
											{t?.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className='mt-5 h-[430px] w-full'>
					<DataGrid
						rowCount={rowCountState}
						loading={isLoading}
						pagination
						paginationMode='server'
						onPageChange={(newPage) => setPageAndCall(newPage)}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						page={page}
						pageSize={pageSize}
						rows={registers}
						columns={columns}
						disableColumnMenu
						style={{ zIndex: 0 }}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						components={{
							NoRowsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје прописи са задатим критеријумом
								</Stack>
							),
							NoResultsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје прописи са задатим критеријумом
								</Stack>
							),
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default SearchRegister;
