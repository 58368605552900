import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CelexInstitutionView from './screens/CelexInstitutionView';
import CelexNPIView from './screens/CelexNPIView';
import QuartalView from './screens/QuartalView';
import SearchRegister from './screens/SearchRegister';

function Views() {
  let navigate = useNavigate();
  const location = useLocation();
  const [screen, setScreen] = useState('default');

  const screenMap = {
    search: <SearchRegister />,
    celexInstitution: <CelexInstitutionView />,
    celexChapter: <CelexNPIView />,
    quartalView: <QuartalView />,
  };
  const menuList = [
    // {
    //   name: 'Претраживање',
    //   subMenus: [{ name: 'searchRegister', title: 'NPAA', url: 'search' }],
    // },
    {
      name: 'Прописи са CELEX-има',
      subMenus: [
        {
          name: 'celexInstitution',
          title: 'По министарствима и посебним организацијама',
          url: 'celexinstitution',
        },
        {
          name: 'celexChapter',
          title: 'По преговарачким поглављима',
          url: 'celexchapter',
        },
        {
          name: 'celexCluster',
          title: 'По кластерима',
          url: 'celexcluster',
        },
      ],
    },
    {
      name: 'Мере, Капацитети и Финансијски аспекти усклађивања са ЕУ законодавством',
      subMenus: [
        { name: 'measures', title: 'Преглед мера', url: 'measures' },
        {
          name: 'fin',
          title: 'Преглед издатака/извора финансирања',
          url: 'fin',
        },
      ],
    },
    {
      name: 'Квартална реализ. и статистика',
      subMenus: [
        {
          name: 'quartalView',
          title: 'По министарствима и посебним организацијама',
          url: 'quartalview',
        },
      ],
    },
    // {
    //   name: 'НПАА Прописи',
    //   subMenus: [
    //     {
    //       name: 'pl22-25',
    //       title: 'Пл.прописи 2022-2025 NPAA',
    //       url: 'npaa/pl22-25',
    //     },
    //     {
    //       name: 'pl-sub22-25',
    //       title: 'Пл.под. акта 2022-2025 NPAAA',
    //       url: 'npaa/pl-sub22-25',
    //     },
    //     {
    //       name: 'pl-sub16-18',
    //       title: 'Пл.под. акта 2016-2018 NPAA',
    //       url: 'npaa/pl-sub16-18',
    //     },
    //     {
    //       name: 'pl16-18',
    //       title: 'Пл.прописи 2016-2018 NPAA',
    //       url: 'npaa/pl16-18',
    //     },
    //     {
    //       name: 'pl-sub13',
    //       title: 'Пл.под. акта 2013 NPAA',
    //       url: 'npaa/pl-sub13',
    //     },
    //     {
    //       name: 'pl13',
    //       title: 'Пл.прописи 2013 NPAA',
    //       url: 'npaa/pl13',
    //     },
    //   ],
    // },
  ];
  return (
    <div className=" w-full h-full flex flex-row justify-start">
      <div className="h-full bg-[#1e3f940a] w-[320px] my-5 first:mt-0  overflow-auto gray-small-scroll">
        {menuList?.map((section) => {
          return (
            <div key={section?.name}>
              <div className="flex items-center pl-6 mt-6 py-3 text-gray-500 font-semibold mx-6 uppercase text-sm text-[#1e4094]">
                {section?.name}
              </div>
              {section?.subMenus?.map((sub) => {
                return (
                  <div
                    key={sub?.name}
                    onClick={() => navigate(sub?.url)}
                    className={`flex items-center cursor-pointer py-1 pl-6  mx-6 my-2  rounded-md ${
                      '/views/' + sub?.url === location?.pathname
                        ? 'text-white bg-[#0F2765]'
                        : 'hover:text-white hover:bg-[#0F2765] text-gray-300'
                    }`}
                  >
                    {sub?.title}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
}

export default Views;
