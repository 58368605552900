import React, { useEffect, useState } from 'react';
import HeaderTable from './srceens/finExpense/header-table';
import {
  addExpenditure,
  createExpi,
  getExpenditure,
  getExpenditureInfo,
  getExpenditureTypes,
  getExpi,
} from '../../server/server';
import { Button } from '@mui/material';
import { FaSave } from 'react-icons/fa';
import RadioButtonGroup from '../../common/RadioButton';
import InfoTable from './srceens/finExpense/info-table';
import ChooseNPI from '../modals/ChooseNPI';
import { notif } from '../utils/util';
import { useNavigate } from 'react-router-dom';

function FinancingInfo({ selRegister, eid, setShowTab }: any) {
  const navigate = useNavigate();

  const [allTypes, setAllTypes] = useState<any>([]);
  const [infoData, setInfoData] = useState<any>([]);
  const [showMain, setShowMain] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pid, setPid] = useState('');
  const [groupNPI, setGroupNPI] = useState<any>(null);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>(
    null
  );
  const [fieldValues, setFieldValues] = useState({
    imeIPrezime: '',
    funkcija: '',
    brojTelefona: '',
    email: '',
  });
  const loadTypes = async () => {
    const resp = await getExpenditureTypes();
    if (resp?.status === 200) {
      setAllTypes(resp?.data);
    }
  };

  const loadExpi = async () => {
    const resp = await getExpi(selRegister?.id);
    if (resp?.status === 200) {
      setPid(resp?.data?.code);
      setDefaultInstitution(resp?.data?.institution);
      setGroupNPI(resp?.data?.group);
      setShowMain(resp?.data?.showMain);
      setShowTab(resp?.data?.showMain);
      setFieldValues({
        imeIPrezime: resp?.data?.userName,
        funkcija: resp?.data?.userFunction,
        brojTelefona: resp?.data?.userPhone,
        email: resp?.data?.userEmail,
      });
    }
  };

  const loadInfo = async () => {
    const resp = await getExpenditureInfo(selRegister?.id);

    if (resp?.status === 200) {
      setInfoData(resp?.data);
    }
  };
  useEffect(() => {
    loadTypes();
    loadInfo();
    loadExpenditure();
    if (eid) {
      loadExpi();
    }
  }, []);

  const loadExpenditure = async () => {
    const response: any = await getExpenditure(selRegister?.id);
    if (response?.status === 200 && response?.data?.length > 0) {
      setDataLoaded(true);
      const isShown =
        response?.data?.filter((d: any) => d?.type?.id === 99)?.show || false;
      setShowMain(isShown);
    }
  };
  const handleSave = async () => {
    const resp = await createExpi({
      measureId: selRegister?.id,
      group: { groupNo: groupNPI?.groupNo ?? groupNPI?.id },
      institution: { id: defaultInstitution?.id },
      code: pid,
      userName: fieldValues?.imeIPrezime,
      userFunction: fieldValues?.funkcija,
      userPhone: fieldValues?.brojTelefona,
      userEmail: fieldValues?.email,
      showMain,
    });
    if (resp.status === 201) {
      notif('Успешно сачувано', 'success');
      navigate('/register/financial/expenditure/insert?id=' + resp?.data?.id);
    } else {
      notif('Чување није успело', 'error');
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <p className="w-[300px] text-[#151515] my-4 text-sm">
          Да ли су потребни додатни трошкови за усклађивање законодавства са
          правном тековином ЕУ и за његову имплементацију?
        </p>
        <RadioButtonGroup
          state={showMain}
          setState={setShowMain}
          isError={false}
          setIsError={() => 1}
        />
      </div>
      <div className="gap-4 flex flex-col">
        <InfoTable
          data={infoData}
          setData={setInfoData}
          text={'1. Општи подаци'}
          selRegister={selRegister}
          allType={allTypes?.filter((t: any) =>
            [
              'Предлагач',
              '2.2 функција',
              '2.3 број телефона',
              '2.4 e-mail',
            ]?.includes(t?.name)
          )}
          pid={pid}
          setPid={setPid}
          defaultInstitution={defaultInstitution}
          setDefaultInstitution={setDefaultInstitution}
          groupNPI={groupNPI}
          setGroupNPI={setGroupNPI}
        />
        <HeaderTable
          fieldValues={fieldValues}
          setFieldValues={setFieldValues}
          text={
            '2. Контакт подаци лица у институцији надлежној за реализацију „правног акта /инвестиције, које је попуњавало овај Формулар'
          }
        />
      </div>
      <div className="w-full flex justify-end mt-12 items-center">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          endIcon={<FaSave />}
          onClick={handleSave}
          disabled={!selRegister || showMain === null}
        >
          Сачувај
        </Button>
      </div>
    </div>
  );
}

export default FinancingInfo;
