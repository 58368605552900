import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useUser } from '../../../auth/UseUser';
import { getHelpPageByName } from '../../../server/server';

import EditPageModal from '../../modals/EditPageModal';
import MDEditor from '@uiw/react-md-editor';
function HelpPage() {
  const { user, setUser } = useUser();
  const location = useLocation();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [page, setPage] = useState<any>();

  const loadPage = async (name: any) => {
    const resp = await getHelpPageByName(name);

    if (resp?.status === 200) {
      setPage(resp?.data);
    }
  };
  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };
  const reloadPage = () => {
    const searchParams = new URLSearchParams(location.search);
    loadPage(searchParams.get('id'));
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    loadPage(searchParams.get('id'));
  }, [location]);

  return (
    <div className="mx-12 pt-12">
      <div className="text-[#04438b] text-3xl font-bold mb-8 flex gap-4 items-center">
        <h1>{page?.title}</h1>
        {user?.roleView?.features?.includes('administration') && (
          <FaEdit
            className="w-[20px] h-[20px] text-[#275d9c] cursor-pointer hover:text-[#3681d8]"
            onClick={() => setEditModalIsOpen(true)}
          />
        )}
      </div>

      {/* <p className="text-base">{page?.text}</p> */}
      <div data-color-mode="light">
        <MDEditor.Markdown source={page?.text} className="h-full md-custom" />
      </div>
      {/* <div className="h-full">
        <ReactMarkdown children={page?.text} remarkPlugins={[remarkGfm]} />
      </div> */}

      <EditPageModal
        modalIsOpen={editModalIsOpen}
        closeModal={closeEditModal}
        editTitle={page?.title}
        editText={page?.text}
        reloadPage={reloadPage}
        editId={page?.id}
      />
    </div>
  );
}

export default HelpPage;
