import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaQuestionCircle, FaSpinner, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  deleteInstitution,
  getAllInstitutions,
  getInstRelationDelete,
  getInstitutions,
} from '../../../server/server';
import { notif } from '../../utils/util';
import HelpPageModal from '../../modals/HelpPageModal';
import HelpButton from '../../../common/HelpButton';
import ConfirmationModal from '../../modals/ConfirmationModal';

function InstitututionManagement() {
  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [institutions, setInstitutions] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [rowCountState, setRowCountState] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [defType, setDefType] = useState<any | null>(null);
  const [helpModal, setHelpModal] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [relationMessage, setRelationMessage] = useState<string | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const getInsRelDel = async (id: any) => {
    if (id) {
      const resp = await getInstRelationDelete(id);
      if (resp?.status) {
        const relationsMap = {
          userscount: 'корисника',
          natmeasurescount: 'националних мера',
          jurisdictionscount: 'надлежности',
          measurescount: 'мера',
          secmeasurescount: 'секундарних мера',
          measurepointercount: 'показивача мера',
          natmeasuresinstscount: 'институција националних мера',
        };

        const errorMessages = [];

        for (const [key, value] of Object.entries(resp.data)) {
          if (key !== 'institution_id' && parseInt(value as any) > 0) {
            errorMessages.push(
              `Не можете обрисати институцију јер има ${value} повезаих ${
                relationsMap[key as keyof typeof relationsMap]
              }.`
            );
          }
        }
        console.log(errorMessages);
        return errorMessages.join('\n');
      }
    }
  };

  const deleteItem = React.useCallback(
    (item: any) => async () => {
      console.log('test1');
      setSelectedItem(item);
      // setDeleteModalIsOpen(true);
      const relationMsg = await getInsRelDel(item?.id);
      setRelationMessage(relationMsg as any);
      // setLoadingDelete(false);
      setDeleteModalIsOpen(true);
    },
    []
  );
  function CustomGridFooter(props: any) {
    const totalPages = Math.ceil(props.count / props.pageSize);
    const handlePageSizeChange = (event: any) => {
      props.onPageSizeChange(event.target.value);
      // props.loadUsers(props.page * event.target.value);
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
        }}
      >
        <div></div>
        <Pagination
          shape="rounded"
          count={totalPages}
          page={props.page + 1}
          onChange={(event, newPage) => props.onPageChange(event, newPage)}
          siblingCount={1}
          boundaryCount={1}
        />
      </div>
    );
  }
  const loadInstitutions = async (offset: any = 0) => {
    const resp = await getInstitutions(
      offset,
      pageSize,
      term,
      defType?.id ?? ''
    );
    if (resp?.status === 200) {
      setIsLoading(false);
      setInstitutions(resp?.data?.institutions);
      setRowCountState(resp?.data?.total);
    }
  };

  const setPageAndCall = (page: any) => {
    const zp = page - 1;
    setPage(zp);
    loadInstitutions(zp * pageSize);
  };
  const editUser = React.useCallback(
    (id: GridRowId) => () => {
      navigate('/administration/institution/edit?id=' + id);
    },
    []
  );
  const removeInstitution = async () => {
    const resp = await deleteInstitution(selectedItem?.id);
    if (resp.status === 200) {
      notif('Институција успешно обрисана', 'success');
      setDeleteModalIsOpen(false);
      loadInstitutions();
    } else {
      notif('Институција није обрисана', 'error');
    }
  };
  useEffect(() => {
    loadInstitutions();
  }, []);
  useEffect(() => {
    loadInstitutions();
  }, [term, defType]);
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'name',
        headerName: 'Назив',
        maxWidth: 260,
        minWidth: 180,
        flex: 1,
      },
      {
        field: 'nameEN',
        headerName: 'Назив - енглески',
        maxWidth: 260,
        minWidth: 180,
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Тип',
        maxWidth: 260,
        minWidth: 180,
        flex: 1,
        renderCell: (params) => {
          console.log(params?.row);
          const typeMAP = {
            0: 'Планирана',
            1: 'Постојећа',
            2: 'Укинут',
          };
          return typeMAP[params?.row?.type as keyof typeof typeMAP];
        },
      },
      {
        field: 'abbreviation',
        headerName: 'Акроним',
        maxWidth: 260,
        minWidth: 180,
        flex: 1,
      },
      {
        field: 'abbreviationEN',
        headerName: 'Акроним - енглески',
        minWidth: 180,
        flex: 0.3,
      },
      {
        field: 'pirvId',
        headerName: 'ПИРВ',
        minWidth: 90,
        flex: 0.3,
        type: 'boolean',
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<FaEdit style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Ажурирај"
            onClick={editUser(params.id)}
          />,
          <Tooltip title="Обриши">
            <GridActionsCellItem
              icon={
                loadingDelete ? (
                  <FaSpinner
                    style={{ width: 20, height: 20, color: '#D24D57' }}
                  />
                ) : (
                  <FaTrash
                    style={{ width: 20, height: 20, color: '#D24D57' }}
                  />
                )
              }
              sx={{ width: 50, height: 50 }}
              label="Обриши"
              onClick={deleteItem(params)}
            />
          </Tooltip>,
        ],
      },
    ],
    [deleteItem, editUser]
  );
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };
  const confirm = () => {
    removeInstitution();
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Управљање институцијама
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm text-[#404040]">По Називу</p>
            <TextField
              label="Назив институције"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[140px] text-sm text-[#404040]">Тип институције</p>

            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defType}
              size="small"
              onChange={(event: any, newValue: any) => setDefType(newValue)}
              id="controllable-states-demo"
              options={[
                { id: 0, name: 'Планирана' },
                { id: 1, name: 'Постојећа' },
                { id: 2, name: 'Укинут' },
              ]}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Тип институције" />
              )}
            />
          </div>
        </div>
        <div className="mt-5 h-[530px] w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            disableColumnMenu
            pageSize={pageSize}
            rows={institutions}
            columns={columns}
            style={{ zIndex: 0 }}
            getRowHeight={() => 'auto'}
            rowsPerPageOptions={[5, 10, 20]}
            getRowClassName={(params) => {
              return params?.row?.pirvId ? 'blueRow' : '';
            }}
            disableSelectionOnClick
            components={{
              Footer: CustomGridFooter,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје институције са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје институције са задатим критеријумом
                </Stack>
              ),
            }}
            componentsProps={{
              footer: {
                count: rowCountState,
                page: page,
                pageSize: pageSize,
                onPageChange: (event: any, newPage: any) =>
                  setPageAndCall(newPage),
                onPageSizeChange: (newPageSize: any) =>
                  setPageSize(newPageSize),
                rowsPerPageOptions: [5, 10, 20, 50, 100],
                loadUsers: loadInstitutions,
              },
            }}
          />
        </div>
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={closeDeleteModal}
        confirmation={confirm}
        message={`Обриши институцију?`}
        subMessage={relationMessage}
        isConfirmDisabled={relationMessage?.length ?? 0 > 0}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'institutionManagement'}
      />
    </div>
  );
}

export default InstitututionManagement;
