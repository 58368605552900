import { useCallback, useRef } from 'react';

export function useFocus() {
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const addRef = useCallback((el: any) => {
    if (el && !inputsRef.current.includes(el)) {
      inputsRef.current.push(el);
    }
  }, []);

  const handleKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const currentInputIndex = inputsRef.current.indexOf(event.target);
      const nextInput = inputsRef.current[currentInputIndex + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  }, []);

  const getInputProps = useCallback(
    () => ({
      ref: addRef,
      onKeyDown: handleKeyDown,
    }),
    [addRef, handleKeyDown]
  );

  return getInputProps;
}
