import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ObjectType } from 'typescript';
import { useUser } from '../auth/UseUser';
import Avatar from '@mui/material/Avatar';
import { FaUserAlt } from 'react-icons/fa';
import { ReactComponent as Grb } from '../assets/svg/srbija-grb.svg';
import ConfirmationModal from './modals/ConfirmationModal';
import { getAllParameterByName } from '../server/server';

function NavBar() {
  const location = useLocation();
  const { user, setUser } = useUser();
  let navigate = useNavigate();
  const [logOutModal, setLogOutModal] = useState(false);
  const [loc, setLoc] = useState('/');
  const [year, setYear] = useState('');
  useEffect(() => {
    const pathList = location.pathname.split('/');
    setLoc('/' + pathList[1]);

    getAllParameterByName('menu-year').then((response: any) => {
      setYear(response?.data?.value);
    });
  }, [location.pathname]);
  const closeLogOutModal = () => {
    setLogOutModal(false);
  };
  const logout = () => {
    localStorage.clear();
    setUser({});
  };
  const viewMap = {
    administration: (
      <p
        key={'administration'}
        className={` hover:bg-darkBlue cursor-pointer px-2 py-1 text-center hover:text-white ${
          loc === '/administration' ? 'bg-darkBlue text-white' : ''
        }
        `}
        onClick={() => navigate('/administration')}
      >
        Администрирање
      </p>
    ),
    register: (
      <p
        key={'register'}
        className={` hover:bg-darkBlue cursor-pointer px-2 py-1 text-center hover:text-white ${
          loc === '/register' ? 'bg-darkBlue text-white' : ''
        }
        `}
        onClick={() => navigate('/register')}
      >
        Регистри
      </p>
    ),
    measure: (
      <p
        key={'measure'}
        className={` hover:bg-darkBlue cursor-pointer px-2 py-1 text-center hover:text-white ${
          loc === '/measure' ? 'bg-darkBlue text-white' : ''
        }
        `}
        onClick={() => navigate('/measure')}
      >
        Мерила
      </p>
    ),
    views: (
      <p
        key={'views'}
        className={` hover:bg-darkBlue cursor-pointer px-2 py-1 text-center hover:text-white ${
          loc === '/views' ? 'bg-darkBlue text-white' : ''
        }
        `}
        onClick={() => navigate('/views')}
      >
        Прегледи
      </p>
    ),
    jurisdiction: (
      <p
        key={'jurisdiction'}
        className={` hover:bg-darkBlue cursor-pointer px-2 py-1 text-center hover:text-white ${
          loc === '/jurisdiction' ? 'bg-darkBlue text-white' : ''
        }
        `}
        onClick={() => navigate('/jurisdiction')}
      >
        Надлежности
      </p>
    ),
    help: (
      <p
        key={'help'}
        className={` hover:bg-darkBlue cursor-pointer px-2 py-1 text-center hover:text-white ${
          loc === '/help' ? 'bg-darkBlue text-white' : ''
        }
        `}
        onClick={() => navigate('/help')}
      >
        Упутства
      </p>
    ),
  };
  const shorteMAP = {
    Администратор: 'Админ',
    Координатор: 'Коор.',
    'Уносилац података': 'УП',
    'Секретар преговарачке групе': 'СП',
  };
  return (
    <div className="w-full bg-[#0F2765]">
      <div className="w-full h-[85px] flex justify-between items-center p-1 px-[60px] bg-[#0F2765] text-white ">
        {/* <div
          onClick={() => navigate('/profile')}
          className="w-[35px] h-[35px] rounded-full cursor-pointer hover:opacity-50 flex text-lg bg-gray-700 items-center justify-center text-white uppercase"
        >
          <FaUserAlt className="w-[12px] h-[12px]" />
        </div> */}
        <div
          className="flex items-center text-white"
          style={{ transform: 'scale(0.9)' }}
        >
          <Grb style={{ height: 75, width: 40 }} />
          <div className="flex flex-col justify-between my-2 ml-3">
            <span className="font-medium text-sm">Република Србија</span>
            <span className="text-lg leading-5 mt-1">
              Министарство за <br /> европске интеграције
            </span>
          </div>
        </div>

        <div className="flex h-full items-end">
          <h2 className="text-xl font-semibold">
            Национални програм за усвајање правних тековина ЕУ {year}
          </h2>
        </div>

        {/* <div className="flex items-center w-[150px] justify-end gap-6">
          <p
            className="hover:text-yellow-400 cursor-pointer"
            onClick={() => navigate('/')}
          >
            Home
          </p>
          {user?.roleView?.map((view: string) => {
            return viewMap[view as keyof typeof viewMap];
          })}
          <p
            onClick={logout}
            className="hover:text-yellow-400 cursor-pointer text-right"
          >
            Logout
          </p>
        </div> */}
        <div className="flex flex-col items-end justify-between h-[68px]">
          <div
            className="hover:opacity-60 cursor-pointer"
            onClick={() => setLogOutModal(true)}
          >
            <p className="text-lg">Одјави се</p>
          </div>
          <p className="text-lg">
            {!!user
              ? `${
                  shorteMAP[user?.roleView?.name as keyof typeof shorteMAP] ??
                  ''
                } ${user?.institution?.abbreviation ?? ''} ${
                  user?.firstName ?? ''
                } ${user?.lastName ?? ''}`
              : ''}
          </p>
        </div>
      </div>
      <div className="min-h-[32px] bg-[#F6F6F6] px-16 flex items-center gap-y-1 sm:gap-x-10 xl:gap-x-20  gap-x-5 pt-[2px] flex-wrap">
        {/* user?.roleView? */}
        {[
          'administration',
          'measure',
          'register',
          'views',
          'jurisdiction',
          'help',
        ].map((view: string) => {
          if (user?.roleView?.features?.includes(view)) {
            return viewMap[view as keyof typeof viewMap];
          } else {
          }
        })}
      </div>
      <ConfirmationModal
        modalIsOpen={logOutModal}
        closeModal={closeLogOutModal}
        confirmation={logout}
        message={`Одјави се`}
      />
    </div>
  );
}

export default NavBar;
