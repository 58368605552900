import {
  Box,
  dividerClasses,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import { getEuMeasureBy, getScreeningGroups } from '../../server/server';

function ChooseEuRegisterModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  setSelRegister,
}: any) {
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);
  const [screening, setScreening] = useState<any>(null);
  const [subScreening, setSubScreening] = useState<any>(null);
  const [subSubScreening, setSubSubScreening] = useState<any>(null);
  const [selScreening, setSelScreening] = useState<any>(null);
  const [selSubSubScreening, setSelSubSubScreening] = useState<any>(null);
  const [selSubScreening, setSelSubScreening] = useState<any>(null);

  const [euMeasures, setEuMeasures] = useState<any>(null);
  const [selEuMeasure, setSelEuMeasure] = useState<any>(null);
  const [term, setTerm] = useState('');
  const [value, setValue] = React.useState(0);
  const [selectionModel, setSelectionModel] = useState<any>(null);
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [selGroupValue, setSelGroupValue] = useState('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const submitConfirm = () => {
    setSelRegister(selectionModel);
    closeModal();
  };
  const handleCancel = () => {
    setSelRegister(null);
    closeModal();
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const loadScreenings = async (
    parent: any,
    setScreen: React.Dispatch<React.SetStateAction<any>>
  ) => {
    const resp = await getScreeningGroups(parent);

    if (resp?.status === 200) {
      setScreen(resp?.data);
    }
  };
  const handleScreeningChange = (e: SelectChangeEvent) => {
    const t = screening.find((r: any) => r?.name === e.target.value);
    setSelGroupValue(t?.groupNo);
    setSelScreening(null);
    setSelSubSubScreening(null);
    loadScreenings(t?.groupNo, setSubScreening);
    loadEuMeasureBy(0, selGroupValue);
    setSelScreening(t as any);
  };

  const handleSubScreeningChange = async (e: SelectChangeEvent) => {
    const t = subScreening.find((r: any) => r?.name === e.target.value);
    const newGroupValue = t?.groupNo;

    if (selSubScreening?.groupNo !== newGroupValue) {
      setSelGroupValue(newGroupValue);
      setSelSubSubScreening(null);
      await loadScreenings(newGroupValue, setSubSubScreening);
      await loadEuMeasureBy(0, newGroupValue);
    }

    setSelSubScreening(t as any);
  };

  const handleSelEuMeasure = (e: SelectChangeEvent) => {
    const t = euMeasures.find((r: any) => r?.celexNo === e.target.value);
    setSelEuMeasure(t as any);
  };

  const loadEuMeasureBy = async (offset: any = 0, groupValue: string) => {
    if (value) {
      const resp = await getEuMeasureBy(pageSize, offset, '', groupValue);
      if (resp?.status === 200) {
        setIsLoading(false);
        setRegisters(resp?.data?.measures);
        setRowCountState(resp?.data?.total);
      }
    } else {
      const resp = await getEuMeasureBy(pageSize, offset, term, '');
      if (resp?.status === 200) {
        setIsLoading(false);
        setRegisters(resp?.data?.measures);
        setRowCountState(resp?.data?.total);
      }
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadEuMeasureBy(page * pageSize, selGroupValue);
  };

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'celexNo',
        headerName: 'Ознака',
        maxWidth: 190,
        minWidth: 190,
        flex: 1,
      },
      {
        field: 'descriptionEN',
        headerName: 'Опис',
        minWidth: 180,
        flex: 1,
      },
    ],
    []
  );
  useEffect(() => {
    loadScreenings(0, setScreening);
    setSelScreening(null);
    setSelSubScreening(null);
    setSelEuMeasure(null);
    setSelSubSubScreening(null);
    setSelGroupValue('');
  }, []);

  useEffect(() => {
    loadEuMeasureBy(0, selGroupValue);
  }, [term]);

  useEffect(() => {
    setSelScreening(null);
    setSelSubScreening(null);
    setSelEuMeasure(null);
    setSelSubSubScreening(null);
    setRegisters([]);
  }, [value]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 800,

          padding: 0,
          opacity: 1,
          minHeight: 600,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
        <div className="w-full">
          <p className="text-[#04438b] text-2xl font-bold">
            Претрага ЕУ прописи са коjима се врши усклађивање
          </p>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="По Celex ознаци" {...a11yProps(0)} />
                <Tab label="По Screening поглављу" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Box>
        </div>
        <div className="w-full flex flex-col ">
          {value === 0 ? (
            <div>
              <p className="text-[#04438b] mb-2">Celex ознака прописа ЕУ</p>
              <TextField
                label="Celex ознака "
                id="outlined-size-small"
                type="CelexNo"
                name="CelexNo"
                sx={{ width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => (term ? setTerm('') : null)}
                      className="cursor-pointer min-h-[35px]"
                    >
                      {term ? <FaTimes /> : <FaSearch />}
                    </InputAdornment>
                  ),
                }}
                size="small"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
              <p className="text-[#04438b] my-2">
                {selEuMeasure?.descriptionEN}
              </p>
            </div>
          ) : (
            <div>
              <div className="flex flex-col gap-4 w-full h-[210px]">
                <p className="text-[#04438b] ">Одабир поглавља</p>
                <FormControl size="small">
                  <InputLabel id="demo-select-small">
                    Screening поглавље
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={selScreening?.name || ''}
                    onChange={handleScreeningChange}
                    label="Screening поглавље"
                    sx={{ minWidth: 470, maxWidth: 470 }}
                    className="capitalize"
                  >
                    {screening?.map((c: any) => {
                      return (
                        <MenuItem
                          value={c?.name}
                          className="capitalize"
                          key={c?.groupNo}
                        >
                          {c?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {selScreening &&
                  (subScreening?.length > 0 ? (
                    <FormControl size="small">
                      <InputLabel id="demo-select-small">
                        Screening под поглавље
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selSubScreening?.name || ''}
                        onChange={handleSubScreeningChange}
                        label="Screening под поглавље"
                        sx={{ minWidth: 470, maxWidth: 470 }}
                        className="capitalize"
                      >
                        {subScreening?.map((c: any) => {
                          return (
                            <MenuItem
                              value={c?.name}
                              className="capitalize"
                              key={c?.groupNo}
                            >
                              {c?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <p className="text-[#162c46] ">Не постоје пот поглавља</p>
                  ))}
                {selSubScreening &&
                  (subSubScreening?.length > 0 ? (
                    <FormControl size="small">
                      <InputLabel id="demo-select-small">
                        Screening пот пот поглавље
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={selSubSubScreening || ''}
                        onChange={(e) => setSelSubSubScreening(e.target.value)}
                        label="Screening пот пот поглавље"
                        sx={{ minWidth: 470, maxWidth: 470 }}
                        className="capitalize"
                      >
                        {subSubScreening?.map((c: any) => {
                          return (
                            <MenuItem
                              value={c?.name}
                              className="capitalize"
                              key={c?.groupNo}
                            >
                              {c?.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <p className="text-[#162c46] ">
                      Не постоје пот потпоглавља
                    </p>
                  ))}
              </div>
            </div>
          )}
        </div>

        <div className="mt-5 h-[390px] w-full h-full ">
          {term || (value && selScreening) ? (
            <DataGrid
              rowCount={rowCountState}
              loading={isLoading}
              pagination
              disableColumnMenu
              paginationMode="server"
              onPageChange={(newPage) => setPageAndCall(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              page={page}
              pageSize={pageSize}
              rows={registers}
              columns={columns}
              style={{ zIndex: 0 }}
              sx={{
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                  {
                    display: 'none',
                  },
              }}
              rowsPerPageOptions={[5]}
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                //   setSelectionModel(
                //     (prevModel: any) =>
                //       registers.filter(
                //         (r: any) =>
                //           r?.id ===
                //           newSelectionModel.filter(
                //             (newId) => prevModel?.id != newId
                //           )
                //       )[0]
                //   )[0];

                const newModel = registers.find(
                  (r: any) =>
                    r?.id ===
                    newSelectionModel.filter(
                      (newId) => selectionModel?.id !== newId
                    )[0]
                );
                setSelectionModel(newModel || null);

                console.log('here', newSelectionModel);
              }}
              selectionModel={selectionModel?.id ?? []}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје прописи са задатим критеријумом
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје прописи са задатим критеријумом
                  </Stack>
                ),
              }}
            />
          ) : null}
        </div>

        <div className="flex flex-row justify-between items-center w-full mt-6">
          <div
            className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={submitConfirm}
          >
            Одабери
          </div>
          <div
            className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => handleCancel()}
          >
            Oткажи
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChooseEuRegisterModal;
