import React, { useState } from 'react';
import { useFocus } from '../../../../common/useFocus';

const DynamicTable = ({
  allType,
  text,
  years,
  setYears,
  data,
  setData,
}: any) => {
  const types = allType.map((t: any) => t?.name);
  const getInputProps = useFocus();

  const addYear = () => {
    let newYear = years[years.length - 1];
    if (newYear >= 2027) {
      newYear = 3000;
    } else {
      newYear++;
    }

    setYears((prevYears: any) => [...prevYears, newYear]);

    setData((prevData: any) => {
      let newData = [...prevData];
      types.forEach((type: any) => {
        newData.push({ year: newYear, type: type, value: 0 });
      });

      return newData;
    });
  };

  const updateValue = (
    year: number | 'Kasnije',
    type: string,
    value: string
  ) => {
    // Remove thousand separators before parsing

    const sanitizedValue = value.replace(/\./g, '').replace(',', '.');
    let parsedValue = parseFloat(sanitizedValue);
    if (isNaN(parsedValue)) {
      parsedValue = 0.0;
    }
    setData((prevData: any) => {
      let newData = [...prevData];
      let actualYear = year === 'Kasnije' ? 3000 : year;

      let itemIndex = newData.findIndex(
        (item) => item.year === actualYear && item.type === type
      );

      if (itemIndex > -1) {
        newData[itemIndex].value = parsedValue;
      } else {
        newData.push({ year: actualYear, type, value: parsedValue });
      }

      return newData;
    });
  };

  const sumForYear = (year: number | 'Kasnije') => {
    const actualYear = year === 'Kasnije' ? 3000 : year;
    return data
      .filter(
        (item: any) =>
          item.year === actualYear && item.type !== 'Промена броја запослених'
      )
      .reduce((sum: any, item: any) => sum + (item.value || 0), 0);
  };

  const sumForType = (type: string) => {
    return data
      .filter((item: any) => item.type === type)
      .reduce((sum: any, item: any) => sum + (item.value || 0), 0);
  };

  const totalSum = () => {
    return data
      .filter((item: any) => item.type !== 'Промена броја запослених')
      .reduce((sum: any, item: any) => sum + (item.value || 0), 0);
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const input = e.target;
    setTimeout(() => {
      const len = input.value.length;
      if (input.setSelectionRange) {
        input.setSelectionRange(len, len);
      }
    }, 10); // 10 ms delay should suffice
  };

  return (
    <div className="overflow-hidden rounded-lg border border-[#81848f3d]">
      {/* header */}
      <div
        className={`flex divide-x divide-[#81848f3d]  text-[#0f2765] bg-[#c3c3d6] text-left text-xs font-medium uppercase tracking-wider justify-items-center`}
      >
        <div className="px-4 py-1 w-60 flex items-center font-bold justify-center">
          {text}
        </div>
        <div className="flex flex-col text-center font-bold items-center justify-center w-24">
          Базна година
          <p>{years[0]}</p>
        </div>
        {years?.slice(1)?.map((year: any, index: any) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center w-24 font-bold"
          >
            <p className="text-sm">{year === 3000 ? 'Касније' : year}</p>
          </div>
        ))}

        <button
          onClick={addYear}
          className="bg-[#3a3f4a] text-white px-2 ml-1 my-4 text-xl rounded hover:bg-[#0f2765]"
          disabled={years[years.length - 1] >= 3000}
        >
          +
        </button>

        <div className="flex items-center justify-center ml-auto w-24 font-bold">
          Укупно
        </div>
      </div>
      {/* rows */}
      <div className="divide-y divide-[#81848f3d] text-sm">
        {types.map((type: any, rowIndex: any) => (
          <div key={rowIndex} className={`flex divide-x divide-[#81848f3d]`}>
            <div
              className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-60 ${
                type === 'Промена броја запослених' ? 'bg-[#e8f6ec]' : ''
              } `}
            >
              {type}
            </div>
            {years.map((year: any, i: any) => {
              let item = data.find(
                (item: any) => item.year === year && item.type === type
              );
              console.log(item);
              return (
                <div
                  key={year}
                  className={`flex items-center justify-center whitespace-nowrap text-sm text-gray-500 w-24 ${
                    type === 'Промена броја запослених' ? 'bg-[#e8f6ec]' : ''
                  } `}
                >
                  {type !== 'Промена броја запослених' ? (
                    <input
                      type="text"
                      onFocus={handleInputFocus}
                      className={`w-20 text-right ${
                        i === 0
                          ? 'flex items-center justify-center w-24 bg-[#e8e8f6]'
                          : ''
                      }`}
                      onChange={(e) => {
                        updateValue(year, type, e.target.value);
                      }}
                      disabled={i === 0}
                      value={
                        item
                          ? item.value
                            ? item.value.toLocaleString('de-DE', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })
                            : '0'
                          : '0'
                      }
                      {...getInputProps()} // Add this
                      onBlur={(e) => {
                        const val = e.target.value
                          .replace(/\./g, '')
                          .replace(',', '.');
                        const numberVal = parseFloat(val);
                        if (isNaN(numberVal)) {
                          e.target.value = '0';
                          updateValue(year, type, '0');
                        } else {
                          e.target.value = numberVal.toLocaleString('de-DE', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          });
                          updateValue(year, type, numberVal.toString());
                        }
                      }}
                    />
                  ) : (
                    <input
                      onFocus={handleInputFocus}
                      className={`w-20 text-right ${
                        i === 0
                          ? 'flex items-center justify-center w-24 bg-[#a1aca4]'
                          : 'bg-[#e8f6ec]'
                      }`}
                      type="text"
                      disabled={i === 0}
                      value={item ? item.value.toString() : '0'}
                      {...getInputProps()} // Add this
                      onChange={(e) => updateValue(year, type, e.target.value)}
                      onBlur={(e) => {
                        const val = e.target.value
                          .replace(/\./g, '')
                          .replace(',', '.');
                        const numberVal = parseFloat(val);
                        if (isNaN(numberVal)) {
                          e.target.value = '0';
                          updateValue(year, type, '0');
                        } else {
                          e.target.value = numberVal.toLocaleString('de-DE', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          });
                          updateValue(year, type, numberVal.toString());
                        }
                      }}
                    />
                  )}
                </div>
              );
            })}

            <div className="flex items-center justify-center bg-[#e8e8f6] ml-auto w-24">
              {sumForType(type).toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          </div>
        ))}
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-60 bg-[#e8e8f6]">
            Укупно
          </div>
          {years.map((year: any) => (
            <div
              key={year}
              className="flex items-center justify-center w-24 bg-[#e8e8f6]"
            >
              {sumForYear(year).toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          ))}

          <div className="flex items-center justify-center ml-auto w-24 bg-[#ced0db]">
            {totalSum().toLocaleString('de-DE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;
