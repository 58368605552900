import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaFolder, FaSearch, FaTimes } from 'react-icons/fa';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { getScreeningTree } from '../../server/server';
import { GroupNode, GroupsTree } from './components/GroupComponent';
import DefaultJur from './screens/DefaultJur';

function Jurisdiction() {
  const [term, setTerm] = useState('');
  const [groups, setGroups] = useState<GroupNode[]>([]);
  const [groupNo, setGroupNo] = useState('');
  // const [menuList, setMenuList] = useState<any>(constMenu);

  const handleTermDelete = () => {
    if (term) {
      setTerm('');

      // setMenuList(constMenu);
    }
  };

  useEffect(() => {
    loadGroups(term);
  }, [term]);

  const loadGroups = async (term: any) => {
    const resp = await getScreeningTree(term);

    if (resp?.status === 200) {
      setGroups(resp?.data);
    }
  };

  return (
    <div className=" w-full h-full flex flex-row justify-start">
      <div className="h-full bg-[#1e3f940a] w-[630px] my-5 first:mt-0">
        <div className="w-full flex items-center justify-center mt-6 mb-4">
          <TextField
            label="Претрага Screening-a"
            id="outlined-size-small"
            type="screening"
            name="screening"
            sx={{ width: 380 }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleTermDelete}
                  className="cursor-pointer min-h-[35px]"
                >
                  {term ? <FaTimes /> : <FaSearch />}
                </InputAdornment>
              ),
            }}
            size="small"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
          />
        </div>
        <div className="overflow-auto gray-small-scroll h-full">
          {/* {screening?.map((s: any) => {
            return (
              <div
                // onClick={() => navigate()}
                className={`flex items-center cursor-pointer gap-2 justify-start py-1 pl-6  mx-6 my-2  rounded-md ${
                  s === '02 Слободно кретање радника'
                    ? 'text-white bg-[#0F2765]'
                    : 'hover:text-white hover:bg-[#0F2765] text-gray-300'
                }`}
              >
                <FaFolder className="w-[30px] min-w-[30px]" />
                {s}
              </div>
            );
          })} */}
          {<GroupsTree groups={groups} />}
        </div>
      </div>
      <div
        className="w-full h-full overflow-y-scroll gray-small-scroll help-page"
        style={{ scrollSnapType: 'y mandatory' }}
      >
        <DefaultJur />
      </div>
    </div>
  );
}

export default Jurisdiction;
