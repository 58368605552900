import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { error } from 'console';
import React, { useEffect, useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  editOJNumber,
  getAllOfficialJournals,
  insertOJNumber,
  loadOJDateById,
} from '../../../server/server';
import { notif } from '../../utils/util';
import { useSearchParams } from 'react-router-dom';
import srLocale from 'date-fns/locale/sr';
import { format } from 'date-fns';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function OJInsertDate() {
  const [searchParams] = useSearchParams();
  const [selectedJournal, setSelectedJournal] = useState<any>(null);
  const [journals, setJournals] = useState<any>([]);
  const [year, setYear] = useState<any>(null);
  const [number, setNumber] = useState<any>(null);
  const [editId, setEditId] = useState('');
  const [date, setDate] = React.useState<any>(null);
  const [errorMap, setErrorMap] = useState({
    journal: false,
    year: false,
    date: false,
    number: false,
  });

  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };

  const createOJ = async () => {
    const resp = await insertOJNumber({
      ojId: selectedJournal?.id,
      number: `${number}/${year}`,
      date: date,
    });
    if (resp.status === 201) {
      notif('Број објаве успешно креиран', 'success');
    } else {
      notif('Број објаве није креиран ' + resp?.data?.message, 'error');
    }
  };

  const editOJ = async () => {
    const resp = await editOJNumber({
      id: editId,
      ojId: selectedJournal?.id,
      number: `${number}/${year}`,
      date: date,
    });
    if (resp.status === 201) {
      notif('Број објаве успешно ажуриран', 'success');
    } else {
      notif('Број објаве није ажуриран', 'error');
    }
  };
  const loadById = async (id: any) => {
    const resp = await loadOJDateById(id);
    if (resp.status === 200) {
      const ojdate = resp?.data?.number.split('/');
      setSelectedJournal(resp?.data?.journal);
      setYear(ojdate[1] ?? 0);
      setNumber(ojdate[0] ?? 0);
      setDate(new Date(resp?.data?.date));

      console.log(resp.data);
    }
  };
  const handleInsert = () => {
    setErrorMap({
      journal: !selectedJournal?.id,
      year: !year,
      date: !date,
      number: !number,
    });
    if (year && date && number && selectedJournal) {
      if (editId) {
        editOJ();
      } else {
        createOJ();
      }
    }
  };
  const loadAllJournals = async () => {
    const resp = await getAllOfficialJournals();

    if (resp?.status === 200) {
      setJournals(resp?.data);
      setSelectedJournal(resp?.data[0]);
    }
  };
  const handleJournalChange = (e: any, prm: any) => {
    setErrorMap({ ...errorMap, journal: false });
    setSelectedJournal(prm as any);
  };
  useEffect(() => {
    loadAllJournals();
    let eId = searchParams.get('id');
    if (eId) {
      loadById(eId);
      setEditId(eId);
    }
  }, []);

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Унос датума објаве Службеног гласника
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 ">
        <div className="flex gap-28">
          <div className="flex gap-8 flex-col">
            <div className="flex items-center gap-6">
              <p className="w-[270px]">Службени гласник</p>
              <div className="flex flex-col">
                <Autocomplete
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  value={selectedJournal}
                  size="small"
                  onChange={(event: any, newValue: any) =>
                    handleJournalChange(event, newValue)
                  }
                  id="controllable-states-demo"
                  options={journals ?? []}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 340 }}
                  renderInput={(params) => (
                    <TextField
                      error={errorMap?.journal as boolean}
                      {...params}
                      label="Службени гласник"
                    />
                  )}
                />
                {errorMap?.journal && (
                  <FormHelperText sx={{ color: 'red' }}>
                    Поље је обавезно
                  </FormHelperText>
                )}
              </div>
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[270px]">Датум објаве Службеног гласника</p>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={srLocale}
              >
                <DatePicker
                  label="Датум објаве"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                    setYear(new Date(newValue).getFullYear() as any);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{ width: 340 }}
                      value={date ? format(date, 'dd. MM. yyyy') : ''}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="flex items-center gap-6 justify-between">
              <p className="w-[270px]">Редни број објаве Службеног гласника </p>
              <div className="flex items-center justify-between w-[340px]">
                <TextField
                  label="Редни број"
                  id="outlined-size-small"
                  type="acronymEN"
                  name="acronymEN"
                  size="small"
                  sx={{ width: 150 }}
                  helperText={errorMap?.number ? 'Поље је обавезно' : ''}
                  error={errorMap?.number as boolean}
                  onFocus={() => setErrorMap({ ...errorMap, number: false })}
                  value={number || ''}
                  onChange={(e) => setNumber(e.target.value)}
                  // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
                  // error={errorMap?.firstName as boolean}
                  // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
                  // value={firstName}
                  // onChange={(e) => setFirstName(e.target.value)}
                />
                <p>/</p>
                <TextField
                  label="Година"
                  id="outlined-size-small"
                  type="acronymEN"
                  name="acronymEN"
                  disabled={true}
                  size="small"
                  sx={{ width: 150 }}
                  helperText={errorMap?.year ? 'Поље је обавезно' : ''}
                  error={errorMap?.year as boolean}
                  onFocus={() => setErrorMap({ ...errorMap, year: false })}
                  value={year || ''}
                  onChange={(e) => setYear(e.target.value)}
                  // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
                  // error={errorMap?.firstName as boolean}
                  // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
                  // value={firstName}
                  // onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-12 items-center">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#0F2765' }}
            endIcon={<FaSave />}
            onClick={handleInsert}
          >
            Потврди
          </Button>
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'ojInsertDate'}
      />
    </div>
  );
}
export default OJInsertDate;
