import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export const baseUrl = 'https://npaa-api.mei.gov.rs';

// export const baseUrl = 'https://npaa-api.chainapptech.net';
interface LoginDto {
  username: string;
  password: string;
}
// interface SingUp {
//   firstName: string;
//   lastName: string;
//   username: string;
//   email: string;
//   password: string;
//   note: string;
//   title: string;
//   roleId: string;
//   institutionId: string;
//   phoneNumber: string;
// }
function handelError(error: any) {
  return { status: error?.response?.status, data: error?.response?.data };
}
function handleSuccess(response: any) {
  const data = !!response.data ? response.data : response.data;
  const responseData = isJson(data) ? JSON.parse(data) : data;
  return { status: response.status, data: responseData };
}
const isJson = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export async function login(user: LoginDto) {
  console.log('read', user);
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/user/signin`,
    data: user,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertUser(user: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/user/insert`,
    data: user,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function addExpenditure(b: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/expenditure`,
    data: b,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNMeasureInfo(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasures/info?id=${id}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getExpenditure(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/expenditure?id=${id}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getCelexInfo(celex: any, offset: any = 0) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/jurisdiction/getcelexinfo?celex=${celex}&offset=${offset}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getExpenditureInfo(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/expenditure/info?id=${id}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getFoundSourceTypes() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/finsourcetypes`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateNatMeasJurastiction(nm: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/natmeas/jurastiction/update`,
    data: nm,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertMeasure(nm: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/measure/insert`,
    data: nm,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editMeasure(nm: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/measure/edit`,
    data: nm,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function measureStatus(nm: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/measure/status`,
    data: nm,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateHelpPage(page: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/help/page/update`,
    data: page,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}

export async function insertPointer(page: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/pointer/insert`,
    data: page,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}

export async function editHarmonizationCompatibility(h: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/harmonizations/compatibility`,
    data: h,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertNoCelex(i: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/nocelexregister/insert`,
    data: i,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editNoCelex(i: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/nocelexregister/edit`,
    data: i,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertHarmonization(h: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/harmonizations`,
    data: h,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertNationalMeasureInChargeInstitutions(h: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/inchargeinstitution`,
    data: h,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertNationalMeasureRelation(r: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/relation`,
    data: r,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editUser(user: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/user/edit`,
    data: user,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertOJ(oj: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/oj/insert`,
    data: oj,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editOJ(oj: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/oj/edit`,
    data: oj,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertNatGroupStructure(structure: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/group/structure`,
    data: structure,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editUserPassword(user: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/user/edit/password`,
    data: user,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertOJNumber(ojNum: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/oj/number/insert`,
    data: ojNum,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editOJNumber(ojNum: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/oj/number/edit`,
    data: ojNum,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertInstitution(institution: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/institutions/insert`,
    data: institution,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertNatMeasure(natMeasure: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/insert`,
    data: natMeasure,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editNatMeasure(natMeasure: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/edit`,
    data: natMeasure,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateInstitution(institution: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/institutions/update`,
    data: institution,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function whoAmI() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/user/whoami`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNationalGroupSet(group: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/nationalgroupset?group=${group}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getExpenditures(
  limit: any,
  offset: any,
  role: any = '',
  term: any = ''
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/expenditures?limit=${limit}&offset=${offset}&role=${role}&term=${term}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNatGroupStructure(id: any, set: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/group/structure?id=${id}&set=${set}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNationalGroupSetByLevel(set: any, level: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/nationalgroupsetbylevel?setNo=${set}&level=${level}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getScreeningGroups(parent: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/groups/screening?parent=${parent}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}

export async function getEuMeasureBy(
  limit: any,
  offset: any,
  celexNo: any,
  group: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/eumeasures/by?limit=${limit}&offset=${offset}&celexNo=${celexNo}&group=${group}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNatMeasureBy(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/by?id=${id}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAdministrationUserInsert() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/user/insert`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getDefaultRole() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/settings/defaultRole`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAllRoles() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/settings/roles`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getEuStats() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/administration/eu/stats`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getSDocs(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/strategydocs?id=${id}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertSDocs(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/register/natmeasure/strategydocs`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNDocs(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/natmeasure/negotiationdocs?id=${id}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertNDocs(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/register/natmeasure/negotiationdocs`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNDocsM(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/measure/negotiationdocs?id=${id}`,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertNDocsM(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/measure/negotiationdocs`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateJur(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/jurisdiction/update/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateJurById(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/jurisdiction/update/id`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function editJur(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/jurisdiction/update/id`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateEuJur(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/jurisdiction/eu/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function createJur(payload: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/jurisdiction/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateUserRole(id: string, roleId: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/settings/user/role/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { id, roleId },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateSettings(id: string, value: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/settings/update/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { value },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}

export async function getAllUsers(
  limit: any,
  offset: any,
  username: any,
  roleId: any,
  institutionId: any,
  name: any,
  orderByFieldName: string, //column name
  orderByName: string, //sorting direction
  valid: any
) {
  console.log('getAllUsers');
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/users`,
    params: {
      limit,
      offset,
      username,
      roleId,
      institutionId,
      name,
      order: orderByFieldName ?? null,
      by: orderByName ?? null,
      valid,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getRegistersManagement(
  limit: any,
  offset: any,
  term: any,
  institutionId: any,
  role: any = '',
  type: any = '',
  user: any = '',
  orderByFieldName: string = '', //column name
  orderByName: string = '' //sorting direction
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasures`,
    params: {
      limit,
      offset,
      term,
      institutionId,
      role,
      type,
      user,
      order: orderByFieldName ?? null,
      by: orderByName ?? null,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}

export async function getRegistersAdmin(
  limit: any,
  offset: any,
  term: any,
  institutionId: any,
  role: any = '',
  title: any = '',
  type: any = 0,
  user: any = '',
  orderByFieldName: string = '',
  orderByName: string = ''
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/natmeasures`,
    params: {
      limit,
      offset,
      term,
      institutionId,
      role,
      title,
      type,
      user,
      order: orderByFieldName,
      by: orderByName,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getMeasures(
  limit: any,
  offset: any,
  term: any,
  institutionId: any,
  filter: any,
  role: any = '',
  orderByFieldName: string = '',
  orderByName: string = ''
) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/views/measures`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit,
      offset,
      institution: institutionId,
      term,
      filter,
      role,
      order: orderByFieldName,
      by: orderByName,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getPirvIns(limit: any, offset: any, term: any) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/administration/pirvins`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      term,
      limit,
      offset,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getPirvMeasurs(
  limit: any,
  offset: any,
  id: any,
  term: any
) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/register/pirvmeasures`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      id,
      term,
      limit,
      offset,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getPointers(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/register/measure/pointers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      id,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getInstRelationDelete(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/administration/instdelrel?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getMeasureById(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/register/measure/info?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getInstCelexViews(
  limit: any,
  offset: any,
  institutionId: any,
  filter: any,
  from: any,
  to: any,
  orderByFieldName: string = '',
  orderByName: string = ''
) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/views/institutioncelex`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit,
      offset,
      institution: institutionId,
      filter,
      from,
      to,
      order: orderByFieldName,
      by: orderByName,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function GetViewsQInstitution(
  limit: any,
  offset: any,
  institutionId: any,
  filter: any,
  from: any,
  to: any
) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/views/realisationinstitution`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit,
      offset,
      institution: institutionId,
      filter,
      from,
      to,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function GetViewsNPICelex(
  limit: any,
  offset: any,
  group: any,
  filter: any,
  from: any,
  to: any,
  orderByFieldName: string = '',
  orderByName: string = ''
) {
  const token = await localStorage.getItem('accessToken');
  const options = {
    method: 'GET',
    url: `${baseUrl}/views/npicelex`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      limit,
      offset,
      group,
      filter,
      from,
      to,
      order: orderByFieldName,
      by: orderByName,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNatMeasuresSimpleRelation(
  limit: any,
  offset: any,
  term: any,
  searchId: any,
  type: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasures/simple/relation?limit=${limit}&offset=${offset}&term=${term}&type=${type}&searchId=${searchId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNatMeasuresSimple(
  limit: any,
  offset: any,
  term: any,
  searchId: any,
  type: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasures/simple?limit=${limit}&offset=${offset}&term=${term}&type=${type}&searchId=${searchId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAllInstitutions(type: any = null) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/institutions/all`,
    params: { type },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getRegisterCompletion(id: any, set: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasure/completion`,
    params: { id, set },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAllOfficialJournals() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/journals/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}

export async function getOfficialJournals(offset: any, limit: any, term: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/journals?offset=${offset}&limit=${limit}&term=${term}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAdministrationNoCelexRelations(
  offset: any,
  limit: any,
  term: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/nocelexregisters?offset=${offset}&limit=${limit}&term=${term}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAdministrationNoCelexRelationById(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/nocelexregister?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function uploadTUFile(file: any, id: any) {
  const token = await localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.append('file', file);
  formData.append('id', id);
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/administration/tufile/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getTUFileById(id: any) {
  // const token = await localStorage.getItem('accessToken');
  // const options: AxiosRequestConfig = {
  //   method: 'GET',
  //   url: `${baseUrl}/administration/tufile/${id}`,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  // const contentDisposition = response.headers['content-disposition'];
  // const fileName = contentDisposition.substring(
  //   contentDisposition.indexOf('=') + 1
  // );

  // return axios(options)
  //   .then((response) => handleSuccess(response))
  //   .catch((error) => handelError(error));

  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(`${baseUrl}/administration/tufile/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob', // specify the response type as 'blob'
  });

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');
  console.log('ovde', response.headers);
  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function uploadEFile(file: any, id: any) {
  const token = await localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.append('file', file);
  formData.append('id', id);
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/register/efile/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getEFileById(id: any) {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(`${baseUrl}/register/efile/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob', // specify the response type as 'blob'
  });

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');
  console.log('ovde', response.headers);
  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getInstitutionCelexFile(
  i: any,
  from: any,
  to: any,
  filter: any
) {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(
    `${baseUrl}/views/doc/institution-celex?institution=${i}&from=${from}&to=${to}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob', // specify the response type as 'blob'
    }
  );

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getEDoc(id: any) {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(
    `${baseUrl}/views/doc/expenditure?id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob', // specify the response type as 'blob'
    }
  );

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getFinDoc() {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(`${baseUrl}/views/doc/fin`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob', // specify the response type as 'blob'
  });

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getMeasureDoc() {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(`${baseUrl}/views/doc/measure`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob', // specify the response type as 'blob'
  });

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getQFile(g: any, from: any, to: any) {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(
    `${baseUrl}/views/doc/quartile?from=${from}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob', // specify the response type as 'blob'
    }
  );

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getJurFile(no: any) {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(
    `${baseUrl}/views/doc/jurisdiction?no=${no}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }
  );

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getClusterFile(g: any, from: any, to: any, filter: any) {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(
    `${baseUrl}/views/doc/cluster?group=${g}&from=${from}&to=${to}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob', // specify the response type as 'blob'
    }
  );

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function getNPIFile(g: any, from: any, to: any, filter: any) {
  const token = await localStorage.getItem('accessToken');
  const response = await axios.get(
    `${baseUrl}/views/doc/npi?group=${g}&from=${from}&to=${to}&filter=${filter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob', // specify the response type as 'blob'
    }
  );

  const contentDisposition = response.headers['content-disposition'];
  const name = contentDisposition?.split('="')[1].replace('"', '');

  return {
    data: { data: response.data, name, type: response.headers['content-type'] },
    status: response?.status,
  };
}
export async function deleteTUFile(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/administration/tufile/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteNatMeasure(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/natmeasure?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getOJNumbers(
  offset: any,
  limit: any,
  year: any,
  jId: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/oj/numbers?offset=${offset}&limit=${limit}&year=${year}&jid=${jId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getViewsNPI() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/views/npi-list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getEFiles(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/efiles?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getExpi(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/expi?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getViewsClusterTree() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/views/cluster-tree`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getViewsCluster() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/views/cluster-list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAllParameters() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/parameters`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAllParameterByName(n: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/parameter/${n}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function updateParameters(id: string, updatedData: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${baseUrl}/administration/parameter/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: updatedData,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getUsersByInstitution(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/users/by/institution?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getScreeningTree(term: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/jurisdiction/screenings?term=${term}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getJurisdictions(
  offset: any,
  limit: any,
  institution: any,
  group: any,
  filter: any,
  celex: any,
  orderByFieldName: string = '',
  orderByName: string = ''
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/jurisdiction/get`,
    params: {
      limit,
      offset,
      institution,
      group,
      filter,
      celex,
      order: orderByFieldName,
      by: orderByName,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getTUList(
  offset: any,
  limit: any,
  celex: any,
  natMeasure: any,
  file: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/tu/list?offset=${offset}&limit=${limit}&celex=${celex}&file=${file}&natMeasure=${natMeasure}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getInstitutions(
  offset: any,
  limit: any,
  term: any,
  type: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/institutions?offset=${offset}&limit=${limit}&term=${term}&type=${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getUserById(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',

    url: `${baseUrl}/administration/userby/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function loadOJDateById(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',

    url: `${baseUrl}/administration/oj/date/by/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getOJbyId(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',

    url: `${baseUrl}/administration/oj/by/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function loadInstitutionById(id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/administration/institution/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getHelpPageByName(name: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/help/page/${name}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getSettingsTypeof(type: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/settings/typeof/${type}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getSettingByName(name: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/settings/name/${name}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAllRolesWithFeatures() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/settings/roles/feature`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getHelpList() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/help/list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}

export async function getNoCelexRelations() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasure/norelationcelex`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getHarmonizations(offset: any, limit: any, id: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasure/harmonizations?id=${id}&limit=${limit}&offset=${offset}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNationalMeasureInChargeInstitutions(
  offset: any,
  limit: any,
  id: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasure/inchargeinstitutions?id=${id}&limit=${limit}&offset=${offset}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getAllFeatures() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/settings/features/all`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteExpenditure(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/expenditure?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteRole(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/settings/user/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteMeasure(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/measure?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteOJNumber(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/administration/oj/number/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteNoCelex(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/administration/nocelexregister/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteInstitution(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/administration/institutions/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteOJ(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/administration/oj/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteNationalMeasureRelation(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/natmeasure/relation?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteNationalMeasureInChargeInstitutions(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/natmeasure/inchargeinstitution?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteHarmonization(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/natmeasure/harmonizations?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function createExpi(h: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/expi`,
    data: h,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function insertMeasureInChargeInstitutions(h: any) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${baseUrl}/register/measure/inchargeinstitution`,
    data: h,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getMeasureInChargeInstitutions(
  offset: any,
  limit: any,
  id: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/measure/inchargeinstitutions?id=${id}&limit=${limit}&offset=${offset}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteMeasureInChargeInstitutions(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/measure/inchargeinstitution?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNationalMeasureRelationTypes() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasure/relation/types`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getNationalMeasureRelations(
  offset: any,
  limit: any,
  id: any
) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/natmeasure/relations?offset=${offset}&limit=${limit}&id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function getExpenditureTypes() {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'GET',
    url: `${baseUrl}/register/expenditure/types`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteNatMeasureStructure(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/register/natmeasure/group/structure?id=${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function deleteUser(id: string) {
  const token = await localStorage.getItem('accessToken');
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    url: `${baseUrl}/administration/user/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error) => handelError(error));
}
export async function measureAddNationalMeasure(
  measureId: number,
  nationalMeasureId: number
): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'POST',
    url: `${baseUrl}/measure/addNationalMeasure`,
    headers,
    data: { measureId, nationalMeasureId },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}

export async function measureRemoveNationalMeasure(id: any): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'DELETE',
    url: `${baseUrl}/measure/removeNationalMeasure`,
    headers,
    data: { id },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}

export async function measureListNationalMeasures(
  measureId: number
): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'GET',
    url: `${baseUrl}/measure/listNationalMeasures/${measureId}`,
    headers,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}

export async function measureAddEuMeasure(
  celexNo: string,
  measureId: number
): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'POST',
    url: `${baseUrl}/measure/addEuMeasure`,
    headers,
    data: { measureId, celexNo },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}
export async function removePointer(id: any): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'DELETE',
    url: `${baseUrl}/measure/pointer`,
    headers,
    data: { id },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}
export async function removeJurResp(id: any): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'DELETE',
    url: `${baseUrl}/jurisdiction/${id}`,
    headers,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}
export async function measureRemoveEuMeasure(id: any): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'DELETE',
    url: `${baseUrl}/measure/removeEuMeasure`,
    headers,
    data: { id },
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}

export async function measureListEuMeasures(measureId: number): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'GET',
    url: `${baseUrl}/measure/listEuMeasures/${measureId}`,
    headers,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}
export async function getResp2Jur(celex: string): Promise<any> {
  const token = await localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'GET',
    url: `${baseUrl}/jurisdiction/resp2?celex=${celex}`,
    headers,
  };
  return axios(options)
    .then((response) => handleSuccess(response))
    .catch((error: any) => handelError(error));
}
