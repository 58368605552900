import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FaHistory } from 'react-icons/fa';
const ResetTextField = ({
  defaultValue,
  value,
  onChange,
  ...otherProps
}: any) => {
  return (
    <div className="flex items-center gap-2">
      <TextField
        value={value || ''}
        onChange={(e: any) => onChange(e.target.value)}
        {...otherProps}
      />
      {value !== defaultValue ? (
        <div
          onClick={() => onChange(defaultValue)}
          className="w-[40px] h-[40px] p-2 flex items-center rounded-full justify-center cursor-pointer  hover:bg-[#e1e1e1]"
        >
          <FaHistory className="w-[20px] h-[20px] fill-[#434343]" />
        </div>
      ) : (
        <div className="w-[40px] h-[40px]"></div>
      )}
    </div>
  );
};

export default ResetTextField;
