import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { notif } from '../../utils/util';
import {
  editUser,
  editUserPassword,
  getAdministrationUserInsert,
  getAllRoles,
  getDefaultRole,
  getSettingByName,
  getUserById,
  insertUser,
  updateSettings,
} from '../../../server/server';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@mui/material';
import {
  FaBook,
  FaHistory,
  FaLock,
  FaLockOpen,
  FaQuestion,
  FaQuestionCircle,
  FaSave,
} from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usernameMap, validator } from '../../../common/constaints';
import withContextMenu from '../../../common/withContextMenu';
import ResetTextField from '../../../common/ResetTextField';
import ResetAutocomplete from '../../../common/ResetAutocomplete';
import HelpPageModal from '../../modals/HelpPageModal';
import { tr } from 'date-fns/locale';

function EditUser() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [editId, setEditId] = useState('');
  type Role = {
    [key: string]: any;
    id?: string;
    name?: string;
  };
  const [isUserDisabled, setIsUserDisabled] = useState(false);
  const [defaultRole, setDefaultRole] = useState<any>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [institutions, setInstitutions] = useState<any>([]);
  const [defaultInstitution, setDefaultInstitution] = useState<any>(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRetry, setPasswordRetry] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [note, setNote] = useState('');
  const [title, setTitle] = useState('');
  const [errorMap, setErrorMap] = useState({
    firstName: '',
    lastName: '',
    note: '',
    email: '',
    password: '',
    defaultRole: '',
    defaultInstitution: '',
    passwordRetry: '',
    passwordsDontMatch: '',
    phoneNumber: '',
    username: '',
    sp: '',
  });
  const [userCount, setUserCount] = useState(0);
  const [startValues, setStartValues] = useState<any>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [defaultGroups, setDefaultGroups] = useState<any>(null);
  const [groups, setGroups] = useState<any>([]);
  const handleChange = (e: SelectChangeEvent) => {
    setErrorMap({ ...errorMap, defaultRole: '' });

    const role = roles.find((r) => r?.name === e.target.value);
    console.log('a', role);
    setUsername(
      usernameMap[role?.name as keyof typeof usernameMap] + userCount
    );
    setDefaultRole(role as any);
  };
  const closeHelpModal = () => {
    setHelpModal(false);
  };

  const handleInstChange = (e: any, prm: any) => {
    setErrorMap({ ...errorMap, defaultInstitution: '' });
    setDefaultInstitution(prm as any);
  };

  const loadAll = async () => {
    const response = await getAdministrationUserInsert();

    if (response?.status === 200) {
      setRoles(response?.data?.roles);
      setInstitutions(response?.data?.institutions);
      setUserCount(response?.data?.userCount);
      setGroups(response?.data?.groups);
    }
  };
  const loadUserById = async (id: any) => {
    const resp = await getUserById(id);
    if (resp.status === 200) {
      setStartValues(resp?.data);
      setDefaultInstitution(resp?.data?.institution);
      setDefaultRole(resp?.data?.roles);
      setLastName(resp?.data?.lastName);
      setFirstName(resp?.data?.firstName);
      setEmail(resp?.data?.email);
      setPhoneNumber(resp?.data?.phoneNumber);
      setTitle(resp?.data?.title);
      setUsername(resp?.data?.username);
      setNote(resp?.data?.note);
      setIsUserDisabled(resp?.data?.status === 0 ? false : true);
      setDefaultGroups(resp?.data?.workGroups);
      console.log(resp.data);
    }
  };

  useEffect(() => {
    loadAll();
    let eId = searchParams.get('id');
    if (eId) {
      loadUserById(eId);
      setEditId(eId);
    }
  }, []);

  const updateUser = async () => {
    const resp = await editUser({
      id: parseInt(editId),
      firstName,
      lastName,
      email,
      username: username.replace(' ', '_'),
      note,
      title,
      roles: defaultRole?.map((r: any) => r?.id),
      workGroups: defaultGroups?.map((g: any) => g?.groupNo),
      institutionId: defaultInstitution?.id,
      phoneNumber,
      status: isUserDisabled ? 1 : 0,
    });
    if (resp.status === 201) {
      notif('Корисник успешно ажуриран', 'success');
      navigate(
        '/administration/user/management?filter=' +
        new URLSearchParams(window.location.search).get('filter')
      );
    } else {
      notif(resp?.data?.message ?? 'Корисник није ажуриран', 'error');
    }
  };

  const handleUserInsert = async () => {
    const validationResults = {
      ...errorMap,
      firstName: validator(firstName, true, true),
      lastName: validator(lastName, true, true),
      note: '',
      email: validator(email, true, false),
      phoneNumber: validator(phoneNumber, true, false),
      username: validator(username, true, false),
      defaultInstitution: validator(defaultInstitution?.id, true, false),
      defaultRole: validator(defaultRole[0]?.id, true, false),
      sp:
        defaultRole
          .map((r: any) => r.id)
          .includes('f8709ff6-fffa-419a-8b8d-2f16330fa244') &&
          groups?.length == 0
          ? 'Поље је обавезно'
          : '',
    };
    setErrorMap(validationResults);

    if (username.includes(' ')) {
      setUsername(username.replace(' ', '_'));
    }

    if (!Object.values(validationResults).some((value) => value)) {
      updateUser(); // Proceed with updating the user
    }
  };

  const changePassword = async () => {
    const resp = await editUserPassword({
      id: parseInt(editId),
      password,
    });
    if (resp.status === 201) {
      notif('Лозинка успешно ажурирана', 'success');
    } else {
      notif('Лозинка није ажурирана', 'error');
    }
  };

  const submitNewPassword = () => {
    setErrorMap({
      ...errorMap,
      password: validator(password, true, false),
      passwordRetry: validator(passwordRetry, true, false),
      passwordsDontMatch: '',
    });
    if (password === passwordRetry) {
      if (password && passwordRetry) {
        changePassword();
      }
    } else {
      setErrorMap({
        ...errorMap,
        passwordsDontMatch: 'Лозинке се не поклапају',
      });
    }
  };
  const multipleHandle = (e: any, data: any) => {
    setDefaultRole(roles?.filter((role) => data?.includes(role?.name as any)));
    if (defaultRole?.length === 0 && !editId) {
      setUsername(userCount?.toString());
    }
  };

  useEffect(() => {
    console.log(username === startValues?.username);
    if (
      email === startValues?.email &&
      username === startValues?.username &&
      firstName === startValues?.firstName &&
      lastName === startValues?.lastName &&
      note === startValues?.note &&
      title === startValues?.title &&
      phoneNumber === startValues?.phoneNumber &&
      defaultInstitution?.id === startValues?.institution?.id &&
      startValues?.roles?.length === defaultRole?.length &&
      startValues?.roles.every(
        (value: any, index: any) => value?.id === defaultRole[index]?.id
      ) &&
      startValues?.workGroups.every(
        (value: any, index: any) => value?.id === defaultGroups[index]?.id
      ) &&
      isUserDisabled === !!startValues?.status
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [
    firstName,
    lastName,
    email,
    note,
    defaultRole,
    defaultInstitution,
    title,
    phoneNumber,
    isUserDisabled,
    startValues,
    username,
  ]);

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Ажурирање Корисника
        </h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => setHelpModal(true)}
        >
          <p className="rounded-full   flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 ">
        <div className="flex gap-28">
          <div className="flex gap-8 flex-col">
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Име</p>
              <ResetTextField
                label="Име"
                id="outlined-size-small"
                type="firstName"
                name="firstName"
                defaultValue={startValues?.firstName}
                helperText={errorMap?.firstName}
                error={!!errorMap?.firstName as boolean}
                onFocus={() => setErrorMap({ ...errorMap, firstName: '' })}
                size="small"
                value={firstName}
                onChange={setFirstName}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Презиме</p>
              <ResetTextField
                label="Презиме"
                type="lastName"
                defaultValue={startValues?.lastName}
                name="lastName"
                id="outlined-size-small"
                size="small"
                value={lastName}
                onChange={setLastName}
                helperText={errorMap?.lastName}
                error={!!errorMap?.lastName as boolean}
                onFocus={() => setErrorMap({ ...errorMap, lastName: '' })}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Email</p>
              <ResetTextField
                label="Email"
                type="email"
                name="email"
                id="outlined-size-small"
                defaultValue={startValues?.email}
                size="small"
                value={email}
                onChange={setEmail}
                helperText={errorMap?.email}
                error={!!errorMap?.email as boolean}
                onFocus={() => setErrorMap({ ...errorMap, email: '' })}
              />
            </div>

            <div className="flex items-center gap-6">
              <p className="w-[140px]">Напомена</p>
              <textarea
                id="message"
                rows={4}
                className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                placeholder="Напомена..."
                value={note ?? ''}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Закључај налог</p>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUserDisabled}
                    onChange={(e: any) => setIsUserDisabled(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '5px' }}>
                      {isUserDisabled ? (
                        <FaLock style={{ color: '#FF5722' }} />
                      ) : (
                        <FaLockOpen style={{ color: '#4CAF50' }} />
                      )}
                    </span>
                    <span>
                      {isUserDisabled
                        ? 'Налог је закључан'
                        : 'Налог је отворен'}
                    </span>
                  </div>
                }
              />
            </div>
          </div>
          <div className="flex gap-8 flex-col">
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Ниво коришћења</p>
              <div className="flex items-center gap-2">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  // options={roles || []}
                  // getOptionLabel={(option) => option.name || ''}
                  // onChange={(event: any, newValue: any) =>
                  //   console.log(event, newValue)
                  // }

                  sx={{ maxWidth: 300, minWidth: 300 }}
                  options={roles?.map((option) => option?.name as any)}
                  value={
                    defaultRole?.map((feature: any) => feature.name) as any
                  }
                  onChange={multipleHandle}
                  freeSolo
                  size="small"
                  // value={defaultRole}
                  renderInput={(params: any) => (
                    <TextField {...params} label="Ниво" placeholder="Ниво" />
                  )}
                />
                {startValues?.roles?.length === defaultRole?.length &&
                  startValues?.roles.every(
                    (value: any, index: any) =>
                      value?.id === defaultRole[index]?.id
                  ) ? (
                  <div className="w-[40px] h-[40px]"></div>
                ) : (
                  <div
                    onClick={() => setDefaultRole(startValues?.roles)}
                    className="w-[40px] h-[40px] p-2 flex items-center rounded-full justify-center cursor-pointer  hover:bg-[#e1e1e1]"
                  >
                    <FaHistory className="w-[20px] h-[20px] fill-[#434343]" />
                  </div>
                )}
              </div>
            </div>
            {defaultRole
              .map((r: any) => r.id)
              .includes('f8709ff6-fffa-419a-8b8d-2f16330fa244') && (
                <div className="flex  items-center gap-6">
                  <p className="w-[140px]">Секретар групе</p>
                  <div className="flex flex-col">
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      // options={roles || []}
                      getOptionLabel={(option) => (option ? option : '')}
                      // onChange={(event: any, newValue: any) =>
                      //   console.log(event, newValue)
                      // }
                      onFocus={() => setErrorMap({ ...errorMap, sp: '' })}
                      sx={{ maxWidth: 300, minWidth: 300 }}
                      options={groups?.map((option: any) => option?.name as any)}
                      value={
                        defaultGroups?.map((feature: any) => feature.name) as any
                      }
                      onChange={(e: any, data: any) => {
                        const startGroups = startValues?.workGroups;

                        const arraySizeMatch = startGroups.length === data.length;
                        const everyMatch = startGroups.every((group: any, index: any) => group.name === data[index]);
                        const isItChanged = !arraySizeMatch || !everyMatch;

                        console.log('Change detected:', isItChanged);
                        console.log('Initial workGroups:', startValues?.workGroups);
                        console.log('Current data:', data);
                        setIsChanged(!isItChanged);
                        setDefaultGroups(
                          groups?.filter((g: any) =>
                            data?.includes(g?.name as any)
                          )
                        );
                      }}
                      freeSolo
                      size="small"
                      // value={defaultRole}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Секретар групе"
                          placeholder="Секретар групе"
                        />
                      )}
                    />
                    {errorMap?.sp && (
                      <FormHelperText sx={{ color: 'red' }}>
                        {errorMap?.sp}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              )}
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Корисничко име</p>
              <ResetTextField
                label="Корисничко име"
                defaultValue={startValues?.username}
                type="username"
                name="username"
                value={username}
                onChange={setUsername}
                helperText={errorMap?.username}
                error={!!errorMap?.username as boolean}
                onFocus={() => setErrorMap({ ...errorMap, username: '' })}
                id="outlined-size-small"
                sx={{ minWidth: 300 }}
                size="small"
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Институција</p>
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <Autocomplete
                    renderOption={(props, option: any) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    value={defaultInstitution}
                    size="small"
                    onChange={(event: any, newValue: any) =>
                      handleInstChange(event, newValue)
                    }
                    id="controllable-states-demo"
                    options={institutions || []}
                    getOptionLabel={(option) => (option ? option?.name : '')}
                    sx={{ minWidth: 300 }}
                    renderInput={(params) => (
                      <TextField
                        error={!!errorMap?.defaultInstitution as boolean}
                        {...params}
                        label="Институција"
                      />
                    )}
                  />
                  {defaultInstitution === startValues?.institution ? (
                    <div className="w-[40px] h-[40px]"></div>
                  ) : (
                    <div
                      onClick={() =>
                        setDefaultInstitution(startValues?.institution)
                      }
                      className="w-[40px] h-[40px] p-2 flex items-center rounded-full justify-center cursor-pointer  hover:bg-[#e1e1e1]"
                    >
                      <FaHistory className="w-[20px] h-[20px] fill-[#434343]" />
                    </div>
                  )}
                </div>
                {errorMap?.defaultInstitution && (
                  <FormHelperText sx={{ color: 'red' }}>
                    {errorMap?.defaultInstitution}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Звање</p>
              <ResetTextField
                label="Звање"
                defaultValue={startValues?.title}
                type="title"
                name="title"
                id="outlined-size-small"
                size="small"
                sx={{ minWidth: 300 }}
                value={title}
                onChange={setTitle}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Телефон</p>
              <ResetTextField
                label="Телефон"
                defaultValue={startValues?.phoneNumber}
                type="phone"
                name="phone"
                id="outlined-size-small"
                size="small"
                sx={{ minWidth: 300 }}
                value={phoneNumber}
                onChange={setPhoneNumber}
                helperText={errorMap?.phoneNumber}
                error={!!errorMap?.phoneNumber as boolean}
                onFocus={() => setErrorMap({ ...errorMap, phoneNumber: '' })}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-12 items-center">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#0F2765' }}
            disabled={isChanged}
            endIcon={<FaSave />}
            onClick={handleUserInsert}
          >
            Ажурирај
          </Button>
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4 flex flex-col gap-4 mt-8 ">
        <p className="text-[#04438b] text-2xl font-bold mb-2">Измена лозинке</p>
        <div className="flex items-center gap-6">
          <p className="w-[140px]">Лозинка</p>
          <TextField
            label="Лозинка"
            type="password"
            name="password"
            id="outlined-size-small"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={errorMap?.password}
            error={
              (!!errorMap?.password ||
                !!errorMap?.passwordsDontMatch) as boolean
            }
            onFocus={() =>
              setErrorMap({
                ...errorMap,
                password: '',
                passwordsDontMatch: '',
              })
            }
          />
        </div>
        <div className="flex items-center gap-6 ">
          <p className="w-[140px]">Понови лозинку</p>
          <TextField
            label="Понови лозинку"
            type="password"
            name="password"
            id="outlined-size-small"
            size="small"
            value={passwordRetry}
            onChange={(e) => setPasswordRetry(e.target.value)}
            helperText={
              errorMap?.passwordsDontMatch
                ? errorMap?.passwordRetry
                  ? 'Поље је обавезно'
                  : 'Лозинке се не поклапају'
                : ''
            }
            error={
              (!!errorMap?.passwordRetry ||
                !!errorMap?.passwordsDontMatch) as boolean
            }
            onFocus={() =>
              setErrorMap({
                ...errorMap,
                passwordRetry: '',
                passwordsDontMatch: '',
              })
            }
          />
        </div>
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          endIcon={<FaSave />}
          onClick={submitNewPassword}
        >
          Ажурирај
        </Button>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'editUser'}
      />
    </div>
  );
}

export default EditUser;
