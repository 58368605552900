import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import InsertRegister from './srceens/InsertRegister';
import ManagementRegister from './srceens/ManagementRegister';
import { useUser } from '../../auth/UseUser';

function Register() {
  let { user } = useUser();
  let navigate = useNavigate();
  const location = useLocation();
  const [screen, setScreen] = useState('default');

  const screenMap = {
    insertRegister: <InsertRegister />,
    managementRegister: <ManagementRegister />,
  };
  const menuList = [
    {
      name: 'Регистри',
      subMenus: [
        {
          name: 'insertRegister',
          title: 'Унос прописа',
          url: 'insert',
          roles: ['Координатор', 'Администратор', 'Уносилац података'],
        },
        {
          name: 'managementRegister',
          title: 'Претраживање / брисање / измена прописа',
          url: 'management',
        },
        {
          name: 'changejur',
          title: 'Промена надлежности корисника',
          url: 'changejur',
          roles: ['Координатор'],
        },
      ],
    },
    {
      name: 'Мере за успостављање институционалних и административних капацитета',
      subMenus: [
        { name: 'addMeasure', title: 'Унос мере', url: 'addmeasure' },
        {
          name: 'measure/management',
          title: 'Управљање мерама',
          url: 'measure/management',
        },
      ],
    },
    {
      name: 'Процена финансијских ефеката',
      subMenus: [
        {
          name: 'financial/expenditure/insert',
          title: 'Образац за процену финансијских ефеката НПАА',
          url: 'financial/expenditure/insert',
        },

        {
          name: 'financial/expenditures',
          title: 'Управљање издацима/Изворима финансирања',
          url: 'financial/expenditures',
        },
      ],
    },
  ];
  return (
    <div className=" w-full h-full flex flex-row justify-start">
      <div className="h-full bg-[#1e3f940a] w-[320px] min-w-[180px] my-5 first:mt-0 overflow-auto gray-small-scroll">
        {menuList?.map((section) => {
          return (
            <div key={section?.name}>
              <div className="flex items-center pl-6 mt-6 py-3 text-gray-500 font-semibold mx-4 uppercase text-sm text-[#1e4094]">
                {section?.name}
              </div>
              {section?.subMenus
                ?.filter((sub) => {
                  // If 'roles' field doesn't exist or is empty, include all
                  if (!sub?.roles?.length) return true;
                  // Otherwise, filter based on roles
                  return sub?.roles?.includes(user?.roleView?.name);
                })
                ?.map((sub) => {
                  return (
                    <div
                      key={sub?.name}
                      onClick={() => navigate(sub?.url)}
                      className={`flex items-center cursor-pointer py-1 pl-6  mx-4 my-2  rounded-md ${
                        '/register/' + sub?.url === location?.pathname
                          ? 'text-white bg-[#0F2765]'
                          : 'hover:text-white hover:bg-[#0F2765] text-gray-300'
                      }`}
                    >
                      {sub?.title}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <div className=" w-full">
        <Outlet />
      </div>
    </div>
  );
}

export default Register;
