import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaCaretDown, FaSearch, FaSearchMinus, FaTrash } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../auth/UseUser';
import {
  deleteHarmonization,
  editHarmonizationCompatibility,
  getHarmonizations,
  getNoCelexRelations,
  getScreeningGroups,
  insertHarmonization,
  measureAddEuMeasure,
  measureListEuMeasures,
  measureRemoveEuMeasure,
} from '../../../../server/server';
import ChooseEuRegisterModal from '../../../modals/ChooseEuRegisterModal';
import { notif } from '../../../utils/util';
import ConfirmationModal from '../../../modals/ConfirmationModal';

function EuMeasureRegisters() {
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selRegister, setSelRegister] = useState<any>(null);
  const [noCelexRels, setNoCelexRels] = useState<any[]>([]);
  const [selNoCelexRel, setSelNoCelexRel] = useState<any>(null);
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [rowCountState, setRowCountState] = useState(6);
  const [isLoading, setIsLoading] = useState(true);
  const [editId, setEditId] = useState('');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [compactibilities, setCompactibilities] = useState([
    'није усклађен',
    'делимично усклађен',
    'поптпуно усклађен',
  ]);
  const [selComp, setSelComp] = useState('');

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const createHarmonization = async () => {
    setIsLoading(false);
    const resp = await measureAddEuMeasure(
      selRegister?.celexNo,
      parseInt(editId)
    );
    if (resp?.status === 201) {
      setSelRegister(null);
      setSelComp('');
      setIsLoading(true);
      setRowCountState(6);
      setPage(0);
      loadHarmonizations();
    }
  };

  const deleteRelation = async () => {
    const resp = await measureRemoveEuMeasure(deleteId);

    if (resp.status === 200) {
      notif('Релација је успешно обрисана', 'success');
      loadHarmonizations();
    } else {
      notif('Релација није обрисана обрисана', 'error');
    }
  };
  const deleteRel = () => {
    deleteRelation();
    setDeleteModalIsOpen(false);
  };
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      setTimeout(() => {
        setDeleteModalIsOpen(true);
        setDeleteId(id);
      });
    },
    []
  );

  const loadNoCelexRels = async () => {
    const resp = await getNoCelexRelations();

    if (resp?.status === 200) {
      setNoCelexRels(resp.data);
    }
  };
  const loadHarmonizations = async () => {
    const resp = await measureListEuMeasures(searchParams.get('id') as any);

    if (resp?.status === 200) {
      console.log(resp.data?.harmonizations, 'aaaaaaaaaaaaaaaaaaaat');

      setItems(resp.data?.harmonizations);
      setRowCountState(resp?.data?.total);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadNoCelexRels();
    let eId = searchParams.get('id');
    if (eId) {
      setEditId(eId);
      loadHarmonizations();
    }
  }, []);

  const COMPACTIBILITY_MAP = {
    0: 'није усклађен',
    1: 'делимично усклађен',
    2: 'поптпуно усклађен',
  };
  const COMPACTIBILITY_MAP_REVERSE = {
    'није усклађен': '0',
    'делимично усклађен': '1',
    'поптпуно усклађен': '2',
  };

  const delSelectedHarmonization = async (id: any) => {
    const resp = await measureRemoveEuMeasure(id);

    if (resp.status === 200) {
      notif('Усклађеност је успешно обрисана', 'success');
    } else {
      notif('Усклађеност је није обрисана', 'error');
    }
  };

  const CustomComponent = (params: any) => {
    const ref = useGridApiContext();
    return (
      <div
        className="flex items-center justify-between w-full"
        onClick={(event) => {
          event.stopPropagation(); // to not select row
          ref.current.startCellEditMode({
            id: params.id,
            field: params.field,
          });
        }}
      >
        <div>
          {COMPACTIBILITY_MAP[params?.value as keyof typeof COMPACTIBILITY_MAP]}
        </div>
        <FaCaretDown className="fill-[#707070]" />
      </div>
    );
  };
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadHarmonizations();
    // loadHarmonizations(page * pageSize, editId);
  };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'celexNo',
        headerName: 'Celex ознака',
        flex: 1,
        maxWidth: 140,
        minWidth: 140,
        renderCell: (params) => params?.value ?? '-',
      },
      {
        field: 'name',
        headerName: 'Назив прописа ЕУ (на енглеском језику)',
        flex: 1,
      },
      {
        field: 'username',
        headerName: 'Корисник',
        maxWidth: 150,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<FaTrash style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Обриши"
            onClick={deleteItem(params.id)}
          />,
        ],
      },
    ],
    [deleteItem]
  );
  return (
    <div className="mt-6">
      <div className="flex gap-16">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col items-start gap-2"></div>
        </div>

        <div className="flex flex-col gap-4 w-full justify-between">
          <div className="flex items-center  justify-between"></div>

          <div className="flex items-center justify-between">
            <div className="flex items-start gap-6 flex-col">
              <button
                className="h-[40px] w-[500px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer"
                onClick={() => setModalIsOpen(true)}
              >
                {selRegister ? (
                  <div className="flex px-4 justify-start text-left items-center">
                    <FaSearchMinus className="fill-[#404040]" />
                    <p className="text-darkBlue w-full pl-3">
                      {selRegister?.celexNo}
                    </p>
                  </div>
                ) : (
                  <div className="flex px-4 justify-between items-center">
                    <FaSearch className="fill-[#404040]" />
                    <p className="text-[#404040] w-full pl-3">
                      Одабир ЕУ прописа
                    </p>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end items-center mt-6">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          onClick={createHarmonization}
          disabled={!selRegister?.id}
        >
          Додај
        </Button>
      </div>
      {items?.length > 0 && (
        <div>
          <div className="text-[#04438b]">
            Институције задужене за спровођење правног оквира
          </div>
          <div className="mt-5 h-[430px] w-full">
            <DataGrid
              rowCount={rowCountState}
              loading={isLoading}
              pagination
              paginationMode="server"
              onPageChange={(newPage) => setPageAndCall(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              page={page}
              pageSize={pageSize}
              experimentalFeatures={{ newEditingApi: true }}
              rows={items}
              disableColumnMenu
              columns={columns}
              style={{ zIndex: 0 }}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје ставке
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје ставке
                  </Stack>
                ),
              }}
            />
          </div>
        </div>
      )}
      <ChooseEuRegisterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setSelRegister={setSelRegister}
        selRegister={selRegister}
      />
      <ConfirmationModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={closeDeleteModal}
        confirmation={deleteRel}
        message={`Обриши релацију прописом?`}
      />
    </div>
  );
}

export default EuMeasureRegisters;
