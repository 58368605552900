import { Autocomplete, Stack, TextField } from '@mui/material';
import { DataGrid, GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetViewsNPICelex, getViewsNPI } from '../../../server/server';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function NPAAView() {
  const { filterName } = useParams();
  const [institutions, setInstitutions] = useState<any>([]);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>({
    id: '',
    name: 'Сва поглавља',
  });
  const [registers, setRegisters] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCountState, setRowCountState] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [helpModal, setHelpModal] = useState(false);

  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };

  const filters = [
    {
      name: 'pl22-25',
      params: {
        filter: 0,
        from: '2022/I',
        to: '2025/IV',
      },
    },
    {
      name: 'pl-sub22-25',
      params: {
        group: 0,
        filter: 'filterValue1',
        from: '2022/I',
        to: '2025/IV',
      },
    },
    {
      name: 'pl-sub16-18',
      params: {
        filter: 0,
        from: '2016/I',
        to: '2016/IV',
      },
    },
    {
      name: 'pl16-18',
      params: {
        filter: 0,
        from: '2016/I',
        to: '2016/IV',
      },
    },
    {
      name: 'pl13',
      params: {
        filter: 0,
        from: '2013/I',
        to: '',
      },
    },
    {
      name: 'pl-sub13',
      params: {
        filter: 0,
        from: '2013/I',
        to: '',
      },
    },
  ];
  const columns: any = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'pid',
        sortable: false,
        headerName: 'Шифра  план. прописа',
        maxWidth: 160,
        flex: 1,
        colSpan: ({ row }) => {
          if (row?.isFakeRow) {
            return 8;
          }
          return undefined;
        },
        renderCell: (params) => {
          if (params.row.isFakeRow) {
            return (
              <div className="font-bold text-[#0f2765]">
                {' '}
                {params.row.celex}
              </div>
            );
          } else {
            return params.value;
          }
        },
      },
      {
        field: 'fid',
        sortable: false,
        headerName: 'Шифра пост. прописа',
        maxWidth: 160,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'title',
        sortable: false,
        headerName: 'Назив прописа',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'type',
        sortable: false,
        headerName: 'Тип прописа',
        maxWidth: 75,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'npi',
        sortable: false,
        headerName: `Место у структури НПАА`,
        maxWidth: 110,
        minWidth: 90,
        renderCell: (params) =>
          params.row.isFakeRow ? null : (
            <div className="font-bold text-[#0f2765]">{params?.row?.npi}</div>
          ),
        flex: 1,
      },
      {
        field: 'planAdoptByGov',
        sortable: false,
        headerName: ` Рок за утврђивање/усвајање од стране Владе/министарства аката`,
        maxWidth: 200,
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'adopByGovDate',
        sortable: false,
        headerName: `Усвојено на Влади/министарству дана`,
        maxWidth: 200,
        flex: 1,
        renderCell: (params) => (params.row.isFakeRow ? null : params.value),
      },
      {
        field: 'oj',
        sortable: false,
        headerName: `Број 'Службеног гласника РС'`,
        maxWidth: 130,
        flex: 1,
        renderCell: (params) => {
          if (params?.row?.isFakeRow) {
            // Handle fake row here, e.g., return an empty div or custom content
            return <div></div>;
          } else {
            const ojt = params?.row?.oj.split('\n');
            return (
              <div>
                <p className="font-bold text-[#0f2765]">{ojt[0]}</p>
                <p>{ojt[1]}</p>
                <p>{ojt[2]}</p>
              </div>
            );
          }
        },
      },
    ],
    []
  );
  const insertFakeRows = (items: any) => {
    const newItems: any = [];

    items.forEach((item: any) => {
      newItems.push(item);
      newItems.push({
        id: item.id * 10000 + Math.floor(Math.random() * 1000), // Create a random id based on the original id
        isFakeRow: true,
        celex: item.celex,
      });
    });

    return newItems;
  };

  const CustomGridFooter = (props: any) => {
    const totalPages = Math.floor(props.count / props.pageSize);
    const handlePageSizeChange = (event: any) => {
      props.onPageSizeChange(event.target.value);
      // props.loadUsers(props.page * event.target.value);
    };
  };
  const loadRegisters = async (offset: any = 0) => {
    const filter = filters.find((f) => f.name === filterName);
    const defaultParams = { filter: '', from: '', to: '' };
    const {
      filter: filterValue,
      from,
      to,
    } = { ...defaultParams, ...filter?.params };
    const resp = await GetViewsNPICelex(
      pageSize,
      offset,
      defaultInstitution?.id || '',
      filterValue,
      from,
      to
    );

    if (resp?.status) {
      setIsLoading(false);
      const itemsWithFakeRows = insertFakeRows(resp?.data?.items);
      setRegisters(itemsWithFakeRows);
      setRowCountState(resp?.data?.total);
    }
  };

  const loadInstitutions = async () => {
    const resp = await getViewsNPI();
    if (resp?.status) {
      setInstitutions([
        {
          id: null,
          name: 'Сва поглавља',
        },
        ...resp?.data,
      ]);
    }
  };

  const setPageAndCall = (page: any) => {
    setPage(page);
    loadRegisters(page * pageSize);
  };
  useEffect(() => {
    loadInstitutions();
    loadRegisters();
  }, []);

  useEffect(() => {
    loadRegisters();
  }, [defaultInstitution, filterName]);

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Прописи по CELEX-има по министарствима и посебним организацијама
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full flex justify-between items-end  mt-6">
          <div className="w-full flex items-center gap-12">
            <div className="flex flex-col items-start gap-3">
              <p className="w-[160px] text-sm text-[#404040]">По поглављу</p>
              <Autocomplete
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={defaultInstitution}
                size="small"
                onChange={(event: any, newValue: any) =>
                  handleInstChange(event, newValue)
                }
                id="controllable-states-demo"
                options={institutions}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label="Поглавље" />
                )}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 h-fit w-full">
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: 'clip',
                whiteSpace: 'break-spaces',
                textAlign: 'center',
                lineHeight: 1,
              },
              // '& .MuiDataGrid-virtualScrollerContent': { maxHeight: 450 },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(n) .MuiDataGrid-cell':
              //   {
              //     height: '60px !important',
              //     minHeight: '60px !important',
              //     maxHeight: '60px !important',
              //   },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(n)': {
              //   height: '60px !important',
              //   minHeight: '60px !important',
              //   maxHeight: '60px !important',
              // },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(2n) .MuiDataGrid-cell':
              //   {
              //     height: '30px !important',
              //     minHeight: '30px !important',
              //     maxHeight: '30px !important',
              //   },
              // '& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(2n)': {
              //   height: '30px !important',
              //   minHeight: '30px !important',
              //   maxHeight: '30px !important',
              // },
              // '& .MuiDataGrid-main > div:nth-child(2)': {
              //   height: 'auto !important',
              //   minHeight: 'max-content !important',
              // },
            }}
            headerHeight={100}
            disableColumnMenu={true}
            rowCount={rowCountState}
            getRowHeight={() => 'auto'}
            autoHeight={true}
            loading={isLoading}
            pagination
            paginationMode="server"
            // onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            rows={registers}
            columns={columns}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
            componentsProps={{
              footer: {
                count: rowCountState,
                page: page,
                pageSize: pageSize,
                onPageChange: (event: any, newPage: any) =>
                  setPageAndCall(newPage),
                onPageSizeChange: (newPageSize: any) =>
                  setPageSize(newPageSize),
                rowsPerPageOptions: [5, 10, 20],
                loadRegisters: loadRegisters,
              },
            }}
          />
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'NPAAView'}
      />
    </div>
  );
}

export default NPAAView;
