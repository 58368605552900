import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TablePagination,
  TablePaginationProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridPagination,
  gridRowCountSelector,
  GridRowId,
  GridToolbar,
  GridValueGetterParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaQuestionCircle, FaTimes, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { usernameMap, usernameMapCyrillic } from '../../../common/constaints';
import {
  deleteUser,
  getAdministrationUserInsert,
  getAllUsers,
} from '../../../server/server';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { notif } from '../../utils/util';
import MuiPagination from '@mui/material/Pagination';
import HelpPageModal from '../../modals/HelpPageModal';
function UserManagement() {
  type Role = {
    [key: string]: any;
    id?: string;
    name?: string;
  };
  let navigate = useNavigate();
  const [institutions, setInstitutions] = useState<any>([]);
  const [defaultRole, setDefaultRole] = useState<Role | null>(null);
  const [users, setUsers] = useState([]);
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>(
    null
  );
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any | null>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [username, setUsername] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCountState, setRowCountState] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const [fullName, setFullName] = useState('');
  const [sortModel, setSortModel] = useState<any>([]);
  const [helpModal, setHelpModal] = useState(false);
  const [filterValid, setFilterValid] = useState(false);

  const [pagination, setPagination] = useState<any>({
    page: page ?? 0,
    pageSize: pageSize ?? 10,
  });
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const loadAll = async () => {
    const response = await getAdministrationUserInsert();
    const params = new URLSearchParams(window.location.search);
    const filter = params.get('filter');
    if (response?.status === 200) {
      if (filter) {
        const [username, roleId, institutionId, fullName] = filter.split(',');
        setUsername(username || '');
        setFullName(fullName || '');
        setDefaultInstitution(
          response?.data?.institutions?.find(
            (i: any) => i?.id === parseInt(institutionId)
          )
        );
        console.log(
          response?.data?.institutions[0]?.id,
          parseInt(institutionId)
        );
        setDefaultRole(
          response?.data?.roles?.find((i: any) => i.id === roleId)
        );
      }
      setInstitutions(response?.data?.institutions);
      setRoles(response?.data?.roles);
    }
  };

  const handleChange = (e: SelectChangeEvent) => {
    const role = roles.find((r) => r?.name === e.target.value);
    setDefaultRole(role as any);
  };
  const loadUsers = async (offset: any = 0) => {
    const resp = await getAllUsers(
      pageSize,
      offset,
      username ?? '',
      defaultRole?.id ?? '',
      defaultInstitution?.id ?? '',
      fullName ?? '',
      sortModel[0]?.field,
      sortModel[0]?.sort,
      filterValid ? 1 : 0
    );

    if (resp?.status === 200) {
      setIsLoading(false);
      setUsers(resp?.data?.users);
      setRowCountState(resp?.data?.total);
    }
  };
  const setPageAndCall = (page: any) => {
    const zeroIndexedPage = page - 1;
    setPage(zeroIndexedPage);
    loadUsers(zeroIndexedPage * pageSize);
  };

  useEffect(() => {
    loadAll();
    loadUsers();
  }, []);
  useEffect(() => {
    loadUsers();
  }, [
    username,
    defaultRole,
    defaultInstitution,
    fullName,
    sortModel,
    filterValid,
  ]);

  // const columns: GridColDef[] = [
  //   { field: 'username', headerName: 'Корисничко име', width: 200 },
  //   { field: 'firstName', headerName: 'Име', width: 210 },
  //   { field: 'lastName', headerName: 'Презиме', width: 210 },
  //   {
  //     field: 'role',
  //     headerName: 'Ниво коришћења',
  //     width: 200,
  //   },
  // ];

  const deleteRowUser = React.useCallback(
    (id: any) => () => {
      setTimeout(() => {
        console.log('test1');
        setDeleteModalIsOpen(true);
        setSelectedUser(id);
      });
    },
    []
  );
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const editUser = React.useCallback(
    (id: GridRowId) => () => {
      navigate(
        `/administration/user/edit?id=${id}&filter=${username ?? ''},${
          defaultRole?.id ?? ''
        },${defaultInstitution?.id ?? ''},${fullName ?? ''}`
      );
    },
    [username, defaultRole, defaultInstitution, fullName, filterValid]
  );
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const deleteSelectedUser = async () => {
    console.log(selectedUser);
    const resp = await deleteUser(selectedUser?.id);

    if (resp.status === 200) {
      setUsers((prevRows) =>
        prevRows.filter((row: any) => row.id !== selectedUser?.id)
      );
      notif('Корисник успешно обрисан', 'success');
      closeDeleteModal();
    } else {
      notif(resp?.data?.message ?? 'Корисник није обрисан', 'error');
    }
  };
  const roleFormat = (roles: any) => {
    return roles
      ?.map((r: any) => {
        return usernameMapCyrillic[r as keyof typeof usernameMapCyrillic];
      })
      .join(', ');
  };

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'username',
        headerName: 'Корисничко име',
        maxWidth: 260,
        minWidth: 180,
        flex: 1,
      },
      { field: 'firstName', headerName: 'Име', width: 190 },
      { field: 'lastName', headerName: 'Презиме', width: 190 },
      {
        field: 'roles',
        headerName: 'Ниво коришћења',
        minWidth: 190,

        renderCell: (params) => <div>{params?.value}</div>,
      },
      {
        field: 'institution',
        headerName: 'Институција',
        flex: 0.3,
        width: 320,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 120,
        getActions: (params) => [
          <Tooltip title="Измени">
            <GridActionsCellItem
              icon={
                <FaEdit
                  style={{
                    width: 20,
                    height: 20,
                    color: params?.row?.status ? '#FFA500' : '#D3D3D3',
                  }}
                />
              }
              sx={{ width: 50, height: 50 }}
              label="Ажурирај корисника"
              onClick={editUser(params.id)}
            />
          </Tooltip>,
          <Tooltip title="Обриши">
            <GridActionsCellItem
              icon={
                <FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />
              }
              sx={{ width: 50, height: 50 }}
              label="Обриши корисника"
              onClick={deleteRowUser(params?.row)}
            />
          </Tooltip>,
        ],
      },
    ],
    [deleteUser, editUser]
  );

  const restartFilters = () => {
    setDefaultRole(null);
    setDefaultInstitution(null);
    setUsername('');
    setFullName('');
    setReset(!reset);
  };

  const isFilter = () => {
    if (username || defaultRole?.id || defaultInstitution?.id) return true;

    return false;
  };
  function CustomGridFooter(props: any) {
    const totalPages = Math.ceil(props.count / props.pageSize);
    const handlePageSizeChange = (event: any) => {
      props.onPageSizeChange(event.target.value);
      // props.loadUsers(props.page * event.target.value);
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
        }}
      >
        <FormControl>
          <InputLabel htmlFor="rows-per-page">Редови</InputLabel>
          <Select
            value={props.pageSize}
            size="small"
            label="Редови"
            sx={{ minWidth: 70 }}
            onChange={handlePageSizeChange}
            inputProps={{
              name: 'rows-per-page',
              id: 'rows-per-page',
            }}
          >
            {props.rowsPerPageOptions.map((option: any) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          shape="rounded"
          count={totalPages}
          page={props.page + 1}
          onChange={(event, newPage) => props.onPageChange(event, newPage)}
          siblingCount={1}
          boundaryCount={1}
        />
      </div>
    );
  }
  useEffect(() => {
    loadUsers(page * pageSize);
  }, [page, pageSize]);

  const handleSortChange = (model: any) => {
    console.log('handleSortChange', model);
    setSortModel(model);
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Управљање корисницима
        </h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => setHelpModal(true)}
        >
          <p className="rounded-full   flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">
          Претрага {isFilter() && `(филтрирана)`}
        </h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm">Корисничко име</p>
            <TextField
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm">Име и презиме</p>
            <TextField
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[130px] text-sm">Ниво коришћења</p>
            <Autocomplete
              key={`${reset}`}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultRole}
              size="small"
              onChange={(event: any, newValue: any) => setDefaultRole(newValue)}
              id="controllable-states-demo"
              options={roles}
              getOptionLabel={(option: any) => option?.name}
              sx={{ width: 250 }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[130px] text-sm">Институција</p>

            <Autocomplete
              key={`${reset}`}
              renderOption={(props, option: any) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultInstitution}
              size="small"
              onChange={(event: any, newValue: any) =>
                handleInstChange(event, newValue)
              }
              id="controllable-states-demo"
              options={institutions}
              getOptionLabel={(option: any) => option?.name}
              sx={{ width: 250 }}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>

          <div className="flex flex-col items-start gap-3">
            <p className="w-[160px] text-sm">Закључан</p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterValid}
                  onChange={(event) => setFilterValid(event.target.checked)}
                />
              }
              label="Закључан"
            />
          </div>
          {isFilter() && (
            <div className="flex h-full mt-8">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#E57373',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#C0392B',
                    color: '#fff',
                  },
                }}
                startIcon={<FaTimes />}
                onClick={restartFilters}
              >
                Поништи филтере
              </Button>
            </div>
          )}
        </div>
        <div className="mt-5 h-fit w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            paginationMode="server"
            // onPageChange={(newPage) => setPageAndCall(newPage)}
            page={page}
            pageSize={pageSize}
            rows={users}
            columns={columns}
            style={{ zIndex: 0 }}
            disableSelectionOnClick
            disableColumnMenu
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            getRowHeight={() => 'auto'}
            sortingMode="server"
            onSortModelChange={(model) => handleSortChange(model)}
            autoHeight={true}
            components={{
              Footer: CustomGridFooter,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје корисници са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје корисници са задатим критеријумом
                </Stack>
              ),
            }}
            componentsProps={{
              footer: {
                count: rowCountState,
                page: page,
                pageSize: pageSize,
                onPageChange: (event: any, newPage: any) =>
                  setPageAndCall(newPage),
                onPageSizeChange: (newPageSize: any) =>
                  setPageSize(newPageSize),
                rowsPerPageOptions: [5, 10, 20, 50, 100],
                loadUsers: loadUsers,
              },
            }}
          />
        </div>
      </div>
      <ConfirmationModal
        modalIsOpen={deleteModalIsOpen}
        closeModal={closeDeleteModal}
        confirmation={deleteSelectedUser}
        message={`Обриши корисника  ${selectedUser?.firstName} ${selectedUser?.lastName}?`}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'editUsers'}
      />
    </div>
  );
}

export default UserManagement;
