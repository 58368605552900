import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
  editMeasure,
  getAllInstitutions,
  getNatGroupStructure,
  getNationalGroupSet,
  getNationalGroupSetByLevel,
  getViewsCluster,
  getViewsClusterTree,
  getViewsNPI,
  insertMeasure,
} from '../../../../server/server';
import { FaSave } from 'react-icons/fa';
import { notif } from '../../../utils/util';
import { Navigate, useNavigate } from 'react-router-dom';
import { useUser } from '../../../../auth/UseUser';
import { validator } from '../../../../common/constaints';

function BasicMeasureData({ measure, setMenuTitle }: any) {
  let navigate = useNavigate();
  let { user } = useUser();
  const [group, setGroup] = useState<any>(null);
  const [subSubGroup, setSubSubGroup] = useState<any>(null);
  const [items, setItems] = useState<any>([]);
  const [selGroup, setSelGroup] = useState<any>(null);
  const [selSubSubGroup, setSelSubSubGroup] = useState<any>(null);
  const [selectedSubSubGroup, setselectedSubSubGroup] = useState<any>(null);
  const [subSubGroups, setSubSubGroups] = useState<any>(null);
  const [errorMap, setErrorMap] = useState({
    title: '',
    type: '',
    deadlineType: '',
    deadlineDate: '',
    group: '',
  });
  const [startValue, setStartValue] = useState<any>('');
  const [year, setYear] = useState<any>('');
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [selectedOption, setSelectedOption] = useState(0);
  const [defaultInstitution, setDefaultInstitution] = useState<any>([]);
  const [originalData, setOriginalData] = useState<any>(null);
  const [cluster, setCluster] = useState<any>(null);
  const [measureType, setMeasureType] = useState<any>(null);
  const [institutions, setInstitutions] = useState<any>([]);
  const [deadlineType, setDeadlineType] = useState<any>(null);
  const [deadlineDate, setDeadlineDate] = useState<any>({ y: '', q: '' });
  const [institution, setInstitution] = useState<any>(null);
  const [primaryInstitution, setPrimaryInstitution] = useState(null);
  const loadSubGroups = async () => {
    const resp = await getViewsClusterTree();
    if (resp?.status === 200 && resp?.data) {
      setOriginalData(resp.data);
      const filteredData = resp.data.filter((x: any) => x?.level !== 0);
      setSubSubGroup(filteredData);
    }
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions(1);
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };

  useEffect(() => {
    if (!measure) {
      const institution = institutions?.find((i: any) => i.name === user?.institution?.name);
      setInstitution(institution)
      console.log(institution);
    }
  }, [institutions])

  useEffect(() => {
    loadSubGroups();
    loadAllInstitutions();
    // setInstitutions(user?.institution?.name);
  }, []);
  type DataItem = {
    id: number;
    name: string;
    level: number;
    parent: number;
  };
  const QUARTER_MAP = ['I', 'II', 'III', 'IV'];
  const YEAR_MAP = [1, 2, 3, 4].map((y, i) => {
    return new Date().getFullYear() + i;
  });
  const MEASURE_TYPE = [
    { id: 0, name: 'Оснивање нових унутрашњих јединица' },
    { id: 1, name: 'Оснивање нових институција' },
    {
      id: 2,
      name: 'Запошљавање нових запослених у оквиру постојећих или нових институција',
    },
    { id: 3, name: 'Обука и усавршавање' },
    { id: 4, name: 'Техничка подршка' },
    { id: 5, name: 'Остало' },
  ];
  const DEADLINE_TYPE = [
    { id: 0, name: 'Континуирано' },
    { id: 1, name: 'Квартал/Година' },
    { id: 2, name: 'До приступања ЕУ' },
  ];
  useEffect(() => {
    if (measure) {
      setTitle(measure?.name);
      setSelectedOption(measure?.status);
      setComment(measure?.comment);
      setDefaultInstitution(measure?.secondaryInstitutions);
      setSelSubSubGroup(measure?.group);
      setselectedSubSubGroup(measure?.subChapter);
      setCluster(measure?.cluster);
      setMenuTitle(measure?.name);
      setMeasureType(measure?.type ?? 0);
      setDeadlineType(measure?.deadlineType);
      setInstitution(measure?.institution);
      let deadline = measure?.deadline?.split('/');
      setDeadlineDate({ y: deadline[0] ?? '', q: deadline[1] ?? '' });
    }
  }, [measure]);

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const subSubChange = (e: any, prm: any) => {
    if (prm?.name) {
      setCluster(findLevel1Parent(prm));
    } else {
      setCluster(null);
    }
    if (prm?.name) {
      loadSubSubGroups(prm?.id || prm?.groupNo);
    }

    setSelSubSubGroup(prm as any);
  };

  const handleInstChange = (e: any, prm: any) => {
    console.log(prm);
    setDefaultInstitution(prm as any);
  };

  const getGroupId = () => {
    if (selSubSubGroup?.id) {
      return selSubSubGroup?.id;
    }
    if (selSubSubGroup?.groupNo) {
      return selSubSubGroup?.groupNo;
    }
  }
  const handleSave = async () => {
    const validationResults = {
      ...errorMap, // Spread the existing errorMap to include existing properties
      title: validator(title, true, true),
      type: validator(measureType?.toString() || '', true, false),
      deadlineType: validator(deadlineType?.toString() || '', true, false),
      deadlineDate:
        deadlineType === 1
          ? validator(`${deadlineDate?.y}${deadlineDate?.q}`, true, false)
          : '',
      group: validator(
        selSubSubGroup?.groupNo || selSubSubGroup?.id,
        true,
        false
      ),
    };
    setErrorMap(validationResults);
    if (!Object.values(validationResults).some((value) => value)) {
      if (measure?.id) {
        const resp = await editMeasure({
          id: measure?.id,
          name: title,
          type: measureType ?? 0,
          deadlineType: deadlineType ?? 0,
          deadlineDate: deadlineDate?.y
            ? `${deadlineDate?.y}/${deadlineDate?.q}`
            : '',
          institution: institution?.id,
          institutions: defaultInstitution?.map((i: any) => i?.id),
          status: selectedOption ?? 0,
          comment: comment ?? '',
          group: getGroupId(),
          subGroup: selectedSubSubGroup?.groupNo,
          cluster: cluster?.groupNo || cluster?.id,
        });
        if (resp?.status === 201) {
          notif('Мера успешно ажурирана', 'success');
        } else {
          notif('Мера није ажурирана', 'error');
        }
      } else {
        const resp = await insertMeasure({
          name: title,
          type: measureType ?? 0,
          deadlineType: deadlineType ?? 0,
          deadlineDate: deadlineDate?.y
            ? `${deadlineDate?.y}/${deadlineDate?.q}`
            : '',
          institution: institution?.id,
          institutions: defaultInstitution?.map((i: any) => i?.id),
          status: selectedOption ?? 0,
          comment: comment ?? '',
          group: getGroupId(),
          subGroup: selectedSubSubGroup?.groupNo,
          cluster: cluster?.id,
        });
        if (resp?.status === 201) {
          notif('Мера успешно креирана', 'success');
          navigate('/register/measure/edit?id=' + resp?.data.id);
        } else {
          notif('Мера није креирана', 'error');
        }
      }
    }
  };

  const translations = [
    { id: 0, name: 'Не' },
    { id: 1, name: 'Делимично остварено' },
    { id: 2, name: 'Да' },
  ];


  function findLevel1Parent(selectedItem: DataItem): DataItem | undefined {
    // Izvlačenje broja nivoa iz imena odabranog elementa
    const levelNumber = selectedItem.name.match(/^\d+/)?.[0];

    // if (!levelNumber) {
    //   return undefined;
    // }

    // Pronalazak elementa nivoa 1 sa istim brojem nivoa
    const level1Parent = originalData?.find(
      (item: any) => item.level === 0 && item.id === selectedItem.parent
    );

    return level1Parent;
  }
  const loadSubSubGroups = async (group: any) => {
    setselectedSubSubGroup(null);
    const resp = await getNationalGroupSet(group || selSubSubGroup?.groupNo || selSubSubGroup?.id);
    if (resp?.status === 200) {
      setSubSubGroups(resp?.data);
    }
  }

  const handleChangeSubSubGroup = (e: any, prm: any) => {

    setselectedSubSubGroup(prm as any);
  }
  return (
    <div>
      <div className="flex gap-10">
        <div className="flex flex-col justify-between gap-3">
          <div className="flex flex-col items-start gap-3 max-w-[340px]">
            <p className="w-[260px]">Кластер</p>{' '}
            <p className="text-sm text-[#04438b]">{cluster?.name ?? '-'}</p>
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[260px]">Поглавље</p>
            <div className="flex flex-col">
              <Autocomplete
                value={selSubSubGroup}
                size="small"
                onChange={(event, newValue) => subSubChange(event, newValue)}
                onFocus={() => setErrorMap({ ...errorMap, group: '' })}
                id="controllable-states-demo"
                noOptionsText={'Не постоји подгрупа'}
                options={subSubGroup || ['']}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 340 }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      ...props.style,
                      // pointerEvents: option.level === 3 ? 'auto' : 'none',
                      // color:
                      //   option.level === 3 ? 'inherit' : 'rgba(0, 0, 0, 0.38)',
                    }}
                  >
                    <span>{option.name}</span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Поглавље" />
                )}
              />
            </div>
            {errorMap?.group && (
              <span style={{ color: 'red' }}>Ово поље је обавезно</span>
            )}
          </div>
          {selSubSubGroup && <div className="flex flex-col items-start gap-3">
            <p className="w-[260px]">Подпоглавље</p>
            <div className="flex flex-col">
              <Autocomplete
                value={selectedSubSubGroup}
                size="small"
                onChange={(event, newValue) => handleChangeSubSubGroup(event, newValue)}
                id="controllable-states-demo"
                noOptionsText={'Не постоји Подпоглавље'}
                options={subSubGroups || ['']}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 340 }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      ...props.style,

                    }}
                  >
                    <span>{option.name}</span>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Подпоглавље" />
                )}
              />
            </div>
          </div>}

          <div className="flex flex-col items-start justify-start text-left gap-3 w-full">
            <p className="w-full mb-1">Врста мере</p>
            <FormControl size="small" sx={{ minWidth: 340 }}>
              <InputLabel id="demo-select-small">Врста мере</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={measureType ?? ''}
                onChange={(e) => setMeasureType(e.target.value)}
                onFocus={() => setErrorMap({ ...errorMap, type: '' })}
                size="small"
                label="Врста мере"
              >
                {MEASURE_TYPE.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorMap?.type && (
              <span style={{ color: 'red' }}>Ово поље је обавезно</span>
            )}
          </div>
          <div className="flex flex-col items-start gap-10">
            <div className="flex flex-col items-start gap-3">
              <p className="w-[260px]">Планирана мера</p>
              <TextField
                label="Назив мере"
                id="outlined-size-small"
                type="institutionName"
                name="institutionName"
                size="small"
                multiline
                sx={{ width: 340 }}
                helperText={errorMap?.title}
                error={!!errorMap?.title}
                onFocus={() => setErrorMap({ ...errorMap, title: '' })}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start text-left gap-3 w-full ">
            <p className="w-full mb-1">Тип рока</p>
            <FormControl size="small" sx={{ minWidth: 340 }}>
              <InputLabel id="demo-select-small">Тип рока</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={deadlineType ?? ''}
                onChange={(e) => setDeadlineType(e.target.value)}
                onFocus={() => setErrorMap({ ...errorMap, deadlineType: '' })}
                size="small"
                label="Тип рока"
              >
                {DEADLINE_TYPE.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorMap?.deadlineType && (
              <span style={{ color: 'red' }}>Ово поље је обавезно</span>
            )}
          </div>
          {deadlineType === 1 && (
            <div>
              {' '}
              <p className="w-full mt-1">Рок</p>
              <div
                className="flex justify-start w-full items-center"
                onClick={() => setErrorMap({ ...errorMap, deadlineDate: '' })}
              >
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={deadlineDate?.q || ''}
                    onChange={(e) =>
                      setDeadlineDate({
                        ...deadlineDate,
                        q: e.target.value,
                      })
                    }
                    label="Квартал"
                  >
                    {QUARTER_MAP?.map((q) => {
                      return <MenuItem value={q}>{q}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={deadlineDate?.y || ''}
                    onChange={(e) =>
                      setDeadlineDate({
                        ...deadlineDate,
                        y: e.target.value,
                      })
                    }
                    label="Година"
                  >
                    {YEAR_MAP?.map((y) => {
                      return <MenuItem value={y}>{y}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
              {errorMap?.deadlineDate && (
                <span style={{ color: 'red' }}>Ово поље је обавезно</span>
              )}
            </div>
          )}
        </div>

        <div className="flex items-start gap-2">
          <div className="flex flex-col items-start gap-2 ">
            <div className="flex flex-col items-start gap-3 max-w-[340px]">
              <p className="w-[260px]">Првонадлежна институција</p>
              <div className="flex flex-col">
                <Autocomplete
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  value={institution}
                  size="small"
                  onChange={(event: any, newValue: any) =>
                    setInstitution(newValue)
                  }
                  id="controllable-states-demo"
                  options={institutions}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 340 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Институција" />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-6">
              <p className="w-[240px]">Другонадлежне институције</p>
              <div className="flex flex-col">
                <Autocomplete
                  multiple
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  value={defaultInstitution}
                  size="small"
                  onChange={(event: any, newValue: any) =>
                    handleInstChange(event, newValue)
                  }
                  id="controllable-states-demo"
                  options={institutions}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: 340 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Институција" />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start text-left gap-3 w-full mb-4">
              <p className="w-full mb-1">Статус испуњености мере</p>
              <FormControl size="small" sx={{ minWidth: 340 }}>
                <InputLabel id="demo-select-small">
                  Статус испуњености мере
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={selectedOption}
                  onChange={handleChange}
                  size="small"
                  label="Статус испуњености мере"
                >
                  {translations
                    .filter((translation) => {
                      if (
                        user?.roleView?.features?.includes('administration')
                      ) {
                        return true;
                      } else {
                        if (selectedOption === 1)
                          return translation.id === 1 || translation.id === 2;
                        if (selectedOption === 2) return translation.id === 2;
                      }
                      return true; // For selectedOption 0, allow all
                    })
                    .map((translation) => (
                      <MenuItem
                        key={translation.id}
                        value={translation.id}
                        className="capitalize"
                      >
                        {translation.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full flex flex-col items-start gap-4">
              <label htmlFor="message" className="text-sm text-gray-600">
                Образложење статуса
              </label>
              <textarea
                id="message"
                rows={4}
                className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                placeholder="Образложење статуса"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end mt-12 items-center">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          endIcon={<FaSave />}
          onClick={handleSave}
        >
          Сачувај
        </Button>
      </div>
    </div>
  );
}

export default BasicMeasureData;
