import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../auth/UseUser';
import {
  deleteNatMeasureStructure,
  getNatGroupStructure,
  getNationalGroupSet,
  getNationalGroupSetByLevel,
  insertNatGroupStructure,
} from '../../../../server/server';
import { notif } from '../../../utils/util';

function NPIStruct({ setAvalible, avalible }: any) {
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const [group, setGroup] = useState<any>(null);
  const [subGroup, setSubGroup] = useState<any>(null);
  const [subSubGroup, setSubSubGroup] = useState<any>(null);
  const [items, setItems] = useState<any>([]);
  const [selGroup, setSelGroup] = useState<any>(null);
  const [selSubGroup, setSelSubGroup] = useState<any>(null);
  const [selSubSubGroup, setSelSubSubGroup] = useState<any>(null);

  const [editId, setEditId] = useState('');
  const loadGroups = async () => {
    const resp = await getNationalGroupSetByLevel(28, 1);
    if (resp?.status === 200) {
      setGroup(resp?.data);
    }
  };

  const loadSubGroups = async () => {
    const resp = await getNationalGroupSet(selGroup?.groupNo ?? 0);
    if (resp?.status === 200) {
      setSubGroup(resp?.data);
    }
  };
  const loadSubSubGroups = async () => {
    const resp = await getNationalGroupSet(selSubGroup?.groupNo ?? 0);
    if (resp?.status === 200) {
      setSubSubGroup(resp?.data);
    }
  };

  const loadStructureById = async (id: string) => {
    const resp = await getNatGroupStructure(id, 28);

    if (resp?.status === 200) {
      setItems(resp?.data);
    }
  };

  useEffect(() => {
    loadSubGroups();
  }, [selGroup]);

  useEffect(() => {
    loadSubSubGroups();
  }, [selSubGroup]);

  useEffect(() => {
    loadGroups();
    let eId = searchParams.get('id');
    if (eId) {
      loadStructureById(eId);
      setEditId(eId);
    }
  }, []);

  const handleInstChange = (e: any, prm: any) => {
    setSelGroup(prm as any);
  };
  const subChange = (e: any, prm: any) => {
    setSelSubGroup(prm as any);
  };
  const subSubChange = (e: any, prm: any) => {
    setSelSubSubGroup(prm as any);
  };
  const deleteItem = React.useCallback(
    (id: GridRowId) => () => {
      deleteStructure(id);
      setTimeout(() => {
        setItems((prevRows: any) =>
          prevRows.filter((row: any) => row.id !== id)
        );
      });
    },
    []
  );
  const getGroupNo = () => {
    if (selSubSubGroup) {
      return selSubSubGroup.groupNo;
    }
    if (selSubGroup) {
      return selSubGroup.groupNo;
    }
    if (selGroup) {
      return selGroup.groupNo;
    }
  };

  const deleteStructure = async (id: any) => {
    const resp = await deleteNatMeasureStructure(id);

    if (resp.status === 200) {
      loadStructureById(editId);
      notif('НПАА структура обрисана', 'success');
      setSelGroup(null);
      setSelSubGroup(null);
      setSelSubSubGroup(null);
    } else {
      notif('НПАА структура није обрисана', 'error');
    }
  };
  const insertStructure = async () => {
    const resp = await insertNatGroupStructure({
      natMeasure: parseInt(editId),
      group: getGroupNo(),
    });

    if (resp.status === 201) {
      loadStructureById(editId);
      notif('НПАА структура додата', 'success');
      setSelGroup(null);
      setSelSubGroup(null);
      setSelSubSubGroup(null);
      setAvalible({
        ...avalible,
        1: true,
      });
    } else {
      notif('НПАА структура није додата', 'error');
    }
  };

  // const handleAdd = () => {
  //   setItems([
  //     ...items,
  //     { id: '1', name: getGroupName(), user: user?.username },
  //   ]);
  // };

  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'name',
        headerName: 'Назив NPAA поглавља',
        flex: 1,
      },
      {
        field: 'username',
        headerName: 'Корисник',
        maxWidth: 180,
        minWidth: 180,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<FaTrash style={{ width: 20, height: 20 }} />}
            sx={{ width: 50, height: 50 }}
            label="Обриши"
            onClick={deleteItem(params.id)}
          />,
        ],
      },
    ],
    [deleteItem]
  );
  return (
    <div className="flex flex-col gap-3 mt-3">
      <div className="flex items-center gap-3">
        <div className="flex flex-col items-start gap-3">
          <p className="w-[260px]">NPAA група</p>
          <div className="flex flex-col">
            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={selGroup}
              size="small"
              onChange={(event: any, newValue: any) =>
                handleInstChange(event, newValue)
              }
              id="controllable-states-demo"
              options={group}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 340 }}
              renderInput={(params) => <TextField {...params} label="Група" />}
            />
          </div>
        </div>
        <div className="flex  flex-col items-start gap-3">
          <p className="w-[260px]">NPAA подгрупа</p>
          <div className="flex flex-col">
            <Autocomplete
              disabled={!selGroup}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={selSubGroup}
              noOptionsText={'Не постоји подгрупа'}
              size="small"
              onChange={(event: any, newValue: any) =>
                subChange(event, newValue)
              }
              id="controllable-states-demo"
              options={subGroup}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 340 }}
              renderInput={(params) => (
                <TextField {...params} label="Подгрупа" />
              )}
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3">
          <p className="w-[260px]">NPAA под-подгрупа</p>
          <div className="flex flex-col">
            <Autocomplete
              disabled={!selSubGroup}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={selSubSubGroup}
              size="small"
              onChange={(event: any, newValue: any) =>
                subSubChange(event, newValue)
              }
              id="controllable-states-demo"
              noOptionsText={'Не постоји подгрупа'}
              options={subSubGroup}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 340 }}
              renderInput={(params) => (
                <TextField {...params} label="Под-подгрупа" />
              )}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end pr-4 items-center">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          onClick={insertStructure}
        >
          Потврди
        </Button>
      </div>
      {items?.length > 0 && (
        <div>
          <div className="text-[#04438b]">Положај у оквиру NPAA структуре</div>
          <div className="mt-5 h-[430px] w-full">
            <DataGrid
              rows={items}
              disableColumnMenu
              columns={columns}
              style={{ zIndex: 0 }}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје NPAA поглавља
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    Не постоје NPAA поглавља
                  </Stack>
                ),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default NPIStruct;
