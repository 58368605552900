import { Button } from '@mui/material';
import React, { useState } from 'react';
import { FaSave } from 'react-icons/fa';

const FinantialTable = ({
  allType,
  text,
  years,
  setYears,
  expData,
  data,
  setData,
}: any) => {
  const types = allType.map((t: any) => t?.name);
  const [inputValues, setInputValues] = useState({}); // Add this state at the beginning of your component
  const [yearSums, setYearSums] = useState({});
  const [exceededYears, setExceededYears] = useState({});

  const addYear = () => {
    setYears((prevYears: any) => {
      const newYear = prevYears[prevYears.length - 1] + 1;
      if (newYear > 2027) {
        return prevYears;
      }
      return [...prevYears, newYear];
    });
  };

  const updateValue = (
    year: number | 'Kasnije',
    type: string,
    value: string
  ) => {
    let parsedValue: number;

    if (type === 'Промена броја запослених') {
      parsedValue = parseInt(value);
    } else {
      parsedValue = parseFloat(value.replace(',', '.'));
    }

    setData((prevData: any) => {
      let newData = [...prevData];
      let actualYear = year === 'Kasnije' ? Infinity : year;

      let itemIndex = newData.findIndex(
        (item) => item.year === actualYear && item.type === type
      );

      if (itemIndex > -1) {
        newData[itemIndex].value = parsedValue;
      } else {
        newData.push({ year, type, value: parsedValue });
      }

      return newData;
    });
  };

  const sumForYear = (year: number) => {
    const filteredExpData = expData?.filter(
      (item: any) =>
        item.year === year && item.type !== 'Промена броја запослених'
    );

    return filteredExpData?.reduce(
      (sum: any, item: any) => sum + (item.value || 0),
      0
    );
  };

  const sumForYearD = (year: number | 'Kasnije') => {
    // Map 'Kasnije' to 3000 or use the provided year
    const actualYear = year === 'Kasnije' ? 3000 : year;

    // Filter the data based on the actualYear and type
    const filteredData = data?.filter(
      (item: any) =>
        item.year === actualYear && item.type !== 'Промена броја запослених'
    );

    // Log the filtered data for debugging

    // Reduce the filtered data to calculate the sum
    return filteredData?.reduce(
      (sum: any, item: any) => sum + (item.value || 0),
      0
    );
  };

  const sumForType = (type: string) => {
    return data
      ?.filter(
        (item: any) =>
          item.type === type && item.type !== 'Промена броја запослених'
      )
      ?.reduce((sum: any, item: any) => sum + (item.value || 0), 0);
  };

  const totalSum = () => {
    let total = 0;
    years.forEach((year: number) => {
      total += sumForYear(year) || 0;
    });
    return total;
  };

  const sumForYearAndType = (year: number, type: string) => {
    return data
      ?.filter((item: any) => item.year === year && item.type === type)
      ?.reduce((sum: any, item: any) => sum + (item.value || 0), 0);
  };

  const unknownSumForYear = (year: number) => {
    const totalNeeded = sumForYearD(year);
    const totalForYear = sumForYear(year);

    const difference = totalForYear - totalNeeded;
    return difference > 0 ? difference : 0;
  };

  const unknownSum = () => {
    let totalUnknown = 0;
    for (let year of years) {
      totalUnknown += unknownSumForYear(year);
    }
    return totalUnknown;
  };
  const formatNumber = (num: number) => {
    return num.toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const input = e.target;
    setTimeout(() => {
      const len = input.value.length;
      if (input.setSelectionRange) {
        input.setSelectionRange(len, len);
      }
    }, 10); // 10 ms delay should suffice
  };
  const calculateTotalAllowableValueForYear = (year: number, expData: any) => {
    const yearDataItems = expData.filter((item: any) => item?.year === year);

    console.log('test1: data', yearDataItems);
    const sumForYear1 = yearDataItems.reduce((sum: number, item: any) => {
      if (typeof item.value === 'number') {
        return sum + item.value;
      } else {
        console.error('Invalid data type:', item.value);
        return sum;
      }
    }, 0);
    console.log('test1: total, taken', sumForYear(year), sumForYear1);
    return sumForYear(year) || 0 - sumForYear1;
  };

  return (
    <div className="overflow-hidden rounded-lg border border-[#81848f3d] text-sm">
      <div
        className={`flex divide-x divide-[#81848f3d] bg-[#c3c3d6] text-[#0f2765] text-left text-xs font-medium uppercase tracking-wider justify-items-center`}
      >
        <div className="px-4 py-1 w-60 flex items-center font-bold justify-center">
          {text}
        </div>
        <div className="flex flex-col py-2 text-center font-bold items-center justify-center w-24">
          Базна година
          <p>{years[0]}</p>
        </div>
        {years?.slice(1)?.map((year: any, index: any) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center w-24 font-bold"
          >
            <p className="text-sm">{year === 3000 ? 'Касније' : year}</p>
          </div>
        ))}

        <div className="flex items-center justify-center ml-auto w-24 font-bold">
          Укупно
        </div>
      </div>
      {/* rows */}
      <div className="divide-y divide-[#81848f3d]">
        {types.map((type: any, rowIndex: any) => (
          <div key={rowIndex} className={`flex divide-x divide-[#81848f3d]`}>
            <div className="px-4 py-2  text-sm text-gray-500 w-60 h-auto overflow-auto">
              {type}
            </div>

            {years.map((year: any, i: any) => {
              let item = data.find(
                (item: any) => item.year === year && item.type === type
              );
              const isExceeded =
                exceededYears[year as keyof typeof exceededYears];

              return (
                <div
                  key={year}
                  className={`flex items-center justify-center whitespace-nowrap text-sm text-gray-500 w-24 ${
                    isExceeded ? 'bg-[#FFCCCC]' : ''
                  }`}
                >
                  {type !== 'Промена броја запослених' ? (
                    <input
                      type="text"
                      onFocus={handleInputFocus}
                      className="w-20 text-right"
                      disabled={i === 0}
                      value={formatNumber(
                        inputValues[
                          `${year}-${type}` as keyof typeof inputValues
                        ] ?? (item ? item.value : 0)
                      )}
                      onChange={(e) => {
                        const val = e.target.value
                          .replace(/\./g, '')
                          .replace(',', '.');
                        let numberVal = parseFloat(val);
                        if (isNaN(numberVal)) {
                          numberVal = 0;
                        }
                        // Existing logic to update the value
                        setInputValues({
                          ...inputValues,
                          [`${year}-${type}`]: numberVal,
                        });
                        updateValue(year, type, numberVal?.toString());
                        const sumWithoutCurrent =
                          sumForYear(year) - (item ? item.value : 0);
                        const maxVal = calculateTotalAllowableValueForYear(
                          year,
                          data
                        );
                        const exceedAmount = numberVal - maxVal;
                        console.log('test1:exceedAmount', exceedAmount);
                        // Check if the value exceeds the maxVal
                        if (exceedAmount > 0) {
                          // Mark the year as exceeded and store the exceed amount
                          setExceededYears({
                            ...exceededYears,
                            [year]: exceedAmount,
                          });
                        } else {
                          // Remove the year from the exceeded list if it's there
                          const newExceededYears = { ...exceededYears };
                          delete newExceededYears[
                            year as keyof typeof exceededYears
                          ];
                          setExceededYears(newExceededYears);
                        }
                      }}
                      defaultValue={
                        item
                          ? item.value
                            ? item?.value?.toFixed(2)?.replace('.', ',')
                            : '0'
                          : '0'
                      }
                      onBlur={(e) => {
                        const val = e.target.value
                          .replace(/\./g, '')
                          .replace(',', '.');
                        const numberVal = parseFloat(val);
                        if (isNaN(numberVal)) {
                          e.target.value = '0';
                          updateValue(year, type, '0');
                        } else {
                          e.target.value = numberVal?.toLocaleString('de-DE', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          });
                          updateValue(year, type, numberVal.toString());
                        }
                      }}
                    />
                  ) : (
                    <input
                      onFocus={handleInputFocus}
                      className="w-20 text-right"
                      type="number"
                      value={item ? item.value?.toString() : '0'}
                      onChange={(e) => updateValue(year, type, e.target.value)}
                    />
                  )}
                </div>
              );
            })}
            {years[years.length - 1] === 2027 && (
              <div className="flex items-center justify-center whitespace-nowrap text-sm text-gray-500 w-24">
                {type !== 'Промена броја запослених' ? (
                  <input
                    type="text"
                    className="w-20"
                    defaultValue="0"
                    onBlur={(e) => {
                      const val = e.target.value
                        .replace(/\./g, '')
                        .replace(',', '.');
                      const numberVal = parseFloat(val);
                      if (isNaN(numberVal)) {
                        e.target.value = '0';
                        updateValue('Kasnije', type, '0');
                      } else {
                        e.target.value = numberVal?.toLocaleString('de-DE', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        });
                        updateValue('Kasnije', type, numberVal?.toString());
                      }
                    }}
                  />
                ) : (
                  <input
                    className="w-20"
                    type="number"
                    defaultValue="0"
                    onChange={(e) =>
                      updateValue('Kasnije', type, e.target.value)
                    }
                  />
                )}
              </div>
            )}
            <div className="flex items-center justify-center bg-[#e8e8f6] ml-auto w-24">
              {sumForType(type)?.toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          </div>
        ))}
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-60 bg-[#f1e8f6]">
            За сада непознато
          </div>
          {years.map((year: any) => (
            <div
              key={year}
              className="flex items-center justify-center w-24 bg-[#f1e8f6]"
            >
              {unknownSumForYear(year)?.toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          ))}
          <div className="flex items-center justify-center ml-auto w-24 bg-[#f1e8f6]">
            {unknownSum()?.toLocaleString('de-DE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        </div>
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-60 bg-[#e8e8f6]">
            Укупно
          </div>
          {years.map((year: any) => (
            <div
              key={year}
              className="flex items-center justify-center w-24 bg-[#e8e8f6]"
            >
              {sumForYear(year)?.toLocaleString('de-DE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          ))}
          <div className="flex items-center justify-center ml-auto w-24 bg-[#ced0db]">
            {totalSum()?.toLocaleString('de-DE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        </div>
      </div>
      <div className="text-[#FF0000]">
        {Object.entries(exceededYears).map(([year, amount]: any, index) => (
          <div key={index}>
            {`Година ${year}: премашујете максимално дозвољену вредност за ${amount?.toLocaleString(
              'de-DE',
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            )} динара.`}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FinantialTable;
