import { useState } from 'react';
import { FaFile, FaFolder, FaFolderOpen } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface GroupProps {
  group: GroupNode;
}
export interface GroupNode {
  groupNo: number;
  name: string;
  children?: GroupNode[];
}
const Group: React.FC<GroupProps> = ({ group }) => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isExpanded, setIsExpanded] = useState(false);

  const isCurrentGroup = () => {
    return group.groupNo === parseInt(searchParams.get('group') ?? '');
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    navigate(
      `/jurisdiction?group=${isCurrentGroup() ? '' : group?.groupNo}&no=${
        isCurrentGroup() ? '' : group?.name?.match(/\d+(\.\d+){0,3}/)?.[0]
      }`
    );
    console.log(group);
    // if (group.children?.length ?? 0 > 0) {

    // } else {

    // }
  };

  return (
    <div>
      <div
        onClick={toggleExpand}
        className={`flex items-center cursor-pointer gap-2 justify-start py-1 pl-6  mx-6 my-2  rounded-md ${
          isCurrentGroup()
            ? 'text-white bg-[#0F2765]'
            : 'hover:text-white hover:bg-[#0F2765]'
        } text-gray-300`}
      >
        {group.children?.length ?? 0 > 0 ? (
          //   <i className={`fa ${ ? 'fa-folder-open' : 'fa-folder'}`} />
          <div>
            {isExpanded ? (
              <FaFolderOpen className="w-[30px] min-w-[30px]" />
            ) : (
              <FaFolder className="w-[30px] min-w-[30px]" />
            )}
          </div>
        ) : (
          //   <i className="fa fa-file" />
          <FaFile className="w-[30px] min-w-[30px] fill-[#878787]" />
        )}
        {group.name}
      </div>
      {isExpanded && (
        <div style={{ marginLeft: 10 }}>
          {group?.children?.map((childGroup) => (
            <Group key={childGroup.groupNo} group={childGroup} />
          ))}
        </div>
      )}
    </div>
  );
};

interface GroupsTreeProps {
  groups: GroupNode[];
}

export const GroupsTree: React.FC<GroupsTreeProps> = ({ groups }) => {
  return (
    <div>
      {groups.map((group) => (
        <Group key={group.groupNo} group={group} />
      ))}
    </div>
  );
};
