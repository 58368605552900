import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { error } from 'console';
import React, { useEffect, useState } from 'react';
import { FaQuestionCircle, FaSave } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getAllInstitutions,
  insertInstitution,
  loadInstitutionById,
  updateInstitution,
} from '../../../server/server';
import { notif } from '../../utils/util';

function InstitutionUpdating() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState('1');
  const [defaultInstitution, setDefaultInstitution] = useState<any>();
  const [institutions, setInstitutions] = useState<any>([]);
  const [secInstitutions, setSecInstitutions] = useState<any>();

  const [errorMap, setErrorMap] = useState({
    title: false,
    titleEN: false,
    acronym: false,
    acronymEN: false,
  });
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };

  const handleSecInstChange = (e: any, prm: any) => {
    setSecInstitutions(prm as any);
  };

  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };
  useEffect(() => {
    loadAllInstitutions();
  }, []);

  const handleSubmit = () => {
    if (type === '0') {
      notif('Институција није спојена', 'error');
    } else {
      notif('Институција није раздвојена', 'error');
    }
  };

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Усвајање структуре институције
        </h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => navigate('/help/page?id=insertInstitution')}
        >
          <p className="rounded-full   flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-[620px] p-8 pb-4  mt-8 ">
        <div className="flex gap-8 flex-col">
          <div className="flex items-start gap-6 flex-col">
            <p className="w-[240px]">Врста уређивања </p>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={type}
                onChange={(e) => {
                  setType((e.target as HTMLInputElement).value);
                }}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Спојање постојеће у планирану"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Раздвајање постојеће у планиране"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="flex flex-col items-start gap-6">
            <p className="w-[240px]">
              {type === '0' ? 'Планирана' : 'Постојећа'} институција
            </p>
            <div className="flex flex-col">
              <Autocomplete
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={defaultInstitution?.name}
                size="small"
                onChange={(event: any, newValue: any) =>
                  handleInstChange(event, newValue)
                }
                id="controllable-states-demo"
                options={institutions}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 555 }}
                renderInput={(params) => (
                  <TextField {...params} label="Институција" />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col items-start gap-6">
            <p className="w-[240px]">
              {type === '0' ? 'Постојеће' : 'Планиране'} институције
            </p>
            <div className="flex flex-col">
              <Autocomplete
                multiple
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={secInstitutions?.name}
                size="small"
                onChange={(event: any, newValue: any) =>
                  handleSecInstChange(event, newValue)
                }
                id="controllable-states-demo"
                options={institutions}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 555 }}
                renderInput={(params) => (
                  <TextField {...params} label="Институција" />
                )}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-12 items-center">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#0F2765' }}
            endIcon={<FaSave />}
            onClick={handleSubmit}
          >
            Ажурирај
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InstitutionUpdating;
