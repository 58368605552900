import { useCallback, useEffect, useRef, useState, memo } from 'react';
import { baseUrl } from './server';
import { EventSourcePolyfill } from 'event-source-polyfill';

interface UseDataImportProps {
  set: any;
  onProgressStart?: () => void;
}

interface UseDataImportProps {
  set: any;
  onProgressStart?: () => void;
}

const useEventSource = (url: string) => {
  console.log('useEventSource called', url);
  const [progress, setProgress] = useState(0);
  const [finish, setFinish] = useState(false);
  const sourceRef = useRef<EventSourcePolyfill | null>(null);
  const [debouncedProgress, setDebouncedProgress] = useState(0);

  useEffect(() => {
    const initializeEventSource = async () => {
      if (!sourceRef.current) {
        const token = await localStorage.getItem('accessToken');
        sourceRef.current = new EventSourcePolyfill(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        sourceRef.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          setProgress(data.progress);
          setDebouncedProgress(data.progress);
          if (data.finish) setFinish(true);
        };
      }

      return () => {
        if (sourceRef.current) {
          sourceRef.current.close();
          sourceRef.current = null;
        }
      };
    };

    initializeEventSource();
  }, [url]);

  useEffect(() => {
    if (finish && sourceRef.current) {
      sourceRef.current.close();
      sourceRef.current = null;
    }
  }, [finish]);

  return { progress: debouncedProgress, finish };
};
const useDataImport = ({ set, onProgressStart }: UseDataImportProps) => {
  const { progress, finish } = useEventSource(
    baseUrl + '/administration/eu/insert/updates'
  );

  const startImport = useCallback(async () => {
    if (onProgressStart) {
      onProgressStart();
    }
    const token = await localStorage.getItem('accessToken');
    const response = await fetch(
      baseUrl + '/administration/eu/insert?set=' + set,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to start data import');
    }
  }, [set, onProgressStart]);

  return { progress, startImport, finish };
};

export default useDataImport;
