import React, { useEffect } from 'react';

type RadioButtonGroupProps = {
  state: boolean | null;
  setState: any;
  isError: boolean;
  setIsError: any;
  isNoDisabled?: boolean;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  state,
  setState,
  isError,
  setIsError,
  isNoDisabled,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value === 'Да' ? true : false);
    setIsError(false);
  };

  useEffect(() => {
    setIsError(null);
  }, []);

  return (
    <div className="flex items-center cursor-pointer">
      <label className="inline-flex items-center mr-3">
        <input
          type="radio"
          className="form-radio"
          value="Да"
          checked={state === true}
          onChange={handleChange}
        />
        <span className="ml-2">Да</span>
      </label>
      <label className="inline-flex items-center">
        <input
          disabled={isNoDisabled}
          type="radio"
          className="form-radio"
          value="Не"
          checked={state === false}
          onChange={handleChange}
        />
        <span className={`ml-2 ${isNoDisabled ? 'text-[#808080]' : ''}`}>
          Не
        </span>
      </label>
      {isError && (
        <span className="ml-2" style={{ color: 'red' }}>
          Ово поље је обавезно
        </span>
      )}
    </div>
  );
};

export default RadioButtonGroup;
