import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BasicMeasureData from './measureScreens/basicMeasureData';
import NatMeasureRegisters from './measureScreens/NatMeasureRegisters';
import EuMeasureRegisters from './measureScreens/EuMeasureRegister';
import PointerMeasure from './measureScreens/pointerMeasure';
import { getMeasureById } from '../../../server/server';
import { useSearchParams } from 'react-router-dom';
import InstitutionsInChargeMeasure from './measureScreens/InstitutionsInCharge';
import MeasureStatus from './measureScreens/MeasureStatus';
import HelpPageModal from '../../modals/HelpPageModal';
import HelpButton from '../../../common/HelpButton';
import NegDocsMeasure from './measureScreens/NegDocs';

function MeasureEdit() {
  const [searchParams] = useSearchParams();
  const [value, setValue] = React.useState(0);
  const [editMeasure, setEditMeasure] = React.useState<any>(null);
  const [title, setTitle] = React.useState<any>(null);
  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const loadMeasure = async (eid: any) => {
    const resp = await getMeasureById(eid);
    if (resp?.status === 200) {
      setEditMeasure(resp?.data);
    }
  };

  useEffect(() => {
    let eId = searchParams.get('id');
    if (eId) {
      loadMeasure(eId);
    }
  }, []);

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Ажурирање мере ({title})
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 ">
        {/* <h1 className="text-[#04438bae] text-2xl font-bold">СТАЊЕ ПРЕГОВОРА</h1> */}

        <div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={(e, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Мера" {...a11yProps(0)} />
                <Tab label="Национални Прописи" {...a11yProps(1)} />
                <Tab label="Европски Прописи" {...a11yProps(2)} />
                <Tab label="Показатељи" {...a11yProps(3)} />
                <Tab
                  label="Везе са преговарачким документом"
                  {...a11yProps(4)}
                />
                {/* <Tab label="Надлежна институција" {...a11yProps(4)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <BasicMeasureData measure={editMeasure} setMenuTitle={setTitle} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <NatMeasureRegisters />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <EuMeasureRegisters />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PointerMeasure />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <NegDocsMeasure />
            </TabPanel>
            {/* <TabPanel value={value} index={4}>
              <InstitutionsInChargeMeasure />
            </TabPanel> */}
          </Box>
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'measureEdit'}
      />
    </div>
  );
}

export default MeasureEdit;
