import React, { useState, useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

function Administration() {
  let navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const menuList = [
    {
      name: 'Администрирање корисника',
      subMenus: [
        { name: 'insertUser', title: 'Унос корисника', url: 'user/insert' },
        {
          name: 'userManagement',
          title: 'Управљање корисницима',
          url: 'user/management',
          select: 'user/edit',
        },
      ],
    },
    {
      name: 'Администрирање институција',
      subMenus: [
        {
          name: 'insertInstitution',
          title: 'Унос институције',
          url: 'institution/insert',
        },
        {
          name: 'institutionManagement',
          title: 'Управљање институцијама',
          url: 'institution/management',
        },
        {
          name: 'changeInstitution',
          title: 'Промена надлежности',
          url: 'institution/change',
        },
        // {
        //   name: 'institutionUpdate',
        //   title: 'Усвајање структуре инст.',
        //   url: 'institution/changestructure',
        // },
      ],
    },
    {
      name: 'Службени гласник',
      subMenus: [
        { name: 'insertOJ', title: 'Унос сл. гласника', url: 'oj/insert' },
        {
          name: 'OJManagement',
          title: 'Управљање сл. гласника',
          url: 'oj/management',
        },
        {
          name: 'ojDateInsert',
          title: 'Унос датума објаве СГ',
          url: 'oj/date/insert',
        },
        {
          name: 'ojDateManagement',
          title: 'Управљање датумом објаве СГИ',
          url: 'oj/date/management',
        },
      ],
    },
    {
      name: 'Међународне конвенције без броја',
      subMenus: [
        {
          name: 'intconvInsert',
          title: 'Унос међународне конвенције',
          url: 'intconv/insert',
        },
        {
          name: 'intconvManagement',
          title: 'Управљање међународним конвенцијама',
          url: 'intconv/management',
        },
      ],
    },
    {
      name: '',
      subMenus: [
        {
          name: 'tuManagement',
          title: 'Табеле усклађености',
          url: 'tu/management',
        },
        {
          name: 'euInsert',
          title: 'Увоз ЕУ прописа',
          url: 'eu/insert',
        },
        {
          name: 'parameters',
          title: 'Параметризација',
          url: 'parameters',
        },
      ],
    },
  ];

  useEffect(() => {
    // const params = [] as any[];
    // const idScreenMap = {
    //   editUserId: 'editUser',
    // };
    // searchParams.forEach((value, key) => {
    //   params.push([key, value]);
    // });
    // if (params[0]?.length > 0) {
    //   setScreen(
    //     idScreenMap[params[0][0] as keyof typeof idScreenMap] as string
    //   );
    // }
  }, []);
  const isSelected = (sub: any) => {
    if ('/administration/' + sub?.url === location?.pathname) return true;
    if ('/administration/' + sub?.select === location?.pathname) return true;

    return false;
  };
  return (
    <div className=" w-full h-full flex flex-row justify-start">
      <div className="h-full bg-[#1e3f940a] w-[320px] min-w-[180px] my-5 first:mt-0 overflow-auto gray-small-scroll">
        {menuList?.map((section) => {
          return (
            <div key={section?.name}>
              <div className="flex items-center pl-6 mt-6 py-3 text-gray-500 font-semibold mx-6 uppercase text-sm text-[#1e4094]">
                {section?.name}
              </div>
              {section?.subMenus?.map((sub) => {
                console.log(isSelected(sub));
                return (
                  <div
                    key={sub?.name}
                    onClick={() => navigate(sub?.url)}
                    className={`flex items-center cursor-pointer py-1 pl-6 mx-6 my-2  rounded-md ${
                      isSelected(sub)
                        ? 'text-white bg-[#0F2765]'
                        : 'hover:text-white hover:bg-[#0F2765] text-gray-300'
                    }`}
                  >
                    {sub?.title}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="w-full overflow-y-scroll gray-small-scroll">
        <Outlet />
      </div>
    </div>
  );
}

export default Administration;
