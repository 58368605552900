import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AddMeasure from './screens/AddMeasure';

function Measure() {
  let navigate = useNavigate();
  const location = useLocation();
  const [screen, setScreen] = useState('default');

  const screenMap = {
    addMeasure: <AddMeasure />,
  };
  const menuList = [
    {
      name: 'Мерила',
      subMenus: [
        { name: 'addMeasure', title: 'Додај мерила', url: 'insert' },
        { name: 'searchMeasures', title: 'Преглед мерила', url: 'search' },
      ],
    },
  ];
  return (
    <div className=" w-full h-full flex flex-row justify-start">
      <div className="h-full bg-[#1e3f940a] w-[320px] my-5 first:mt-0">
        {menuList?.map((section) => {
          return (
            <div key={section?.name}>
              <div className="flex items-center pl-6 mt-6 py-3 text-gray-500 font-semibold mx-6 uppercase text-sm text-[#1e4094]">
                {section?.name}
              </div>
              {section?.subMenus?.map((sub) => {
                return (
                  <div
                    key={sub?.name}
                    onClick={() => navigate(sub?.url)}
                    className={`flex items-center cursor-pointer py-1 pl-6  mx-6 my-2  rounded-md ${
                      '/measure/' + sub?.url === location?.pathname
                        ? 'text-white bg-[#0F2765]'
                        : 'hover:text-white hover:bg-[#0F2765] text-gray-300'
                    }`}
                  >
                    {sub?.title}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
}

export default Measure;
