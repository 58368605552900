import { Autocomplete, Stack, TextField } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash, FaUsersCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  getAllInstitutions,
  getInstitutions,
  getRegistersAdmin,
  getRegistersManagement,
} from '../../../server/server';
import ChangeInstUserOnNatMeaModal from '../../modals/ChangeInstUserOnNatMeaModal';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function ChangeInstitution() {
  let navigate = useNavigate();
  const [term, setTerm] = useState('');
  const [title, setTitle] = useState('');
  const [registers, setRegisters] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [rowCountState, setRowCountState] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [institutions, setInstitutions] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selMeasure, setSelMeasure] = useState<any>(null);
  const [defType, setDefType] = useState<any | null>({
    id: 9,
    name: 'Сви',
  });
  const [defaultInstitution, setDefaultInstitution] = useState<any | null>(
    null
  );
  const [helpModal, setHelpModal] = useState(false);
  const closeHelpModal = () => {
    setHelpModal(false);
  };

  const returnType = () => {
    if (defType?.id !== null) {
      return defType?.id === 9 ? '' : defType?.id;
    } else {
      return '';
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadMeasures(page * pageSize);
  };
  const editItem = React.useCallback(
    (nm: any) => () => {
      setSelMeasure(nm);
      setModalIsOpen(true);
    },
    []
  );
  useEffect(() => {
    loadMeasures();
    loadAllInstitutions();
  }, []);
  useEffect(() => {
    loadMeasures();
  }, [term, defaultInstitution, title, defType]);
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions(1);
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };
  const loadMeasures = async (offset: any = 0) => {
    const resp = await getRegistersAdmin(
      pageSize,
      offset,
      term,
      defaultInstitution?.id ?? '',
      '',
      title,
      returnType()
    );

    if (resp?.status) {
      setIsLoading(false);
      setRegisters(resp?.data?.measures);
      setRowCountState(resp?.data?.total);
    }
  };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'pid',
        headerName: 'Идентификација прописа',
        maxWidth: 260,
        minWidth: 180,
        flex: 1,
        sortable: false,
        renderCell: (params) => {
          return params?.row?.fid || params?.row?.pid;
        },
      },
      {
        field: 'title',
        headerName: 'Назив',
        minWidth: 180,
        flex: 1,
      },
      {
        field: 'user',
        headerName: 'Корисник',
        maxWidth: 150,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'fid',
        headerName: 'Тип прописа',
        maxWidth: 150,
        minWidth: 150,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          if (params?.row?.outOfForce) {
            return 'укинут';
          }
          return params?.row?.fid ? 'постојећи' : 'планиран';
        },
      },
      {
        field: 'institution',
        headerName: 'Институција',
        maxWidth: 150,
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) =>
          params?.row?.outOfForce
            ? [<div className="h-[50px]"></div>]
            : [
                <GridActionsCellItem
                  icon={<FaUsersCog style={{ width: 20, height: 20 }} />}
                  sx={{ width: 50, height: 50 }}
                  label="Ажурирај"
                  onClick={editItem(params.row)}
                />,
              ],
      },
    ],
    [editItem]
  );
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Промена надлежности
        </h1>
        <HelpButton onClick={() => setHelpModal(true)} />
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 ">
        <h1 className="text-[#04438bae] text-3xl font-bold">Претрага</h1>
        <div className="w-full mt-6 flex items-center gap-12">
          <div className="flex flex-col items-start gap-3">
            <p className="w-[180px] text-sm text-[#404040]">
              По Идентификацији прописа
            </p>
            <TextField
              label="Идентификација прописа"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[180px] text-sm text-[#404040]">
              По називу прописа
            </p>
            <TextField
              label="Назив прописа"
              id="outlined-size-small"
              size="small"
              autoComplete="off"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="flex flex-col items-start gap-3">
            <p className="w-[140px] text-sm text-[#404040]">По Институцији</p>

            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultInstitution}
              size="small"
              onChange={(event: any, newValue: any) =>
                setDefaultInstitution(newValue)
              }
              id="controllable-states-demo"
              options={institutions}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Институција" />
              )}
            />
          </div>
          <div className="flex flex-col items-start gap-3">
            <p className="w-[140px] text-sm text-[#404040]">Тип прописа</p>

            <Autocomplete
              disableClearable
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defType}
              size="small"
              onChange={(event: any, newValue: any) => setDefType(newValue)}
              id="controllable-states-demo"
              options={[
                { id: 9, name: 'Сви' },
                { id: 0, name: 'Планирани' },
                { id: 1, name: 'Постојећи' },
              ]}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Тип прописа" />
              )}
            />
          </div>
        </div>
        <div className="mt-5 h-fit w-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            autoHeight={true}
            getRowHeight={() => 'auto'}
            rows={registers}
            columns={columns}
            style={{ zIndex: 0 }}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowClassName={(params) => {
              console.log(params?.row.outOfForce);
              return params?.row?.outOfForce ? 'redRow' : '';
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи са задатим критеријумом
                </Stack>
              ),
            }}
          />
        </div>
      </div>
      <ChangeInstUserOnNatMeaModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        confirmation={loadMeasures}
        selMeasure={selMeasure}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'changeInstitution'}
      />
    </div>
  );
}

export default ChangeInstitution;
