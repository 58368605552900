import { Stack, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { deleteOJ, getOfficialJournals } from '../../../server/server';
import { notif } from '../../utils/util';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function OJManagement() {
	let navigate = useNavigate();
	const [term, setTerm] = useState('');
	const [journals, setJournals] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [rowCountState, setRowCountState] = useState(6);
	const [isLoading, setIsLoading] = useState(true);
	const [helpModal, setHelpModal] = useState(false);
	const closeHelpModal = () => {
		setHelpModal(false);
	};

	const deleteItem = React.useCallback(
		(id: GridRowId) => () => {
			removeTheOJ(id);
			setTimeout(() => {
				setJournals((prevRows: any) => prevRows.filter((row: any) => row.id !== id));
			});
		},
		[]
	);
	const removeTheOJ = async (id: any) => {
		const resp = await deleteOJ(id);
		if (resp.status === 200) {
			notif('Службени гласник успешно обрисан', 'success');
		} else {
			notif('Службени гласник није обрисан', 'error');
		}
	};
	const loadOJ = async (offset: any = 0) => {
		const resp = await getOfficialJournals(offset, 8, term);
		if (resp?.status === 200) {
			setIsLoading(false);
			setRowCountState(resp.data?.total);
			setJournals(resp.data?.journals);
		}
	};
	const setPageAndCall = (page: any) => {
		setPage(page);
		loadOJ(page * pageSize);
	};
	const editUser = React.useCallback(
		(id: GridRowId) => () => {
			navigate('/administration/oj/edit?id=' + id);
		},
		[]
	);

	const columns = React.useMemo<GridColumns<any>>(
		() => [
			{
				field: 'name',
				headerName: 'Назив',
				flex: 0.1,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 120,
				getActions: (params) => [
					<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20 }} />} sx={{ width: 50, height: 50 }} label='Обриши' onClick={deleteItem(params.id)} />,
					<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} sx={{ width: 50, height: 50 }} label='Ажурирај' onClick={editUser(params.id)} />,
				],
			},
		],
		[deleteItem, editUser]
	);
	useEffect(() => {
		loadOJ();
	}, []);

	useEffect(() => {
		loadOJ();
	}, [term]);
	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<div className='flex gap-6'>
				<h1 className='text-[#04438b] text-4xl font-bold'>Управљање Службеног Гласника</h1>
				<HelpButton onClick={() => setHelpModal(true)} />
			</div>
			<div className='rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 '>
				<h1 className='text-[#04438bae] text-3xl font-bold'>Претрага</h1>
				<div className='w-full mt-6 flex items-center gap-12'>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По Називу</p>
						<TextField label='Назив сл гласника' id='outlined-size-small' size='small' autoComplete='off' value={term} onChange={(e) => setTerm(e.target.value)} />
					</div>
				</div>
				<div className='mt-5 h-[530px] w-full'>
					<DataGrid
						rowCount={rowCountState}
						loading={isLoading}
						pagination
						disableColumnMenu
						paginationMode='server'
						onPageChange={(newPage) => setPageAndCall(newPage)}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						page={page}
						pageSize={pageSize}
						rows={journals}
						columns={columns}
						style={{ zIndex: 0 }}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						components={{
							NoRowsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје службени гласници са задатим критеријумом
								</Stack>
							),
							NoResultsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје службени гласници са задатим критеријумом
								</Stack>
							),
						}}
					/>
				</div>
			</div>
			<HelpPageModal modalIsOpen={helpModal} closeModal={closeHelpModal} name={'ojManagement'} />
		</div>
	);
}

export default OJManagement;
