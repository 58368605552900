import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ChooseNatRegisterModal from '../modals/ChooseNatRegisterModal';
import {
  FaQuestionCircle,
  FaSave,
  FaSearch,
  FaSearchMinus,
} from 'react-icons/fa';
import DynamicTable from './srceens/finExpense/dynamic-table';
import {
  addExpenditure,
  deleteExpenditure,
  getExpenditureTypes,
  getNMeasureInfo,
} from '../../server/server';
import { notif } from '../utils/util';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FinTable from '../views/screens/FinTable';
import FinantialTable from './srceens/finExpense/fin-table';
import { TabPanel } from '@mui/lab';
import InsertFinancing from './InsertFinancing';
import InsertFinExpense from './InsertFinExpense';
import HelpPageModal from '../modals/HelpPageModal';
import FinancingInfo from './FinancingInfo';

function FinancingMain() {
  const [searchParams] = useSearchParams();
  const [selRegister, setSelRegister] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [eid, setEid] = useState<any>(null);
  const [value, setValue] = useState(0);
  const [showTab, setShowTab] = useState(false);
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const loadNMI = async (id: any) => {
    const resp = await getNMeasureInfo(id);
    if (resp?.status === 200) {
      setSelRegister(resp.data);
    }
  };
  useEffect(() => {
    let eId = searchParams.get('id');
    if (eId) {
      setEid(eid);
      loadNMI(eId);
    }
  }, []);
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          Образац за процену финансијских ефеката НПАА
        </h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => setHelpModal(true)}
        >
          <p className="rounded-full flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] min-w-fit max-w-[1400px] p-8 pb-4  mt-8">
        <div className="flex flex-col">
          {selRegister ? (
            <p className="text-[#04438b] w-full font-bold mb-6 text-lg break-word">
              {selRegister?.title}
            </p>
          ) : (
            <div>
              <p className="w-[260px] text-[#4a4a4a] mb-2">
                Одабир Националног прописа
              </p>
              <button
                className="h-[40px] w-[500px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer"
                onClick={() => setModalIsOpen(true)}
              >
                {selRegister ? (
                  <div className="flex px-4 justify-start text-left items-center">
                    <FaSearchMinus className="fill-[#404040]" />
                    <p className="text-darkBlue w-full pl-3">
                      {selRegister?.title}
                    </p>
                  </div>
                ) : (
                  <div className="flex px-4 justify-between items-center">
                    <FaSearch className="fill-[#404040]" />
                    <p className="text-[#404040] w-full pl-3">
                      Одабир Националног прописа
                    </p>
                  </div>
                )}
              </button>{' '}
            </div>
          )}
          {selRegister?.institution && (
            <p className="leading-10 text-start text-lg text-[#04438b]">
              Надлежна институција
              <span className="font-bold">: {selRegister?.institution}</span>
            </p>
          )}
          {selRegister?.celex && (
            <p className="leading-10 text-start text-lg text-[#04438b]">
              Celex
              <span className="font-bold">: {selRegister?.celex}</span>
            </p>
          )}
        </div>
        {selRegister && (
          <div className="mt-4">
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={(e, newValue) => setValue(newValue)}
                  aria-label="basic tabs example"
                >
                  <Tab label="Општи подаци" {...a11yProps(0)} />
                  {showTab && <Tab label="Унос издатака" {...a11yProps(1)} />}
                  {showTab && (
                    <Tab label="Унос финансирања" {...a11yProps(2)} />
                  )}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <FinancingInfo
                  selRegister={selRegister}
                  eid={searchParams.get('id')}
                  setShowTab={setShowTab}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <InsertFinExpense
                  selRegister={selRegister}
                  eid={searchParams.get('id')}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <InsertFinancing selRegister={selRegister} />
              </TabPanel>
            </Box>
          </div>
        )}
      </div>
      <ChooseNatRegisterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setSelRegister={setSelRegister}
        modalTitle={'Одабир националног прописа'}
        type={''}
      />
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'insertFinancing'}
      />
    </div>
  );
}

export default FinancingMain;
