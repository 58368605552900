import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { notif } from '../../utils/util';
import {
  editUser,
  getAdministrationUserInsert,
  getAllRoles,
  getDefaultRole,
  getSettingByName,
  getUserById,
  insertUser,
  updateSettings,
} from '../../../server/server';
import {
  Autocomplete,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { FaBook, FaQuestion, FaQuestionCircle, FaSave } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usernameMap, validator } from '../../../common/constaints';
import HelpPageModal from '../../modals/HelpPageModal';
function InsertUser() {
  const navigate = useNavigate();
  type Role = {
    [key: string]: any;
    id?: string;
    name?: string;
  };
  const [defaultRole, setDefaultRole] = useState<any>([]);
  const [helpModal, setHelpModal] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [institutions, setInstitutions] = useState<any>([]);
  const [defaultInstitution, setDefaultInstitution] = useState<any>(null);
  const [defaultGroups, setDefaultGroups] = useState<any>(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRetry, setPasswordRetry] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [note, setNote] = useState('');
  const [title, setTitle] = useState('');
  const [errorMap, setErrorMap] = useState({
    firstName: '',
    lastName: '',
    note: '',
    email: '',
    password: '',
    defaultRole: '',
    defaultInstitution: '',
    passwordRetry: '',
    passwordsDontMatch: '',
    phoneNumber: '',
    username: '',
    sp: '',
  });
  const [userCount, setUserCount] = useState(0);
  const [groups, setGroups] = useState<any>([]);
  const handleChange = (e: SelectChangeEvent) => {
    setErrorMap({ ...errorMap, defaultRole: '' });

    const role = roles.find((r) => r?.name === e.target.value);
    console.log('a', role);
    setUsername(
      usernameMap[role?.name as keyof typeof usernameMap] + userCount
    );
    setDefaultRole(role as any);
  };

  const handleInstChange = (e: any, prm: any) => {
    setErrorMap({ ...errorMap, defaultInstitution: '' });
    setDefaultInstitution(prm as any);
  };
  // const handleDefaultRole = async () => {
  //   const settings = await getSettingByName('defaultRole');

  //   const resp = await updateSettings(
  //     settings?.data?.id as string,
  //     defaultRole?.id as string
  //   );
  //   if (resp.status === 201) {
  //     notif('Default role is set to ' + defaultRole?.name, 'success');
  //   } else {
  //     notif('Setting default role failed', 'error');
  //   }
  // };
  const loadAll = async () => {
    const response = await getAdministrationUserInsert();

    if (response?.status === 200) {
      setRoles(response?.data?.roles);
      setInstitutions(response?.data?.institutions);
      setUserCount(response?.data?.userCount);
      setGroups(response?.data?.groups);
    }
  };

  useEffect(() => {
    loadAll();
  }, []);

  const closeHelpModal = () => {
    setHelpModal(false);
  };
  const createUser = async () => {
    const resp = await insertUser({
      firstName,
      lastName,
      email,
      password,
      username,
      note,
      title,
      roles: defaultRole?.map((r: any) => r?.id),
      workGroups: defaultGroups?.map((g: any) => g?.groupNo),
      institutionId: defaultInstitution?.id,
      phoneNumber,
    });
    if (resp.status === 201) {
      notif('Корисник успешно креиран', 'success');
      navigate('/administration/user/management');
    } else if (resp.status === 409) {
      setErrorMap({ ...errorMap, username: resp?.data?.message });
      notif('Корисник није креиран', 'error');
    } else {
      notif('Корисник није креиран', 'error');
    }
  };

  const handleUserInsert = async () => {
    console.log('defaultRole:', defaultRole);
    console.log('defaultGroups:', defaultGroups);

    if (defaultRole) {
      console.log(
        'Mapped roles:',
        defaultRole.map((r: any) => r?.name)
      );
      console.log(
        'Role exists:',
        defaultRole
          .map((r: any) => r?.name)
          .includes('Секретар преговарачке групе')
      );
    } else {
      console.log('defaultRole is undefined or null');
    }

    if (defaultGroups) {
      console.log('defaultGroups length:', defaultGroups.length);
    } else {
      console.log('defaultGroups is undefined or null');
    }

    console.log(
      defaultRole
        .map((r: any) => r?.name)
        .includes('Секретар преговарачке групе') &&
        (!defaultGroups || defaultGroups.length === 0)
        ? 'Поље је обавезно'
        : ''
    );
    const validationResults = {
      ...errorMap, // Spread the existing errorMap to include existing properties
      firstName: validator(firstName, true, true),
      lastName: validator(lastName, true, true),
      note: '',
      email: validator(email, true, false),
      phoneNumber: validator(phoneNumber, true, false),
      username: validator(username, true, false),
      defaultInstitution: validator(defaultInstitution?.id, true, false),
      defaultRole: validator(defaultRole[0]?.id, true, false),
      sp:
        defaultRole
          .map((r: any) => r?.name)
          .includes('Секретар преговарачке групе') &&
        (!defaultGroups || defaultGroups.length === 0)
          ? 'Поље је обавезно'
          : '',
    };
    setErrorMap(validationResults); // Set the error map with the validation results
    console.log('123', validationResults);
    if (username.includes(' ')) {
      setUsername(username.replace(' ', '_'));
    }

    if (password === passwordRetry) {
      if (!Object.values(validationResults).some((value) => value)) {
        // Check if all validations are false
        createUser(); // Proceed with creating the user
      }
    } else {
      setErrorMap({
        ...errorMap,
        passwordsDontMatch: 'Лозинке се не поклапају',
      });
    }
  };

  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">Нов Корисник</h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => setHelpModal(true)}
        >
          <p className="rounded-full   flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>
      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 ">
        <div className="flex gap-28">
          <div className="flex gap-8 flex-col">
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Име</p>
              <TextField
                label="Име"
                id="outlined-size-small"
                type="firstName"
                name="firstName"
                helperText={errorMap?.firstName}
                error={!!errorMap?.firstName as boolean}
                onFocus={() => setErrorMap({ ...errorMap, firstName: '' })}
                size="small"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Презиме</p>
              <TextField
                label="Презиме"
                type="lastName"
                name="lastName"
                id="outlined-size-small"
                size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                helperText={errorMap?.lastName}
                error={!!errorMap?.lastName as boolean}
                onFocus={() => setErrorMap({ ...errorMap, lastName: '' })}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Лозинка</p>
              <TextField
                label="Лозинка"
                type="password"
                name="password"
                id="outlined-size-small"
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                helperText={errorMap?.password}
                error={
                  (!!errorMap?.password ||
                    !!errorMap?.passwordsDontMatch) as boolean
                }
                onFocus={() =>
                  setErrorMap({
                    ...errorMap,
                    password: '',
                    passwordsDontMatch: '',
                  })
                }
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Понови лозинку</p>
              <TextField
                label="Понови лозинку"
                type="password"
                name="password"
                id="outlined-size-small"
                size="small"
                value={passwordRetry}
                onChange={(e) => setPasswordRetry(e.target.value)}
                helperText={
                  errorMap?.passwordsDontMatch
                    ? errorMap?.passwordRetry
                      ? 'Поље је обавезно'
                      : 'Лозинке се не поклапају'
                    : ''
                }
                error={
                  (!!errorMap?.passwordRetry ||
                    !!errorMap?.passwordsDontMatch) as boolean
                }
                onFocus={() =>
                  setErrorMap({
                    ...errorMap,
                    passwordRetry: '',
                    passwordsDontMatch: '',
                  })
                }
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Напомена</p>
              <textarea
                id="message"
                rows={4}
                className="block p-2.5 w-full text-sm text-black bg-gray-50 rounded-lg border border-[#C0C0C0]"
                placeholder="Напомена..."
                value={note}
                onChange={(e) => setNote(e.target.value)}
                onFocus={() => setErrorMap({ ...errorMap, note: '' })}
              ></textarea>
              {/* {errorMap?.note && (
                <FormHelperText sx={{ color: 'red' }}>
                  {errorMap?.note}
                </FormHelperText>
              )} */}
            </div>
          </div>
          <div className="flex gap-8 flex-col">
            <div className="flex  items-center gap-6">
              <p className="w-[140px]">Ниво коришћења</p>
              <div className="flex flex-col">
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  // options={roles || []}
                  // getOptionLabel={(option) => option.name || ''}
                  // onChange={(event: any, newValue: any) =>
                  //   console.log(event, newValue)
                  // }
                  sx={{ maxWidth: 300, minWidth: 300 }}
                  options={roles?.map((option) => option?.name as any)}
                  value={
                    defaultRole?.map((feature: any) => feature.name) as any
                  }
                  onChange={(e: any, data: any) => {
                    setDefaultRole(
                      roles?.filter((role) => data?.includes(role?.name as any))
                    );
                    if (defaultRole?.length === 0) {
                      setUsername(userCount?.toString());
                    }
                  }}
                  freeSolo
                  size="small"
                  // value={defaultRole}
                  renderInput={(params) => (
                    <TextField {...params} label="Ниво" placeholder="Ниво" />
                  )}
                />
                {errorMap?.defaultRole && (
                  <FormHelperText sx={{ color: 'red' }}>
                    {errorMap?.defaultRole}
                  </FormHelperText>
                )}
              </div>
            </div>
            {defaultRole
              .map((r: any) => r.id)
              .includes('f8709ff6-fffa-419a-8b8d-2f16330fa244') && (
              <div className="flex  items-center gap-6">
                <p className="w-[140px]">Секретар групе</p>
                <div className="flex flex-col">
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    // options={roles || []}
                    // getOptionLabel={(option) => option.name || ''}
                    // onChange={(event: any, newValue: any) =>
                    //   console.log(event, newValue)
                    // }
                    onFocus={() => setErrorMap({ ...errorMap, sp: '' })}
                    sx={{ maxWidth: 300, minWidth: 300 }}
                    options={groups?.map((option: any) => option?.name as any)}
                    value={
                      defaultGroups?.map((feature: any) => feature.name) as any
                    }
                    onChange={(e: any, data: any) => {
                      setDefaultGroups(
                        groups?.filter((g: any) =>
                          data?.includes(g?.name as any)
                        )
                      );
                    }}
                    freeSolo
                    size="small"
                    // value={defaultRole}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Секретар групе"
                        placeholder="Секретар групе"
                      />
                    )}
                  />
                  {errorMap?.sp && (
                    <FormHelperText sx={{ color: 'red' }}>
                      {errorMap?.sp}
                    </FormHelperText>
                  )}
                </div>
              </div>
            )}
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Корисничко име</p>
              <TextField
                label="Корисничко име"
                type="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                helperText={errorMap?.username}
                error={!!errorMap?.username as boolean}
                onFocus={() => setErrorMap({ ...errorMap, username: '' })}
                id="outlined-size-small"
                sx={{ minWidth: 300 }}
                size="small"
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Институција</p>
              <div className="flex flex-col">
                <Autocomplete
                  renderOption={(props, option: any) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  value={defaultInstitution}
                  size="small"
                  onChange={(event: any, newValue: any) =>
                    handleInstChange(event, newValue)
                  }
                  id="controllable-states-demo"
                  options={institutions || []}
                  getOptionLabel={(option) => option?.name || ''}
                  sx={{ minWidth: 300 }}
                  renderInput={(params) => (
                    <TextField
                      error={!!errorMap?.defaultInstitution as boolean}
                      {...params}
                      label="Институција"
                    />
                  )}
                />
                {errorMap?.defaultInstitution && (
                  <FormHelperText sx={{ color: 'red' }}>
                    {errorMap?.defaultInstitution}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Звање</p>
              <TextField
                label="Звање"
                type="title"
                name="title"
                id="outlined-size-small"
                size="small"
                sx={{ minWidth: 300 }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Email</p>
              <TextField
                label="Email"
                type="email"
                name="email"
                sx={{ minWidth: 300 }}
                id="outlined-size-small"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={errorMap?.email}
                error={!!errorMap?.email as boolean}
                onFocus={() => setErrorMap({ ...errorMap, email: '' })}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[140px]">Телефон</p>
              <TextField
                label="Телефон"
                type="phone"
                name="phone"
                id="outlined-size-small"
                size="small"
                sx={{ minWidth: 300 }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                helperText={errorMap?.phoneNumber}
                error={!!errorMap?.phoneNumber as boolean}
                onFocus={() => setErrorMap({ ...errorMap, phoneNumber: '' })}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-12 items-center">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#0F2765' }}
            endIcon={<FaSave />}
            onClick={handleUserInsert}
          >
            Сачувај
          </Button>
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'insertUser'}
      />
    </div>
  );
}

export default InsertUser;
