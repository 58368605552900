import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import {
  getAllInstitutions,
  getUsersByInstitution,
  updateNatMeasJurastiction,
} from '../../server/server';
import { notif } from '../utils/util';

function ChangeInstUserOnNatMeaModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  confirmation,
  selMeasure,
  isInstDisabled = false,
}: any) {
  useEffect(() => {
    ReactModal.setAppElement('#root');
    loadAllInstitutions();
  }, []);
  const [institutions, setInstitutions] = useState<any>([]);
  const [defaultInstitution, setDefaultInstitution] = useState<any>(null);
  const [users, setUsers] = useState<any>([]);
  const [defUser, setDefUser] = useState<any>(null);

  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions(1);
    if (resp?.status === 200) {
      setInstitutions(resp?.data);
    }
  };

  useEffect(() => {
    if (selMeasure?.institutionId && institutions?.length > 0)
      setDefaultInstitution(
        institutions.find((i: any) => i.id === selMeasure?.institutionId)
      );
  }, [selMeasure, institutions]);

  //   useEffect(() => {
  //     if (selMeasure?.userId && users?.length > 0)
  //       console.log(
  //         'ageage',
  //         users.find((u: any) => u.id === selMeasure?.userId)
  //       );
  //   }, [selMeasure, users]);

  useEffect(() => {
    if (defaultInstitution?.id) loadUsersByInstId(defaultInstitution?.id);
  }, [defaultInstitution]);

  const loadUsersByInstId = async (id: any) => {
    const resp = await getUsersByInstitution(id);

    if (resp?.status === 200) {
      setDefUser(
        resp?.data.find((u: any) => u.id === selMeasure?.userId) ?? null
      );
      setUsers(resp?.data);
    }
  };

  const update = async () => {
    const resp = await updateNatMeasJurastiction({
      id: selMeasure?.id,
      institution: defaultInstitution?.id,
      user: defUser?.id,
    });

    if (resp.status === 201) {
      notif('Надлежност прописа успешно ажурирана', 'success');
      closeModal();
      confirmation();
    } else {
      notif('Надлежност прописа није ажурирана', 'error');
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 600,
          height: 450,
          padding: 0,
          opacity: 1,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
        <div className="flex items-center flex-col justify-center">
          <p className="text-[#04438b] text-2xl font-bold">
            Промена надлежности
          </p>
          <p className="text-[#81848f] text-sm">{selMeasure?.title}</p>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col items-start gap-1">
            <p className="w-[240px]">Надлежна институција</p>
            <div className="flex flex-col">
              <Autocomplete
                disabled={isInstDisabled}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={defaultInstitution}
                size="small"
                onChange={(event: any, newValue: any) =>
                  setDefaultInstitution(newValue)
                }
                id="controllable-states-demo"
                options={institutions}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label="Институција" />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col items-start gap-1">
            <p className="w-[240px]">Надлежни корисник</p>
            <div className="flex flex-col">
              <Autocomplete
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                value={defUser}
                size="small"
                onChange={(event: any, newValue: any) => setDefUser(newValue)}
                id="controllable-states-demo"
                options={users}
                getOptionLabel={(option) => option?.name}
                sx={{ width: 450 }}
                renderInput={(params) => (
                  <TextField {...params} label="Корисник" />
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center w-full mt-6">
          <div
            className="bg-darkBlue cursor-pointer flex items-center justify-center text-white hover:opacity-90 text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={update}
          >
            Потврди
          </div>
          <div
            className="border border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => closeModal()}
          >
            Oткажи
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChangeInstUserOnNatMeaModal;
