import { Autocomplete, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridCellParams, GridColumns, GridRenderCellParams, GridRow, GridRowId, GridRowParams } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import download from 'downloadjs';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaFileWord, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GetViewsQInstitution, getAllInstitutions, getInstCelexViews, getInstitutionCelexFile, getQFile, getRegistersManagement } from '../../../server/server';
import { quarterYearEncoder } from '../../utils/util';
import HelpButton from '../../../common/HelpButton';
import HelpPageModal from '../../modals/HelpPageModal';

function QuartalView() {
	let navigate = useNavigate();
	const [term, setTerm] = useState('');
	const [institutions, setInstitutions] = useState<any>([]);
	const [registers, setRegisters] = useState([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(6);
	const [rowCountState, setRowCountState] = useState(6);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);
	const [registerType, setRegisterType] = useState<any>({
		id: 0,
		name: 'Сви прописи',
	});
	const [defaultInstitution, setDefaultInstitution] = useState<any | null>({
		id: null,
		name: 'Све институције',
	});
	const [fromDate, setFromDate] = React.useState<any>(null);
	const [toDate, setToDate] = React.useState<any>(null);
	const [fromYear, setFromYear] = useState('');
	const [toQ, setToQ] = useState('');
	const [fromQ, setFromQ] = useState('');
	const [toYear, setToYear] = useState('');
	const [fileLoading, setFileLoading] = useState(false);
	const [helpModal, setHelpModal] = useState(false);
	const closeHelpModal = () => {
		setHelpModal(false);
	};
	const handleInstChange = (e: any, prm: any) => {
		setDefaultInstitution(prm as any);
	};
	const deleteUser = React.useCallback(
		(id: GridRowId) => () => {
			setTimeout(() => {
				setRegisters((prevRows: any) => prevRows.filter((row: any) => row.id !== id));
			});
		},
		[]
	);
	const loadRegisters = async (offset: any = 0) => {
		const resp = await GetViewsQInstitution(pageSize, offset, defaultInstitution?.id ?? '', registerType?.id, quarterYearEncoder(fromYear, fromQ), quarterYearEncoder(toYear, toQ));

		if (resp?.status) {
			setIsLoading(false);
			setRegisters(resp?.data?.items);
			setRowCountState(resp?.data?.total);
		}
	};

	const loadInstitutions = async () => {
		const resp = await getAllInstitutions(1);
		if (resp?.status) {
			setInstitutions([
				{
					id: null,
					name: 'Све институције',
				},
				...resp?.data,
			]);
		}
	};

	const setPageAndCall = (page: any) => {
		setPage(page);
		loadRegisters(page * pageSize);
	};
	const editUser = React.useCallback(
		(id: GridRowId) => () => {
			navigate('/register/edit?id=' + id);
		},
		[]
	);
	function CustomHeader({ label }: any) {
		const lines = label.split('\n');
		return (
			<>
				{lines.map((line: any, index: any) => (
					<div key={index}>{line}</div>
				))}
			</>
		);
	}

	const columns = React.useMemo<GridColumns<any>>(
		() => [
			{
				field: 'pid',
				headerName: 'Шифра  план. прописа',
				maxWidth: 160,

				flex: 1,
			},
			{
				field: 'fid',
				headerName: 'Шифра пост. прописа',
				maxWidth: 160,
				flex: 1,
			},
			{
				field: 'title',
				headerName: 'Назив прописа',
				minWidth: 180,
				flex: 1,
			},
			{
				field: 'type',
				headerName: 'Тип прописа',
				maxWidth: 80,
				flex: 1,
			},
			{
				field: 'npi',
				headerName: `Место у структури НПАА`,
				maxWidth: 110,
				minWidth: 110,
				renderCell: (params) => <div className='font-bold text-[#0f2765]'>{params?.row?.npi}</div>,
				flex: 1,
			},
			{
				field: 'planAdoptByGov',
				headerName: ` Рок за утврђивање/усвајање од стране Владе/министарства аката`,
				maxWidth: 200,
				minWidth: 200,
				flex: 1,
			},
			{
				field: 'adopByGovDate',
				headerName: `Датум утврђивања предлога закона, стратегије или усвајања стратегије од стране Владе/министарства`,
				maxWidth: 200,
				flex: 1,
			},
			{
				field: 'oj',
				headerName: `Број 'Службеног гласника РС'`,
				maxWidth: 130,
				flex: 1,
				renderCell: (params) => {
					const ojt = params?.row?.oj.split('\n');
					return (
						<div>
							<p className='font-bold text-[#0f2765]'>{ojt[0]}</p>
							<p>{ojt[1]}</p>
							<p>{ojt[2]}</p>
						</div>
					);
				},
			},
			{
				field: 'obligationFulfilled',
				headerName: `Обавеза извршена да или не`,
				maxWidth: 110,
				minWidth: 110,
				flex: 1,
			},
		],
		[deleteUser, editUser]
	);
	useEffect(() => {
		loadRegisters();
		loadInstitutions();
	}, []);

	useEffect(() => {
		loadRegisters();
	}, [term, defaultInstitution, registerType, fromQ, toQ, fromYear, toYear]);

	const typeRegister = [
		{ id: 0, name: 'Сви прописи' },
		{ id: 1, name: 'Постојећи прописи' },
		{ id: 2, name: 'Планирани прописи' },
	];

	const handleTypeRegisterChange = (e: SelectChangeEvent) => {
		const t = typeRegister.find((r) => r?.name === e.target.value);
		setRegisterType(t as any);
	};

	const downloadWord = async () => {
		if (!defaultInstitution?.id) {
			setError(true);
		}
		setFileLoading(true);
		const resp = await getQFile(defaultInstitution?.id, quarterYearEncoder(fromYear, fromQ), quarterYearEncoder(toYear, toQ));
		if (resp?.status === 200) {
			setFileLoading(false);
			download(resp?.data?.data, resp?.data?.name, resp?.data?.type);
		}
	};
	const QUARTER_MAP = ['I', 'II', 'III', 'IV'];
	const YEAR_MAP = [1, 2, 3, 4].map((y, i) => {
		return new Date().getFullYear() + i;
	});
	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<div className='flex gap-6'>
				<h1 className='text-[#04438b] text-4xl font-bold'>КВАРТАЛНА РЕАЛИЗАЦИЈА NPAA по министарствима и посебним организацијама</h1>
				<HelpButton onClick={() => setHelpModal(true)} />
			</div>
			<div className='rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 '>
				<h1 className='text-[#04438bae] text-3xl font-bold'>Претрага</h1>
				<div className='w-full flex justify-between items-end  mt-6'>
					<div className='w-full flex items-center gap-12'>
						<div className='flex flex-col items-start gap-3'>
							<p className='w-[160px] text-sm text-[#404040]'>По Институцији</p>
							<Autocomplete
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									);
								}}
								value={defaultInstitution}
								size='small'
								onChange={(event: any, newValue: any) => handleInstChange(event, newValue)}
								onFocus={() => setError(false)}
								id='controllable-states-demo'
								options={institutions}
								getOptionLabel={(option) => option?.name}
								sx={{ width: 300 }}
								renderInput={(params) => <TextField {...params} label='Институција' />}
							/>
						</div>
						<div className='flex flex-col items-start gap-3'>
							<p className='w-[160px] text-sm text-[#404040]'>Тип прописа</p>
							<FormControl size='small' sx={{ minWidth: 140 }}>
								<InputLabel id='demo-select-small'>Тип прописа</InputLabel>
								<Select labelId='demo-select-small' id='demo-select-small' value={registerType?.name} onChange={handleTypeRegisterChange} label='Тип прописа' className='capitalize'>
									{typeRegister?.map((t) => {
										return (
											<MenuItem value={t?.name} className='capitalize' key={t?.id}>
												{t?.name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>

						<div className='flex pt-4 items-end'>
							<FormControl size='small' sx={{ m: 1, width: 85 }}>
								{/* <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel> */}
								<p className='w-[160px] text-sm text-[#404040]'>Од Квартала</p>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={fromQ || ''}
									onChange={(e) => setFromQ(e.target.value)}
								// label="Квартал"
								>
									{QUARTER_MAP?.map((q) => {
										return <MenuItem value={q}>{q}</MenuItem>;
									})}
								</Select>
							</FormControl>
							<FormControl size='small' sx={{ m: 1, width: 85 }}>
								{/* <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel> */}
								<p className='w-[160px] text-sm text-[#404040]'>Од Године</p>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={fromYear || ''}
									onChange={(e) => setFromYear(e.target.value)}
								// label="Година"
								>
									{YEAR_MAP?.map((y) => {
										return <MenuItem value={y}>{y}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</div>

						<div className='flex pt-4 items-end'>
							<FormControl size='small' sx={{ m: 1, width: 85 }}>
								{/* <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel> */}
								<p className='w-[160px] text-sm text-[#404040]'>До Квартала</p>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={toQ || ''}
									onChange={(e) => setToQ(e.target.value)}
								// label="Квартал"
								>
									{QUARTER_MAP?.map((q) => {
										return <MenuItem value={q}>{q}</MenuItem>;
									})}
								</Select>
							</FormControl>
							<FormControl size='small' sx={{ m: 1, width: 85 }}>
								{/* <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel> */}
								<p className='w-[160px] text-sm text-[#404040]'>До Године</p>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={toYear || ''}
									onChange={(e) => setToYear(e.target.value)}
								// label="Година"
								>
									{YEAR_MAP?.map((y) => {
										return <MenuItem value={y}>{y}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</div>
					</div>
					{fileLoading ? <CircularProgress /> : <FaFileWord className='text-[#043e81ae] w-8 h-8 cursor-pointer hover:text-[#043e81]' onClick={downloadWord} />}
				</div>
				<div className='mt-5 h-fit w-full'>
					<DataGrid
						sx={{
							'& .MuiDataGrid-columnHeaderTitle': {
								textOverflow: 'clip',
								whiteSpace: 'break-spaces',
								textAlign: 'center',
								lineHeight: 1,
							},
							'& .MuiDataGrid-virtualScrollerRenderZone > div:nth-child(n) .MuiDataGrid-cell': {
								minHeight: '60px !important',
							},
							'& .MuiDataGrid-virtualScrollerRenderZone > div': {
								minHeight: '60px !important',
							},
						}}
						headerHeight={100}
						getRowHeight={() => 'auto'}
						disableColumnMenu={true}
						autoHeight={true}
						rowCount={rowCountState}
						loading={isLoading}
						pagination
						paginationMode='server'
						onPageChange={(newPage) => setPageAndCall(newPage)}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						page={page}
						pageSize={pageSize}
						rows={registers}
						columns={columns}
						style={{ zIndex: 0 }}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						components={{
							NoRowsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје прописи са задатим критеријумом
								</Stack>
							),
							NoResultsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје прописи са задатим критеријумом
								</Stack>
							),
						}}
					/>
				</div>
			</div>
			<HelpPageModal modalIsOpen={helpModal} closeModal={closeHelpModal} name={'quartalView'} />
		</div>
	);
}

export default QuartalView;
