import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { error } from 'console';
import React, { useEffect, useState } from 'react';
import { FaQuestionCircle, FaSave } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { editOJ, getOJbyId, insertOJ } from '../../../server/server';
import { notif } from '../../utils/util';
import HelpPageModal from '../../modals/HelpPageModal';

function InsertOfficialJournal() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [editId, setEditId] = useState('');
  const [title, setTitle] = useState('');
  const [titleEN, setTitleEN] = useState('');
  const [acronym, setAcronym] = useState('');
  const [acronymEN, setAcronymEN] = useState('');
  const [type, setType] = useState('existing');
  const [helpModal, setHelpModal] = useState(false);
  const [errorMap, setErrorMap] = useState({
    title: false,
    titleEN: false,
    acronym: false,
    acronymEN: false,
  });

  const createOJ = async () => {
    const resp = await insertOJ({
      name: title,
      nameEN: titleEN,
      acronym,
      acronymEN,
    });
    if (resp.status === 201) {
      notif('Службени гласник успешно креиран', 'success');
    } else {
      notif('Службени гласник није креиран', 'error');
    }
  };
  const editTheOJ = async () => {
    const resp = await editOJ({
      id: parseInt(editId),
      name: title,
      nameEN: titleEN,
      acronym,
      acronymEN,
    });
    if (resp.status === 201) {
      notif('Службени гласник успешно ажуриран', 'success');
    } else {
      notif('Службени гласник није ажуриран', 'error');
    }
  };

  const handleInsert = () => {
    setErrorMap({
      title: !title,
      titleEN: !titleEN,
      acronym: !acronym,
      acronymEN: !acronymEN,
    });
    if (title && titleEN && acronym && acronymEN) {
      if (editId) {
        editTheOJ();
      } else {
        createOJ();
      }
    }
  };

  const loadOjBy = async (id: any) => {
    const resp = await getOJbyId(id);
    if (resp.status === 200) {
      setTitle(resp?.data?.name);
      setTitleEN(resp?.data?.nameEN);
      setAcronym(resp?.data?.acronym);
      setAcronymEN(resp?.data?.acronymEN);
    }
  };

  useEffect(() => {
    let eId = searchParams.get('id');
    if (eId) {
      loadOjBy(eId);
      setEditId(eId);
    }
  }, []);
  const closeHelpModal = () => {
    setHelpModal(false);
  };
  return (
    <div className="py-10 px-2 overflow-auto gray-small-scroll h-full">
      <div className="flex gap-6">
        <h1 className="text-[#04438b] text-4xl font-bold">
          {editId ? 'Ажурирање' : 'Унос'} Службеног Гласника
        </h1>
        <div
          className="flex items-center justify-between gap-3 rounded-full border border-[#81848f] px-2 text-[#60626a] h-[25px] w-fit  hover:bg-[#303135] hover:text-white cursor-pointer"
          onClick={() => setHelpModal(true)}
        >
          <p className="rounded-full   flex items-center justify-center">
            <FaQuestionCircle />
          </p>
          <p className="">Упутство</p>
        </div>
      </div>

      <div className="rounded-lg border border-[#81848f3d] w-fit p-8 pb-4  mt-8 ">
        <div className="flex gap-28">
          <div className="flex gap-8 flex-col">
            <div className="flex items-center gap-6">
              <p className="w-[260px]">Назив службеног гласника</p>
              <TextField
                label="Назив службеног гласника"
                id="outlined-size-small"
                type="institutionName"
                name="institutionName"
                size="small"
                sx={{ width: 340 }}
                helperText={errorMap?.title ? 'Поље је обавезно' : ''}
                error={errorMap?.title as boolean}
                onFocus={() => setErrorMap({ ...errorMap, title: false })}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
                // error={errorMap?.firstName as boolean}
                // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
                // value={firstName}
                // onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[260px]">Назив службеног гласника - енглески</p>
              <TextField
                label="Назив службеног гласника - енглески"
                id="outlined-size-small"
                type="institutionNameEN"
                name="institutionNameEN"
                size="small"
                sx={{ width: 340 }}
                helperText={errorMap?.titleEN ? 'Поље је обавезно' : ''}
                error={errorMap?.titleEN as boolean}
                onFocus={() => setErrorMap({ ...errorMap, titleEN: false })}
                value={titleEN}
                onChange={(e) => setTitleEN(e.target.value)}
                // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
                // error={errorMap?.firstName as boolean}
                // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
                // value={firstName}
                // onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[260px]">Акроним</p>
              <TextField
                label="Акроним"
                id="outlined-size-small"
                type="acronym"
                name="acronym"
                size="small"
                sx={{ width: 340 }}
                helperText={errorMap?.acronym ? 'Поље је обавезно' : ''}
                error={errorMap?.acronym as boolean}
                onFocus={() => setErrorMap({ ...errorMap, acronym: false })}
                value={acronym}
                onChange={(e) => setAcronym(e.target.value)}
                // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
                // error={errorMap?.firstName as boolean}
                // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
                // value={firstName}
                // onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="flex items-center gap-6">
              <p className="w-[260px]">Акроним - енглески</p>
              <TextField
                label="Акроним - енглески"
                id="outlined-size-small"
                type="acronymEN"
                name="acronymEN"
                size="small"
                sx={{ width: 340 }}
                helperText={errorMap?.acronymEN ? 'Поље је обавезно' : ''}
                error={errorMap?.acronymEN as boolean}
                onFocus={() => setErrorMap({ ...errorMap, acronymEN: false })}
                value={acronymEN}
                onChange={(e) => setAcronymEN(e.target.value)}
                // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
                // error={errorMap?.firstName as boolean}
                // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
                // value={firstName}
                // onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end mt-12 items-center">
          <Button
            variant="contained"
            sx={{ backgroundColor: '#0F2765' }}
            endIcon={<FaSave />}
            onClick={handleInsert}
          >
            Сачувај
          </Button>
        </div>
      </div>
      <HelpPageModal
        modalIsOpen={helpModal}
        closeModal={closeHelpModal}
        name={'insertOJ'}
      />
    </div>
  );
}

export default InsertOfficialJournal;
