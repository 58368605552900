export const usernameMap = {
  Администратор: 'a',
  Координатор: 'k',
  'Уносилац података': 'up',
  'Секретар преговарачке групе': 'sp',
  'Одговорна особа': 'oo',
};
export const usernameMapCyrillic = {
  Администратор: 'А',
  Координатор: 'К',
  'Уносилац података': 'УП',
  'Секретар преговарачке групе': 'СП',
  'Одговорна особа': 'ОО',
};
export const roleMap = {
  Администратор: 0,
  Координатор: 1,
  'Уносилац података': 2,
  'Секретар преговарачке групе': 3,
  'Одговорна особа': 4,
};

// export const cyrilicValidation = (
//   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
//   setState: React.Dispatch<React.SetStateAction<string>>
// ) => {
//   setState(e.target.value.replace(/[a-zA-Z]/g, ''));
// };
type OptionsTypes = {
  isDefined: boolean;
  isCyrillic: boolean;
};
export const validator = (
  text: string,
  isDefined: boolean,
  isCyrillic: boolean
) => {
  if (isDefined && !text) {
    return 'Поље је обавезно';
  }

  if (isCyrillic && text.match(/[a-zA-Z]/g)) {
    return 'Латиница није дозвољена';
  }

  return '';
};
