import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import { getCelexInfo } from '../../server/server';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';

function GetJurInfoModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  confirmation,
  celex,
  isConfirmDisabled = false,
}: any) {
  const [celexRels, setCelexRels] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [rowCountState, setRowCountState] = useState(8);
  const [isLoading, setIsLoading] = useState(true);
  const [compactibilities, setCompactibilities] = useState([
    'није усклађен',
    'делимично усклађен',
    'поптпуно усклађен',
  ]);
  useEffect(() => {
    ReactModal.setAppElement('#root');
    if (celex) loadCelexInfo(celex);
  }, []);
  useEffect(() => {
    if (celex) loadCelexInfo(celex);
  }, [celex]);

  const loadCelexInfo = async (offset: any) => {
    const resp = await getCelexInfo(celex, offset);
    if (resp?.status) {
      setCelexRels(resp?.data?.items);
      setRowCountState(resp?.data?.total);
      setIsLoading(false);
    }
  };
  const setPageAndCall = (page: any) => {
    setPage(page);
    loadCelexInfo(page * pageSize);
  };
  const columns = React.useMemo<GridColumns<any>>(
    () => [
      {
        field: 'pid',
        headerName: 'Ознака прописа',
        minWidth: 140,
      },
      {
        field: 'title',
        headerName: 'Назив',
        minWidth: 190,
        flex: 1,
        // renderCell: (params) => <div>{params?.row.nationalMeasure?.title}</div>,
      },
      {
        field: 'compatibility',
        headerName: 'Усклађеност',
        width: 180,
        renderCell: (params) => (
          <div className="h-10">
            {compactibilities?.[params?.row.compatibility]}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      style={{
        overlay: {
          zIndex: 10,
        },
        content: {
          width: 1000,
          height: 720,
          padding: 0,
          opacity: 1,
          minWidth: 600,
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          maxHeight: '80%',
          border: 'none',
          boxShadow: 'none',
          overflow: 'hidden',
        },
      }}
    >
      <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-10">
        <div className="flex flex-col gap-0 items-center">
          <h1 className="text-[#04438b] text-3xl font-bold text-left ">
            {celex}
          </h1>
          <h4 className="text-blue-700 font-bold text-lg text-[#08305ddf]">
            Информације о прописима
          </h4>
        </div>

        <div className="flex flex-row justify-between items-center w-full mt-6 h-full">
          <DataGrid
            rowCount={rowCountState}
            loading={isLoading}
            pagination
            disableColumnMenu
            paginationMode="server"
            onPageChange={(newPage) => setPageAndCall(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            rows={celexRels}
            columns={columns}
            style={{ zIndex: 0 }}
            getRowHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                {
                  display: 'none',
                },
            }}
            rowsPerPageOptions={[5]}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Не постоје прописи
                </Stack>
              ),
            }}
          />
        </div>
        <div className="w-full flex justify-end">
          <div
            className="border mt-4 border-[#81848f] hover:bg-[#81848f] hover:text-white cursor-pointer flex items-center justify-center border-redError text-redError text-base font-semibold rounded-lg w-[250px] h-[55px]"
            onClick={() => closeModal()}
          >
            Oткажи
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default GetJurInfoModal;
