import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { useState, useEffect } from 'react';
import { FaSave, FaSearch, FaSearchMinus } from 'react-icons/fa';
import { useUser } from '../../../../auth/UseUser';
import { validator } from '../../../../common/constaints';
import {
  editNatMeasure,
  getAllInstitutions,
  getAllOfficialJournals,
  getEuMeasureBy,
  getNatMeasureBy,
} from '../../../../server/server';
import { notif } from '../../../utils/util';
import { useSearchParams } from 'react-router-dom';
import ChooseNatRegisterModal from '../../../modals/ChooseNatRegisterModal';
import { format, parseISO } from 'date-fns';
import srLocale from 'date-fns/locale/sr';

function EditNPI({ setParentTitle, setParentInstitution, setAvalible }: any) {
  const { user, setUser } = useUser();
  const [searchParams] = useSearchParams();
  const [journals, setJournals] = useState<any>([]);
  const [adopByGovDate, setAdopByGovDate] = useState<any>(null);
  const [dateOfOJ, setDateOfOJ] = useState<any>(null);
  const [inForceOJ, setInForceOJ] = useState<any>(null);
  const [inUseOJ, setInUseOJ] = useState<any>(null);
  const [OJNumber, setOJNumber] = useState<any>(null);
  const [OJPagesNumber, setOJPagesNumber] = useState<any>(null);
  const [institutions, setInstitutions] = useState<any>([]);
  const [selectedJournal, setSelectedJournal] = useState<any>(null);
  const [planAdoptByGov, setPlanAdoptByGov] = useState<any>(null);
  const [planAdoptByParl, setPlanAdoptByParl] = useState<any>(null);
  const [planEntryIntoForce, setPlanEntryIntoForce] = useState<any>(null);
  const [inForceId, setInForceId] = useState('');
  const [plannedId, setPlannedId] = useState('');
  const [title, setTitle] = useState<any>('');
  const [titleEN, setTitleEN] = useState<any>('');
  const [comment, setComment] = useState('');
  const [existingType, setExistingType] = useState('0');
  const [isNewForceSet, setIsNewForceSet] = useState(true)
  const [type, setType] = useState<any>(null);
  const [pirv, setPirv] = useState('');
  const [defaultInstitution, setDefaultInstitution] = useState<any>(null);
  const [errorMap, setErrorMap] = useState({
    title: '',
    titleEN: '',
    type: '',
    bylaw: '',
    comment: '',
    OJNumber: '',
    OJPagesNumber: '',
    plan: '',
  });
  const [selSubRegister, setSelSubRegister] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checked, setChecked] = React.useState<readonly number[]>([]);
  const [left, setLeft] = React.useState<any[]>([]);
  const [right, setRight] = React.useState<any[]>([]);
  const [editId, setEditId] = useState('');

  const types = [
    { id: 0, name: 'С - Стратешки документ - Влада' },
    // { id: 1, name: 'С - Стратешки документ - Министарство' },
    { id: 2, name: 'С - Стратешки документ - НС' },
    { id: 3, name: 'З - Закон' },
    { id: 4, name: 'В - Подзаконска акта (Влада)' },
    { id: 5, name: 'М - Подзаконска акта (Министарства, НБС ...)' },
  ];
  const handleChange = (e: SelectChangeEvent) => {
    setErrorMap({ ...errorMap, type: '' });
    setType(types.find((t: any) => t.name === e.target.value));
  };

  function intersection(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }
  function not(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  const loadAllInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status === 200) {
      setLeft(resp?.data);
      setInstitutions(resp?.data);
    }
  };

  const loadAllJournals = async () => {
    const resp = await getAllOfficialJournals();

    if (resp?.status === 200) {
      setJournals(resp?.data);
      setSelectedJournal(resp?.data[0]);
    }
  };

  const stringToDate = (str: string | null | undefined) => {
    if (!str) {
      return { q: null, y: null };
    }
    const [y, q] = str?.split('/');
    return { q, y };
  };

  const loadMeasureById = async (id: string) => {
    const resp = await getNatMeasureBy(id);

    if (resp.status === 200) {
      console.log('test', resp?.data);
      setInForceId(resp?.data?.inForceId);
      console.log('inf present');
      if (resp?.data?.inForceId) {
        setExistingType('1');
        setIsNewForceSet(false)

      }
      setPirv(resp?.data?.pirv);
      setTitle(resp?.data?.title);
      setParentTitle(resp?.data?.title);
      setParentInstitution(resp?.data?.institution?.name);

      setTitleEN(resp?.data?.titleEN);
      setComment(resp?.data?.comment);

      setPlanAdoptByGov(stringToDate(resp?.data?.planAdoptByGov));
      setPlanAdoptByParl(stringToDate(resp?.data?.planAdoptByParl));
      setPlanEntryIntoForce(stringToDate(resp?.data?.planEntryIntoForce));

      setAdopByGovDate(resp?.data?.adopByGovDate);
      setDateOfOJ(resp?.data?.dateOfOJ);
      setInForceOJ(resp?.data?.inForceOJ);
      setInUseOJ(resp?.data?.inUseOJ);
      console.log('col te');

      setOJNumber(resp?.data?.OJNumber);
      setOJPagesNumber(resp?.data?.OJPagesNumber);
      setPlannedId(resp?.data?.plannedId);

      setType(types?.find((t) => t.id === resp?.data?.type));
      setDefaultInstitution(resp?.data?.institution);
      setRight(
        resp?.data?.natMeasuresInsts?.map((i: any) => {
          return { id: i.id, name: i.name };
        })
      );
      setAvalible({
        1: resp?.data?.npi,
        2: resp?.data?.ssp,
        3: resp?.data?.euregister,
        4: resp?.data?.np,
        5: resp?.data?.institutions,
      });
    }
  };
  const editNationalMeasure = async () => {
    const resp = await editNatMeasure({
      id: parseInt(editId),
      inForceId: existingType === '1' ? '1' : '',
      isNewForceSet: isNewForceSet,
      title,
      titleEN,
      subMeasure: selSubRegister?.id,
      type: type?.id?.toString(),
      comment,
      adopByGovDate,
      dateOfOJ,
      inForceOJ,
      inUseOJ,
      OJNumber,
      OJPagesNumber,
      ojId: selectedJournal?.id,
      institutions: right?.map((i) => i?.id) ?? null,
      planAdoptByGov: `${planAdoptByGov?.y}/${planAdoptByGov?.q}`,
      planAdoptByParl: `${planAdoptByParl?.y}/${planAdoptByParl?.q}`,
      planEntryIntoForce: `${planEntryIntoForce?.y}/${planEntryIntoForce?.q}`,
    });

    if (resp.status === 201) {
      notif('Пропис је ажуриран', 'success');
      // navigate('/register/management');
    } else {
      notif(resp?.data?.message || 'Пропис није ажуриран', 'error');
    }
  };

  useEffect(() => {
    loadAllInstitutions();
    loadAllJournals();
    let eId = searchParams.get('id');
    if (eId) {
      loadMeasureById(eId);
      setEditId(eId);
    }
  }, []);

  const customList = (items: any[]) => (
    <Paper sx={{ width: 300, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value?.id}
              className={`cursor-pointer ${checked.indexOf(value) !== -1 ? 'bg-[#04438be8] text-white' : ''
                }`}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemText id={labelId} primary={value?.name} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
  const QUARTER_MAP = ['I', 'II', 'III', 'IV'];
  const YEAR_MAP = [1, 2, 3, 4, 5, 6].map((y, i) => {
    return 2022 + i;
  });
  if (!YEAR_MAP.includes(planAdoptByGov?.y)) {
    YEAR_MAP.push(planAdoptByGov?.y);
    YEAR_MAP.sort(); // Sort to maintain chronological order
  }
  const handleJournalChange = (e: any, prm: any) => {
    setSelectedJournal(prm as any);
  };
  const handleRegisterInsert = () => {
    const newErrorMap = {
      title: validator(title, true, true),
      titleEN: validator(titleEN, true, false),
      type: validator(type?.id?.toString(), true, false),
      comment: validator(title, false, true),
      bylaw: '',
      OJNumber: '',
      OJPagesNumber: '',
      plan: checkChronologicalOrder(),
    };
    //Поље је обавезно
    setErrorMap(newErrorMap);
    if (!Object.values(newErrorMap).some((e) => e)) {
      editNationalMeasure();
    }
  };
  const date = new Date();
  const isAdmin = user?.roleView?.name === 'Администратор';
  const CURRENT_YEAR = date.getFullYear();
  const CURRENT_QUARTER = Math.ceil((date.getMonth() + 1) / 3);

  const handleExistingTypeChange = (e: any) => {
    const v = (e.target as HTMLInputElement).value;
    if (v === '1' && !adopByGovDate) {
      notif(
        `Обавезно је унети датум усвајања на
      Влади, министарству, односно у одговарајућој институцији`,
        'error'
      );
    } else {
      setOJNumber(null);
      setOJPagesNumber(null);
      setDateOfOJ(null);
      setInForceOJ(null);
      setInUseOJ(null);
      setExistingType(v);
    }
  };
  const checkChronologicalOrder = () => {
    if (
      (!planAdoptByGov?.q && ![2].includes(type?.id)) ||
      (!planAdoptByGov?.y && ![2].includes(type?.id)) ||
      (!planAdoptByParl?.q && ![2, 4, 5].includes(type?.id)) ||
      (!planAdoptByParl?.y && ![2, 4, 5].includes(type?.id)) ||
      !planEntryIntoForce?.q ||
      !planEntryIntoForce?.y
    ) {
      return 'Сва поља Плана законодавног поступка су обавезна!';
    }

    const govValue =
      parseInt(planAdoptByGov.y) * 4 + QUARTER_MAP.indexOf(planAdoptByGov?.q);
    const parlValue = [4, 5].includes(type?.id)
      ? govValue
      : parseInt(planAdoptByParl.y) * 4 +
      QUARTER_MAP.indexOf(planAdoptByParl?.q);
    const entryValue =
      parseInt(planEntryIntoForce.y) * 4 +
      QUARTER_MAP.indexOf(planEntryIntoForce?.q);

    if (govValue > parlValue || parlValue > entryValue) {
      return 'Усвајања морају бити у хронолошком редоследу!';
    }
    return '';
  };
  console.log('q', isAdmin)
  console.log('q', CURRENT_YEAR)
  console.log('q', CURRENT_QUARTER)
  console.log('q', planAdoptByGov?.y)
  console.log('q', !isAdmin &&
    CURRENT_YEAR === planAdoptByGov?.y)
  // {() => { console.log('q', CURRENT_YEAR); return 1 }}
  // {() => { console.log('q', planAdoptByGov?.y); return 1 }}
  // {() => { console.log('q', i + 1); return 1 }}
  // {() => { console.log('q', CURRENT_QUARTER); return 1 }}
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-6">
        <p className="w-[260px]">Назив прописа</p>
        <TextField
          label="Назив прописа"
          id="outlined-size-small"
          type="institutionName"
          name="institutionName"
          size="small"
          rows={2}
          multiline
          sx={{ width: 550 }}
          helperText={errorMap?.title}
          error={!!errorMap?.title}
          onFocus={() => setErrorMap({ ...errorMap, title: '' })}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
        // error={errorMap?.firstName as boolean}
        // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
        // value={firstName}
        // onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="flex items-center gap-6">
        <p className="w-[260px]">Назив прописа (енглески)</p>
        <TextField
          label="Назив прописа (енглески)"
          id="outlined-size-small"
          type="institutionName"
          name="institutionName"
          size="small"
          rows={2}
          multiline
          sx={{ width: 550 }}
          helperText={errorMap?.titleEN}
          error={Boolean(errorMap?.titleEN)}
          onFocus={() => setErrorMap({ ...errorMap, titleEN: '' })}
          value={titleEN}
          onChange={(e) => setTitleEN(e.target.value)}
        // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
        // error={errorMap?.firstName as boolean}
        // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
        // value={firstName}
        // onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="flex items-center gap-6 my-2">
        <p className="w-[260px]">Планирани/Постојећи Пропис</p>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={existingType}
            onChange={(e) => handleExistingTypeChange(e)}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              label="Планирани пропис"
              disabled={existingType === '1' && !isAdmin}
            />
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Постојећи пропис"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="flex items-center gap-6">
        <p className="w-[260px]">Врста прописа</p>
        <FormControl size="small" sx={{ minWidth: 450 }}>
          <InputLabel id="demo-select-small">Врста прописа</InputLabel>
          <Select
            labelId="demo-select-small"
            label="Врста прописа"
            id="demo-select-small"
            value={type?.name ?? ''}
            onChange={handleChange}
            className="capitalize"
            error={Boolean(errorMap?.type)}
          >
            {types?.map((t) => {
              return (
                <MenuItem value={t?.name} className="capitalize" key={t?.id}>
                  {t?.name}
                </MenuItem>
              );
            })}
          </Select>
          {errorMap?.type && (
            <FormHelperText sx={{ color: 'red' }}>
              {errorMap?.type}
            </FormHelperText>
          )}
        </FormControl>
      </div>
      {[4, 5].includes(type?.id) && (
        <div className="flex items-center gap-6">
          <p className="w-[260px]">Је подзаконски акт прописа</p>
          <div className="flex flex-col">
            <button
              className="h-[40px] w-[450px] border-[#81848f3d] border bg-white hover:bg-[#d9d9d9] rounded-md  cursor-pointer"
              onClick={() => setModalIsOpen(true)}
            >
              {selSubRegister ? (
                <div className="flex px-4 justify-start text-left items-center">
                  <FaSearchMinus className="fill-[#404040]" />
                  <p className="text-darkBlue w-full pl-3">
                    {selSubRegister?.title?.length > 45
                      ? selSubRegister?.title?.substring(0, 45) + '...'
                      : selSubRegister?.title}
                  </p>
                </div>
              ) : (
                <div className="flex px-4 justify-between items-center">
                  <FaSearch className="fill-[#404040]" />
                  <p className="text-[#404040] w-full pl-3">
                    Одабир Националног прописа
                  </p>
                </div>
              )}
            </button>
          </div>
        </div>
      )}
      <div className="flex items-center gap-6 my-3">
        <p className="w-[240px]">
          Други органи који сарађују при припреми прописа
        </p>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item style={{ width: '320px' }}>
            {customList(left)}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{ width: '320px' }}>
            {customList(right)}
          </Grid>
        </Grid>
      </div>
      {existingType === '0' ? (
        <div className="flex items-center gap-6 mt-4">
          <p className="w-[260px]">План законодавног поступка</p>
          <div className="flex items-center gap-14">
            <div className="flex flex-col justify-center">
              <p className="text-[#04438b] text-center  text-sm">
                Усваја Влада/министарство/НБС
              </p>
              <div className="flex justify-start w-full items-center">
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByGov?.q || ''}
                    onChange={(e) => {
                      setPlanAdoptByGov({
                        ...planAdoptByGov,
                        q: e.target.value,
                      });
                    }}
                    // disabled={isAdmin ? false : [4, 5].includes(type?.id)}
                    label="Квартал"
                  >
                    {QUARTER_MAP?.map((q, i) => {
                      return (
                        <MenuItem
                          value={q}
                        // disabled={
                        //   !isAdmin &&
                        //   CURRENT_YEAR === planAdoptByGov?.y &&
                        //   i + 1 < CURRENT_QUARTER
                        // }
                        >
                          {q}

                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByGov?.y || ''}
                    onChange={(e) => {
                      setPlanAdoptByGov({
                        ...planAdoptByGov,
                        y: e.target.value,
                      });
                    }}
                    // disabled={isAdmin ? false : [4, 5].includes(type?.id)}
                    label="Година"
                  >
                    {YEAR_MAP?.map((y) => {
                      return (
                        <MenuItem
                          value={y}
                          disabled={!isAdmin && y < CURRENT_YEAR}
                        >
                          {y}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p
                className={`text-center text-sm ${[4, 5].includes(type?.id)
                  ? 'text-[#6d6d6d]'
                  : 'text-[#04438b]'
                  }`}
              >
                Усваја Скупштина
              </p>
              <div className="flex justify-start w-full items-center">
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByParl?.q || ''}
                    onChange={(e) => {
                      setPlanAdoptByParl({
                        ...planAdoptByParl,
                        q: e.target.value,
                      });
                    }}
                    disabled={[4, 5].includes(type?.id)}
                    label="Квартал"
                  >
                    {QUARTER_MAP?.map((q) => {
                      return <MenuItem value={q}>{q}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planAdoptByParl?.y || ''}
                    onChange={(e) => {
                      setPlanAdoptByParl({
                        ...planAdoptByParl,
                        y: e.target.value,
                      });
                    }}
                    disabled={[4, 5].includes(type?.id)}
                    label="Година"
                  >
                    {YEAR_MAP?.map((y) => {
                      return <MenuItem value={y}>{y}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-[#04438b] text-center text-sm">
                Почетак примене
              </p>
              <div className="flex justify-start w-full items-center">
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Квартал
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planEntryIntoForce?.q || ''}
                    onChange={(e) => {
                      setPlanEntryIntoForce({
                        ...planEntryIntoForce,
                        q: e.target.value,
                      });
                    }}
                    label="Квартал"
                  >
                    {QUARTER_MAP?.map((q) => {
                      return <MenuItem value={q}>{q}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 85 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Година
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={planEntryIntoForce?.y || ''}
                    onChange={(e) => {
                      setPlanEntryIntoForce({
                        ...planEntryIntoForce,
                        y: e.target.value,
                      });
                    }}
                    label="Година"
                  >
                    {YEAR_MAP?.map((y) => {
                      return <MenuItem value={y}>{y}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <p style={{ color: 'red' }}>{errorMap.plan}</p>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-6 my-4">
            <p className="w-[270px]">Службени гласник</p>
            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={selectedJournal}
              size="small"
              onChange={(event: any, newValue: any) =>
                handleJournalChange(event, newValue)
              }
              id="controllable-states-demo"
              options={journals}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 340 }}
              renderInput={(params) => (
                <TextField {...params} label="Службени гласник" />
              )}
            />
            <TextField
              label="Број"
              id="outlined-size-small"
              type="OJNumber"
              name="OJNumber"
              size="small"
              sx={{ width: 180 }}
              helperText={errorMap?.OJNumber}
              error={Boolean(errorMap?.OJNumber)}
              onFocus={() => setErrorMap({ ...errorMap, OJNumber: '' })}
              value={OJNumber}
              onChange={(e) => setOJNumber(e.target.value)}
            />
            <TextField
              label="Редни број објаве у СГ"
              id="outlined-size-small"
              type="OJPageNumber"
              name="OJPageNumber"
              size="small"
              sx={{ width: 200 }}
              helperText={errorMap?.OJPagesNumber}
              error={Boolean(errorMap?.OJPagesNumber)}
              onFocus={() => setErrorMap({ ...errorMap, OJPagesNumber: '' })}
              value={OJPagesNumber}
              onChange={(e) => setOJPagesNumber(e.target.value)}
            />
          </div>
          <div className="flex items-center gap-6 my-4">
            <p className="w-[270px]"></p>
            <div className="flex flex-col justify-center">
              <p className="text-center text-sm text-[#04438b]">
                Датум објаве у СГ
              </p>
              <div className="flex justify-start w-full items-center mt-3">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={srLocale}
                >
                  <DatePicker
                    label="Датум објаве"
                    minDate={adopByGovDate ? adopByGovDate : undefined}
                    value={dateOfOJ ? dateOfOJ : null}
                    inputFormat="dd. MM. yyyy"
                    onChange={(newValue) => {
                      setDateOfOJ(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" sx={{ width: 240 }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-center text-sm text-[#04438b]">
                Ступање на снагу
              </p>
              <div className="flex justify-start w-full items-center mt-3">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={srLocale}
                >
                  <DatePicker
                    label="Датум ступања на снагу"
                    minDate={dateOfOJ ? dateOfOJ : undefined}
                    value={inForceOJ}
                    inputFormat="dd. MM. yyyy"
                    onChange={(newValue) => {
                      setInForceOJ(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" sx={{ width: 240 }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-center text-sm text-[#04438b]">
                Почетак примене
              </p>
              <div className="flex justify-start w-full items-center mt-3">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={srLocale}
                >
                  <DatePicker
                    label="Датум примене"
                    minDate={inForceOJ ? inForceOJ : undefined}
                    value={inUseOJ}
                    inputFormat="dd. MM. yyyy"
                    onChange={(newValue) => {
                      setInUseOJ(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField size="small" sx={{ width: 240 }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center gap-6 my-4">
        <p className="w-[270px]">
          Усвојено на <br /> Влади/министарству дана
        </p>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={srLocale}
        >
          <DatePicker
            label="Датум усвајања"
            disabled={existingType === '1' && !isAdmin}
            value={adopByGovDate}
            inputFormat="dd. MM. yyyy"
            onChange={(newValue) => {
              setAdopByGovDate(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" sx={{ width: 240 }} {...params} />
            )}
          />
        </LocalizationProvider>
      </div>
      <div className="flex items-center gap-6">
        <p className="w-[260px]">Коментар</p>
        <TextField
          label="Коментар"
          id="outlined-size-small"
          type="institutionName"
          name="institutionName"
          size="small"
          rows={2}
          multiline
          sx={{ width: 550 }}
          helperText={errorMap?.comment}
          error={Boolean(errorMap?.comment)}
          onFocus={() => setErrorMap({ ...errorMap, comment: '' })}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        // helperText={errorMap?.firstName ? 'Поље је обавезно' : ''}
        // error={errorMap?.firstName as boolean}
        // onFocus={() => setErrorMap({ ...errorMap, firstName: false })}
        // value={firstName}
        // onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      {existingType === '1' && (
        <div className="flex flex-col space-y-2">
          <h4 className="text-blue-700 font-bold text-lg text-[#08305d]">
            План законодавног поступка
          </h4>
          <div className="flex space-x-4 text-[#04438b]">
            <p className="text-blue-700">
              {planAdoptByGov
                ? `Усваја Влада/министарство/НБС: ${planAdoptByGov.q}/${planAdoptByGov.y}`
                : 'Усваја Влада/министарство/НБС: '}
            </p>
            <p className="text-blue-700">
              {planAdoptByParl
                ? `Усваја Народна скупштина: ${planAdoptByParl.q}/${planAdoptByParl.y}`
                : 'Усваја Народна скупштина: '}
            </p>
            <p className="text-blue-700">
              {planEntryIntoForce
                ? `Почетак примене: ${planEntryIntoForce.q}/${planEntryIntoForce.y}`
                : 'Почетак примене: '}
            </p>
          </div>
        </div>
      )}{' '}
      {pirv && (
        <div className="flex flex-col justify-between text-[#04438b]">
          <h4 className="text-blue-700 font-bold text-lg text-[#08305d]">
            ПИРВ назив прописа
          </h4>
          <p className="text-blue-700 text-sm">{pirv}</p>
        </div>
      )}
      <div className="w-full flex justify-between items-center">
        <div className="flex flex-col justify-between text-[#04438b]">
          <h4 className="text-blue-700 font-bold text-lg text-[#08305d]">
            Ознака прописа
          </h4>
          <p className="text-blue-700">
            {inForceId ? `Ознака прописа: ${inForceId}` : ''}
          </p>
          <p className="text-red-700">
            {plannedId ? `Ознака прописа (Планираног): ${plannedId}` : ''}
          </p>
        </div>

        <Button
          variant="contained"
          sx={{ backgroundColor: '#0F2765' }}
          endIcon={<FaSave />}
          onClick={handleRegisterInsert}
        >
          Потврди
        </Button>
      </div>
      <ChooseNatRegisterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        setSelRegister={setSelSubRegister}
      />
    </div>
  );
}

export default EditNPI;
