import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowId } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import { FaEdit, FaFile, FaFileArchive, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getOfficialJournals, getTUList } from '../../../server/server';
import EditTUFileModal from '../../modals/EditTUFileModal';
import HelpPageModal from '../../modals/HelpPageModal';
import HelpButton from '../../../common/HelpButton';

function TUManagement() {
	let navigate = useNavigate();
	const [celex, setCelex] = useState('');
	const [tuModalIsOpen, setTuModalIsOpen] = useState(false);
	const [natRegister, setNatRegister] = useState('');
	const [files, setFiles] = useState<any>({ id: 0, name: 'Сви' });
	const [registers, setRegisters] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [rowCountState, setRowCountState] = useState(6);
	const [isLoading, setIsLoading] = useState(true);
	const [selTU, setSelTU] = useState<any>();
	const [translation, setTranslation] = useState<any>();

	const [helpModal, setHelpModal] = useState(false);
	const closeHelpModal = () => {
		setHelpModal(false);
	};

	const deleteUser = React.useCallback(
		(id: GridRowId) => () => {
			setTimeout(() => {
				setRegisters((prevRows: any) => prevRows.filter((row: any) => row.id !== id));
			});
		},
		[]
	);
	// const loadOJ = async (offset: any = 0) => {
	//   const resp = await getOfficialJournals(offset, 8, term);
	//   if (resp?.status === 200) {
	//     setIsLoading(false);
	//     setRowCountState(resp.data?.total);
	//     setRegisters(resp.data?.journals);
	//   }
	// };

	const loadTU = async (offset: any = 0) => {
		const resp = await getTUList(offset, pageSize, celex, natRegister, files?.id);

		if (resp?.status === 200) {
			setRegisters(resp?.data?.items);
			setRowCountState(resp?.data?.total);
			setIsLoading(false);
		}
	};
	const closeTUModal = () => {
		setTuModalIsOpen(false);
	};

	const setPageAndCall = (page: any) => {
		setPage(page);
		loadTU(page * pageSize);
	};
	const editUser = React.useCallback(
		(tu: any) => () => {
			setSelTU(tu);
			setTuModalIsOpen(true);
		},
		[]
	);

	const columns = React.useMemo<GridColumns<any>>(
		() => [
			{
				field: 'pid',
				headerName: 'Шифра планираног прописа',
				maxWidth: 260,
				minWidth: 180,
				flex: 1,
			},
			{
				field: 'fid',
				headerName: 'Шифра постојећег прописа',
				maxWidth: 260,
				minWidth: 180,
				flex: 1,
			},
			{
				field: 'title',
				headerName: 'Назив домаћег прописа',
				flex: 0.1,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 60,
				getActions: (params: any) => [
					<GridActionsCellItem
						icon={
							<FaFile
								style={{
									width: 20,
									height: 20,
									color: params?.row?.file ? '#0f2765' : 'gray',
								}}
							/>
						}
						sx={{ width: 50, height: 50 }}
						label='Ажурирај'
						onClick={editUser(params.row)}
					/>,
				],
			},
		],
		[deleteUser, editUser]
	);
	useEffect(() => {
		loadTU();
	}, [files, celex, natRegister]);

	const translations = [{ id: 1, name: 'Без Превода' }];

	const handleTranslationsChange = (e: SelectChangeEvent) => {
		const t = translations.find((r) => r?.name === e.target.value);
		setTranslation(t as any);
	};

	const FILE_MAP = [
		{ id: 0, name: 'Сви' },
		{ id: 1, name: 'Приложена датотека' },
		{ id: 2, name: 'Нема датотеке' },
	];
	return (
		<div className='py-10 px-2 overflow-auto gray-small-scroll h-full'>
			<div className='flex gap-6'>
				<h1 className='text-[#04438b] text-4xl font-bold'>Управљање Табела Усклађености</h1>
				<HelpButton onClick={() => setHelpModal(true)} />
			</div>
			<div className='rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4  mt-8 '>
				<h1 className='text-[#04438bae] text-3xl font-bold'>Претрага</h1>
				<div className='w-full mt-6 flex items-center gap-12'>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По CELEX-у</p>
						<TextField label='CELEX' type='CelexNo' name='CelexNo' id='outlined-size-small' size='small' value={celex} onChange={(e) => setCelex(e.target.value)} />
					</div>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[180px] text-sm text-[#404040]'>По Назив домаћег прописа</p>
						<TextField label='Назив прописа' id='outlined-size-small' size='small' autoComplete='off' sx={{ width: 340 }} value={natRegister} onChange={(e) => setNatRegister(e.target.value)} />
					</div>
					<div className='flex flex-col items-start gap-3'>
						<p className='w-[160px] text-sm text-[#404040]'>По Доступности датотеке</p>
						<FormControl size='small' sx={{ minWidth: 140 }}>
							<InputLabel id='demo-select-small'>Датотеке</InputLabel>
							<Select labelId='demo-select-small' id='demo-select-small' value={files?.name} onChange={(e) => setFiles(FILE_MAP?.find((f) => f.name === e.target.value))} label='Датотеке' className='capitalize'>
								{FILE_MAP?.map((t) => {
									return (
										<MenuItem value={t?.name} className='capitalize' key={t?.id}>
											{t?.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className='mt-5 h-[530px] w-full'>
					<DataGrid
						rowCount={rowCountState}
						loading={isLoading}
						pagination
						disableColumnMenu
						paginationMode='server'
						onPageChange={(newPage) => setPageAndCall(newPage)}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						page={page}
						pageSize={pageSize}
						rows={registers}
						columns={columns}
						style={{ zIndex: 0 }}
						rowsPerPageOptions={[5]}
						disableSelectionOnClick
						components={{
							NoRowsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје службени гласници са задатим критеријумом
								</Stack>
							),
							NoResultsOverlay: () => (
								<Stack height='100%' alignItems='center' justifyContent='center'>
									Не постоје службени гласници са задатим критеријумом
								</Stack>
							),
						}}
					/>
				</div>
			</div>
			<EditTUFileModal modalIsOpen={tuModalIsOpen} closeModal={closeTUModal} confirmation={loadTU} fileId={selTU?.file} id={selTU?.id} />
			<HelpPageModal modalIsOpen={helpModal} closeModal={closeHelpModal} name={'tuManagement'} />
		</div>
	);
}

export default TUManagement;
