import React, { useEffect, useState } from 'react';
import { useFocus } from '../../../../common/useFocus';
import { Autocomplete, TextField } from '@mui/material';
import { getAllInstitutions } from '../../../../server/server';
import ChooseNPI from '../../../modals/ChooseNPI';

function InfoTable({
  allType,
  text,
  years,
  setYears,
  data,
  setData,
  selRegister,
  pid,
  setPid,
  defaultInstitution,
  setDefaultInstitution,
  groupNPI,
  setGroupNPI,
}: any) {
  const types = allType?.map((t: any) => t?.name);
  const getInputProps = useFocus();
  const [institutions, setInstitutions] = useState<any>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const updateValue = (
    year: number | 'Kasnije',
    type: string,
    value: string
  ) => {
    // Remove thousand separators before parsing

    const sanitizedValue = value.replace(/\./g, '').replace(',', '.');
    let parsedValue = parseFloat(sanitizedValue);
    if (isNaN(parsedValue)) {
      parsedValue = 0.0;
    }
    setData((prevData: any) => {
      let newData = [...prevData];
      let actualYear = year === 'Kasnije' ? 3000 : year;

      let itemIndex = newData.findIndex(
        (item) => item.year === actualYear && item.type === type
      );

      if (itemIndex > -1) {
        newData[itemIndex].value = parsedValue;
      } else {
        newData.push({ year: actualYear, type, value: parsedValue });
      }

      return newData;
    });
  };
  const loadInstitutions = async () => {
    const resp = await getAllInstitutions();
    if (resp?.status) {
      setInstitutions(resp?.data);
    }
  };
  const closeDeleteModal = () => {
    console.log('here close  modal');
    setModalIsOpen(false);
  };
  const handleInstChange = (e: any, prm: any) => {
    setDefaultInstitution(prm as any);
  };
  useEffect(() => {
    loadInstitutions();
  }, []);

  return (
    <div className="overflow-hidden rounded-lg border border-[#81848f3d]">
      <div
        className={`flex divide-x divide-[#81848f3d] text-[#0f2765] bg-[#c3c3d6] text-left text-xs font-medium uppercase tracking-wider justify-items-center`}
      >
        <div className="px-4 py-3 w-[50%]  flex items-left font-bold justify-start">
          {text}
        </div>
      </div>

      <div className="divide-y divide-[#81848f3d] text-sm">
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            Предлагач
          </div>
          <div
            className={`flex items-center justify-start whitespace-nowrap text-sm text-gray-500 w-full `}
          >
            <p className="leading-10 text-start text-[#04438b]">
              <span className="font-bold"> {selRegister?.institution}</span>
            </p>
          </div>
        </div>
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            1.1 Организациона шифра:
          </div>
          <div
            className={`flex items-center justify-center whitespace-nowrap text-sm text-gray-500 w-full `}
          >
            <input
              type="text"
              className={`w-full h-full`}
              value={pid}
              onChange={(e) => setPid(e.target.value)}
            />
          </div>
        </div>
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            Назив институције надлежне за <br /> „правни акт / инвестицију“
          </div>
          <div
            className={`flex items-center justify-start whitespace-nowrap text-sm text-gray-500 w-full `}
          >
            <Autocomplete
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              value={defaultInstitution} // Corrected here
              size="small"
              onChange={(event: any, newValue: any) =>
                handleInstChange(event, newValue)
              }
              id="controllable-states-demo"
              options={institutions}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 450 }}
              renderInput={(params) => (
                <TextField {...params} label="Институција" />
              )}
            />
          </div>
        </div>
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            1.2 Број поглавља:
          </div>
          <div
            className={`flex cursor-pointer items-center justify-start whitespace-nowrap text-sm text-gray-500 w-full `}
            onClick={() => setModalIsOpen(true)}
          >
            {groupNPI?.name ? (
              groupNPI?.name.match(/[0-9.]+/g)
            ) : (
              <p className="italic bold text-[#04438b]"> Одабир НПАА групе</p>
            )}
          </div>
        </div>
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            Назив поглавља:
          </div>
          <div
            className={`flex items-center justify-start whitespace-nowrap text-sm text-gray-500 w-full `}
          >
            {groupNPI?.name.match(/[^\d.]+/g)}
          </div>
        </div>
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            1.3 Шифра акта:
          </div>
          <div
            className={`flex items-center justify-start whitespace-nowrap text-sm text-gray-500 w-full `}
          >
            <p className="leading-10 text-start text-[#04438b]">
              <span className="font-bold"> {selRegister?.pid || selRegister?.fid || selRegister?.ppid || selRegister?.pfid}</span>
            </p>
          </div>
        </div>
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            1.4 Назив акта:
          </div>
          <div
            className={`flex items-center justify-start text-sm text-gray-500 w-full`}
          >
            <p className="leading-5 text-start text-[#04438b] break-word">
              <span className="font-bold"> {selRegister?.title}</span>
            </p>
          </div>
        </div>{' '}
        <div className={`flex divide-x divide-[#81848f3d]`}>
          <div
            className={`px-4 py-2 whitespace-nowrap text-sm text-gray-500 w-1/4 `}
          >
            Назив ЕУ прописа са <br /> којим се врши усклађивање:
          </div>
          <div
            className={`flex items-center justify-start whitespace-nowrap text-sm text-gray-500 w-full `}
          >
            <p className="leading-10 text-start text-[#04438b]">
              <span className="font-bold"> {selRegister?.celex}</span>
            </p>
          </div>
        </div>
      </div>
      <ChooseNPI
        modalIsOpen={modalIsOpen}
        closeModal={closeDeleteModal}
        setSelNPIGroup={setGroupNPI}
        register={selRegister}
        type={''}
      />
    </div>
  );
}

export default InfoTable;
